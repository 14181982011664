import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormikContext } from "formik";
import React, { Fragment, useCallback, useState } from "react";
import { useEffect } from "react";
import { Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  ENROLLMENT_ACTIONS,
  ORDER_FROM,
  placeOrder,
  setTermsMutation,
} from "../../../graphql/Mutations";
import { requiresTermsQuery } from "../../../graphql/Queries";
import useCheckOrder from "../../../hooks/useCheckOrder";
import useIncontact from "../../../hooks/useIncontact";
import useSummary from "../../../hooks/useSummary";
import useZuora from "../../../hooks/useZuora";
import { termsConditionsMap } from "../../../utility/util";
import FooterButtons from "../components/FooterButtons";
import Header from "../components/Header";
import ProcessingOrderModal from "../components/ProcessingOrderModal";
import StatusModal from "../components/StatusModal";
import Summary from "../components/Summary";
import { EligibilityFlow, FormikWizardState } from "../types";

const Checkout = ({ previous }: { previous?: () => void }) => {
  const [paymentMethod, setPaymentMethod] = useState("CREDIT");
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const { error: summaryError, forceRefresh } = useSummary();
  const history = useHistory();
  const { values } = useFormikContext<FormikWizardState<EligibilityFlow>>();

  const { success, error, start, reset } = useCheckOrder();

  const {
    isImpersonated,
    loading: csrLoading,
    error: csrError,
    canContinue,
    setMaskDisabled,
  } = useIncontact();

  const [placeOrderMutation] = useMutation(placeOrder, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const phase = data.enrollmentPlaceOrderAction.state.phase;
      if (phase && phase === "PERFORM") {
        start();
      }
    },
    onError: useCallback(() => {
      setShow(false);
      setShowError(true);
    }, []),
  });

  const [termsRequired] = useLazyQuery(requiresTermsQuery);

  const [setTermsAndConditions] = useMutation(setTermsMutation);

  const onSuccess = useCallback(async (res) => {
    if (res.refId) {
      try {
        const req = await termsRequired();
        if (req.data.enrollment.state.enrollmentTermsConditionsRequired) {
          //reenrollment requires terms be submitted on checkout
          await setTermsAndConditions({
            variables: {
              terms: termsConditionsMap(values.membership!).join(","),
            },
          });
        }
        placeOrderMutation({
          variables: {
            from: ORDER_FROM.PREVIEW,
            action: ENROLLMENT_ACTIONS.NONE,
            paymentMethod: res.refId,
          },
        });
      } catch {
        setShow(false);
        setShowError(true);
      }
    }
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 10), 1000));
    setShow(true);
    return;
  }, []);
  const onSubmissionFailed = useCallback(() => {
    setShow(false);
    setShowError(true);
    return;
  }, []);
  const onRequestFailed = useCallback(() => {
    setShow(false);
    setShowError(true);
    return;
  }, []);

  const { render, handleSubmit, hasRendered } = useZuora({
    paymentType: paymentMethod,
    onSuccess,
    onSubmissionFailed,
    onRequestFailed,
  });

  useEffect(() => {
    (async () => {
      if (success) {
        localStorage.removeItem("enrollment-flow");
        if (isImpersonated) {
          try {
            await setMaskDisabled();
            sessionStorage.removeItem("enrollment-flow");
            sessionStorage.removeItem("pipeline-session-id");
            history.push("/success");
          } catch (e) {
            setShow(false);
            setShowError(true);
          }
        } else {
          sessionStorage.removeItem("enrollment-flow");
          sessionStorage.removeItem("pipeline-session-id");
          history.push("/success");
        }
      }
      if (error) {
        if (isImpersonated) await setMaskDisabled();
        setShow(false);
        setShowError(true);
      }
    })();
  }, [success, error]);

  useEffect(() => {
    forceRefresh();
  }, []);

  useEffect(() => {
    if (show && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, show]);

  useEffect(() => {
    if (!csrLoading) {
      render();
    }
  }, [render, paymentMethod, csrLoading]);

  const handleCancelError = () => {
    reset();
    setShowError(false);
  };
  const handlePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(e.target.value);
  };
  const handlePrevious = async () => {
    if (isImpersonated) await setMaskDisabled();
    if (previous) previous();
  };

  const CSRState = () => {
    if (csrLoading) {
      <div className="center-loading">
        <Spinner animation="border" />
      </div>;
    }
    if (csrError) {
      return (
        <div>
          <h5 style={{ color: "red" }}>
            Unable to load page due to call recording error
          </h5>
        </div>
      );
    }
    if (!canContinue) {
      return (
        <div>
          <h5>
            Incontact requires a valid sessionId for masking billing data.
          </h5>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Row>
        <Col xs={12} md={7}>
          <Header
            title="Add Payment Method*"
            subtitle="How would you like to pay? Choose your preferred monthly payment method"
          />

          <Card>
            {csrLoading || csrError || !canContinue ? (
              <Fragment>{CSRState()}</Fragment>
            ) : (
              <Card.Body
                className="checkout-card"
                style={hasRendered ? { padding: "24px" } : { padding: "8px" }}
              >
                <Form.Group className="mb-1">
                  <Form.Label>PAYMENT METHOD</Form.Label>
                  <Form.Control as="select" onChange={handlePaymentMethod}>
                    <option value="CREDIT">Credit Card</option>
                    <option value="ECHECK">eCheck</option>
                  </Form.Control>
                </Form.Group>
                {!hasRendered && !summaryError && (
                  <div className="zuora-loading">
                    <Spinner animation="border" />
                  </div>
                )}
                {summaryError && (
                  <div className="zuora-center-error">
                    <h2>
                      Sorry, there was an error loading this payment page.
                    </h2>
                  </div>
                )}
                {!summaryError && !csrError && <div id="zuora_payment"></div>}
              </Card.Body>
            )}
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Summary />
        </Col>
      </Row>
      <FooterButtons
        onSubmit={handleSubmit}
        onBack={handlePrevious}
        submitDisabled={!hasRendered || error}
        submitText="Submit"
      />
      <ProcessingOrderModal
        show={show}
        progress={progress}
        onClose={() => {
          return;
        }}
      />
      <StatusModal
        show={showError}
        status={"ERROR"}
        message=""
        onCancel={handleCancelError}
        onNext={() => {
          return;
        }}
      />
    </div>
  );
};

export default Checkout;
