import React, { Fragment, useEffect, useState } from "react";
import { client, contentfulConfig } from "../client";
import Section from "../components/terms/Section";
import { TermsOfUse, TermsOfUseSection } from "../types";
import { convertToTerms } from "../utility/contentful";
import { ITermsOfUseFields } from "../@types/generated/contentful";

const Terms = ({ home }: { home: string }) => {
  const [content, setContent] = useState<TermsOfUse | null>(null);
  const [storedHash, setStoredHash] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      const entry = await client.getEntry<ITermsOfUseFields>(
        contentfulConfig.ids.privacy,
        {
          include: 10,
        }
      );
      setContent(convertToTerms(entry));
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (content) {
      if (storedHash) {
        window.location.hash = storedHash;
      }
    } else {
      setStoredHash(window.location.hash === "" ? null : window.location.hash);
      window.location.hash = "";
    }
  }, [content]);

  if (!content) {
    return null;
  }
  return (
    <Fragment>
      <p>
        <a href={home} className="a-link">
          Home
        </a>
        {" > "}Privacy Policy
      </p>
      {/* <h1>PRIVACY POLICY AND TERMS OF USE</h1> */}
      {/* <span>Last updated: {content.lastUpdated}</span> */}
      {content.sections.map((section: TermsOfUseSection) => (
        <Section {...section} key={section.header} />
      ))}
    </Fragment>
  );
};
export default Terms;
