import React, { useState, useEffect } from "react";
// import ActivityFilters from "./ActivityFilters";
import ActivityListTile from "../Activity/ActivityListTile";
// import { ActivityPagination } from "./ActivityPagination";
import { Tile } from "./Tile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { Button, Spinner } from "react-bootstrap";
import { fetchActivities } from "../../../api/enrollment/Queries";
import ActivitiesTileSVG from "../../../resources/ActivitiesTileSVG";
import { Link } from "react-router-dom";
import { momentStartOfDay } from "../../../utility/util";

export interface IActivity {
  activeLivingMemberId: string;
  class?: string;
  location: string;
  time: string;
  date: string;
  type: string;
  visitType: string;
}
type IState = IActivity[];

const ActivityLogTile = () => {
  const today = momentStartOfDay().format("l");
  const now = momentStartOfDay();
  const defaultStartDate = now.subtract(1, "y").format("l");
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [selectedActivities, setSelectedActivities] = useState<IState>([]);
  const [loadingActivityLog, setLoadingActivityLog] = useState(true);

  const getActivities = async (endDate?: string, startDate?: string) => {
    try {
      setLoadingActivityLog(true);
      const activityList = await fetchActivities(startDate, endDate);
      setSelectedActivities(activityList.activities);
    } catch (error) {
      setSelectedActivities([]);
    } finally {
      setLoadingActivityLog(false);
    }
  };

  useEffect(() => {
    getActivities(today, defaultStartDate);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currentActivityPage")) {
      const currentActivityPage = localStorage.getItem("currentActivityPage");
      setCurrentPageNum(Number(currentActivityPage));
    }
    return () => {
      localStorage.removeItem("currentActivityPage");
    };
  }, []);

  return (
    <Tile className="widget__card">
      <Tile.THeader>
        <div>
          <FontAwesomeIcon icon={faRunning} /> <b>ACTIVITY LOG</b>
        </div>
        <Link to="/account/activity" className="a-link">
          <span>View Full Activity Log</span>
        </Link>
      </Tile.THeader>

      <Tile.TBody>
        {loadingActivityLog ? (
          <div className="d-flex justify-content-center align-items-center m-2">
            <Spinner animation="border" />
          </div>
        ) : !selectedActivities || selectedActivities.length === 0 ? (
          <div
            style={{
              display: "flex",
              marginBottom: "0",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ActivitiesTileSVG />
            <h5 className="text-center mb-0 mt-3">No logged activity yet</h5>
            <p style={{ padding: "0" }} className="text-center">
              Visits will show up here when you start visiting participating
              locations.
            </p>
          </div>
        ) : (
          <>
            <ActivityListTile
              activities={selectedActivities}
              currentPageNum={currentPageNum}
            />
          </>
        )}
      </Tile.TBody>
      {loadingActivityLog ? null : !selectedActivities ||
        selectedActivities.length === 0 ? (
        <Tile.TFooter>
          <Button
            as={Link}
            to="/locations"
            variant="outline-primary"
            className="widget__btn align-self-center mb-3 m-lg-0 widget__link-button text-center"
          >
            View Locations Near You
          </Button>
        </Tile.TFooter>
      ) : null}
    </Tile>
  );
};

export default ActivityLogTile;
