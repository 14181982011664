import * as yup from "yup";
import {
  IAddressFields,
  IAddressForm,
  ICheckoutForm,
  IEligibilityFormProfileBCBSA,
  IEligibilityFormProfileBSC,
  IMembershipForm,
  IPackageSelectForm,
} from "../types";
import { ModelValidations } from "./ModelValidations";

export namespace FormValidation {
  export const BscValidation: yup.SchemaOf<IEligibilityFormProfileBSC> =
    ModelValidations.BscValidation.shape({
      privacyPolicy: yup
        .bool()
        .oneOf([true], "Please agree to the terms and conditions")
        .defined(),
    }).defined();

  export const FEPValidation: yup.SchemaOf<IEligibilityFormProfileBSC> =
    ModelValidations.FEPValidation.shape({
      privacyPolicy: yup
        .bool()
        .oneOf([true], "Please agree to the terms and conditions")
        .defined(),
    }).defined();

  export const BcbsaValidation: yup.SchemaOf<IEligibilityFormProfileBCBSA> =
    ModelValidations.BcbsaValidation.shape({
      privacyPolicy: yup
        .bool()
        .oneOf([true], "Please agree to the terms and conditions")
        .defined(),
      contactInfo: yup
        .bool()
        .oneOf([true], "Please agree to the terms and conditions")
        .defined(),
    }).defined();

  export const PackageValidation: yup.SchemaOf<IPackageSelectForm> =
    ModelValidations.PackageValidation.shape({}).defined();

  export const MembershipValidation: yup.SchemaOf<IMembershipForm> =
    ModelValidations.MembershipValidation.shape({
      fitnessPolicy: yup
        .bool()
        .oneOf([true], "Please agree to the terms and conditions"),
    }).defined();

  export const AddressFieldsValidation: yup.SchemaOf<IAddressFields> =
    ModelValidations.AddressFieldsValidation.shape({}).defined();

  export const AddressFieldsValidationNotRequired: yup.SchemaOf<IAddressFields> =
    ModelValidations.AddressFieldsValidation.shape({}).defined();

  export const AddressValidation: yup.SchemaOf<IAddressForm> =
    ModelValidations.AddressValidation.shape({});

  export const CheckoutValidation: yup.SchemaOf<ICheckoutForm> =
    ModelValidations.CheckoutValidation.shape({}).defined();
}
