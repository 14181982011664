import { gql } from "@apollo/client";

export const checkActionMutation = gql`
  mutation eligibilityCheck($result: String) {
    eligibilityCheckCheckAction(action: { dummy: $result }) {
      state {
        status
        statusDetail
      }
    }
  }
`;

export const selectActionMutation = gql`
  mutation SelectActionMutation($client: String) {
    clientProfileSelectAction(action: { client: $client }) {
      state {
        client
        clientStatus
      }
    }
  }
`;

export const setProfileMutation = gql`
  mutation SetProfile($profile: ProfileInput) {
    eligibilityCheckSetProfileAction(action: { profile: $profile }) {
      state {
        status
        statusDetail
      }
    }
  }
`;

export const enrollMutation = gql`
  mutation EnrollAction {
    eligibilityCheckSetToEnrollmentAction {
      state {
        parentProfile {
          firstName
          lastName
          subscriberId
        }
        profile {
          firstName
          lastName
          subscriberId
          dob
        }
        status
        statusDetail
      }
    }
  }
`;

export const handoffEnrollment = gql`
  mutation HandoffToEnrollment {
    eligibilityCheckHandoffToEnrollmentAction(action: {}) {
      state {
        client
        eligibilityFlow
        status
        statusDetail
      }
    }
  }
`;

export const addressEnrollment = gql`
  mutation EnrollmentAddress($id: String, $type: String) {
    enrollmentSetAddressAction(action: { id: $id, type: $type }) {
      state {
        orderInput {
          billingAddressId
          shippingAddressId
        }
      }
    }
  }
`;

export const paymentMethod = gql`
  mutation PaymentMethod(
    $paymentMethod: String
    $name: String
    $number: String
    $expiration: String
    $cvv: String
  ) {
    enrollmentPayment(
      action: {
        paymentMethod: $paymentMethod
        name: $name
        number: $number
        expiration: $expiration
        cvv: $cvv
      }
    ) {
      state {
        payment {
          paymentMethod
          name
          number
          expiration
          cvv
        }
      }
    }
  }
`;

export const checkPreview = gql`
  mutation checkPreview($force: Boolean) {
    enrollmentPreviewOrderAction(action: { force: $force }) {
      state {
        orderHandle
        orderHandleMessage
        orderHandleStatus
        orderId
        orderInput {
          sku
        }
        order {
          handle
          onetimeDiscount
          onetimeSubtotal
          onetimeTaxes
          onetimeTotal
          periodicDiscount
          periodicSubtotal
          periodicTotal
          taxCalculated
        }
        paymentPageCc {
          hostedPaymentPageId
          hostedPaymentPageUrl
          key
          signature
          tenantId
          token
        }
        paymentPageECheck {
          hostedPaymentPageId
          hostedPaymentPageUrl
          key
          signature
          tenantId
          token
        }
        phase
      }
    }
  }
`;

export const setMembershipMutation = gql`
  mutation SetMembership($email: String, $password: String) {
    enrollmentMembership(action: { email: $email, password: $password }) {
      state {
        membership {
          email
          password
        }
      }
    }
  }
`;

export const setFEPEmailMutation = gql`
  mutation setEmail($email: String) {
    eligibilityCheckSetEmailAction(action: { email: $email }) {
      state {
        profileStatus
      }
    }
  }
`;

export const eligibilityStart = gql`
  mutation EligibilityStart($client: String!) {
    eligibilityCheckStartAction(action: { client: $client }) {
      state {
        eligibilityScope
      }
    }
  }
`;

export enum ORDER_FROM {
  ACTION = "ACTION",
  PREVIEW = "PREVIEW",
}

export const placeOrder = gql`
  mutation PlaceOrder($action: String, $from: String, $paymentMethod: String) {
    enrollmentPlaceOrderAction(
      action: { action: $action, from: $from, paymentMethod: $paymentMethod }
    ) {
      state {
        orderId
        phase
      }
    }
  }
`;

export const setPromoCodeMutation = gql`
  mutation SetPromoCode($promo: String) {
    enrollmentSetPromoCodeAction(action: { promoCode: $promo }) {
      state {
        order {
          promoCodes {
            code
            status
            description
          }
        }
      }
    }
  }
`;

export enum ENROLLMENT_ACTIONS {
  CANCEL = "CANCEL",
  CHANGE = "CHANGE",
  DISCARD = "DISCARD",
  NEW = "NEW",
  NONE = "",
}
export enum ENROLLMENT_STATUSES {
  TRANSITIONING = "TRANSITIONING",
  CANCELLING = "CANCELLING",
}

export const enrollmentStartMutation = gql`
  mutation EnrollmentStartMutation($action: String, $sku: String) {
    enrollmentStartEnrollmentAction(action: { action: $action, sku: $sku }) {
      state {
        orderInput {
          sku
        }
      }
    }
  }
`;

export const enrollmentResumeMutation = gql`
  mutation enrollmentResumeQuery($dummy: String) {
    enrollmentResumeEnrollmentAction(action: { dummy: $dummy }) {
      state {
        client
      }
    }
  }
`;

export const setTermsMutation = gql`
  mutation setTermsMutation($terms: String) {
    enrollmentSetTermsConditionsAction(action: { termsConditions: $terms }) {
      state {
        client
      }
    }
  }
`;
