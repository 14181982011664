import React from "react";

import { useTheme } from "react-jss";
import { ToastContainer, toast } from "react-toastify";

import Map from "../Map";
import SidePane from "../SidePane";
import Filters from "../Filters";
import useStyles from "./styles";
import LocationsProvider from "../../../context/LocationsProvider";
import ModalSearch from "../ModalSearch";

const AppContainer = (props) => {
  const { tier, ...rest } = props;
  const classes = useStyles({ ...rest, theme: useTheme() });

  return (
    <LocationsProvider packageSelected={tier}>
      <div className={classes.locationFinder}>
        <SidePane />
        <div className={classes.locationFinderMapSearch}>
          <ModalSearch />
          <Filters />
          <Map />
        </div>
      </div>
      <div className={classes.toastWrapper}>
        <ToastContainer
          position={toast.POSITION.BOTTOM_LEFT}
          toastClassName={classes.toast}
          bodyClassName={classes.toastBody}
          progressClassName={classes.toastProgress}
          closeOnClick={true}
          autoClose={5000}
          pauseOnHover={false}
        />
      </div>
    </LocationsProvider>
  );
};

export default AppContainer;
