import { useMutation } from "@apollo/client";
import { getIn, setNestedObjectValues, useFormikContext } from "formik";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
} from "react-bootstrap";
import { setTermsAndConditions } from "../../../api/identity/Queries";
import { UserContext, USER_STATUS } from "../../../context/UserProvider";
import {
  checkActionMutation,
  handoffEnrollment,
  setProfileMutation,
} from "../../../graphql/Mutations";
import { tags } from "../../../utility/contentful";
import { getDays, getMonths, getYears } from "../../../utility/SelectDates";
import { termsConditionsMap } from "../../../utility/util";
import AlreadyEligibleToast from "../components/AlreadyEligibleToast";
import CardImagePortals from "../components/CardImagePortals";
import FooterCard from "../components/FooterCard";
import Header from "../components/Header";
import HelperCard from "../components/HelperCard";
import StatusModal from "../components/StatusModal";
import { FormikWizardState, IEligibilityFormProfileBCBSA } from "../types";

const Eligibility = ({ next }: { next?: () => void }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setTouched,
  } = useFormikContext<FormikWizardState<IEligibilityFormProfileBCBSA>>();

  const { user, setUser } = useContext(UserContext);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [cardOneRef, setCardOneRef] = useState<HTMLDivElement | null>(null);
  const [refs, setRefs] = useState<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    setRefs([cardOneRef]);
  }, [cardOneRef]);

  useEffect(() => {
    if (values.eligibility?.completed && next) {
      next();
    }
  }, []);

  // eslint-disable-next-line
  const [check] = useMutation(checkActionMutation, {
    onCompleted: useCallback((data) => {
      if (data) {
        setMessage(data.eligibilityCheckCheckAction?.state?.statusDetail);
        setStatus(data.eligibilityCheckCheckAction.state.status);
      } else {
        setStatus("error");
      }
    }, []),
    onError: useCallback(() => {
      setStatus("error");
    }, []),
  });

  // eslint-disable-next-line
  const [setProfile] = useMutation(setProfileMutation, {
    onCompleted: useCallback(() => {
      setStatus("loading");
      check({
        variables: {
          result: "",
        },
      });
    }, [check]),
    onError() {
      setStatus("error");
    },
  });

  const [handoff] = useMutation(handoffEnrollment, {
    onCompleted: useCallback(() => {
      setFieldValue("eligibility.completed", true);
      setUser({ status: USER_STATUS.ELIGIBLE });
      if (next) {
        next();
      }
    }, [next, setFieldValue, setUser]),
  });

  const valuesMapping = (
    values: Record<string, unknown>
  ): Record<string, unknown> => {
    const stringValues = { ...values };

    stringValues.dob = `${stringValues.month}/${stringValues.day}/${stringValues.year}`;
    stringValues.subscriberId = stringValues.subscriberId
      ? (stringValues.subscriberId as string).toUpperCase()
      : "";

    delete stringValues.isEligible;
    delete stringValues.privacyPolicy;
    delete stringValues.contactInfo;
    delete stringValues.fitnessPolicy;
    delete stringValues.completed;
    delete stringValues.day;
    delete stringValues.month;
    delete stringValues.year;

    return stringValues;
  };

  const handleEligibility = async () => {
    if (user.status === USER_STATUS.ELIGIBLE || values.eligibility?.completed) {
      setStatus("ALREADY_CONFIRMED");
      setMessage("");
      return;
    }
    try {
      const vals = values.eligibility!;
      await setTermsAndConditions(
        termsConditionsMap(values.eligibility! as Record<string, unknown>),
        {
          email: vals.email,
          firstName: vals.firstName,
          lastName: vals.lastName,
          dob: `${vals.year}-${vals.month}-${vals.day}`,
        }
      );

      if (!errors.eligibility!) {
        const { eligibility } = values;
        const newVals = valuesMapping(eligibility ?? {});

        setProfile({
          variables: {
            profile: newVals,
          },
        });
      } else {
        setTouched({
          eligibility: setNestedObjectValues(errors.eligibility!, true),
        });
      }
    } catch {
      setStatus("error");
    }
  };

  const handleHandoff = () => {
    handoff();
  };

  return (
    <div className="eligibility-form-container">
      <StatusModal
        show={status !== "" && status !== "EXISTS"}
        status={status}
        message={message}
        onCancel={() => setStatus("")}
        onNext={handleHandoff}
      />
      <Header
        title="Check your eligibility"
        subtitle="Please fill out the information below to see if you're eligible"
      />
      {status === "EXISTS" && <AlreadyEligibleToast />}
      <Card className="eligibility-form">
        <Card.Body style={{ padding: "24px" }}>
          <Card.Title>Eligibility Information</Card.Title>
          <Form.Group className="mb-3">
            <Form.Label className="eligibility-form__label" column sm={12}>
              Are you the primary subscriber for your health plan or a
              dependent?<span className="required-field-marker">*</span>
            </Form.Label>
            <Form.Check
              type="radio"
              id="sub-yes"
              label="Subscriber"
              value="true"
              isInvalid={
                getIn(touched, "eligibility.primarySubscriber") &&
                getIn(errors, "eligibility.primarySubscriber")
              }
              checked={values.eligibility!.primarySubscriber === "true"}
              name="eligibility.primarySubscriber"
              inline
              style={{ paddingLeft: 0 }}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Check
              type="radio"
              id="sub-no"
              label="Dependent"
              value="false"
              isInvalid={
                getIn(touched, "eligibility.hasBlue365") &&
                getIn(errors, "eligibility.hasBlue365")
              }
              checked={values.eligibility!.primarySubscriber === "false"}
              name="eligibility.primarySubscriber"
              inline
              style={{ paddingLeft: 0 }}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {getIn(errors.eligibility!, "primarySubscriber")}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="eligibility-form__label" column sm={12}>
              Do you currently have a Blue365 account?
              <span className="required-field-marker">*</span>
            </Form.Label>
            <Form.Check
              type="radio"
              id="blue-yes"
              label="Yes"
              value="true"
              isInvalid={
                getIn(touched, "eligibility.hasBlue365") &&
                getIn(errors, "eligibility.hasBlue365")
              }
              checked={values.eligibility!.hasBlue365 === "true"}
              name="eligibility.hasBlue365"
              inline
              style={{ paddingLeft: 0 }}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Check
              type="radio"
              id="blue-no"
              label="No"
              value="false"
              isInvalid={
                getIn(touched, "eligibility.hasBlue365") &&
                getIn(errors, "eligibility.hasBlue365")
              }
              checked={values.eligibility!.hasBlue365 === "false"}
              name="eligibility.hasBlue365"
              inline
              style={{ paddingLeft: 0 }}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
          <HelperCard open={values.eligibility!.hasBlue365 === "false"}>
            <Fragment>
              <section>
                <h6>Note: </h6>
                Having a valid account with Blue365 before proceeding will help
                us better determine your eligibility for Fitness Your Way.{" "}
                <a
                  href="https://www.blue365deals.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Register for a Blue365 account.
                </a>
              </section>
            </Fragment>
          </HelperCard>
          <Row className="eligibility-form__row">
            <Form.Group
              as={Col}
              controlId="firstName"
              xs={12}
              md={4}
              lg={3}
              className="mb-3"
            >
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                First Name<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                maxLength={35}
                name="eligibility.firstName"
                value={values.eligibility!.firstName}
                isInvalid={
                  getIn(touched, "eligibility.firstName") &&
                  getIn(errors, "eligibility.firstName")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.firstName")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="lastName" xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Last Name<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                maxLength={60}
                name="eligibility.lastName"
                value={values.eligibility!.lastName}
                isInvalid={
                  getIn(touched, "eligibility.lastName") &&
                  getIn(errors, "eligibility.lastName")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.lastName")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Label className="form-control-sm col-form-label p-0 eligibility-form__label">
            Date of Birth <span className="required-field-marker">*</span>
          </Form.Label>
          <Row className="eligibility-form__row">
            <Form.Group as={Col} controlId="month" xs={3} sm={2} md={1}>
              <Form.Label className="form-control-sm col-form-label p-0">
                Month
              </Form.Label>
              <Form.Control
                as="select"
                name="eligibility.month"
                value={values.eligibility!.month}
                isInvalid={
                  getIn(touched, "eligibility.month") &&
                  getIn(errors, "eligibility.month")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {getMonths()}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="day" xs={3} sm={2} md={1}>
              <Form.Label className="form-control-sm col-form-label p-0">
                Day
              </Form.Label>
              <Form.Control
                as="select"
                name="eligibility.day"
                value={values.eligibility!.day}
                isInvalid={
                  getIn(touched, "eligibility.day") &&
                  getIn(errors, "eligibility.day")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {getDays()}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="year" xs={4} md={2}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-0">
                Year
              </Form.Label>
              <Form.Control
                as="select"
                name="eligibility.year"
                value={values.eligibility!.year}
                isInvalid={
                  getIn(touched, "eligibility.year") &&
                  getIn(errors, "eligibility.year")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {getYears()}
              </Form.Control>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label
              className="form-control-sm col-form-label p-0 pb-3 pt-4 eligibility-form__label"
              column
              xs={12}
            >
              Gender <span className="required-field-marker">*</span>
            </Form.Label>
            <Form.Check
              type="radio"
              id="gender-m"
              label="Male"
              value="M"
              isInvalid={
                getIn(touched, "eligibility.gender") &&
                getIn(errors, "eligibility.gender")
              }
              name="eligibility.gender"
              checked={values.eligibility!.gender === "M"}
              inline
              style={{ paddingLeft: 0 }}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Check
              type="radio"
              id="gender-f"
              label="Female"
              value="F"
              isInvalid={
                getIn(touched, "eligibility.gender") &&
                getIn(errors, "eligibility.gender")
              }
              name="eligibility.gender"
              checked={values.eligibility!.gender === "F"}
              inline
              style={{ paddingLeft: 0 }}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Row className="eligibility-form__row">
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.gender")}
              </Form.Control.Feedback>
            </Row>
          </Form.Group>
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={9} sm={5} md={3} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                ZIP Code<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.zip"
                value={values.eligibility!.zip}
                isInvalid={
                  getIn(touched, "eligibility.zip") &&
                  getIn(errors, "eligibility.zip")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.zip")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={9} sm={5} md={3} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Coverage Type<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="eligibility.coverageType"
                className="form-select"
                value={values.eligibility!.coverageType}
                isInvalid={
                  getIn(touched, "eligibility.coverageType") &&
                  getIn(errors, "eligibility.coverageType")
                }
                onChange={handleChange}
                onBlur={handleBlur}
                custom
              >
                <option value=""></option>
                <option value="medical">Medical</option>
                <option value="dental">Dental</option>
                <option value="vision">Vision</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.coverageType")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={9} md={5} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Subscriber ID (or RNumber)
                <span className="required-field-marker">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="eligibility.subscriberId"
                  value={values.eligibility!.subscriberId}
                  maxLength={17}
                  isInvalid={
                    getIn(touched, "eligibility.subscriberId") &&
                    getIn(errors, "eligibility.subscriberId")
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <div id="card-placeholder-container">
                      <div
                        id="card-one-one"
                        ref={(newRef) => setCardOneRef(newRef)}
                      ></div>
                    </div>
                  }
                >
                  <Button className="input-group-button">?</Button>
                </OverlayTrigger>
                <Form.Control.Feedback type="invalid">
                  {getIn(errors, "eligibility.subscriberId")}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            {/* <Form.Group as={Col} xs={9} md={4} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Group #
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="eligibility.groupNumber"
                  value={values.eligibility!.groupNumber}
                  isInvalid={
                    getIn(touched, "eligibility.groupNumber") &&
                    getIn(errors, "eligibility.groupNumber")
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {getIn(errors, "eligibility.groupNumber")}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group> */}
            <CardImagePortals nodes={refs} tags={tags["bcbsa"]} />
          </Row>
          <HelperCard
            open={
              values.eligibility!.coverageType !== "medical" &&
              values.eligibility!.coverageType !== ""
            }
          >
            <section>
              <h6>Note: </h6>
              Members with dental and/or vision coverage MUST have a valid
              Blue365 email account in order to be eligible for Fitness Your
              Way.{" "}
              <a
                href="https://www.blue365deals.com/"
                target="_blank"
                rel="noreferrer"
              >
                Register for a Blue365 account.
              </a>
            </section>
          </HelperCard>
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={12} sm={9} md={5} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                {values.eligibility!.hasBlue365 === "true"
                  ? "Blue365 Email Address"
                  : "Email Address"}
                <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.email"
                value={values.eligibility!.email}
                isInvalid={
                  getIn(touched, "eligibility.email") &&
                  getIn(errors, "eligibility.email")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.email")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="eligibility-form__row">
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckboxPrivacyPolicy"
            >
              <Row className="eligibility-form__row">
                <Col xs={1} style={{ width: "3%", paddingTop: "4px" }}>
                  <Form.Check
                    id="privacy"
                    value="true"
                    isInvalid={
                      getIn(touched, "eligibility.privacyPolicy") &&
                      getIn(errors, "eligibility.privacyPolicy")
                    }
                    name="eligibility.privacyPolicy"
                    checked={values.eligibility!.privacyPolicy === true}
                    inline
                    style={{ paddingLeft: 0 }}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={8} style={{ padding: 0, margin: 0 }}>
                  <span style={{ fontSize: "0.8rem" }}>
                    <span className="required-field-marker">*</span>By providing
                    your email address and/or unknown other personal
                    information, as defined under applicable law, you
                    acknowledge that you are agreeing to our use of your
                    information as provided in our{" "}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Privacy Policy.
                    </a>
                  </span>
                </Col>
              </Row>
              <Row className="eligibility-form__row">
                <Form.Control.Feedback type="invalid">
                  {getIn(errors, "eligibility.privacyPolicy")}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
          </Row>
          <Row className="eligibility-form__row">
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckboxContactInfo"
            >
              <Row className="eligibility-form__row">
                <Col xs={1} style={{ width: "3%", paddingTop: "4px" }}>
                  <Form.Check
                    id="privacy"
                    value="true"
                    isInvalid={
                      getIn(touched, "eligibility.contactInfo") &&
                      getIn(errors, "eligibility.contactInfo")
                    }
                    name="eligibility.contactInfo"
                    checked={values.eligibility!.contactInfo === true}
                    inline
                    style={{ paddingLeft: 0 }}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={8} style={{ padding: 0, margin: 0 }}>
                  <span style={{ fontSize: "0.8rem" }}>
                    <span className="required-field-marker">*</span>I agree to
                    share my contact information with Blue Cross and Blue Shield
                    Association
                  </span>
                </Col>
              </Row>
              <Row className="eligibility-form__row">
                <Form.Control.Feedback type="invalid">
                  {getIn(errors, "eligibility.contactInfo")}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
      <FooterCard>
        <Container>
          <Row>
            <Col sm={{ span: 3, offset: 5 }}></Col>
            <Col sm={3}>
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap", width: "100%" }}
                onClick={handleEligibility}
                disabled={
                  Object.keys(touched).length === 0
                    ? true
                    : Boolean(errors.eligibility !== undefined)
                }
              >
                Check My Eligibility
              </Button>
            </Col>
          </Row>
        </Container>
      </FooterCard>
    </div>
  );
};

export default Eligibility;
