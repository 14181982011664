import { useEffect, useState } from "react";
import { fetchTiers } from "../api/platform/Queries";

const useGetTiers = () => {
  const [tiers, setTiers] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchTiers();
        setTiers(response.tiers ?? []);
      } catch (err: unknown) {
        Promise.reject(err);
      }
    })();
  }, []);
  return tiers;
};

export default useGetTiers;
