import React, { useContext, useEffect, useState } from "react";
import { Tile } from "./Tiles/Tile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { client, contentfulConfig } from "../../client";
import { IIssuesListFields } from "../../@types/generated/contentful";
import { convertToIssueList, tags } from "../../utility/contentful";
import { IssueList } from "../../types";
import { useAuthKit } from "@authkitcom/react";
import CheckoutModal from "./EditPlan/CheckoutModal";
import ProfileToast from "../../utility/ProfileToast";
import { AccountContext } from "../../context/AccountProvider";
import { convertToCurrency, momentStartOfDay } from "../../utility/util";
import ProcessingOrderModal from "../wizard/components/ProcessingOrderModal";
import { payoffBalance } from "../../api/enrollment/Queries";

const OutStandingBalanceBanner = ({
  amount,
  due,
}: {
  amount: number;
  due: string;
}) => {
  const { nextPayment, refresh } = useContext(AccountContext);
  const { authKit } = useAuthKit();
  const clientID = authKit!.getUserinfo();
  const [phoneAndHours, setPhoneAndHours] = useState<IssueList>({
    phoneNumber: "",
    hours: "",
    spanishPhoneNumber: "",
  });
  // const { client } = useContext(ClientConfigContext);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [openProcessingModal, setOpenProcessingModal] = useState(false);

  const handleCheckout = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleConfirm = async () => {
    try {
      setOpen(false);
      setProgress(0);
      setIntervalId(setInterval(() => setProgress((p) => p + 5), 700));
      setOpenProcessingModal(true);
      const res = await payoffBalance();
      setOpenProcessingModal(false);
      if (res.error) {
        const errorMessage = res.message;
        ProfileToast(
          res.code === 13
            ? "Looks like we had an issue processing your payment. Please continue to the Billing & Payment page and validate or add a new payment method."
            : errorMessage,
          false,
          clientID
        );
      } else {
        ProfileToast("Your payment of has been accepted", true, clientID);
      }
      refresh();
    } catch {
      setOpenProcessingModal(false);
      ProfileToast(
        "Your payment could not be processed at this time.",
        false,
        clientID
      );
    }
  };
  useEffect(() => {
    if (openProcessingModal && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, openProcessingModal]);

  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<IIssuesListFields>({
        content_type: "issuesList",
        links_to_entry: contentfulConfig.ids.help,
        "metadata.tags.sys.id[all]":
          tags[clientID?.metadata.client.toLowerCase()],
        include: 10,
      });
      const phone = convertToIssueList(entry.items[0]);
      setPhoneAndHours(phone);
    })();
    // eslint-disable-next-line
  }, [clientID]);

  const dueFormated = momentStartOfDay(due).format("MM/DD/YYYY");
  return (
    <>
      <CheckoutModal
        open={open}
        price={`${convertToCurrency(nextPayment?.amount ?? 0)}`}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        outstandingBalance={true}
      />
      <ProcessingOrderModal
        onClose={() => setOpenProcessingModal(false)}
        progress={progress}
        show={openProcessingModal}
      />
      <Tile
        className="outstanding-balance-banner"
        style={{ marginBottom: "1rem" }}
      >
        <Tile.THeader className="outstanding-balance__header">
          <div className="d-flex">
            <h5 style={{ color: "red" }} className="mb-1">
              <FontAwesomeIcon color={"red"} width="20px" icon={faInfoCircle} />{" "}
              Notice
            </h5>
          </div>
        </Tile.THeader>
        <Tile.TBody className="outstanding-balance__body">
          <p>
            Your account shows a past due balance of{" "}
            <b>
              {convertToCurrency(amount)}, which was due on {dueFormated}.
            </b>{" "}
            In order to avoid deactivation of your account, we require for you
            to complete payment immediately. If you feel you have reached this
            balance summarization in error please call us at{" "}
            <a
              className="underline"
              href={`tel:${phoneAndHours.phoneNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {phoneAndHours.phoneNumber}
            </a>{" "}
            {phoneAndHours.hours}
          </p>
        </Tile.TBody>
        <Tile.TFooter className="outstanding-balance__footer">
          <div className="d-flex">
            <button
              role="link"
              className="payoff-button"
              onClick={handleCheckout}
              // disabled={!summaryData.pending || loadingSummary || promoLoading}
            >
              Pay Off Existing Balance
            </button>
          </div>
        </Tile.TFooter>
      </Tile>
    </>
  );
};
export default OutStandingBalanceBanner;
