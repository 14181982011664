// import { useAuthKit } from "@authkitcom/react";
import React, { Fragment, useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Modal, Spinner } from "react-bootstrap";
import { fetchPaymentMethods, Payment } from "../../../api/enrollment/Queries";
import { getCurrentAddress } from "../../../api/platform/Queries";
import { AccountContext } from "../../../context/AccountProvider";
import { ADDRESS } from "../../wizard/forms/Address";
import { CurrentAddress } from "../Profile/ShippingAddress";

const CheckoutModal = ({
  open,
  price,
  onCancel,
  onConfirm,
  outstandingBalance,
}: {
  open: boolean;
  price: string;
  onCancel: () => void;
  onConfirm: (termsAccepted: boolean) => void;
  outstandingBalance?: boolean;
}) => {
  // const { authKit } = useAuthKit();
  const { creditBalance } = useContext(AccountContext);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [defaultPayment, setDefaultPayment] = useState<Payment | null>(null);
  const [defaultAddress, setDefaultAddress] = useState<CurrentAddress | null>(
    null
  );
  const [loading, setLoading] = useState({
    payment: true,
    address: true,
  });
  const [error, setError] = useState({
    payment: false,
    address: false,
  });

  useEffect(() => {
    if (open) {
      setTermsAccepted(false);
      if (outstandingBalance) {
        setTermsAccepted(true);
      }
      (async () => {
        try {
          const res = await fetchPaymentMethods();
          if (res.paymentMethods && res.paymentMethods.length > 0) {
            const filtered = res.paymentMethods
              .filter((p) => p.isDefault)
              .reduce((r) => r);
            if (filtered) {
              setDefaultPayment(filtered);
            }
          }
        } catch (e: unknown) {
          setError((e) => ({ ...e, payment: true }));
        } finally {
          setLoading((l) => ({ ...l, payment: false }));
        }
      })();
      (async () => {
        try {
          const res = await getCurrentAddress();
          if (res.data.length > 0) {
            const filtered = res.data
              .filter((a) =>
                a.defaultFor?.includes(ADDRESS.SHIPPING.toLowerCase())
              )
              .reduce((a) => a);
            if (filtered) {
              setDefaultAddress(filtered);
            }
          }
        } catch (e: unknown) {
          setError((e) => ({ ...e, address: true }));
        } finally {
          setLoading((l) => ({ ...l, address: false }));
        }
      })();
    }
  }, [open]);

  const handleTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked);
  };

  return (
    <Modal show={open} centered onHide={onCancel}>
      <Modal.Body>
        <div className="checkout-modal">
          <div className="checkout-modal__title">
            <h4>
              <strong>Checkout - {price}</strong>
            </h4>
          </div>
          <div className="checkout-modal__container">
            <div className="checkout-modal__item">
              <span className="checkout-modal__subheader text-muted">
                Payment Method
              </span>
              {creditBalance !== undefined && parseInt(creditBalance) > 0 ? (
                <div className="creditBalance">
                  Your account credits (<strong>balance:</strong>{" "}
                  <span>${creditBalance}</span>) will be applied automatically.
                  If there is a remaining balance, we will charge your default
                  payment method
                </div>
              ) : null}
              <Card>
                <Card.Body className="checkout-modal__card">
                  {error.payment ? (
                    <h4>
                      <strong>Unfortunately, there was an error.</strong>
                    </h4>
                  ) : (
                    <Fragment>
                      {loading.payment ? (
                        <div style={{ margin: "0 auto" }}>
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <Fragment>
                          {defaultPayment && (
                            <Fragment>
                              <span>{`${defaultPayment.name}`}</span>
                              {defaultPayment.card && (
                                <Fragment>
                                  <span>{`${defaultPayment.card.type} ${defaultPayment.card.number}`}</span>
                                  <span>{`Exp: ${defaultPayment.card.expMonth}/${defaultPayment.card.expYear} Zip Code: ${defaultPayment.card.zip}`}</span>
                                </Fragment>
                              )}
                              {defaultPayment.ach && (
                                <Fragment>
                                  <span>{`${defaultPayment.ach.bankName}`}</span>
                                  <span>{`${defaultPayment.ach.type} ${defaultPayment.ach.mask}`}</span>
                                </Fragment>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Card.Body>
              </Card>
            </div>
            <div className="checkout-modal__item">
              <span className="checkout-modal__subheader text-muted">
                Shipping Address
              </span>
              <Card>
                <Card.Body className="checkout-modal__card">
                  {error.address ? (
                    <h4>
                      <strong>Unfortunately, there was an error.</strong>
                    </h4>
                  ) : (
                    <Fragment>
                      {loading.address ? (
                        <div style={{ margin: "0 auto" }}>
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <Fragment>
                          {defaultAddress && (
                            <Fragment>
                              <span>{`${defaultAddress.address1} ${
                                defaultAddress.address2
                                  ? defaultAddress.address2
                                  : ""
                              }`}</span>
                              <span>{`${defaultAddress.city}, ${defaultAddress.state} ${defaultAddress.zip}`}</span>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Card.Body>
              </Card>
            </div>
            <>
              {!outstandingBalance && (
                <div className="checkout-modal__item">
                  <span className="checkout-modal__subheader text-muted">
                    Agreements
                  </span>
                  <div>
                    <div>
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          onChange={handleTerms}
                          style={{ marginRight: "12px" }}
                        />
                        By checking this box, I acknowledge that I have read and
                        agree to comply with the terms and conditions of the{" "}
                        <a href="/agreement" target="_blank" className="a-link">
                          Fitness Program Agreement
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </>
            <div className="checkout-modal__button-container">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll-change font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="nav-btn-enroll-change font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => onConfirm(termsAccepted)}
                disabled={!termsAccepted}
              >
                Complete Checkout
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CheckoutModal;
