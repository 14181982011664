import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { SetNewHomeGym } from "../../../api/enrollment/Queries";

const HomeGymIcon = () => {
  return (
    <svg
      style={{ width: "40px", margin: "-6px 5px 0 0" }}
      viewBox="0 0 782.208 752"
    >
      <path
        id="Combined_Shape"
        data-name="Combined Shape"
        d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
        fill="#072c4c"
      />
    </svg>
  );
};

const CheckCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="currentColor"
      className="check-circle-fill"
      viewBox="0 0 16 16"
    >
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg>
  );
};
const SetHomeGym = ({
  show,
  setShowModal,
  locationId,
  locationName,
  locationAddress,
  onRefresh,
}: {
  show: boolean;
  setShowModal: (val: boolean) => void;
  locationId: string;
  locationName: string;
  locationAddress: string;
  onRefresh: () => Promise<void>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleSetHomeGym = async () => {
    try {
      setIsLoading(true);
      await SetNewHomeGym(locationId);
      await onRefresh();
      setIsLoading(false);
      setShowConfirm(true);
    } catch {
      setIsLoading(false);
      throw new Error("Error setting home gym");
    }
  };
  return (
    <Modal show={show} centered>
      <div className="setHomeGymModal">
        <Modal.Title>
          <h2>Confirm Home Gym</h2>
        </Modal.Title>
        {isLoading ? (
          <>
            <div className="center mb-4">
              <Spinner animation="border" />
            </div>
            <div className="d-flex justify-content-center">
              <p>Updating your home gym</p>
            </div>
          </>
        ) : (
          <>
            {showConfirm ? (
              <>
                <Modal.Body>
                  <div className="center">
                    <CheckCircle />
                    <p className="mt-4">
                      <b>Home Gym Set!</b>
                    </p>
                    <p className="mt-3">
                      You can visit location beginning today. Be sure to bring
                      your member ID card with you and present to the front desk
                      when you arrive.
                    </p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
                    <button
                      onClick={() => setShowModal(false)}
                      className="col btn btn-secondary ml-2"
                    >
                      Close
                    </button>
                  </div>
                </Modal.Footer>
              </>
            ) : (
              <>
                <Modal.Body>
                  <div className="setHomeGymModal__details">
                    <HomeGymIcon />
                    <p className="setHomeGymModal__title m-0">{locationName}</p>
                    <p className="setHomeGymModal__subtitle m-0">
                      {locationAddress}
                    </p>
                  </div>
                  <p className="mt-3">
                    You&lsquo;re about to select the above location as your home
                    location. Confirming this selection means you&lsquo;ll be
                    able to begin accessing this Elite location effective
                    immediately. You can also continue to enjoy access to all
                    other locations in the Power, Core, and Base. If you&lsquo;d
                    like to access another Elite location, you&lsquo;ll need to
                    update this selection in your profile and access will be
                    granted to your new location the 1st of the following month.
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
                    <button
                      onClick={() => setShowModal(false)}
                      className="col btn btn-outline-primary ml-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleSetHomeGym()}
                      className="col mx-2 btn btn-secondary "
                    >
                      Confirm
                    </button>
                  </div>
                </Modal.Footer>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default SetHomeGym;
