import React, { Fragment } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive } from "../../utility/Carousel";
import CarouselCardVideo from "./CarouselCardVideo";
import { Program, VideoData } from "../../pages/LesMills";

const VideoList = ({
  videos,
  programs,
}: {
  videos: VideoData[];
  programs: Program[];
}) => {
  return (
    <>
      <div className="bg-col">
        <div className="lesmills-lp">
          <div className="lm-back">
            <img
              src="https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2021/08/lesMills_Hero.png"
              alt="logo-alt"
              className="img-fluid"
            />
          </div>
          <div className="ml-2 lm-text">
            <div className="row pt-4">
              <div className="col pt-4">
                <img
                  src="https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2021/06/LesMills-logo.png"
                  alt="logo-alt"
                  className="lm-logo"
                />
                <h1 className="pt-3">
                  Take your workouts to <br />
                  the next level
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="lesmills-lp-bg">
          <div className="mx-2">
            <div className="row"></div>
            <div>
              {programs &&
                programs.map((program) => {
                  return (
                    <div key={program.name}>
                      <div className="category-section pt-5" key={program.name}>
                        <h1 style={{ fontSize: "32px" }}>
                          {program.name}{" "}
                          <span className="font-weight-normal">
                            ({program.totalProgramVideos})
                          </span>
                        </h1>
                        <p className="font-small">{program.description}</p>
                      </div>

                      <Carousel
                        className="mt-4 carousel"
                        showDots={false}
                        responsive={responsive}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        partialVisible={true}
                      >
                        {videos &&
                          videos
                            .filter(
                              (video) => program.name == video.program.name
                            )
                            .map((video) => {
                              return (
                                <div key={video.title}>
                                  <CarouselCardVideo
                                    fileId={video.fileId}
                                    thumbnail={video.thumbnail}
                                    title={video.title}
                                    duration={video.duration}
                                    selectedVideo={""}
                                  />
                                </div>
                              );
                            })}
                      </Carousel>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoList;
