type SkuMap = {
  [sku: string]: {
    name: string;
    className: string;
    numGyms: number;
    features: { title: string; content: string }[];
    display: boolean;
    bannerText?: string;
    recommended?: boolean;
  };
};

const skuMap: SkuMap = {
  "FITNESS-BASE": {
    name: "Base",
    className: "base",
    numGyms: 3000,
    features: [{ title: "Digital Content", content: "Videos and Livestreams" }],
    display: true,
  },
  "FITNESS-CORE": {
    name: "Core",
    className: "core",
    numGyms: 8000,
    features: [{ title: "Digital Content", content: "Videos and Livestreams" }],
    display: true,
    recommended: true,
  },
  "FITNESS-POWER": {
    name: "Power",
    className: "power",
    numGyms: 12000,
    features: [{ title: "Digital Content", content: "Videos and Livestreams" }],
    display: true,
  },
  "FITNESS-ELITE": {
    name: "Elite",
    className: "elite",
    numGyms: 12000,
    features: [{ title: "Digital Content", content: "Videos and Livestreams" }],
    display: true,
  },
  "FITNESS-DIGITAL": {
    name: "Digital Content Only",
    className: "digital",
    numGyms: 0,
    features: [
      { title: "Digital Content", content: "30% off every 10th class" },
    ],
    display: true,
  },
  "FITNESS-LEGACY": {
    name: "POWER - Legacy Member Promotional Rate",
    bannerText: "POWER",
    className: "power",
    numGyms: 12000,
    features: [{ title: "Digital Content", content: "Videos and Livestreams" }],
    display: false,
  },
};
export default skuMap;
