import { Entry } from "contentful";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { IIssuesListFields } from "../../@types/generated/contentful";
import { IssueList } from "../../types";
import { client, contentfulConfig } from "../../client";
import HelpForm from "../../components/wizard/forms/Help";
import {
  convertToIssueList,
  convertToIssuesList,
} from "../../utility/contentful";
import { Spinner } from "react-bootstrap";

const Help = ({ tags }: { tags: string }) => {
  const [content, setContent] = useState<Entry<IIssuesListFields> | null>(null);
  const [phoneAndHours, setPhoneAndHours] = useState<IssueList>({
    phoneNumber: "",
    hours: "",
    spanishPhoneNumber: "",
  });
  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<IIssuesListFields>({
        content_type: "issuesList",
        links_to_entry: contentfulConfig.ids.help,
        "metadata.tags.sys.id[all]": tags,
        include: 10,
      });
      const phone = convertToIssueList(entry.items[0]);
      setPhoneAndHours(phone);
      setContent(entry.items[0]);
      setPhoneAndHours(phone);
    })();
    // eslint-disable-next-line
  }, [tags]);

  return (
    <Card className="account-card">
      <Card.Body className="account-card__body">
        <div className="account-help">
          <div className="account-help__containers">
            <div className="account-help__card-container">
              <h5>
                <b>How can we help?</b>
              </h5>
              <p>
                We&apos;re sorry you&apos;re experiencing an issue. For
                assistance, please try an option below.
              </p>
              {!content ? (
                <div className="center-loading">
                  <Spinner animation="border" />
                </div>
              ) : (
                <Card className="p-2 mb-2 border-primary account-help__assistance">
                  <h5 className="m-0 mb-2 text-black">
                    For immediate assistance, call us:
                  </h5>
                  <p>
                    Call the toll-free number below to get help right away from
                    a Fitness Program service representative.
                  </p>
                  <p className="m-0 p-2 pb-0 text-black">
                    <span style={{ fontWeight: `bold` }}>Phone: </span>
                    <a href={`tel:${phoneAndHours.phoneNumber}`} className="a-link">
                      {phoneAndHours.phoneNumber}
                    </a>{" "}
                    {"(English)"}
                    <a
                      style={{
                        borderLeft: "1px solid grey",
                        height: "10px",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                    </a>
                    <a href={`tel:${phoneAndHours.spanishPhoneNumber}`} className="a-link">
                      {phoneAndHours.spanishPhoneNumber}
                    </a>{" "}
                    {"(Spanish)"}
                  </p>
                  <p className="m-0 p-2 pt-1 text-black">
                    <span style={{ fontWeight: `bold` }}>Hours: </span>
                    {phoneAndHours.hours}
                  </p>
                </Card>
              )}
              <h5 style={{ fontWeight: "normal" }}>For general questions:</h5>
              <p className="m-0 p-2 pb-0 text-black">
                Check out our
                <a href={`/account/faqs`} rel="noreferrer" className="a-link">
                  {" "}
                  Frequently Asked Questions.
                </a>
              </p>
            </div>
          </div>
          <hr className="w-75" />
          <div className="account-help__containers">
            <div className="account-help__card-container">
              {!content ? (
                <div className="center-loading">
                  <Spinner animation="border" />
                </div>
              ) : (
                <HelpForm issuesList={convertToIssuesList(content)} />
              )}
            </div>
          </div>
          {/* <div className="account-overview__activity-container">
            <div className="account-card__title">
              <h5>
                <b>My Activities</b>
              </h5>
            </div>
          </div> */}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Help;
