import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

const DiscardHomeGymModal = ({
  currentHomeGym,
  newHomeGym,
  onDate,
  takePlaceDate,
  open,
  setOpen,
  discardHomeGymChanges,
  loading,
}: {
  currentHomeGym: string;
  newHomeGym: string;
  onDate: string;
  takePlaceDate: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  client: string;
  discardHomeGymChanges: () => void;
  loading: boolean;
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => setOpen(false);
  // const handleDiscard = async () => {
  //   try {
  //     setIsLoading(true);
  //     await discardNewHomeGym();
  //     ProfileToastDiscardPlan(
  //       "Your home gym change has been successfully discarded.",
  //       true,
  //       client
  //     );
  //   } catch {
  //     setIsLoading(false);
  //     ProfileToastDiscardPlan(
  //       "Your home gym change could not be discarded.",
  //       false,
  //       client
  //     );
  //     throw new Error("Error discarding new home gym");
  //   }
  //   setIsLoading(false);
  //   setOpen(false);
  // };
  return (
    <Modal show={open} centered>
      <Modal.Header>
        <Modal.Title>
          <b>Discard Home Gym Change?</b>
        </Modal.Title>
      </Modal.Header>
      {loading ? (
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            <p>
              <p>
                Our records indicate you changed your home gym to{" "}
                <b>
                  {newHomeGym} on {onDate}
                </b>{" "}
                . This change is scheduled to take effect on{" "}
                <b>{takePlaceDate}.</b> Would you like to discard this change?
                Your home gym will remain at <b>{currentHomeGym}</b> until
                further changes are made.
              </p>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: "white", width: "48%" }}
              onClick={handleClose}
            >
              <span style={{ color: "black" }}>No, Keep Changes</span>
            </Button>
            <Button
              style={{ width: "48%" }}
              variant="primary"
              onClick={discardHomeGymChanges}
            >
              Yes, Discard Changes
            </Button>
          </Modal.Footer>{" "}
        </>
      )}
    </Modal>
  );
};
export default DiscardHomeGymModal;
