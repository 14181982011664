import { IClientConfig } from "../../context/ClientConfigProvider";
import { fetchWrapper } from "../../utility/util";

export interface IToken {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  refreshToken: string;
}
/*
Post Requests
 */
export const signup = async (
  username: string,
  password: string,
  acceptedTerms: string[],
  callback: (tokens: IToken) => Promise<void>
): Promise<void> => {
  const res = await fetchWrapper("/app/v1/account/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "pipeline-session-id":
        sessionStorage.getItem("pipeline-session-id") ?? "",
    },
    body: JSON.stringify({
      username: username,
      password: password,
      acceptedTerms: acceptedTerms,
    }),
  });

  const json: IToken & { success: boolean; message?: string } =
    await res.json();

  if (json.success) {
    await callback({
      accessToken: json.accessToken,
      refreshToken: json.refreshToken,
      expiresIn: json.expiresIn,
      idToken: json.idToken,
    });
    return;
  }

  throw new Error(json.message);
};
//TODO - Refactor backend to not return an array
export const getClientConfig = async (host: string): Promise<IClientConfig> => {
  if (host == "") {
    return Promise.reject(new Error("host required"));
  }
  try {
    const query = "/app/v1/client-config?host=" + encodeURI(host);
    const sessionId = sessionStorage.getItem("pipeline-session-id");
    let headers = {};

    if (sessionId) {
      headers = {
        "pipeline-session-id": sessionId,
      };
    }

    const results = await fetchWrapper(query, {
      method: "GET",
      headers: headers,
    });

    const resultsJson: IClientConfig[] = await results.json();

    return Promise.resolve(resultsJson[0]);
  } catch (err: unknown) {
    return Promise.reject(err);
  }
};

export const getBurnalong = async (): Promise<string> => {
  try {
    const resp = await fetchWrapper("/app/v1/partner/burnalong/forward");
    if (resp.ok) {
      const json = await resp.json();
      return json.redirectUri;
    }
    return Promise.reject("unknown error");
  } catch (e: unknown) {
    return Promise.reject(e);
  }
};

export const setAcceptanceDate = async () => {
  try {
    await fetchWrapper(`/app/blue365/accept`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e: unknown) {
    throw new Error("Error accepting Terms and Conditions");
  }
};

export const getSessionId = async (): Promise<string> => {
  try {
    const resp = await fetchWrapper("/app/session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!resp.ok) return Promise.reject("unknown error");

    const json = await resp.json();
    return json.id;
  } catch (e: unknown) {
    return Promise.reject(e);
  }
};
