import React from "react";
import { ProgramAgreementSection, TermsOfUseSection } from "../../types";

const Section = ({
  header,
  content,
  agreement,
}: { agreement?: boolean } & (TermsOfUseSection | ProgramAgreementSection)) => {
  return (
    <div className={agreement ? "agreement" : "terms"}>
      <h4 className={`${agreement ? "agreement" : "terms"}__sectionHeader`}>
        <b>{header}</b>
      </h4>
      {content}
    </div>
  );
};

export default Section;
