import React, { useContext } from "react";
import { Tile } from "./Tiles/Tile";
import { Button } from "react-bootstrap";
import ReEnrollImg from "./Tiles/assets/reenroll_banner.png";
import { Link } from "react-router-dom";
import { AccountContext } from "../../context/AccountProvider";
import { momentStartOfDay } from "../../utility/util";

const ReEnrollSpecialBanner = ({ enrollHref }: { enrollHref: string }) => {
  const { enrollmentFrame } = useContext(AccountContext);
  const now = momentStartOfDay();
  const offerInvalid = momentStartOfDay(enrollmentFrame.current?.term)
    .add(29, "days")
    .format("MM/DD/YYYY");

  const daysLeft = () => {
    let daysString = `${now.diff(offerInvalid, "days") * -1} days`;
    if (now.diff(offerInvalid, "days") * -1 === 1) {
      daysString = `day`;
    }
    return daysString;
  };
  return (
    <Tile className="reenroll-banner">
      <Tile.THeader className="reenroll-banner__header">
        <div className="h-100">
          <img width="100%" height="100%" src={ReEnrollImg} alt="enroll"/>
        </div>
      </Tile.THeader>
      <Tile.TBody className="reenroll-banner__body">
        <div>
          <h5 className="text-left">
            <b>What if we waived enrollment fees? Limited time offer.</b>
          </h5>
          <hr />
          <p style={{ fontSize: `0.8rem` }} className="text-left p-0 mb-0">
            As a courtesy for being a member, if you re-enroll within the next{" "}
            {daysLeft()} we'll waive any enrollment fees. This{" "}
            <b>offer expires on {offerInvalid}.</b>
          </p>
        </div>
      </Tile.TBody>
      <Tile.TFooter
        className="reenroll-banner__footer__special"
        // className="d-flex mb-2 justify-content-center flex-column"
      >
        <div className="d-flex justify-content-between align-items-center">
          <Button
            as={Link}
            style={{ color: "white", borderRadius: "20px" }}
            className="btn btn-primary align-self-left  w-75"
            to={enrollHref}
          >
            Re-Enroll
          </Button>
        </div>
      </Tile.TFooter>
    </Tile>
  );
};
export default ReEnrollSpecialBanner;
