import React, { useContext, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import ProfileToast from "../../../utility/ProfileToast";
import {
  changeDateOfBirth,
  changePersonName,
} from "../../../api/identity/Queries";
import { userinfoWrapper } from "../../../utility/util";
import * as Yup from "yup";
import { ClientConfigContext } from "../../../context/ClientConfigProvider";
import { useAuthKit } from "@authkitcom/react";
import { Form, Col, Row } from "react-bootstrap";

interface IChangePersonNameValues {
  firstName: string;
  lastName: string;
}

interface IChangeBirthdate {
  birthDate: string;
}

const PersonalInformation = () => {
  const { authKit } = useAuthKit();
  const userInfo = authKit!.getUserinfo();
  const [initialNameChangeValues] = useState<IChangePersonNameValues>({
    firstName: userinfoWrapper(userInfo)?.given_name ?? "",
    lastName: userinfoWrapper(userInfo)?.family_name ?? "",
  });
  const [initialBirthdate] = useState<IChangeBirthdate>({
    birthDate: userInfo?.metadata.dob ?? "",
  });
  const [, setFormErrors] = useState({});
  const { client } = useContext(ClientConfigContext);
  const handleNameChange = async (firstName: string, lastName: string) => {
    try {
      setFormErrors(changePersonNameSchema.values);
      const res = await changePersonName(firstName, lastName);
      sessionStorage.setItem(
        "userinfoModified",
        JSON.stringify({
          given_name: firstName,
          family_name: lastName,
        })
      );

      if (res.status === "success") {
        ProfileToast("Successfully changed name.", true, client);
      } else {
        const errorMessage = "There was an error with the name change.";
        ProfileToast(errorMessage, false, client);
      }
    } catch (Error) {
      const errorMessage = "There was an error with the name change.";
      ProfileToast(errorMessage, false, client);
    }
  };
  const handleBirthdateChange = async (birthDate: string) => {
    try {
      setFormErrors(changeBirthdateSchema.values);
      const res = await changeDateOfBirth(birthDate);
      userInfo.metadata.dob = birthDate;
      sessionStorage.setItem("userinfoModified", JSON.stringify(userInfo));
      if (res.status === "success") {
        ProfileToast("Successfully changed birthdate.", true, client);
      } else {
        const errorMessage = "There was an error with birthdate change.";
        ProfileToast(errorMessage, false, client);
      }
    } catch (Error) {
      const errorMessage = "There was an error with birthdate change.";
      ProfileToast(errorMessage, false, client);
    }
  };
  const changePersonNameSchema = Yup.object()
    .shape({
      firstName: Yup.string().required().defined("Please enter a first name."),
      lastName: Yup.string().required().defined("Please enter a last name."),
    })
    .defined();
  const changeBirthdateSchema = Yup.object()
    .shape({
      birthDate: Yup.date().required().defined("Please enter birth date."),
    })
    .defined();
  return (
    <div className="edit-profile">
      <span>Personal Information</span>
      <Row>
        <Col>
          <Formik
            initialValues={initialNameChangeValues}
            validationSchema={changePersonNameSchema}
            onSubmit={(values, { resetForm }) => {
              const firstName = values.firstName;
              const lastName = values.lastName;
              handleNameChange(firstName, lastName);
              resetForm({
                values: { lastName: lastName, firstName: firstName },
              });
            }}
          >
            {(props) => (
              <FormikForm style={{ marginLeft: "14px" }}>
                <Row>
                  <Form.Group as={Col} xs={8} className="mb-3 p-0">
                    <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                      First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={props.handleChange}
                      value={props.values.firstName}
                      className="edit-profile__input"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} xs={8} className="mb-3 p-0">
                    <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={props.values.lastName}
                      className="edit-profile__input"
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col className="p-0">
                    <button
                      disabled={!(props.isValid && props.dirty)}
                      type="submit"
                      className="nav-btn-enroll font-weight-bold btn btn-primary"
                      style={{ marginLeft: "-5px" }}
                    >
                      Change Name
                    </button>
                  </Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </Col>
        <Col>
          <Formik
            initialValues={initialBirthdate}
            validationSchema={changeBirthdateSchema}
            onSubmit={(values, { resetForm }) => {
              const birthDate = values.birthDate;
              handleBirthdateChange(birthDate);
              resetForm({
                values: { birthDate: birthDate },
              });
            }}
          >
            {(props) => (
              <FormikForm style={{ marginLeft: "14px" }}>
                <Row>
                  <Form.Group as={Col} xs={8} className="mb-3 p-0">
                    <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                      Date of Birth
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="birthDate"
                      id="birthDate"
                      onChange={props.handleChange}
                      value={props.values.birthDate}
                      className="edit-profile__input"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col className="p-0">
                    <button
                      disabled={!(props.isValid && props.dirty)}
                      type="submit"
                      className="nav-btn-enroll font-weight-bold btn btn-primary"
                      style={{ marginLeft: "-5px" }}
                    >
                      Change Date of Birth
                    </button>
                  </Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
      <div className="vp__personal-info my-4" />
    </div>
  );
};

export default PersonalInformation;
