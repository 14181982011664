import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  paneContainer: {
    padding: "3px 20px 0",
    width: "100%",
    height: "100%",
    minHeight: 700,
    overflow: "auto",

    "& > div > .ReactVirtualized__Grid": {
      // Add extra space below virtualized list
      // to indicate you've reached the bottom
      paddingBottom: 40,
    },
  },
}));

export default useStyles;
