import React, { Fragment, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useFormikContext, Field, getIn } from "formik";
import { Card, Col, Form, Row } from "react-bootstrap";
import Header from "../components/Header";
import Summary from "../components/Summary";
import { EligibilityFlow, FormikWizardState } from "../types";
import { useEffect } from "react";
import useSummary from "../../../hooks/useSummary";
import { IToken, signup } from "../../../api/portal/Queries";
import { useState } from "react";
import StatusModal from "../components/StatusModal";
import { useAuthKit } from "@authkitcom/react";
import {
  enrollmentStartMutation,
  ENROLLMENT_ACTIONS,
} from "../../../graphql/Mutations";
import FooterButtons from "../components/FooterButtons";
import { termsConditionsMap, userinfoWrapper } from "../../../utility/util";
import BundlesDropDown from "../../shared/BundlesDropDown";

const Members = ({
  previous,
  next,
}: {
  previous?: () => void;
  next?: () => void;
}) => {
  const {
    values,
    errors,
    touched,
    dirty,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormikContext<FormikWizardState<EligibilityFlow>>();

  const [duplicate, setDuplicate] = useState(false);

  const [show, setShow] = useState(false);

  const { setMutated, cancel: cancelSummary } = useSummary();

  const { authKit } = useAuthKit();

  useEffect(() => {
    setMutated(true);
    //already been here
    if (values.membership!.completed) {
      setFieldValue("membership.email", values.membership!.email);
    } else if (authKit?.getTokens()?.accessToken !== undefined) {
      //has a token
      setFieldValue(
        "membership.email",
        userinfoWrapper(authKit?.getUserinfo())?.email
      );
      setFieldValue("membership.completed", true);
      setFieldValue("membership.password", "ThisIsValid!1");
    } else {
      //havent been here
      setFieldValue(
        "membership.email",
        values.eligibility?.email ?? values.membership!.email
      );
    }
    // eslint-disable-next-line
  }, []);

  const [setPackage] = useMutation(enrollmentStartMutation, {
    onCompleted: useCallback(() => {
      cancelSummary();
      setMutated(true);
    }, []),
    onError: useCallback(() => {
      setShow(true);
    }, []),
  });

  const handlePackageChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue("package.sku", e.target.value);
    try {
      await setPackage({
        variables: {
          action: ENROLLMENT_ACTIONS.NEW,
          sku: e.target.value,
        },
      });
    } catch (e: unknown) {
      setShow(true);
    }
  };

  const handleUpdateUser = async (tokens: IToken) => {
    await authKit?.setTokens(tokens);
  };
  /** Submits the signup */
  const handleMembership = async () => {
    try {
      if (authKit?.getTokens()?.accessToken && next) {
        //already exists
        next();
        return;
      }
      const termsArr = termsConditionsMap(
        values.membership! as Record<string, unknown>
      );
      await signup(
        values.membership!.email,
        values.membership!.password,
        termsArr,
        handleUpdateUser
      );

      if (next) {
        setFieldValue("membership.completed", true);
        next();
      } else {
        setShow(true);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message) {
          if ((e.message as string).includes("ALREADY_EXISTS")) {
            setDuplicate(true);
            return;
          }
        }
      }
      setShow(true);
    }
  };

  const isPasswordValid = () => {
    if (
      getIn(errors, "membership.password") &&
      getIn(touched, "membership.password")
    ) {
      return false;
    } else if (
      getIn(touched, "membership.password") &&
      getIn(errors, "membership.email") === undefined
    ) {
      return true;
    } else {
      return undefined;
    }
  };

  // const getName = () => {
  //   return `${values.eligibility?.firstName}${
  //     values.eligibility?.middleName && values.eligibility?.middleName !== ""
  //       ? ` ${values.eligibility?.middleName}`
  //       : ""
  //   } ${values.eligibility?.lastName}`;
  // };

  const handleCancel = () => {
    setShow(false);
  };

  return (
    <Fragment>
      <StatusModal
        show={show}
        status={"ERROR"}
        message=""
        onCancel={handleCancel}
        onNext={() => {
          return;
        }}
      />
      <Row className="membership-row">
        <Col lg={12} xl={7} className="px-0">
          <Header
            title="Create Account"
            subtitle="Please enter a valid email address and password to use as your account credentials."
          />
          <Card>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3">
                  {/* <Form.Label>
                    <b>Primary Member</b>
                  </Form.Label>
                  <p>{getName()}</p> */}
                </Form.Group>
                <Row>
                  <Form.Group as={Col} lg={12} xl={5} className="mb-3 p-0 me-5">
                    <Form.Label>
                      <b>Email Address</b>
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter email here"
                      name="membership.email"
                      value={values.membership!.email}
                      onChange={(e: React.ChangeEvent<unknown>) => {
                        setDuplicate(false);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      isInvalid={
                        (getIn(errors, "membership.email") &&
                          getIn(touched, "membership.email")) ||
                        duplicate
                      }
                      disabled={authKit?.getTokens()?.accessToken !== undefined}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="form-requirements invalid mt-2 mx-2"
                    >
                      {duplicate
                        ? "This email is associated with an existing account. Please login if this is your account or enter a different email"
                        : getIn(errors, "membership.email")}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} lg={12} xl={5} className="mb-3 p-0">
                    <Form.Label>
                      <b>Create Password</b>
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter password here"
                      name="membership.password"
                      value={values.membership!.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        isPasswordValid() === false &&
                        getIn(touched, "membership.password")
                      }
                      disabled={authKit?.getTokens()?.accessToken !== undefined}
                    />

                    <div
                      className={`form-requirements mt-2 mx-2 ${
                        isPasswordValid() === true
                          ? "valid"
                          : isPasswordValid() === false
                          ? "invalid"
                          : ""
                      }`}
                    >
                      Eight or more characters, with at least one lowercase
                      letter, one uppercase letter, and one special character.
                    </div>
                  </Form.Group>
                </Row>
                <div>
                  By Providing your email address and/or unknown other personal
                  information, as defined under applicable law, you acknowledge
                  that you are agreeing to our use of your information as
                  provided in our{" "}
                  <a
                    href="/terms"
                    target="_blank"
                    rel="noreferrer"
                    className="a-link"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                    className="a-link"
                  >
                    Privacy Policy
                  </a>
                </div>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Form.Group>
                <Form.Label>Fitness Package</Form.Label>
                <Form.Control
                  as="select"
                  name="package.sku"
                  value={values.package!.sku}
                  onChange={handlePackageChange}
                  style={{ appearance: "auto" }}
                >
                  <BundlesDropDown />
                </Form.Control>
              </Form.Group>
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={12} xl={4} className="px-0 ms-md-auto">
          <Summary />
        </Col>
      </Row>
      <Row className="terms-container">
        <Col lg={12} xl={7} className="px-0">
          <h3>Agree to Our Terms</h3>
          <Card>
            <Card.Body>
              <Card.Text>
                <div className="form-check">
                  <Field
                    className="form-check-input"
                    type="checkbox"
                    name="membership.fitnessPolicy"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    By checking this box, I acknowledge that I have read and
                    agree to comply with the terms and conditions of the{" "}
                    <a href="/agreement" target="_blank" className="a-link">
                      Fitness Program Agreement
                    </a>
                  </label>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <FooterButtons
        onSubmit={handleMembership}
        onBack={previous}
        submitDisabled={errors.membership !== undefined || !dirty}
      />
    </Fragment>
  );
};

export default Members;
