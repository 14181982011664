import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useState } from "react";
import { checkPreview, setPromoCodeMutation } from "../graphql/Mutations";
import { enrollment } from "../graphql/Queries";
import { convertToCurrency } from "../utility/util";
type PaymentPage = {
  hostedPaymentPageId: string;
  hostedPaymentPageUrl: string;
  key: string;
  signature: string;
  tenantId: string;
  token: string;
};

type PackageDetails = {
  name: string;
  subtotal: number;
};

type PromoCodeDetails = {
  code: string;
  status: string;
  description: string;
};

type SummaryType = {
  sku: string;
  handle: string;
  onetimeCreditTotal: number;
  onetimeDiscount: number;
  onetimeDiscounts: string[];
  onetimePackageDetails: PackageDetails[];
  onetimeSubtotal: number;
  onetimeSubtotalAfterDiscount: number; //before tax after discount
  onetimeTaxes: number;
  onetimeTotal: number;
  onetimeSetupFee: number;
  periodicDiscount: number;
  periodicDiscounts: string[];
  periodicPackageDetails: PackageDetails[];
  periodicSubtotal: number;
  periodicTaxes: number;
  periodicTotal: number;
  periodicServiceStartDate: string;
  // promoCode: string;
  // promoCodeDescription: string;
  // promoCodeStatus: string;
  promoCodes: PromoCodeDetails[];
  paymentPageCC: PaymentPage;
  paymentPageECheck: PaymentPage;
  paymentMethodRequired: boolean;
  taxCalculated: boolean;
};

export type SummaryUIMap = {
  pending?: {
    //edit plan summary only
    planTotal: string;
    planList: PackageDetails[];
    discounts: string[];
    showDiscounts: boolean;
    discountsTotal: string;
    taxes: string;
    total: string;
    startDate: string;
  };
  due?: {
    //edit plan summary only
    planTotal: string;
    planList: PackageDetails[];
    credits: string;
    discounts: string[];
    discountsTotal: string;
    discountsTotalMinusDigital: string;
    promoCodes: PromoCodeDetails[];
    // promoCode: string;
    // promoCodeDescription: string;
    // promoCodeStatus: string;
    subtotal: string;
    enrollmentFee?: string;
    taxes: string;
    total: string;
  };
  checkout?: {
    //can be used in checkout summary, possibly in edit plan
    planTotal: string;
    planList: PackageDetails[];
    enrollmentFee?: string;
    discounts: string[];
    showDiscounts: boolean;
    discountsTotal: string;
    discountsTotalMinusDigital: string;
    subtotal: string;
    startDate: string;
    monthlyTotal: string;
    total: string;
    taxes: string;
  };
};

type SummaryContextType = {
  setMutated: (val: boolean) => void;
  cancel: () => void;
  forceRefresh: () => void;
  setPromoCode: (code: string) => void;
  mutated: boolean;
  force: boolean;
  summary: SummaryType | undefined;
  error: boolean;
  loading: boolean;
  promoLoading: boolean;
  uiSummary: SummaryUIMap | null;
};

export const SummaryContext = React.createContext<SummaryContextType>({
  setMutated: () => {
    return;
  },
  cancel: () => {
    return;
  },
  setPromoCode: () => {
    return;
  },
  forceRefresh: () => {
    return;
  },
  uiSummary: null,
  mutated: false,
  force: false,
  summary: undefined,
  error: false,
  loading: true,
  promoLoading: false,
});

const pollInterval = 500;

// TODO - use a reducer pattern
const SummaryProvider = ({ children }: { children: React.ReactNode }) => {
  const initialState = {
    mutated: false,
    force: false,
    shouldPoll: false,
    isLoading: false,
    promoLoading: false,
    errorRequest: false,
  };

  const [summary, setSummary] = useState<SummaryType>();

  const [state, setState] = useState(initialState);

  let uiMapping: SummaryUIMap | null = null;

  const getDigitalPlanIndex = (planList: PackageDetails[]) => {
    return planList.findIndex((obj) => obj.name.includes("Digital"));
  };

  const changeDigitalPlanValues = (digitalPlan: PackageDetails) => {
    if (digitalPlan?.name === "Digital") {
      digitalPlan.name = "Digital (included)";
      digitalPlan.subtotal = 0;
    }
  };

  const digitalPlanChanges = (summary: SummaryType) => {
    if (summary.sku !== "FITNESS-DIGITAL") {
      if (summary.onetimePackageDetails.length > 0) {
        const packageDetails = summary.onetimePackageDetails;
        const index = getDigitalPlanIndex(packageDetails);
        changeDigitalPlanValues(packageDetails[index]);
      }

      if (summary.periodicPackageDetails.length > 0) {
        const packageDetails = summary.periodicPackageDetails;
        const index = getDigitalPlanIndex(packageDetails);
        changeDigitalPlanValues(packageDetails[index]);
      }
    }
  };

  if (summary) {
    // Alter the way digital plan shows when included as part of another package
    digitalPlanChanges(summary);

    uiMapping = {
      checkout: {
        planTotal: convertToCurrency(
          summary.onetimePackageDetails.length > 1
            ? summary.onetimePackageDetails
                .filter((plan) => plan.name !== "Digital (included)")
                .reduce((a, b) => a + b.subtotal, 0)
            : summary.onetimePackageDetails[0]?.subtotal
        ),
        planList:
          summary.onetimePackageDetails.sort((a, b) =>
            a.name.localeCompare(b.name)
          ) ?? [],
        discounts:
          summary.onetimeDiscounts.filter(
            (discount) => discount !== "Digital Discount"
          ) ?? [],
        showDiscounts: summary.promoCodes.length === 0 ? false : true,
        discountsTotal: convertToCurrency(
          Math.abs(summary?.onetimeDiscount ?? 0)
        ),
        discountsTotalMinusDigital: convertToCurrency(
          summary.onetimeDiscount + 10
        ),
        subtotal: convertToCurrency(summary?.onetimeSubtotalAfterDiscount ?? 0),
        monthlyTotal: convertToCurrency(summary?.periodicTotal ?? 0),
        startDate: new Date(
          `${summary.periodicServiceStartDate}T00:00:00` ?? ""
        ).toLocaleDateString("en-US", {
          timeZone: "UTC",
          month: "long",
          day: "numeric",
        }),
        total: convertToCurrency(summary?.onetimeTotal ?? 0),
        taxes: convertToCurrency(summary?.onetimeTaxes ?? 0),
        enrollmentFee: convertToCurrency(summary?.onetimeSetupFee ?? 0),
      },
      due: {
        credits: convertToCurrency(summary.onetimeCreditTotal ?? 0),
        discounts:
          summary.onetimeDiscounts.filter(
            (discount) => discount !== "Digital Discount"
          ) ?? [],
        discountsTotal: convertToCurrency(summary.onetimeDiscount ?? 0),
        discountsTotalMinusDigital: convertToCurrency(
          summary.onetimeDiscount - 10
        ),
        planTotal: convertToCurrency(
          summary.onetimePackageDetails.length > 0
            ? summary.onetimePackageDetails.reduce((a, b) => a + b.subtotal, 0)
            : 0
        ),
        planList: summary.onetimePackageDetails ?? [],
        promoCodes: summary.promoCodes ?? [],
        // promoCode: summary.promoCode ?? "",
        // promoCodeDescription: summary.promoCodeDescription,
        // promoCodeStatus: summary.promoCodeStatus,
        //TODO -NEED THIS
        subtotal: convertToCurrency(summary.onetimeSubtotalAfterDiscount ?? 0),
        enrollmentFee: convertToCurrency(summary.onetimeSetupFee ?? 0),
        taxes: convertToCurrency(summary.onetimeTaxes ?? 0),
        total: convertToCurrency(summary.onetimeTotal ?? 0),
      },
      pending: {
        total: convertToCurrency(summary.periodicTotal ?? 0),
        discounts:
          summary.periodicDiscounts.filter(
            (discount) => discount !== "Digital Discount"
          ) ?? [],
        showDiscounts: summary.periodicDiscounts.some((discount) =>
          discount !== "Digital Discount" ? true : false
        ),
        discountsTotal: convertToCurrency(summary.periodicDiscount ?? 0),
        planTotal: convertToCurrency(
          summary.periodicPackageDetails.length > 1
            ? summary.periodicPackageDetails
                .filter((plan) => plan.name !== "Digital (included)")
                .reduce((a, b) => a + b.subtotal, 0)
            : summary.periodicPackageDetails[0]?.subtotal
        ),
        planList:
          summary.periodicPackageDetails.sort((a, b) =>
            a.name.localeCompare(b.name)
          ) ?? [],
        startDate: new Date(
          `${summary.periodicServiceStartDate}T00:00:00` ?? ""
        ).toLocaleDateString("en-US", {
          timeZone: "UTC",
          month: "long",
          day: "numeric",
        }),
        taxes: convertToCurrency(summary.periodicTaxes) ?? "$0.00",
      },
    };
  }

  const [setPromo] = useMutation(setPromoCodeMutation, {
    onCompleted: () => {
      setState((s) => ({
        ...s,
        mutated: true,
      }));
    },
    onError: () => {
      setState((s) => ({
        ...s,
        promoLoading: false,
        errorRequest: true,
      }));
    },
  });

  const { startPolling, stopPolling } = useQuery(enrollment, {
    fetchPolicy: "no-cache",
    skip: !state.shouldPoll,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.enrollment?.state) {
        const res = data.enrollment.state;
        if (res.orderHandleStatus === "COMPLETED") {
          setSummary({
            sku: res.orderInput.sku,
            handle: res.orderHandle,
            onetimeCreditTotal: res.order.onetimeCreditTotal,
            onetimeDiscount: res.order.onetimeDiscount,
            onetimeDiscounts: res.order.onetimeDiscounts ?? [],
            onetimePackageDetails: res.order.onetimePackageDetails ?? [],
            onetimeSubtotal: res.order.onetimeSubtotal,
            onetimeSubtotalAfterDiscount:
              res.order.onetimeSubtotalAfterDiscount,
            onetimeTaxes: res.order.onetimeTaxes,
            onetimeTotal: res.order.onetimeTotal,
            onetimeSetupFee: res.order.onetimeSetupFee,
            periodicDiscount: res.order.periodicDiscount,
            periodicDiscounts: res.order.periodicDiscounts ?? [],
            periodicPackageDetails: res.order.periodicPackageDetails ?? [],
            periodicSubtotal: res.order.periodicSubtotal,
            periodicTotal: res.order.periodicTotal,
            periodicServiceStartDate: res.order.periodicServiceStartDate,
            paymentMethodRequired: res.order.paymentMethodRequired,
            periodicTaxes: res.order.periodicTaxes,
            promoCodes: res.order.promoCodes ?? [],
            // promoCode: res.order.promoCode,
            // promoCodeDescription: res.order.promoCodeDescription,
            // promoCodeStatus: res.order.promoCodeStatus,
            taxCalculated: res.order.taxCalculated,
            paymentPageCC: {
              hostedPaymentPageId: res.paymentPageCc.hostedPaymentPageId,
              hostedPaymentPageUrl: res.paymentPageCc.hostedPaymentPageUrl,
              key: res.paymentPageCc.key,
              signature: res.paymentPageCc.signature,
              tenantId: res.paymentPageCc.tenantId,
              token: res.paymentPageCc.token,
            },
            paymentPageECheck: {
              hostedPaymentPageId: res.paymentPageECheck.hostedPaymentPageId,
              hostedPaymentPageUrl: res.paymentPageECheck.hostedPaymentPageUrl,
              key: res.paymentPageECheck.key,
              signature: res.paymentPageECheck.signature,
              tenantId: res.paymentPageECheck.tenantId,
              token: res.paymentPageECheck.token,
            },
          });
          setState((s) => ({
            ...s,
            shouldPoll: false,
            promoLoading: false,
            isLoading: false,
            force: false,
          }));
        } else if (res.orderHandleStatus === "ERROR") {
          setState((s) => ({
            ...s,
            promoLoading: false,
            isLoading: false,
            shouldPoll: false,
            force: false,
            errorRequest: true,
          }));
        }
      } else {
        setState((s) => ({
          ...s,
          promoLoading: false,
          isLoading: false,
          shouldPoll: false,
          force: false,
          errorRequest: true,
        }));
      }
    },
    onError: (error) => {
      setState((s) => ({
        ...s,
        errorRequest: error !== undefined,
        shouldPoll: false,
        promoLoading: false,
        isLoading: false,
        force: false,
      }));
    },
  });

  const handleMutate = (val: boolean) => {
    setState((s) => ({
      ...s,
      mutated: val,
    }));
  };

  const handleCancel = () => {
    stopPolling();
    setState(initialState);
    setSummary(undefined);
  };

  const [checkPreviewMutation] = useMutation(checkPreview, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.enrollmentPreviewOrderAction.state) {
        const res = data.enrollmentPreviewOrderAction.state;
        //not on initial load
        if (
          (res.orderHandle !== summary?.handle || state.force) &&
          res.phase === "PREVIEW"
        ) {
          setState((s) => ({
            ...s,
            shouldPoll: true,
          }));
        } else {
          setState((s) => ({
            ...s,
            promoLoading: false,
            isLoading: false,
            shouldPoll: false,
          }));
        }
      }
    },
    onError: (error) => {
      setState((s) => ({
        ...s,
        shouldPoll: false,
        isLoading: false,
        errorRequest: error !== undefined,
      }));
    },
  });

  useEffect(() => {
    if (state.shouldPoll) {
      startPolling(pollInterval);
    } else {
      stopPolling();
    }
    return () => stopPolling();
  }, [state]);

  useEffect(() => {
    if (state.mutated) {
      checkPreviewMutation({
        variables: { force: state.force },
      });
      setState((s) => ({
        ...s,
        errorRequest: false,
        mutated: false,
        isLoading: true,
      }));
    }
    () => {
      setState((s) => ({
        ...s,
        mutated: false,
        isLoading: false,
      }));
    };
  }, [state]);

  const setPromoCode = async (code: string) => {
    setState((s) => ({
      ...s,
      promoLoading: true,
    }));
    await setPromo({
      variables: {
        promo: code,
      },
    });
  };

  const forceRefresh = () => {
    setState((s) => ({
      ...s,
      force: true,
      mutated: true,
    }));
  };

  return (
    <SummaryContext.Provider
      value={{
        setMutated: handleMutate,
        cancel: handleCancel,
        forceRefresh,
        setPromoCode,
        mutated: state.mutated,
        summary: summary,
        error: state.errorRequest,
        loading: state.isLoading,
        force: state.force,
        promoLoading: state.promoLoading,
        uiSummary: uiMapping,
      }}
    >
      {children}
    </SummaryContext.Provider>
  );
};

export default SummaryProvider;
