import React from "react";
import { useHistory } from "react-router-dom";
import FeatureButton from "../features/FeatureButton";

const PackageSelect = ({
  header,
  subHeader,
  description,
  digitalHeader,
  digitalSubHeader,
  digitalDescription,
}: {
  header: string;
  subHeader: string;
  description: string;
  digitalHeader: string;
  digitalSubHeader: string;
  digitalDescription: string;
}) => {
  const history = useHistory();
  const packageData = [
    {
      tier: "base",
      price: 19,
      gymCount: 3000,
      elite: false,
      bestValue: false,
    },
    {
      tier: "core",
      price: 29,
      gymCount: 8000,
      elite: false,
      bestValue: false,
    },
    {
      tier: "power",
      price: 39,
      gymCount: 12000,
      elite: false,
      bestValue: true,
    },
    {
      tier: "elite",
      price: 99,
      gymCount: 12000,
      elite: true,
      bestValue: false,
    },
  ];

  return (
    <div className="feature-package-select">
      <section>
        <h1>{header}</h1>
        <p className="h3">{subHeader}</p>
        <p className="h4">
          <i>{description}</i>
        </p>
      </section>
      <section className="feature-package-select__cards d-flex flex-wrap">
        {packageData.map((card, index) => (
          <div
            className={`feature-package-select__card ${
              card.bestValue ? "best-value" : ""
            }`}
            key={`feature-card-${index}`}
          >
            <div className="feature-package-select__tier-tag">{card.tier}</div>
            <div className="feature-package-select__price-container">
              <div className="feature-package-select__price">${card.price}</div>
              <div className="feature-package-select__duration">/ month</div>
            </div>
            <div className="feature-package-select__gym-count-container">
              <div className="feature-package-select__check-mark-icon">
                <svg width="11.979" height="8.933">
                  <path
                    d="M4.069 8.758L.176 4.865a.6.6 0 0 1 0-.847l.847-.847a.6.6 0 0 1 .847 0l2.623 2.623L10.11.177a.6.6 0 0 1 .847 0l.847.847a.6.6 0 0 1 0 .847L4.916 8.758a.6.6 0 0 1-.847 0z"
                    fill="#82b265"
                  />
                </svg>
              </div>
              <div className="feature-package-select__gym-count">
                &nbsp;{card.gymCount}+&nbsp;
              </div>
              <div className="feature-package-select__gym-text">gyms</div>
            </div>
            <div className="feature-package-select__studio-class-reward">
              <svg width="11.979" height="8.933">
                <path
                  d="M4.069 8.758L.176 4.865a.6.6 0 0 1 0-.847l.847-.847a.6.6 0 0 1 .847 0l2.623 2.623L10.11.177a.6.6 0 0 1 .847 0l.847.847a.6.6 0 0 1 0 .847L4.916 8.758a.6.6 0 0 1-.847 0z"
                  fill="#82b265"
                />
              </svg>
              &nbsp;Digital Content
            </div>
            <div className="feature-package-select__elite">
              {card.elite ? (
                <div className="elite-container">
                  <div className="elite-top-text">1 Elite gym choice</div>
                  <div className="elite-bottom-text">
                    from <span>100+</span> available
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="feature-package-select__explore-locations">
              <a
                href={`/locations?tier=${card.tier}`}
                style={{ color: "#0D7BAB" }}
              >
                <svg width="12.439" height="12.441">
                  <path
                    d="M12.27 10.756L9.848 8.334a.583.583 0 0 0-.413-.17h-.4a5.051 5.051 0 1 0-.875.875v.4a.583.583 0 0 0 .17.413l2.422 2.422a.581.581 0 0 0 .824 0l.688-.688a.586.586 0 0 0 .006-.83zM5.054 8.164a3.11 3.11 0 1 1 3.11-3.11 3.108 3.108 0 0 1-3.11 3.11z"
                    fill="#1099d6"
                  />
                </svg>
                &nbsp;Explore locations
              </a>
            </div>
            {/* <button type="button" className="btn btn-primary"><span>Select</span><svg aria-hidden="true" data-prefix="fas" data-icon="chevron-right" viewBox="0 0 320 512">
              <path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
            </button> */}
            <FeatureButton
              text="select"
              onClick={() => history.push(`/enroll/package?tier=${card.tier}`)}
            />
            {card.bestValue && (
              <div className="best-value-tag">the best value</div>
            )}
          </div>
        ))}
      </section>
      <section className="digital-only-text">
        <h1>{digitalHeader}</h1>
        <p className="h3">{digitalSubHeader}</p>
        <p className="h4">
          <i>{digitalDescription}</i>
        </p>
      </section>
      <section>
        <div className="digital-content-only-container">
          <div className="digital-content-only-container__tag">
            digital content only
          </div>
          <div className="digital-content-only-container__content-row">
            <div className="digital-content-only-container__price-container">
              <div className="digital-content-only-container__price">$10</div>
              <div className="digital-content-only-container__duration">
                / month
              </div>
            </div>
            <div className="digital-content-only-container__content-column">
              <div className="digital-content-only-container__description">
                <div className="digital-content-only-container__description-top-line">
                  Add hundreds of on-demand videos and live streams to your
                  plan.
                </div>
                <div className="digital-content-only-container__description-bottom-line">
                  Content includes workouts, wellness videos, nutrition
                  tutorials, and more!
                </div>
              </div>
            </div>
          </div>
          <FeatureButton
            text="select"
            onClick={() => history.push(`/enroll/package?tier=digital`)}
          />
        </div>
      </section>
    </div>
  );
};

export default PackageSelect;
