import React, { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { client, contentfulConfig } from "../client";
import Section from "../components/faq/Section";
import StillHaveQuestions from "../components/faq/StillHaveQuestions";
import { convertToFAQStillHaveQuestions } from "../utility/contentful";
import { EntryCollection, Entry } from "contentful";
import {
  IFaqSectionFields,
  IStillHaveQuestionsFields,
} from "../@types/generated/contentful";

const FAQ = ({ tags }: { tags: string }) => {
  const [content, setContent] =
    useState<EntryCollection<IFaqSectionFields> | null>(null);
  const [bottom, setBottom] =
    useState<EntryCollection<IStillHaveQuestionsFields> | null>(null);

  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<IFaqSectionFields>({
        content_type: "faqSection",
        links_to_entry: contentfulConfig.ids.faq,
        "metadata.tags.sys.id[all]": tags,
        order: "fields.priority",
        include: 10,
      });
      setContent(entry);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<IStillHaveQuestionsFields>({
        content_type: "stillHaveQuestions",
        links_to_entry: contentfulConfig.ids.faq,
        "metadata.tags.sys.id[all]": tags,
        include: 1,
      });
      setBottom(entry);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Container className="faq-page faq-page__container">
        <h1 className="faq-page faq-page__header">
          Frequently Asked Questions
        </h1>
        {content?.items.map((item: Entry<IFaqSectionFields>, i: number) => (
          <Section data={item} key={i} />
        ))}
        <div className="faq-page faq-page__bottom">
          {bottom?.items.map(
            (item: Entry<IStillHaveQuestionsFields>, i: number) => (
              <StillHaveQuestions
                {...convertToFAQStillHaveQuestions(item)}
                key={i}
              />
            )
          )}
        </div>
      </Container>
      {/* <section id="legal">
        <div className="legal-footer">
          <Footer />
        </div>
      </section> */}
    </Fragment>
  );
};
export default FAQ;
