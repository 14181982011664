import React, { useContext, useEffect, useState } from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import {
  deletePaymentMethod,
  fetchPaymentMethods,
  Payment,
  setAsDefaultPaymentMethod,
  updatePaymentMethod,
} from "../../../api/enrollment/Queries";
import PaymentWidget from "../PaymentWidget";
import DeletePaymentModal from "./DeletePaymentModal";
import AddPaymentModal from "./AddPaymentModal";
import EditPaymentModal from "./EditPaymentModal";
import ProfileToast from "../../../utility/ProfileToast";
import { ClientConfigContext } from "../../../context/ClientConfigProvider";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PaymentMethods = () => {
  const { client } = useContext(ClientConfigContext);
  const [paymentMethods, setPaymentMethods] = useState<Payment[] | null>(null);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<string>("");
  const [editPayment, setEditPayment] = useState<Payment | null>(null);
  const [reloadToggle, setReloadToggle] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const res = await fetchPaymentMethods();
        setPaymentMethods(res.paymentMethods);
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    })();
  }, [reloadToggle]);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length === 1) {
      if (!paymentMethods[0].isDefault) {
        setAsDefault(paymentMethods[0].id);
      }
    }
  }, [paymentMethods]);

  const onEdit = (paymentMethod: Payment) => async (): Promise<void> => {
    setEditLoading(true);
    const res = await updatePaymentMethod(paymentMethod);
    const updated: Payment[] = [];
    paymentMethods?.map((p) => {
      if (p.id === res.id) {
        updated.push(res);
      } else {
        updated.push(p);
      }
    });
    setPaymentMethods(updated);
    setOpenEditModal(false);
    ProfileToast(`Your payment method has been updated.`, true, client);
    setEditLoading(false);
  };

  const onAdd = () => {
    setReloadToggle(!reloadToggle);
    ProfileToast(`Your payment method has been added.`, true, client);
  };

  const onRemove = (id: string) => async () => {
    try {
      await deletePaymentMethod(id);
    } catch (err: unknown) {
      ProfileToast(`Your payment method could not be deleted.`, false, client);
      return;
    }

    const updated: Payment[] = [];
    paymentMethods?.map((p) => {
      if (p.id != id) {
        updated.push(p);
      }
    });
    setPaymentMethods(updated);
    ProfileToast(`Your payment method has been deleted.`, true, client);
    setOpenDeleteModal(false);
    setDeleteID("");
  };

  const openAndSetDelete = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteID(id);
  };

  const setAsDefault = (id: string) => {
    setLoading(true);
    const res = setAsDefaultPaymentMethod(id);
    res.then((p) => {
      if (p) {
        setReloadToggle(!reloadToggle);
        ProfileToast(
          `Your default payment method has been succesfully changed.`,
          true,
          client
        );
      } else {
        ProfileToast(
          `There was an error changing your default payment method.`,
          false,
          client
        );
      }
    });
    // if(res === true){

    // }
  };

  const openAndSetEdit = (payment: Payment) => {
    setOpenEditModal(true);
    setEditPayment(payment);
  };

  //fetch payment methods
  return (
    <div className="payment-methods">
      {openAddModal ? (
        <AddPaymentModal
          open={openAddModal}
          onClose={() => setOpenAddModal(!openAddModal)}
          onAdd={onAdd}
        />
      ) : null}
      {openDeleteModal ? (
        <DeletePaymentModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(!openDeleteModal)}
          onDelete={onRemove(deleteID)}
        />
      ) : null}
      {openEditModal ? (
        <EditPaymentModal
          open={openEditModal}
          loading={editLoading}
          payment={editPayment}
          onClose={() => setOpenEditModal(!openEditModal)}
          onEdit={onEdit}
        />
      ) : null}
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner animation="border" />
        </div>
      ) : null}
      {error && (
        <div className="alert alert-danger" role="alert">
          There was an error retrieving your payment methods.
        </div>
      )}
      {!error &&
        paymentMethods &&
        paymentMethods.map((p, i) => (
          <PaymentWidget key={p.id} selected={p.isDefault}>
            <PaymentWidget.Body>
              <PaymentWidget.Label>{p.name}</PaymentWidget.Label>
              {p.paymentMethodType === "CreditCard" && (
                <PaymentWidget.Label>
                  {p.card?.type.toUpperCase()} {p.card?.number}
                </PaymentWidget.Label>
              )}
              {p.paymentMethodType === "CreditCard" && (
                <PaymentWidget.Label>
                  Exp: {p.card?.expMonth}/{p.card?.expYear} Zip Code:{" "}
                  {p.card?.zip}
                </PaymentWidget.Label>
              )}
              {p.paymentMethodType === "ACH" && (
                <PaymentWidget.Label>
                  {p.ach?.bankName}, {p.ach?.type} {p.ach?.mask}
                </PaymentWidget.Label>
              )}
            </PaymentWidget.Body>
            <PaymentWidget.Footer>
              {p.isDefault ? (
                <span className="payment-widget__default">
                  <i>This is your default</i>
                </span>
              ) : (
                <PaymentWidget.Button
                  index={i}
                  className="payment-widget w-50"
                  setDefault={() => setAsDefault(p.id)}
                  variant="success"
                >
                  Set as default
                </PaymentWidget.Button>
              )}
              {p.paymentMethodType === "CreditCard" ? (
                <PaymentWidget.Button
                  variant="success"
                  className="payment-widget__edit"
                  onClick={() => openAndSetEdit(p)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} /> Edit
                </PaymentWidget.Button>
              ) : (
                <></>
              )}

              {p.isDefault ? (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      Can not remove payment while it is the default Payment.
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <PaymentWidget.Button
                      className="payment-widget__remove"
                      variant="error"
                      disabled={p.isDefault}
                      onClick={() => openAndSetDelete(p.id)}
                    >
                      <b>X</b> Remove
                    </PaymentWidget.Button>
                  </span>
                </OverlayTrigger>
              ) : (
                <PaymentWidget.Button
                  className="payment-widget__remove"
                  variant="error"
                  disabled={p.isDefault}
                  onClick={() => openAndSetDelete(p.id)}
                >
                  <b>X</b> Remove
                </PaymentWidget.Button>
              )}
            </PaymentWidget.Footer>
          </PaymentWidget>
        ))}

      <div className="payment-methods__add-new">
        <Button onClick={() => setOpenAddModal(true)}>Add New</Button>
      </div>
    </div>
  );
};

export default PaymentMethods;
