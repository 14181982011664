import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import StatusModal from "../../wizard/components/StatusModal";

const WidgetCard = ({
  iconName,
  title,
  imageUrl,
  content,
  linkText,
  linkUrl,
  external,
}: {
  iconName: IconProp;
  title: string;
  imageUrl: string;
  content: string;
  linkText: string;
  linkUrl: string | null;
  external?: boolean;
}) => {
  const [show, setShow] = useState(false);

  const handleSubmit = () => {
    setShow(false);
    window.open(linkUrl ? linkUrl : "");
  };
  const handleOpen = () => {
    setShow(true);
  };
  const handleCancel = () => {
    setShow(false);
  };

  return (
    <>
      <div className="widget__card">
        <div className="widget__position mw-50">
          <img src={imageUrl} alt="image" className="widget__img img-fluid" />
        </div>
        <div className="widget__overlay-text">
          <FontAwesomeIcon icon={iconName} />
          <div
            className="widget__icon"
            dangerouslySetInnerHTML={{ __html: `${title}` }}
          />
        </div>
        <div className="widget__content">
          <p>{content}</p>
        </div>
        <div className="widget__link">
          {linkUrl ? (
            <>
              {external ? (
                <>
                  <StatusModal
                    show={show}
                    status={"BURNALONG"}
                    message=""
                    onCancel={handleCancel}
                    onNext={handleSubmit}
                  />
                  <button
                    // href={linkUrl}
                    className="widget__btn align-self-center mb-3 m-lg-0 widget__link-button text-center"
                    // target="_blank"
                    // rel="noreferrer"
                    onClick={handleOpen}
                  >
                    {linkText}
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className="widget__link-icon"
                    />
                  </button>
                </>
              ) : (
                <NavLink to={linkUrl}>
                  <button className="widget__btn align-self-center mb-3 m-lg-0 widget__link-button">
                    {linkText}
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className="widget__link-icon"
                    />
                  </button>
                </NavLink>
              )}
            </>
          ) : (
            <button className="widget__btn align-self-center mb-3 m-lg-0 widget__link-button">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default WidgetCard;
