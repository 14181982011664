import React, { Fragment, useEffect, useState } from "react";
import { client, contentfulConfig } from "../client";
import FeatureFactory from "../components/features/FeatureFactory";
import PoweredBy from "../components/shared/PoweredBy";
import LegalDisclaimer from "../components/shared/LegalDisclaimer";
import { EntryCollection, Entry } from "contentful";
import { IFeatureFields, ITabPaneFields } from "../@types/generated/contentful";

const HowItWorks = ({ tags }: { tags: string }) => {
  const [features, setFeatures] =
    useState<EntryCollection<IFeatureFields> | null>(null);
  const [tabContent, setTabContent] =
    useState<EntryCollection<ITabPaneFields> | null>(null);
  const [step, setStep] = useState<number>(0);
  useEffect(() => {
    (async () => {
      const f = await client.getEntries<IFeatureFields>({
        content_type: "feature",
        links_to_entry: contentfulConfig.ids.howItWorks,
        "metadata.tags.sys.id[all]": tags,
        order: "fields.priority",
        include: 10,
      });
      const t = await client.getEntries<ITabPaneFields>({
        content_type: "tabPane",
        links_to_entry: contentfulConfig.ids.howItWorks,
        "metadata.tags.sys.id[all]": tags,
        order: "fields.priority",
        include: 10,
      });
      setTabContent(t);
      setFeatures(f);
    })();
    // eslint-disable-next-line
  }, []);

  const handleStep = (s: number) => () => {
    setStep(s);
  };
  return (
    <Fragment>
      {features?.items.map((item: Entry<IFeatureFields>) => (
        <FeatureFactory
          type={item.fields.type ?? ""}
          data={item}
          key={item.fields.type ?? ""}
          params={{
            onStep: handleStep,
            step: step,
            tabContent: tabContent?.items,
          }}
        />
      ))}
      <LegalDisclaimer tags={tags} />
      <PoweredBy />
    </Fragment>
  );
};

export default HowItWorks;
