import { Form, useFormikContext } from "formik";
import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import AddressForm from "../../wizard/components/AddressForm";
import { IAddressFields, SmartyAddress } from "../../wizard/types";
import "react-toastify/dist/ReactToastify.css";
import AddressVerificationModal from "../../wizard/components/AddressVerificationModal";

export interface CurrentAddress {
  id: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  defaultFor?: string[];
}
const ShippingAddress = ({
  verifyOpen,
  setVerifyOpen,
  handle,
  setIsVerifiedAddress,
}: {
  verifyOpen: boolean;
  setVerifyOpen: (val: boolean) => void;
  handle: (values: IAddressFields) => void;
  setIsVerifiedAddress: (val: boolean) => void;
}) => {
  const { values, setFieldValue, setFieldTouched, handleChange } =
    useFormikContext<IAddressFields>();
  const [disable, setDisable] = useState(true);

  const setAddressValues = (smartAddressArr: SmartyAddress[]) => {
    if (smartAddressArr.length > 0) {
      //timeouts required by formik :/
      const smartAddress = smartAddressArr[0];
      setFieldValue("street1", smartAddress.address.streetLine);
      setTimeout(() => setFieldTouched("street1", true));
      setFieldValue("street2", smartAddress.address.secondary);
      setTimeout(() => setFieldTouched("street2", true));
      setFieldValue("city", smartAddress.address.city);
      setTimeout(() => setFieldTouched("city", true));
      setFieldValue("state", smartAddress.address.state);
      setTimeout(() => setFieldTouched("state", true));
      setFieldValue("zip", smartAddress.address.zipcode);
      setTimeout(() => setFieldTouched("zip", true));
      setIsVerifiedAddress(false);
    }
  };

  const handleChangeWrapper = (e: React.ChangeEvent<unknown>) => {
    setDisable(false);
    setIsVerifiedAddress(true);
    handleChange(e);
  };

  const handleInputChange = (input: string) => {
    setFieldValue("street1", input);
    setTimeout(() => setFieldTouched("street1", true));
    setIsVerifiedAddress(true);
    setDisable(false);
  };

  const handleCancel = () => {
    setVerifyOpen(false);
  };
  const handleConfirm = () => {
    handle(values);
  };

  const formatHomeAddress = (): string => {
    return `${values.street1}${
      values.street2 !== "" ? " " + values.street2 : ""
    } ${values.city}, ${values.state} ${values.zip}`;
  };

  return (
    <>
      <AddressVerificationModal
        homeAddress={formatHomeAddress()}
        billingAddress={undefined}
        show={verifyOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <div className="edit-profile">
        <span>Home Address</span>
        <p className="mb-0">
          This is the address that will show on your invoices and is used to
          calculate tax rates.
        </p>
        <Card.Body className="p-0">
          <Form style={{ maxWidth: "516px" }}>
            <AddressForm
              //select an item from suggestions true
              onChangeSmarty={setAddressValues}
              //every other field apart from street1 false
              onChange={handleChangeWrapper}
              //regular typing in street1 input field  false
              onInputChange={handleInputChange}
              prefix=""
              values={values}
            />
            <Row>
              <Col className="p-0">
                <button
                  type="submit"
                  className="nav-btn-enroll font-weight-bold btn btn-primary p-0"
                  disabled={disable}
                >
                  Save Changes
                </button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        <div className="vp__personal-info my-4" />
      </div>
    </>
  );
};
export default ShippingAddress;
