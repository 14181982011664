import React from "react";
import { IActivity } from "../Tiles/ActivityLogTile";
import { momentStartOfDay } from "../../../utility/util";

interface IProps {
  activity: IActivity;
}

const ActivityListItemTile = ({ activity }: IProps) => (
  <div className="activity-log__activity-item-tile">
    <div className=" p-1 mb-1 d-flex justify-content-between">
      <p className="m-0 p-2 col">{activity.location}</p>
      <p className="m-0 p-2 col d-flex justify-content-end">
        {momentStartOfDay(activity.date).format("MM/DD/YYYY")}
      </p>
    </div>
  </div>
);

export { ActivityListItemTile };
