import React, { useContext } from "react";
import { Tile } from "./Tiles/Tile";
import { Button, Col } from "react-bootstrap";
import ReEnrollImg from "./Tiles/assets/fyw_reenroll.png";
import { Link } from "react-router-dom";
import { AccountContext } from "../../context/AccountProvider";

/**
 *
 *  Enroll and gain access to our vast network of participating gyms and
            digital content from Les Mills and Burnalong. Packages start{" "}
            <b>as low as $10 a month.</b>
 */
const ReEnrollBanner = ({
  title,
  content,
  enrollHref,
  exploreHref,
}: {
  title: React.ReactNode;
  content: React.ReactNode;
  enrollHref: string;
  exploreHref?: string;
}) => {
  const { enrollmentFrame } = useContext(AccountContext);

  return (
    <Tile className="reenroll-banner">
      <Tile.THeader className="reenroll-banner__header">
        <div className="h-100">
          <img width="100%" height="100%" src={ReEnrollImg} alt="enroll"/>
        </div>
      </Tile.THeader>
      <Tile.TBody className="reenroll-banner__body">
        <div>
          <h5 className="text-left">
            <b>{title}</b>
          </h5>
          <hr />
          <p style={{ fontSize: `0.8rem` }} className="text-left p-0 mb-0">
            {content}
          </p>
        </div>
      </Tile.TBody>
      <Tile.TFooter
        className="reenroll-banner__footer"
        // className="w-25 d-flex mb-2 justify-content-end flex-column"
      >
        <div className="d-flex justify-content-between">
          {exploreHref && (
            <Col xs={6} style={{ paddingRight: "8px" }}>
              <Button
                as={Link}
                variant="outline-primary"
                style={{ whiteSpace: "nowrap", borderRadius: "20px" }}
                className="btn btn-outline-primary align-self-left btn-sm w-90"
                to={exploreHref}
              >
                Explore Gyms
              </Button>
            </Col>
          )}
          <Col xs>
            <Button
              as={Link}
              style={{ color: "white", borderRadius: "20px" }}
              className="btn btn-primary align-self-left btn-sm w-90"
              to={enrollHref}
            >
              {enrollmentFrame.status === "NONE" ? "Enroll" : "Re-Enroll"}
            </Button>
          </Col>
        </div>
      </Tile.TFooter>
    </Tile>
  );
};
export default ReEnrollBanner;
