import { useState, useEffect, useCallback, useContext } from "react";
import { ClientConfigContext } from "../context/ClientConfigProvider";
import useSummary from "./useSummary";

export type ZuoraProps = Record<string, string | number | boolean | null>;

export interface IZuoraResponse {
  success: boolean;
  refId: string;
  responseFrom: string;
}

interface IZuoraHook {
  render: () => void;
  handleSubmit: (e: unknown) => void;
  hasRendered: boolean;
}

const useZuora = ({
  paymentType,
  onSuccess,
  onSubmissionFailed,
  onRequestFailed,
}: {
  paymentType: string;
  onSuccess: (res: unknown) => unknown;
  onSubmissionFailed: (res: unknown) => unknown;
  onRequestFailed: (res: unknown) => unknown;
}): IZuoraHook => {
  const { summary } = useSummary();
  const { config } = useContext(ClientConfigContext);

  const [props, setProps] = useState<ZuoraProps>({
    tenantId: "",
    id: "",
    token: null,
    signature: null,
    style: "inline",
    key: null,
    submitEnabled: true,
    locale: "English (en)",
    param_supportedTypes: "AmericanExpress,Visa,MasterCard,Discover",
    url: config["zuora.hostedPagePath"],
    paymentGateway: config["zuora.paymentGatewayCode.cc"],
  });
  const [toRender, setToRender] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prepop] = useState({});
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setToRender(false);
    setRendered(false);
    setLoading(true);
  }, [paymentType]);

  useEffect(() => {
    //call the required api and set props
    if (summary?.paymentPageCC && summary?.paymentPageECheck) {
      if (paymentType === "CREDIT") {
        setProps((p) => ({
          ...p,
          ...{
            paymentGateway: config["zuora.paymentGatewayCode.cc"],
            tenantId: summary.paymentPageCC.tenantId,
            token: summary.paymentPageCC.token,
            id: summary.paymentPageCC.hostedPaymentPageId,
            signature: summary.paymentPageCC.signature,
            key: summary.paymentPageCC.key,
            url: summary.paymentPageCC.hostedPaymentPageUrl,
          },
        }));
      } else {
        setProps((p) => ({
          ...p,
          ...{
            paymentGateway: config["zuora.paymentGatewayCode.echeck"],
            tenantId: summary.paymentPageECheck.tenantId,
            token: summary.paymentPageECheck.token,
            id: summary.paymentPageECheck.hostedPaymentPageId,
            signature: summary.paymentPageECheck.signature,
            key: summary.paymentPageECheck.key,
            url: summary.paymentPageECheck.hostedPaymentPageUrl,
          },
        }));
      }
      setLoading(false);
    }
  }, [summary, paymentType]);

  const callback = useCallback(
    (response: IZuoraResponse) => {
      if (response.success) {
        onSuccess(response);
      } else {
        if (response.responseFrom === "Response_From_Submit_Page") {
          onSubmissionFailed(response);
        } else {
          onRequestFailed(response);
        }
      }
    },
    [onSuccess, onSubmissionFailed, onRequestFailed]
  );

  //render when we can.
  useEffect(() => {
    if (toRender && !loading && !rendered) {
      window.Z.render(props, prepop, callback);
      setRendered(true);
    }
  }, [toRender, loading, props, prepop, callback, rendered]);
  //
  const render = useCallback(() => {
    setToRender(true);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    window.Z.submit();
  }, []);

  return { render, handleSubmit, hasRendered: rendered };
};

export default useZuora;
