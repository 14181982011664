import React from "react";
import { FeatureInput, FeatureMobileBody } from "../../types";
import FeatureBase from "./FeatureBase";

const FeatureMobile = ({ input }: { input: FeatureInput }) => {
  const body = input.body as FeatureMobileBody;
  return (
    <FeatureBase
      background={`https://${input.media.slice(2)}`}
      backgroundColor={input.backgroundColor}
      leftAlign={true}
      size="small"
    >
      <div className="feature-content feature-mobile">
        {body.text}
        <div className="app-store-button-container">
          <img src={body.iosIcon} alt="IOS" height="54" />
          <img src={body.androidIcon} alt="Android" height="54" />
        </div>
      </div>
      <div>{/*input.callout*/}</div>
    </FeatureBase>
  );
};

export default FeatureMobile;
