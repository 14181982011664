import React, { useContext, useEffect, useState } from "react";
import { Tile } from "./Tiles/Tile";
import ArrowRotateLeftSolidBlue from "../../resources/ArrowRotateLeftSolidBlue.svg";
import DiscardCancellationModal from "./DiscardCancellationModal";
import { AccountContext } from "../../context/AccountProvider";
import skuMap from "../../utility/skuMap";
import {
  ENROLLMENT_ACTIONS,
  ORDER_FROM,
  placeOrder,
} from "../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import useCheckOrder from "../../hooks/useCheckOrder";
import ProfileToastDiscardPlan from "../../utility/ProfileToastDiscardPlan";
import { ClientConfigContext } from "../../context/ClientConfigProvider";
import { momentStartOfDay } from "../../utility/util";
import { Col, Row } from "react-bootstrap";

/**
 *
 *  Enroll and gain access to our vast network of participating gyms and
            digital content from Les Mills and Burnalong. Packages start{" "}
            <b>as low as $10 a month.</b>
 */
const DiscardCancelBanner = ({
  sku,
  discardUntil,
}: {
  sku: string;
  discardUntil: string;
}) => {
  const { start, success: checkSuccess, error: checkError } = useCheckOrder();
  const [placeOrderMutation] = useMutation(placeOrder, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const phase = data.enrollmentPlaceOrderAction.state.phase;
      if (phase && phase === "PERFORM") {
        start();
      }
    },
  });
  const { enrollmentFrame, refresh } = useContext(AccountContext);
  const [openDiscardCancellationModal, setOpenDiscardCancellationModal] =
    useState<boolean>(false);
  const [discardLoading, setDiscardLoading] = useState<boolean>(false);
  const myPlan = () => {
    const program = skuMap[sku];
    return program.bannerText ?? program.name;
  };
  const isDigital = enrollmentFrame.current?.sku === "FITNESS-DIGITAL";

  const { client, config } = useContext(ClientConfigContext);

  const handleDiscard = () => {
    setDiscardLoading(true);
    placeOrderMutation({
      variables: {
        from: ORDER_FROM.ACTION,
        action: ENROLLMENT_ACTIONS.DISCARD,
        paymentMethod: "",
      },
    });
  };

  useEffect(() => {
    if (checkSuccess) {
      setOpenDiscardCancellationModal(false);
      ProfileToastDiscardPlan(
        "Your plan change has been successfully discarded.",
        true,
        client
      );
      refresh();
    }
    if (checkError) {
      ProfileToastDiscardPlan(
        "Your plan change could not be discarded.",
        false,
        client
      );
    }
    setDiscardLoading(false);
  }, [checkSuccess, checkError]);

  const daysToDiscard = () => {
    const dayToDiscard = momentStartOfDay(discardUntil);
    return dayToDiscard.diff(momentStartOfDay(), "days");
  };

  return (
    <Tile className="discard-cancel-banner">
      <div className="discard-cancel-banner__body">
        <Row className="justify-content-between align-items-center">
          <Col className="discard-cancel-banner__header" xs={12} sm>
            <h5 className="text-left">
              <b>Changed your mind about cancelling your {myPlan()} package?</b>
            </h5>
            <p className="text-left p-0 mb-0">
              {isDigital ? (
                <>
                  You have {daysToDiscard()} days left to discard your
                  cancellation before you lose access to{" "}
                  {config["lesmills.enable"] == "true"
                    ? `Les
                Mills and`
                    : ``}{" "}
                  Burnalong. Your digital subscription is set to end on{" "}
                  {momentStartOfDay(discardUntil).format("MM/DD/YY")}.
                </>
              ) : (
                <>
                  You have {daysToDiscard()} days left to discard your
                  cancellation, and avoid fees if you want to re-enroll. Your
                  gym subscription is set to end effective{" "}
                  {momentStartOfDay(discardUntil).format("MM/DD/YY")}.
                </>
              )}
            </p>
          </Col>
          <Col className="justify-content-center d-flex" sm={4}>
            <button
              // variant="outline-primary"
              className="discard-cancel-banner__discard-button"
              onClick={() => setOpenDiscardCancellationModal(true)}
            >
              <span>
                <img
                  alt="arrow"
                  className="discard-cancel-banner__back-arrow"
                  src={ArrowRotateLeftSolidBlue}
                />
                <b>
                  {enrollmentFrame.pending?.sku === "FITNESS-DIGITAL"
                    ? "Discard Change"
                    : "Discard Cancellation"}
                </b>
              </span>
            </button>
          </Col>
        </Row>
      </div>
      <DiscardCancellationModal
        currentPackage={skuMap[enrollmentFrame.current?.sku ?? ""]?.name}
        open={openDiscardCancellationModal}
        takePlaceDate={
          enrollmentFrame?.current?.term ? enrollmentFrame?.current?.term : ""
        }
        onClose={() => setOpenDiscardCancellationModal(false)}
        onDiscard={handleDiscard}
        loading={discardLoading}
      />
    </Tile>
  );
};
export default DiscardCancelBanner;
