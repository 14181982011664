import { useQuery } from "@apollo/client";
import { useAuthKit } from "@authkitcom/react";
import React from "react";
import { useContext } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ImpersonationContext } from "../context/ImpersonationProvider";
import { impersonation } from "../graphql/Queries";

const Impersonate = () => {
  const { setValues } = useContext(ImpersonationContext);
  const { authKit } = useAuthKit();
  const history = useHistory();
  useQuery(impersonation, {
    onCompleted: async (data) => {
      if (data.impersonation.state.csrUsername) {
        setValues(data.impersonation.state);
      } else {
        throw new Error("Invalid Impersonation");
      }

      if (data.impersonation.state.accessToken) {
        try {
          const expiresIn = data.impersonation.state.expiresIn === "" ? -1 : data.impersonation.state.expiresIn;
          await authKit?.setTokens({
            expiresIn: parseInt(expiresIn, 10),
            accessToken: data.impersonation.state.accessToken,
            idToken: "",
            refreshToken: "",
          });
          history.push("/account");
        } catch (e) {
          throw new Error("Tokens invalid");
        }
      } else {
        history.push("/");
      }
    },
    onError: () => {
      throw new Error("An error occured");
    },
  });
  return (
    <div className="center-loading">
      <Spinner animation="border" />
    </div>
  );
};

export default Impersonate;
