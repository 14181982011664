import React, { Fragment } from "react";
import { useState } from "react";
import { Button, Col, Nav, Row } from "react-bootstrap";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import ActivityLog from "../components/account/Activity/ActivityLog";
import PoweredBy from "../components/shared/PoweredBy";
import SideNavigation from "../components/account/SideNavigation";
import NavBar from "../components/navigation/NavBar";
import Overview from "../pages/account/Overview";
import Help from "../pages/account/Help";
import { useAuthKit } from "@authkitcom/react";
import { useCallback } from "react";
import { tags } from "../utility/contentful";
import FAQ from "../pages/FAQ";
import LesMills from "../pages/LesMills";
import { ErrorBoundary } from "react-error-boundary";
import ViewProfile from "../pages/account/ViewProfile";
import ManagePlan from "../pages/account/ManagePlan";
import InformationDisplay from "../components/account/InformationDisplay";
import EditPlan from "../pages/account/EditPlan";
import {
  faHome,
  faCog,
  faFileInvoiceDollar,
  faRunning,
  faQuestionCircle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import Billing from "../pages/account/Billing";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AccountContext } from "../context/AccountProvider";
import SummaryProvider from "../context/SummaryProvider";
import DigitalUpgradeBanner from "../components/account/DigitalUpgradeBanner";
import DiscardCancelBanner from "../components/account/DiscardCancelBanner";
import LegalDisclaimer from "../components/shared/LegalDisclaimer";
import { ClientConfigContext } from "../context/ClientConfigProvider";
import OutStandingBalanceBanner from "../components/account/OutStandingBalanceBanner";
import Migration from "../pages/Migration";
import NotFound from "../pages/NotFound";
import SomethingWentWrong from "../pages/SomethingWentWrong";
import { UserContext, USER_STATUS } from "../context/UserProvider";
import useScrollToTop from "../hooks/useScrollToTop";
import BannerWrapper from "../components/shared/BannerWrapper";

type NavLink = {
  label: string;
  url: string;
  cssClass?: string;
  newTab?: boolean;
  onClick?: () => void;
};

export type SideNavLink = {
  label: string;
  url?: string;
  icon?: IconDefinition;
  newTab?: boolean;
  disabledForStatus?: string[];
  onClick?: () => Promise<void>;
};

const AccountRouting = () => {
  toast.configure();
  useScrollToTop();
  const [fullWidth, setFullWidth] = useState(false);
  const { enrollmentFrame, nextPayment, suspension } =
    useContext(AccountContext);
  const { setUser } = useContext(UserContext);
  const { path } = useRouteMatch();
  const location = useLocation();
  const { authKit } = useAuthKit();

  const history = useHistory();

  const handleLogout = async () => {
    sessionStorage.clear();
    await authKit.logout("https://" + window.location.host + "/account");
  };

  const fullwidthRoutes = ["lesmills", "migrate"];

  const navBarLinks: NavLink[] = [
    { label: "My Account", url: "/account" },
    { label: "Locations", url: "/locations" },
    { label: "FAQs", url: "/account/faqs" },
    {
      label: "View Profile",
      url: "/account/view-profile",
      cssClass: "isHiddenOnLarge",
    },
    { label: "Account Home", url: "/account", cssClass: "isHiddenOnLarge" },
    {
      label: "Manage Plan",
      url: "/account/manage",
      cssClass: "isHiddenOnLarge",
    },
    {
      label: "Billing & Payment",
      url: "/account/billing",
      cssClass: "isHiddenOnLarge",
    },
    {
      label: "Activity Log",
      url: "/account/activity",
      cssClass: "isHiddenOnLarge",
    },
    {
      label: "Terms of Use",
      url: "/terms",
      newTab: true,
      cssClass: "isHiddenOnLarge",
    },
    {
      label: "Privacy Policy",
      url: "/privacy-policy",
      newTab: true,
      cssClass: "isHiddenOnLarge",
    },
    {
      label: "Program Agreement",
      url: "/agreement",
      cssClass: "isHiddenOnLarge",
    },
    { label: "Help", url: "/account/help", cssClass: "isHiddenOnLarge" },
    {
      label: "Log Out",
      url: "/",
      onClick: handleLogout,
      cssClass: "isHiddenOnLarge logout-button",
    },
  ];

  const sideNavLinks: SideNavLink[] = [
    { label: "Account Home", url: "/account", icon: faHome },
    {
      label: "Manage Plan",
      url: "/account/manage",
      icon: faCog,
      disabledForStatus: ["TERMED", "CANCELLED", "NONE"],
    },
    {
      label: "Billing & Payment",
      url: "/account/billing",
      icon: faFileInvoiceDollar,
      disabledForStatus: ["NONE"],
    },
    {
      label: "Activity Log",
      url: "/account/activity",
      icon: faRunning,
      disabledForStatus: ["NONE"],
    },
    { label: "Help", url: "/account/help", icon: faQuestionCircle },
    { label: "Terms of Use", url: "/terms", newTab: true },
    { label: "Privacy Policy", url: "/privacy-policy", newTab: true },
    { label: "Program Agreement", url: "/agreement" },
    {
      label: "Log Out",
      onClick: handleLogout,
    },
  ];

  useEffect(() => {
    setUser({ status: USER_STATUS.ELIGIBLE });
  }, []);

  //specific routes using full width layouts
  useEffect(() => {
    setFullWidth(
      fullwidthRoutes.filter((r) => location.pathname.toLowerCase().includes(r))
        .length > 0
    );
  }, [location]);

  //migration flow
  useEffect(() => {
    const ui = authKit.getUserinfo().metadata.legacyPending;
    const hasMigrated = sessionStorage.getItem("has_migrated");
    if (ui && !hasMigrated) {
      history.push("/account/migrate");
    }
  }, [authKit]);

  const getName = useCallback(() => {
    const userInfo = authKit!.getUserinfo();
    if (userInfo && userInfo?.givenName && userInfo?.familyName) {
      return `${userInfo.givenName} ${userInfo.familyName}`;
    }
    return "";
  }, [authKit]);

  const getInitials = useCallback(() => {
    const userInfo = authKit!.getUserinfo();

    if (userInfo && userInfo["given_name"] && userInfo["family_name"]) {
      return `${userInfo["given_name"].charAt(0)} ${userInfo[
        "family_name"
      ].charAt(0)}`;
    }
    return "";
  }, [authKit]);

  const client = useContext(ClientConfigContext);

  const digitalUpsell = enrollmentFrame.current
    ? enrollmentFrame.current.sku === "FITNESS-DIGITAL" &&
      enrollmentFrame.status.toLowerCase() !== "termed" &&
      enrollmentFrame.status !== "CANCELLING" &&
      suspension.status === "NONE"
    : false;

  return (
    <Fragment>
      <Route
        exact
        path={[
          `${path}/`,
          `${path}/activity`,
          `${path}/faqs`,
          `${path}/help`,
          `${path}/manage`,
          `${path}/manage/*`,
          `${path}/billing`,
          `${path}/view-profile`,
        ]}
      >
        <NavBar logo>
          <Nav activeKey={path}>
            <Nav.Link className="my-account-header"></Nav.Link>
            {navBarLinks.map((linkItem: NavLink, index: number) => (
              <Fragment key={index}>
                {!linkItem.onClick ? (
                  <Nav.Link
                    as={Link}
                    eventKey={index}
                    to={linkItem.url}
                    key={index}
                    target={linkItem.newTab ? "_blank" : ""}
                    rel="noreferrer"
                    className={linkItem.cssClass ? linkItem.cssClass : ""}
                  >
                    {linkItem.label}
                  </Nav.Link>
                ) : (
                  <Button
                    variant="link"
                    onClick={linkItem.onClick}
                    className={linkItem.cssClass ? linkItem.cssClass : ""}
                  >
                    {linkItem.label}
                  </Button>
                )}
              </Fragment>
            ))}
          </Nav>
        </NavBar>
      </Route>

      <div className="account-template">
        <Route
          exact
          path={[
            `${path}/`,
            `${path}/activity`,
            `${path}/help`,
            `${path}/manage`,
            `${path}/manage/*`,
            `${path}/billing`,
            `${path}/view-profile`,
          ]}
        >
          <SideNavigation
            className="account-template__nav d-none d-lg-flex"
            name={getName()}
            avatar={getInitials()}
            links={sideNavLinks}
            status={enrollmentFrame.status}
          />
        </Route>
        <div
          className={`${
            !fullWidth
              ? "account-template__content"
              : "account-template__content-no-padding"
          }`}
        >
          <Row>
            <Col xs={12} xl={fullWidth ? 12 : 9} style={{ padding: 0 }}>
              <Switch>
                <Route exact path={`${path}/`}>
                  <InformationDisplay header="Account Home" />
                  <BannerWrapper
                    show={
                      enrollmentFrame.status === "CANCELLING" ||
                      enrollmentFrame.pending?.sku === "FITNESS-DIGITAL"
                    }
                  >
                    <DiscardCancelBanner
                      sku={
                        enrollmentFrame?.current?.sku
                          ? enrollmentFrame.current.sku
                          : ""
                      }
                      discardUntil={
                        enrollmentFrame.current?.term
                          ? enrollmentFrame?.current?.term
                            ? enrollmentFrame.current.term
                            : ""
                          : enrollmentFrame.pending?.discardUntil
                          ? enrollmentFrame.pending.discardUntil
                          : ""
                      }
                    />
                  </BannerWrapper>
                  <DigitalUpgradeBanner show={digitalUpsell} />
                  <Overview />
                </Route>
                <Route exact path={`${path}/migrate`}>
                  <Migration />
                </Route>
                <Route exact path={`${path}/activity`}>
                  <InformationDisplay header="Activity Log" />
                  <DigitalUpgradeBanner show={digitalUpsell} />
                  <div className="activity-log-container">
                    <ActivityLog />
                  </div>
                </Route>
                <Route exact path={`${path}/billing`}>
                  <InformationDisplay header="Billing and Payment" />
                  <DigitalUpgradeBanner show={digitalUpsell} />
                  <BannerWrapper show={nextPayment?.overdue ?? false}>
                    <OutStandingBalanceBanner
                      amount={nextPayment?.amount ?? 0}
                      due={nextPayment?.due ?? ""}
                    />
                  </BannerWrapper>
                  <Billing />
                </Route>
                <Route path={`${path}/lesmills`}>
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    <LesMills />
                  </ErrorBoundary>
                </Route>
                <Route exact path={`${path}/manage`}>
                  <InformationDisplay header="Manage Plan" />
                  <DigitalUpgradeBanner show={digitalUpsell} />
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    <ManagePlan />
                  </ErrorBoundary>
                </Route>
                <Route exact path={`${path}/manage/edit`}>
                  <DigitalUpgradeBanner show={digitalUpsell} />
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h1>Maintenance Ongoing!</h1>
                      <h3>We will be back soon.</h3>
                    </div> */}
                    <SummaryProvider>
                      <EditPlan />
                    </SummaryProvider>
                  </ErrorBoundary>
                </Route>
                <Route exact path={`${path}/faqs`}>
                  <FAQ
                    tags={
                      tags[
                        client.config["network.clientCode"]
                          ? client.config["network.clientCode"].toLowerCase()
                          : ""
                      ]
                    }
                  />
                </Route>
                <Route exact path={`${path}/help`}>
                  <Help
                    tags={
                      tags[
                        client.config["network.clientCode"]
                          ? client.config["network.clientCode"].toLowerCase()
                          : ""
                      ]
                    }
                  />
                </Route>
                <Route exact path={`${path}/view-profile`}>
                  <ViewProfile />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Col>
          </Row>
        </div>
        <div className="account-template__footer">
          <LegalDisclaimer
            tags={
              tags[
                client.config["network.clientCode"]
                  ? client.config["network.clientCode"].toLowerCase()
                  : ""
              ]
            }
          />
          <PoweredBy />
        </div>
      </div>
    </Fragment>
  );
};

export default AccountRouting;
