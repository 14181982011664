import { useAuthKit } from "@authkitcom/react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SuccessStandard from "../components/account/SuccessStandard";
import { AccountContext } from "../context/AccountProvider";
import { getSessionId } from "../api/portal/Queries";

/**
 * Handles calling the aggregated enrollment when a user transitions from invalid to a valid user
 * @returns loading, error, or valid success page
 */
const Success = () => {
  const history = useHistory();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [called, setCalled] = useState(false);
  const { populate, entitlements, loading, error } = useContext(AccountContext);

  useEffect(() => {
    (async () => {
      let sessionId = sessionStorage.getItem("pipeline-session-id");
      if (!sessionId) {
        sessionId = await getSessionId();
        sessionStorage.setItem("pipeline-session-id", sessionId);
      }
      await populate();
      setCalled(true);
    })();
  }, []);

  //we have refreshed and its done loading
  useEffect(() => {
    if (!loading && called) setHasLoaded(true);
  }, [loading, called]);

  const handleAccount = () => {
    history.push("/account");
  };
  const handleMembershipCard = () => {
    history.push("/account");
  };
  const { authKit } = useAuthKit();
  const userInfo = authKit!.getUserinfo();

  if (!hasLoaded) {
    return (
      <div className="center-loading">
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) throw new Error("Invalid");

  return (
    <div>
      <div className="success__header">
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="5x"
          className="success__icon"
        />
        <div className="success__header_text">
          <h3>
            <b>Congratulations!</b>
          </h3>
          <h4>
            <b>
              A confirmation with more details has been emailed to{" "}
              {userInfo?.preferred_username}
            </b>
          </h4>
        </div>
        <div className="success__button-container">
          <Button className="success__button" onClick={handleAccount}>
            View Account Home
            <svg
              aria-hidden="true"
              data-prefix="fas"
              data-icon="chevron-right"
              className="chevron-right-success"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
              />
            </svg>
          </Button>
        </div>
      </div>
      <SuccessStandard
        entitlements={entitlements ?? []}
        onAccount={handleAccount}
        onMembershipCard={handleMembershipCard}
      />
    </div>
  );
};

export default Success;
