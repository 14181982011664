import React, { useContext } from "react";
import { AccountContext } from "../../context/AccountProvider";
import { Alert } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientConfigContext } from "../../context/ClientConfigProvider";

const AccountSuspensionBanner = () => {
  const { suspension } = useContext(AccountContext);
  const { config } = useContext(ClientConfigContext);

  // If the user is not suspended, don't show the banner
  if (suspension.status === "NONE") {
    return null;
  }

  const phoneNumber =
    config["commerce.clientCode"] === "BCBSA" ? "888-572-7833" : "888-502-0867";

  const convertDate = (date: string): string => {
    if (!date) {
      return "";
    }
    const s = date.split("-");
    return [s[1], s[2], s[0]].join("/");
  };

  const suspensionMessage = {
    tense: suspension.status === "CURRENT" ? "took" : "will take",
    resumeDateSet:
      suspension.resume === undefined
        ? ""
        : `This
        account is scheduled to resume on ${convertDate(suspension.resume)}.`,
  };

  return (
    <Alert className="account-suspension-banner">
      <Alert.Heading>
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        Attention
      </Alert.Heading>
      <div className="account-suspension-banner__body">
        Our records indicate that you have placed a temporary pause on this
        account, which {suspensionMessage.tense} effect on{" "}
        {convertDate(suspension.suspend)}. {suspensionMessage.resumeDateSet} You
        will not have access to the program for this period of time. If you have
        any questions please contact us at{" "}
        <u>
          <a href={`tel:${phoneNumber}`} className="a-link">{phoneNumber}</a>
        </u>{" "}
        Monday - Friday 7 a.m. - 7 p.m. CT.
      </div>
    </Alert>
  );
};

export default AccountSuspensionBanner;
