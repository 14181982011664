import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

const CancelSubscriptionModal = ({
  name,
  open,
  loading,
  setOpen,
  onCancel,
}: {
  name: string;
  open: boolean;
  loading: boolean;
  setOpen: (val: boolean) => void;
  onCancel: () => void;
}) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Header>
        <Modal.Title
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h2>Confirm Cancellation</h2>
          <span style={{ cursor: "pointer" }} onClick={() => setOpen(false)}>
            X
          </span>
        </Modal.Title>
      </Modal.Header>
      {loading ? (
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            <p>
              Would you like to permanently cancel the gym subscription of{" "}
              <b>{name}</b>? Cancellation will become effective at the end of
              this biling period.
            </p>
          </Modal.Body>
        </>
      )}
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "white", width: "48%" }}
          onClick={() => handleClose()}
          disabled={loading}
        >
          <span style={{ color: "black" }}>Discard Cancellation</span>
        </Button>
        <Button
          style={{ width: "48%" }}
          variant="primary"
          onClick={onCancel}
          disabled={loading}
        >
          Yes, Cancel Subscription
        </Button>
      </Modal.Footer>{" "}
    </Modal>
  );
};
export default CancelSubscriptionModal;
