import React from "react";
import { IActivity } from "../Tiles/ActivityLogTile";
import { ActivityListItemTile } from "./ActivityListItemTile";

interface IProps {
  activities: IActivity[];
  currentPageNum: number;
}

const ActivityListTile = ({ activities, currentPageNum }: IProps) => {
  return (
    <div className="activity-log-tile mx-2">
      <div className="d-flex">
        <span className="p-2 text-uppercase text-secondary p-2 col">
          Location
        </span>
        <span className="p-2 col d-flex justify-content-end">Date</span>
      </div>
      {activities
        .slice(6 * (currentPageNum - 1), 6 * currentPageNum)
        .sort(
          (
            x: { date: string | number | Date },
            y: { date: string | number | Date }
          ) => +new Date(y.date) - +new Date(x.date)
        )
        .map((activity, index) => (
          <ActivityListItemTile activity={activity} key={index} />
        ))}
    </div>
  );
};
export default ActivityListTile;
