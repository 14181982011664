import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { orderStatus } from "../graphql/Queries";

interface ICheckOrder {
  success: boolean;
  loading: boolean;
  error: boolean;
  start: () => void;
  reset: () => void;
}

enum ORDER_STATUS {
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}

const pollInterval = 400;

const useCheckOrder = (): ICheckOrder => {
  const [skip, setSkip] = useState(true);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { startPolling, stopPolling } = useQuery(orderStatus, {
    fetchPolicy: "no-cache",
    skip: skip,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const status = data.enrollment.state.orderHandleStatus;
      if (status === ORDER_STATUS.COMPLETED) {
        setSuccess(true);
      } else if (status === ORDER_STATUS.ERROR) {
        setError(true);
      }
    },
    onError: () => {
      setError(true);
    },
  });
  const start = () => {
    setSkip(false);
    return;
  };
  const reset = () => {
    setSkip(true);
    setSuccess(false);
    setLoading(false);
    setError(false);
  };

  useEffect(() => {
    if (error || success) {
      setLoading(false);
      setSkip(true);
      stopPolling();
    }
  }, [error, success]);

  useEffect(() => {
    if (!skip) {
      setLoading(true);
      setError(false);
      setSuccess(false);
      startPolling(pollInterval);
    }
  }, [skip]);

  return { success, loading, error, start, reset };
};

export default useCheckOrder;
