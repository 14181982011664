import { useAuthKit } from "@authkitcom/react";
import {
  faDownload,
  faLaptop,
  faPrint,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

import ArrowRotateLeftSolid from "../../resources/ArrowRotateLeftSolid.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import { useContext } from "react";
import { Button, Card, Col, ListGroup, Row, Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import PlanListItem from "../../components/account/PlanListItem";
import { AccountContext, ENTITLEMENTS } from "../../context/AccountProvider";
import ProfileToastDiscardPlan from "../../utility/ProfileToastDiscardPlan";
import { ClientConfigContext } from "../../context/ClientConfigProvider";
import skuMap from "../../utility/skuMap";
import { getHomeGymDetails } from "../../api/platform/Queries";
import DiscardModal from "../../components/account/EditPlan/DiscardModal";
import {
  ENROLLMENT_ACTIONS,
  ORDER_FROM,
  placeOrder,
} from "../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import useCheckOrder from "../../hooks/useCheckOrder";
import DiscardHomeGymModal from "../../components/account/DiscardHomeGymModal";
import CancelSubscriptionModal from "../../components/account/CancelSubscriptionModal";
import DiscardCancellationModal from "../../components/account/DiscardCancellationModal";
import OutStandingBalanceBanner from "../../components/account/OutStandingBalanceBanner";
import { discardNewHomeGym } from "../../api/enrollment/Queries";
import ProfileToast from "../../utility/ProfileToast";
import HomeGymRow from "../../components/account/ManagePlan/HomeGymRow";
import AccountSuspensionBanner from "../../components/account/AccountSuspensionBanner";
import { ProductBundleContext } from "../../context/ProductBundleProvider";
import { momentStartOfDay } from "../../utility/util";

function useRouterQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ManagePlan = () => {
  const { authKit } = useAuthKit();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { client, config } = useContext(ClientConfigContext);
  const { bundles, loading: loadingBundles } = useContext(ProductBundleContext);
  const {
    entitlements,
    homeGymFrame,
    enrollmentFrame,
    memberCardBase64,
    nextPayment,
    suspension,
    currentPrice,
    refresh,
    refreshing: accountLoading,
  } = useContext(AccountContext);
  const q = useRouterQuery();

  const [openDiscardModal, setOpenDiscardModal] = useState<boolean>(false);
  const [openDiscardCancellationModal, setOpenDiscardCancellationModal] =
    useState<boolean>(false);
  const [handleCancelModal, setHandleCancelModal] = useState<boolean>(false);

  const [discardLoading, setDiscardLoading] = useState(false);
  const [cancellationLoading, setCancellationLoading] = useState(false);
  const [openHomeGymDiscardModal, setOpenHomeGymDiscardModal] =
    useState<boolean>(false);

  const handleCloseDiscard = () => setOpenDiscardModal(false);
  const handleShowDiscard = () => setOpenDiscardModal(true);
  const handleHomeGymShow = () => setOpenHomeGymDiscardModal(true);
  const newHomeGymEffectiveDate =
    momentStartOfDay(homeGymFrame.pending?.effective).format("MM/DD/YY") || "";
  const newHomeGymSetDate =
    momentStartOfDay(homeGymFrame.pending?.set).format("MM/DD/YY") || "";
  const pendingChangeEffectiveDate =
    momentStartOfDay(enrollmentFrame.pending?.effective) || null;
  const [newHomeGymName, setNewHomeGymName] = useState<string>("");
  const [currentHomeGymName, setCurrentHomeGymName] = useState<string>("");
  const { start, success: checkSuccess, error: checkError } = useCheckOrder();

  const [placeOrderMutation] = useMutation(placeOrder, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const phase = data.enrollmentPlaceOrderAction.state.phase;
      if (phase && phase === "PERFORM") {
        start();
      }
    },
  });

  useEffect(() => {
    if (checkSuccess) {
      setOpenDiscardModal(false);
      //setShowPendingChange(false);
      setOpenDiscardCancellationModal(false);
      setHandleCancelModal(false);
      ProfileToastDiscardPlan(
        "Your plan change has been successfully discarded.",
        true,
        client
      );
      refresh();
    }
    if (checkError) {
      setOpenDiscardModal(false);
      //setShowPendingChange(false);
      setHandleCancelModal(false);
      ProfileToastDiscardPlan(
        "Your plan change could not be discarded.",
        false,
        client
      );
    }
    if (cancellationLoading && checkSuccess) {
      refresh().then(() => history.push("/account"));
      // setTimeout(() => {
      //   history.push("/account");
      // }, 1000);
    }
    setDiscardLoading(false);
    setCancellationLoading(false);
  }, [checkSuccess, checkError]);

  useEffect(() => {
    if (q.get("edit_success") === "true") {
      setTimeout(() => {
        ProfileToast(
          "Your subscription has been successfully updated.",
          true,
          client
        );
        refresh();
      }, 250);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (homeGymFrame.status === "TRANSITIONING") {
          const currentLocation = await getHomeGymDetails(
            homeGymFrame.current?.locationId
          );
          const newLocation = await getHomeGymDetails(
            homeGymFrame.pending?.locationId
          );
          setCurrentHomeGymName(currentLocation.name || "");
          setNewHomeGymName(newLocation.name || "");
        }
      } catch (e) {
        throw new Error("Unable to fetch home gym name");
      }
    })();
  }, []);

  const SetHomeGym = () => {
    if (
      entitlements &&
      entitlements.findIndex((v) => v === ENTITLEMENTS.HOME_GYM) !== -1 &&
      !homeGymFrame.current?.locationId
    ) {
      return (
        <ListGroup.Item>
          <div className="manage-plans__grid_digital">
            <div className="manage-plans__header">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35%"
                viewBox="0 0 782.208 752"
              >
                <path
                  id="Combined_Shape"
                  className="manage-plans__svg"
                  data-name="Combined Shape"
                  d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
                />
              </svg>
              <span className="icon-text">Home Gym</span>
            </div>
            <div className="manage-plans__information">
              <span>
                <Row style={{ justifyContent: "space-between", width: "100%" }}>
                  <Col style={{ margin: 0, padding: 0, flex: 1 }}>
                    Looks like you haven&apos;t set a home gym yet. Set one now
                    to take full advantage of your ELITE access.
                  </Col>
                  <Col style={{ flex: 0 }}>
                    <Button
                      as={Link}
                      variant="outline-primary"
                      className="btn w-15 ml-2 btn-outline-primary btn-sm p-2"
                      style={{
                        float: "right",
                        whiteSpace: "nowrap",
                      }}
                      to={`/locations`}
                    >
                      Find Home Gym
                    </Button>
                  </Col>
                </Row>
              </span>
            </div>
          </div>
        </ListGroup.Item>
      );
    }
    return null;
  };
  const DigitalOnly = () => {
    if (
      entitlements &&
      (entitlements.findIndex((v) => v === ENTITLEMENTS.BURNALONG) !== -1 ||
        entitlements.findIndex((v) => v === ENTITLEMENTS.LESMILLS) !== -1)
    ) {
      return (
        <ListGroup.Item>
          <div className="manage-plans__grid_digital">
            <div className="manage-plans__header">
              <FontAwesomeIcon
                icon={faLaptop}
                className="manage-plans__svg"
                size="2x"
              />
              <span className="icon-text">Digital Content</span>
            </div>
            <div className="manage-plans__digital_information">
              <span>
                You have access to live virtual classes and thousands of
                on-demand videos! Your membership includes access to our digital{" "}
                {config["lesmills.enable"] == "true"
                  ? `partners BurnAlong and LES MILLS&trade;`
                  : `partner BurnAlong.`}
              </span>
              {enrollmentFrame.allowedActions.includes(
                ENROLLMENT_ACTIONS.CANCEL
              ) &&
              entitlements.findIndex((v) => v === ENTITLEMENTS.GYM) === -1 ? (
                <div
                  style={{
                    display: "flex",
                    width: "30%",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    className="manage-plans__cancel_digital"
                    onClick={() => setHandleCancelModal(true)}
                  >
                    <b style={{ marginLeft: `10px`, textAlign: "right" }}>
                      X Cancel Subscription
                    </b>
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </ListGroup.Item>
      );
    }
    return null;
  };
  const CurrentPlan = () => {
    if (
      entitlements &&
      entitlements.length > 0 &&
      enrollmentFrame.status.toLowerCase() !== "termed"
    ) {
      return (
        <ListGroup.Item>
          <div className="manage-plans__grid">
            <div className="manage-plans__header">
              <span className="name-plan">
                {`${authKit?.getUserinfo()?.given_name} ${
                  authKit?.getUserinfo()?.family_name
                }`}
              </span>
            </div>
            <div
              className={`manage-plans__information${
                enrollmentFrame.status === "ACTIVE" ? `-long` : ""
              }`}
            >
              {enrollmentFrame.current ? (
                <>
                  {loadingBundles ? (
                    <div className="center-loading">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <PlanListItem
                      header="Current Package"
                      body={skuMap[
                        enrollmentFrame.current.sku
                      ].name.toUpperCase()}
                      footer={currentPrice ? `$${currentPrice}.00/month` : ""}
                      loading={accountLoading}
                    />
                  )}
                </>
              ) : (
                <div>
                  <h6>
                    <i>
                      <strong>No current plan selected</strong>
                    </i>
                  </h6>
                </div>
              )}
            </div>
            {/* {enrollmentFrame.status === "CANCELLING" ? null : (
              <div> */}
            <NewPackage />
            <NewPlanEffective />
            {/* </div>
            )} */}
            <DiscardChangeButton />
          </div>
        </ListGroup.Item>
      );
    }
    return null;
  };
  const handleCardPrint = () => {
    const w = window.open("", "_new");
    if (w) {
      w.document.write(
        `<img src="data:image/png;base64,${memberCardBase64}" alt="card"/>`
      );
      setTimeout(() => {
        w.focus();
        w.print();
      }, 750);
    }
  };

  const NewPackage = () => {
    if (
      enrollmentFrame.allowedActions.includes(ENROLLMENT_ACTIONS.DISCARD) &&
      enrollmentFrame.status !== "CANCELLING"
    ) {
      return (
        <div className="manage-plans__new-package">
          <FontAwesomeIcon
            className="manage-plans__new-package__arrow"
            icon={faArrowRight}
          />
          <PlanListItem
            header="New Package"
            body={skuMap[enrollmentFrame.pending?.sku ?? ""].name ?? ""}
            footer={`$${
              loadingBundles
                ? "Loading..."
                : bundles?.data.find(
                    (b) => b.sku === enrollmentFrame.pending?.sku ?? ""
                  )?.periodicPrice ?? 0
            }.00/month`}
            loading={accountLoading}
          />
        </div>
      );
    }
    return null;
  };

  const NewPlanEffective = () => {
    if (
      enrollmentFrame.allowedActions.includes(ENROLLMENT_ACTIONS.DISCARD) &&
      enrollmentFrame.status !== "CANCELLING"
    ) {
      return (
        <div className="manage-plans__effective-date">
          <PlanListItem
            header="Effective Date"
            body={`${pendingChangeEffectiveDate.format(
              "MMMM"
            )} ${pendingChangeEffectiveDate.format("DD")}`}
            footer={`${pendingChangeEffectiveDate.format("YYYY")}`}
            loading={accountLoading}
          />
        </div>
      );
    }
    return null;
  };

  const DiscardChangeButton = () => {
    if (enrollmentFrame.allowedActions.includes(ENROLLMENT_ACTIONS.DISCARD)) {
      return (
        <div
          className="manage-plans__discard"
          onClick={
            enrollmentFrame.status === "CANCELLING"
              ? () => setOpenDiscardCancellationModal(true)
              : handleShowDiscard
          }
        >
          <img
            alt="arrow"
            className="manage-plans__discard__back-arrow"
            src={ArrowRotateLeftSolid}
          />
          <p>
            <b>
              {enrollmentFrame.status === "CANCELLING"
                ? `Discard Cancellation`
                : `Discard Change`}
            </b>
          </p>
        </div>
      );
    }
    return null;
  };

  const discardHomeGymChanges = async () => {
    try {
      setDiscardLoading(true);
      await discardNewHomeGym();
      ProfileToastDiscardPlan(
        "Your home gym change has been successfully discarded.",
        true,
        client
      );
      refresh();
    } catch {
      ProfileToastDiscardPlan(
        "Your home gym change could not be discarded.",
        false,
        client
      );
    } finally {
      setDiscardLoading(false);
      setOpenHomeGymDiscardModal(false);
    }
  };

  const handleDiscard = () => {
    setDiscardLoading(true);
    placeOrderMutation({
      variables: {
        from: ORDER_FROM.ACTION,
        action: ENROLLMENT_ACTIONS.DISCARD,
        paymentMethod: "",
      },
    });
  };

  const handleCancelation = () => {
    setCancellationLoading(true);
    placeOrderMutation({
      variables: {
        from: ORDER_FROM.ACTION,
        action: ENROLLMENT_ACTIONS.CANCEL,
        paymentMethod: "",
      },
    });
  };

  return (
    <Fragment>
      <AccountSuspensionBanner />
      {nextPayment?.overdue ? (
        <OutStandingBalanceBanner
          amount={nextPayment?.amount}
          due={nextPayment?.due}
        />
      ) : null}
      <Card className="manage-plans">
        <Card.Body>
          <Card.Title className="manage-plans__top-bar">
            <span>Manage Plan</span>
            {suspension.status === "CURRENT" ||
            suspension.status === "FUTURE" ? (
              <Button
                variant="outline-secondary"
                className="btn w-15 ml-2 btn-outline-primary btn-sm p-2"
                disabled
              >
                Edit Plan
              </Button>
            ) : (
              <Button
                as={Link}
                variant="outline-primary"
                className="btn w-15 ml-2 btn-outline-primary btn-sm p-2"
                to={`${path}/edit`}
              >
                Edit Plan
              </Button>
            )}
          </Card.Title>
          <Card.Text className="manage-plans__sub-text">
            {entitlements ? "Primary Member" : ""}
            <ListGroup>
              {!entitlements && (
                <div className="mt-2">
                  <h3>
                    <i>No current plan</i>
                  </h3>
                </div>
              )}
              <CurrentPlan />
              <HomeGymRow
                onHomeGymShow={handleHomeGymShow}
                loading={accountLoading}
              />
              <SetHomeGym />
              <DigitalOnly />
              {entitlements && entitlements.includes("memberCard") && (
                <ListGroup.Item
                  variant="secondary"
                  className="manage-plans__footer"
                >
                  <a
                    download="membership_card.png"
                    className="btn w-15 btn-outline-primary btn-sm"
                    href={`data:image/png;base64,${memberCardBase64}`}
                  >
                    <FontAwesomeIcon icon={faDownload} /> Download Card
                  </a>
                  <Button
                    variant="outline-primary"
                    className="btn w-15 ml-2 btn-outline-primary btn-sm"
                    onClick={handleCardPrint}
                  >
                    <FontAwesomeIcon icon={faPrint} /> Print Card
                  </Button>
                  {enrollmentFrame.allowedActions.includes(
                    ENROLLMENT_ACTIONS.CANCEL
                  ) ? (
                    <span
                      className="manage-plans__cancel"
                      onClick={() => setHandleCancelModal(true)}
                    >
                      <b>X Cancel Subscription</b>
                    </span>
                  ) : null}
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card.Text>
        </Card.Body>
      </Card>
      <DiscardHomeGymModal
        currentHomeGym={currentHomeGymName}
        newHomeGym={newHomeGymName}
        onDate={newHomeGymSetDate}
        takePlaceDate={newHomeGymEffectiveDate}
        open={openHomeGymDiscardModal}
        setOpen={setOpenHomeGymDiscardModal}
        client={client}
        discardHomeGymChanges={discardHomeGymChanges}
        loading={discardLoading}
      />
      <DiscardModal
        currentPackage={skuMap[enrollmentFrame.current?.sku ?? ""]?.name}
        newPackage={skuMap[enrollmentFrame.pending?.sku ?? ""]?.name ?? ""}
        open={openDiscardModal}
        onClose={handleCloseDiscard}
        onDate={
          enrollmentFrame?.pending?.effective
            ? enrollmentFrame?.pending?.effective
            : ""
        }
        onDiscard={handleDiscard}
        takePlaceDate={enrollmentFrame.pending?.effective ?? ""}
        loading={discardLoading}
      />
      <CancelSubscriptionModal
        name={`${authKit?.getUserinfo()?.given_name} ${
          authKit?.getUserinfo()?.family_name
        }`}
        open={handleCancelModal}
        loading={cancellationLoading}
        setOpen={setHandleCancelModal}
        onCancel={handleCancelation}
      />
      <DiscardCancellationModal
        currentPackage={skuMap[enrollmentFrame.current?.sku ?? ""]?.name}
        open={openDiscardCancellationModal}
        takePlaceDate={
          enrollmentFrame?.current?.term ? enrollmentFrame?.current?.term : ""
        }
        onClose={() => setOpenDiscardCancellationModal(false)}
        onDiscard={handleDiscard}
        loading={discardLoading}
      />
    </Fragment>
  );
};

export default ManagePlan;
