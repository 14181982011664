import { useState, useEffect, useCallback, useContext } from "react";
import { fetchPaymentPages } from "../api/enrollment/Queries";
import { ClientConfigContext } from "../context/ClientConfigProvider";
import { PaymentPages } from "../types";
import {AccountContext} from "../context/AccountProvider";

export type ZuoraProps = Record<string, string | number | boolean | null>;

export interface IZuoraResponse {
  success: boolean;
  refId: string;
  responseFrom: string;
  errorCode?: string;
}

interface IZuoraHook {
  render: () => void;
  handleSubmit: (e: unknown) => void;
  hasRendered: boolean;
}

const useZuora = ({
  paymentType,
  onSuccess,
  onSubmissionFailed,
  onRequestFailed,
}: {
  paymentType: string;
  onSuccess: (res: unknown) => unknown;
  onSubmissionFailed: (res: unknown) => unknown;
  onRequestFailed: (res: unknown) => unknown;
}): IZuoraHook => {
  const {refresh} = useContext(AccountContext);
  const { config } = useContext(ClientConfigContext);
  const [zuoraSettings, setZuoraSettings] = useState<PaymentPages | null>(null);
  const [props, setProps] = useState<ZuoraProps>({
    tenantId: "",
    id: "",
    token: null,
    signature: null,
    style: "inline",
    key: null,
    submitEnabled: true,
    locale: "English (en)",
    param_supportedTypes: "AmericanExpress,Visa,MasterCard,Discover",
    url: config["zuora.hostedPagePath"],
    paymentGateway: config["zuora.paymentGatewayCode.cc"],
  });
  const [toRender, setToRender] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prepop] = useState({});
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setToRender(false);
    setRendered(false);
    setLoading(true);
  }, [paymentType]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await fetchPaymentPages();
      setZuoraSettings(res);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (zuoraSettings !== null) {
        if (paymentType === "CREDIT") {
          setProps((p) => ({
            ...p,
            ...{
              field_accountId: zuoraSettings.accountId,
              paymentGateway: config["zuora.paymentGatewayCode.cc"],
              tenantId: zuoraSettings.paymentPageKeyCc.tenantId,
              token: zuoraSettings.paymentPageKeyCc.token,
              id: zuoraSettings.hostedPaymentPageIdCc,
              signature: zuoraSettings.paymentPageKeyCc.signature,
              key: zuoraSettings.paymentPageKeyCc.key,
              url: zuoraSettings.hostedPaymentPageUrlCc,
            },
          }));
        } else {
          setProps((p) => ({
            ...p,
            ...{
              field_accountId: zuoraSettings.accountId,
              paymentGateway: config["zuora.paymentGatewayCode.echeck"],
              tenantId: zuoraSettings.paymentPageKeyECheck.tenantId,
              token: zuoraSettings.paymentPageKeyECheck.token,
              id: zuoraSettings.hostedPaymentPageIdECheck,
              signature: zuoraSettings.paymentPageKeyECheck.signature,
              key: zuoraSettings.paymentPageKeyECheck.key,
              url: zuoraSettings.hostedPaymentPageUrlECheck,
            },
          }));
        }
        setLoading(false);
      }
    })();
  }, [zuoraSettings, paymentType]);

  const callback = useCallback(
    async (response: IZuoraResponse) => {
      if (response.success) {
        onSuccess(response);
        await refresh();
      } else {
        if (response.responseFrom === "Response_From_Submit_Page") {
          onSubmissionFailed(response);
        } else {
          onRequestFailed(response);
        }
      }
    },
    [onSuccess, onSubmissionFailed, onRequestFailed]
  );

  //render when we can.
  useEffect(() => {
    if (toRender && !loading && !rendered) {
      window.Z.render(props, prepop, callback);
      setRendered(true);
    }
  }, [toRender, loading, props, prepop, callback, rendered]);
  //
  const render = useCallback(() => {
    setToRender(true);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    window.Z.submit();
  }, []);

  return { render, handleSubmit, hasRendered: rendered };
};

export default useZuora;
