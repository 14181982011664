import { useState } from "react";
import { fetchTierCount } from "../api/platform/Queries";
// TODO: Other query parameters
const useGetTierCount = () => {
  const [tierCount, setTierCount] = useState<unknown>(0);

  const getTierCount = {
    run: async (tier: string) => {
      try {
        const result = await fetchTierCount(tier);
        if (result) {
          setTierCount(result.count);
        }
        return Promise.resolve();
      } catch (err: unknown) {
        return Promise.reject();
      }
    },
  };

  return {
    getTierCount,
    tierCount,
  };
};

export default useGetTierCount;
