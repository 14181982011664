import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DigitalUpgradeBanner = ({ show }: { show: boolean }) => {
  return (
    <>
      {show ? (
        <div className="d-flex digital-upgrade-banner justify-content-between align-items-center">
          <div>
            <h5 style={{ marginBottom: "-1px" }}>
              Go to gyms<sup>1</sup> near home and work
            </h5>
            <span>
              <i>
                Gym packages as low as $19 per month<sup>2</sup>
              </i>
            </span>
          </div>
          <div className="explore-locations">
            <Link to="/locations" className="a-link">
              <FontAwesomeIcon icon={faSearch} /> Explore Locations
            </Link>
          </div>
          <div>
            <Button
              as={Link}
              className="digital-upgrade-banner__button"
              to="/account/manage/edit"
              style={{ marginRight: "10px", fontSize: "14px" }}
            >
              See Gym Packages {"  "}
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DigitalUpgradeBanner;
