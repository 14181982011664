import React from "react";
import { Card, Collapse } from "react-bootstrap";

const HelperCard = ({
  open,
  children,
}: {
  open: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Collapse in={open}>
      <div>
        <Card.Body className="helper-card">
          <div className="helper-card__badge">i</div>
          <div className="helper-card__content">{children}</div>
        </Card.Body>
      </div>
    </Collapse>
  );
};

export default HelperCard;
