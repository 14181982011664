import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import FooterCard from "./FooterCard";

const FooterButtons = ({
  onSubmit,
  onBack,
  submitDisabled,
  submitText,
}: {
  onSubmit: (e?: unknown) => void;
  onBack?: () => void;
  submitDisabled: boolean;
  submitText?: string;
}) => {
  return (
    <FooterCard>
      <Container>
        <Row className="justify-content-end">
          <Col xs={6} lg={3}>
            {onBack && (
              <Button
                variant="outline-primary"
                className="wizard-footer__button font-weight-bold"
                onClick={onBack}
              >
                Go Back
              </Button>
            )}
          </Col>
          <Col xs={onBack ? 6 : 12} lg={3}>
            <Button
              variant="primary"
              className="wizard-footer__button"
              onClick={onSubmit}
              disabled={submitDisabled}
            >
              {submitText ?? "Continue"}
            </Button>
          </Col>
        </Row>
      </Container>
    </FooterCard>
  );
};

export default FooterButtons;
