import React, { Fragment, useContext, useState } from "react";
import Switch from "react-bootstrap/esm/Switch";
import { Link, Route, useHistory } from "react-router-dom";
import { useEffect } from "react";
import NavBar from "../components/navigation/NavBar";
import { Container, Nav } from "react-bootstrap";
import NavMenu from "../components/navigation/NavMenu";
import Landing from "../pages/Landing";
import HowItWorks from "../pages/HowItWorks";
import { tags } from "../utility/contentful";
import Flyin from "../components/Flyin";
import { ButtonContext } from "../context/ButtonProvider";
import NavBranding from "../components/navigation/NavBranding";
import FAQ from "../pages/FAQ";
import StandaloneRoutes from "../components/shared/StandaloneRoutes";
import PoweredBy from "../components/shared/PoweredBy";
import FEPSSO from "../components/FEPSSO";
import AuthWrapper from "../components/shared/AuthWrapper";
import ProgramAgreement from "../pages/ProgramAgreement";

/**
 *
 * Routing page for fep.
 */
const FEPRouting = () => {
  const { setButton } = useContext(ButtonContext);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleOpen = () => {
    setOpen((open) => !open);
  };

  useEffect(() => {
    setButton({
      text: "Enroll Now",
      onClick: () => history.push("/enroll"),
    });
  }, [setButton]);
  return (
    <Fragment>
      {/* Route specific nav bars */}
      <StandaloneRoutes>
        <NavBar logo>
          <NavBranding provider={tags["bcbsa"]} alt="BCBSA" />
        </NavBar>
      </StandaloneRoutes>
      <Route exact path={["/", "/how-it-works", "/faqs"]}>
        <Flyin
          open={open}
          handler={() => setOpen(false)}
          viewOnly
          clientCode="bcbsa"
        />
        <NavBar logo>
          <NavBranding provider={tags["bcbsa"]} alt="BCBSA" />
        </NavBar>
        <NavBar second>
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Item>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to={`/how-it-works`}>
                How It Works
              </Nav.Link>
            </Nav.Item>
            <Fragment>
              <Nav.Item>
                <Nav.Link as={Link} to="/locations">
                  Locations
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={`/faqs`}>
                  FAQs
                </Nav.Link>
              </Nav.Item>
            </Fragment>
          </Nav>
          <NavMenu onEnroll={() => history.push("/enroll")} />
        </NavBar>
      </Route>
      {/* FEP routes */}
      <Switch>
        <Route exact path={`/`}>
          <Landing onOpen={handleOpen} tags={tags["fep"]} />
        </Route>
        <Route exact path="/sso">
          <AuthWrapper>
            <FEPSSO />
          </AuthWrapper>
        </Route>
        <Route exact path={`/how-it-works`}>
          <HowItWorks tags={tags["fep"]} />
        </Route>
        <Route exact path={`/faqs`}>
          <Fragment>
            <FAQ tags={tags["fep"]} />
            <section id="legal">
              <div className="legal-footer">
                <PoweredBy />
              </div>
            </section>
          </Fragment>
        </Route>
        <Route exact path="/agreement">
          <Container>
            <div style={{ padding: "24px" }}></div>
            <ProgramAgreement tags={tags["fep"]} />
          </Container>
        </Route>
      </Switch>
    </Fragment>
  );
};

export default FEPRouting;
