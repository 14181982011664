import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { momentStartOfDay } from "../../../utility/util";
export const DiscardModal = ({
  currentPackage,
  newPackage,
  takePlaceDate,
  open,
  loading,
  onClose,
  onDiscard,
}: {
  currentPackage: string;
  newPackage: string;
  onDate: string;
  takePlaceDate: string;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onDiscard: () => void;
}) => {
  return (
    <Modal
      show={open}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <b>Discard Package Change?</b>
        </Modal.Title>
        <span style={{ cursor: "pointer" }} onClick={onClose}>
          X
        </span>
      </Modal.Header>
      {loading ? (
        <Modal.Body style={{ minHeight: "160px" }}>
          <div className="d-flex flex-column justify-content-center">
            <div className="center-loading" style={{ marginTop: "0" }}>
              <Spinner animation="border" />
            </div>
            <span className="text-center mt-3">
              Processing... This may take a minute.
            </span>
            <p className="text-center">Please do not refresh the page.</p>
          </div>
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            <p>
              {`It looks like you recently changed your package from `}
              <b> {currentPackage}</b> {` to `}
              <b>{newPackage}</b>, {`which is scheduled to take place on `}{" "}
              <b>{momentStartOfDay(takePlaceDate).format("MM/DD/YY")}</b>.{" "}
              {`Would you like to discard this change?`}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              style={{ backgroundColor: "white", width: "48%" }}
              onClick={onClose}
              disabled={loading}
            >
              <span style={{ color: "black" }}>No, Keep Changes</span>
            </Button>
            <Button
              style={{ width: "48%" }}
              variant="primary"
              onClick={onDiscard}
            >
              Yes, Discard Changes
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default DiscardModal;
