import { IAccount } from "../../context/AccountProvider";
import { fetchWrapper, momentStartOfDay } from "../../utility/util";
import { InvoiceFile, PaymentPages, InvoiceResponse } from "../../types";
type Card = {
  type: string;
  number: string;
  expMonth: string;
  expYear: string;
  zip: string;
};

type Ach = {
  bankName: string;
  type: string;
  mask: string;
};
export type Payment = {
  id: string;
  name: string;
  isDefault: boolean;
  paymentMethodType: string;
  card: Card | null;
  ach: Ach | null;
};
export type PaymentResponse = {
  code: number;
  error: string;
  message: string;
};
export async function fetchAggregatedEnrollment(): Promise<IAccount> {
  try {
    const query = "/api/enrollment/v1/me/aggregated-enrollment";

    const results = await fetchWrapper(query);
    if (results.ok) {
      const resultsJson = await results.json();
      return Promise.resolve(resultsJson);
    } else {
      throw new Error("error with agreggated enrollment");
    }
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function fetchActivities(
  startDate?: string | null,
  endDate?: string | null
) {
  try {
    const defaultStartDate = momentStartOfDay()
      .subtract(1, "y")
      .format("MM/DD/YY");
    const defaultEndDate = momentStartOfDay().format("MM/DD/YY");

    const sDate = encodeURIComponent(
      startDate
        ? momentStartOfDay(startDate).format("MM/DD/YY")
        : defaultStartDate
    );
    const eDate = encodeURIComponent(
      endDate ? momentStartOfDay(endDate).format("MM/DD/YY") : defaultEndDate
    );
    const query = `/api/enrollment/v1/me/activities?startDate=${sDate}&endDate=${eDate}`;

    const results = await fetchWrapper(query);

    if (results.ok) {
      const resultsJson = await results.json();
      return Promise.resolve(resultsJson);
    }

    return Promise.reject([]);
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export const SetNewHomeGym = async (locationId: string) => {
  try {
    const authToken = sessionStorage.getItem("authkit.storage.tokens");
    if (!authToken) {
      throw new Error("AuthToken not present");
    }
    const accessToken = await JSON.parse(authToken);
    if (!accessToken.accessToken) {
      Promise.reject("No access token");
    }
    await fetchWrapper(`/api/enrollment/v1/me/home-gym`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        locationId: locationId,
      }),
    });
    // if (homeGym.status === 200) {
    //   const json = await homeGym.json();
    // }
  } catch (e: unknown) {
    throw new Error("Error Setting New Home Gym");
  }
};

export const discardNewHomeGym = async () => {
  try {
    await fetchWrapper(`/api/enrollment/v1/me/home-gym`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e: unknown) {
    throw new Error("Error Discarding New Home Gym");
  }
};

export async function fetchPaymentMethods(): Promise<{
  paymentMethods: Payment[];
}> {
  try {
    const query = "/api/enrollment/v1/me/payment-methods";

    const results = await fetchWrapper(query);

    const resultsJson: { paymentMethods: Payment[] } = await results.json();
    return Promise.resolve(resultsJson);
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function deletePaymentMethod(id: string): Promise<void> {
  try {
    const query = `/api/enrollment/v1/me/payment-methods/${id}`;
    const results = await fetchWrapper(query, {
      method: "DELETE",
    });
    if (results.ok) {
      return;
    }
    return Promise.reject("invalid request");
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function updatePaymentMethod(
  paymentMethod: Payment
): Promise<Payment> {
  try {
    const query = `/api/enrollment/v1/me/payment-methods/${paymentMethod.id}`;
    const results = await fetchWrapper(query, {
      method: "PUT",
      body: JSON.stringify(paymentMethod),
    });
    if (results.ok) {
      const resultsJson = await results.json();
      return resultsJson;
    }
    return Promise.reject("invalid request");
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function setAsDefaultPaymentMethod(paymentId: string) {
  try {
    const query = `/api/enrollment/v1/me/payment-methods/default`;
    const results = await fetchWrapper(query, {
      method: "POST",
      body: JSON.stringify({ id: paymentId }),
    });
    if (results.ok) {
      return Promise.resolve(results.ok);
    }
    return Promise.reject("invalid request");
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function fetchPaymentPages(): Promise<PaymentPages> {
  try {
    const query = "/api/enrollment/v1/me/payment-pages";

    const results = await fetchWrapper(query);

    const resultsJson = await results.json();
    return resultsJson;
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function fetchInvoices(
  pageSize: number,
  pageNumber: number
): Promise<InvoiceResponse> {
  try {
    const query = `/api/enrollment/v1/me/invoices?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    const results = await fetchWrapper(query);

    const resultsJson = await results.json();
    return resultsJson;
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function fetchInvoiceById(id: string): Promise<InvoiceFile> {
  try {
    const query = `/api/enrollment/v1/me/invoices/${id}`;

    const results = await fetchWrapper(query);

    const resultsJson = await results.json();
    return resultsJson;
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export const payoffBalance = async (): Promise<PaymentResponse> => {
  try {
    const res = await fetchWrapper(`/api/enrollment/v1/me/payoff-balance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (res.status !== 200) {
      if (res.status === 400) {
        return json as PaymentResponse;
      } else if (res.status === 500) {
        return json as PaymentResponse;
      }
      throw new Error("Error submitting payment");
    }
    return json as PaymentResponse;
  } catch (e: unknown) {
    throw new Error("Error submitting payment");
  }
};
