import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Button, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import {
  fetchInvoiceById,
  fetchInvoices,
} from "../../../api/enrollment/Queries";
import { ClientConfigContext } from "../../../context/ClientConfigProvider";
import { InvoiceResponse } from "../../../types";
import ProfileToast from "../../../utility/ProfileToast";
import { pdfDownloadFromBase64 } from "../../../utility/util";

const BillingHistoryTable = () => {
  const [invoices, setInvoices] = useState<InvoiceResponse | null>(null);
  const [invoiceDownloadError, setInvoiceDownloadError] = useState(false);
  const [errorInvoices, setErrorInvoices] = useState(false);
  const [loadingInvoices, setLoadingInvoices] = useState(true);
  const { client } = useContext(ClientConfigContext);
  const pageSize = 40;
  const pageNumber = 1;

  useEffect(() => {
    (async () => {
      try {
        const res = await fetchInvoices(pageSize, pageNumber);
        setInvoices(res);
      } catch (e: unknown) {
        setErrorInvoices(true);
      } finally {
        setLoadingInvoices(false);
      }
    })();
  }, []);

  const handleInvoiceDownload = (fileId: string, date: string) => async () => {
    try {
      const res = await fetchInvoiceById(fileId);
      if (res.result.file) {
        pdfDownloadFromBase64(res.result.file, date);
        ProfileToast(`Successfully downloaded your invoice.`, true, client);
      }
    } catch (e: unknown) {
      setInvoiceDownloadError(true);
    }
  };

  useEffect(() => {
    if (invoiceDownloadError) {
      ProfileToast(
        `There was an error downloading your invoice.`,
        false,
        client
      );
    }
  }, [invoiceDownloadError]);

  const addZeroes = (num: string) => {
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    return Number(num).toFixed(len);
  };
  return (
    <Tabs defaultActiveKey="billing">
      <Tab eventKey="billing" title="BILLING HISTORY" className="p-0 p-md-3">
        <div>
          <Table
            responsive
            striped={!loadingInvoices && !errorInvoices}
            hover={!loadingInvoices && !errorInvoices}
            className="billing-table"
          >
            <thead>
              <tr>
                <th>DATE</th>
                <th>DESCRIPTION</th>
                <th>STATUS</th>
                <th>AMOUNT</th>
                <th>INVOICE DOC (PDF)</th>
              </tr>
            </thead>
            <tbody>
              {loadingInvoices && (
                <tr>
                  <td colSpan={5}>
                    <div
                      className="center-loading"
                      style={{ marginTop: 0, background: "none" }}
                    >
                      <Spinner animation="border" />
                    </div>
                  </td>
                </tr>
              )}
              {!loadingInvoices && errorInvoices && (
                <tr>
                  <td colSpan={5}>
                    <div
                      className="center-loading"
                      style={{ marginTop: "24px", background: "none" }}
                    >
                      <h2>
                        <strong>
                          Sorry there was an error fetching your invoices.
                        </strong>
                      </h2>
                    </div>
                  </td>
                </tr>
              )}
              {!loadingInvoices &&
                !errorInvoices &&
                invoices?.invoices &&
                invoices?.invoices.map((inv) => (
                  <tr key={inv.id}>
                    <td>
                      {inv.date ? moment(inv.date).format("MM/DD/YYYY") : ""}
                    </td>
                    <td>{inv.description}</td>
                    <td>{inv.status}</td>
                    <td>
                      ${inv.amount ? addZeroes(inv.amount.toString()) : "0.00"}
                    </td>
                    <td>
                      {inv.fileId ? (
                        <Button
                          variant="link"
                          className="unstyled-button"
                          onClick={handleInvoiceDownload(inv.fileId, inv.date)}
                        >
                          Download
                        </Button>
                      ) : (
                        <span>Unavailable</span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Tab>
    </Tabs>
  );
};

export default BillingHistoryTable;
