import { gql } from "@apollo/client";

export const enrollment = gql`
  query GetEnrollment {
    enrollment {
      state {
        client
        orderHandle
        orderHandleStatus
        orderHandleMessage
        orderId
        orderInputPreviewHash
        orderInput {
          billingAddressId
          paymentToken
          shippingAddressId
          sku
        }
        order {
          handle
          onetimeCreditTotal
          onetimeDiscount
          onetimeDiscounts
          onetimePackageDetails {
            name
            subtotal
          }
          onetimeSubtotal
          onetimeSubtotalAfterDiscount
          onetimeTaxes
          onetimeTotal
          onetimeSetupFee
          periodicDiscount
          periodicDiscounts
          periodicPackageDetails {
            name
            subtotal
          }
          periodicSubtotal
          periodicTotal
          periodicServiceStartDate
          periodicTaxes
          paymentMethodRequired
          taxCalculated
          promoCodes {
            code
            status
            description
          }
        }
        paymentPageCc {
          hostedPaymentPageId
          hostedPaymentPageUrl
          key
          signature
          tenantId
          token
        }
        paymentPageECheck {
          hostedPaymentPageId
          hostedPaymentPageUrl
          key
          signature
          tenantId
          token
        }
        phase
      }
    }
  }
`;

export const eligibility = gql`
  query GetEligibility {
    eligibilityCheck {
      state {
        attributes
        client
        eligibilityFlow
        eligibilityKind
        eligibilityScope
        profile {
          coverageType
          dob
          email
          firstName
          gender
          groupNumber
          hasBlue365
          lastName
          middleName
          primarySubscriber
          subscriberId
          zip
        }
        profileStatus
        ssoLinkName
        ssoLinkUpstreamId
        status
        statusDetail
      }
    }
  }
`;

export const packageSelect = gql`
  query GetPackageSelect {
    packageSelect {
      state {
        plan {
          name
        }
        price
      }
    }
  }
`;

export const orderStatus = gql`
  query orderStatus {
    enrollment {
      state {
        orderHandleStatus
      }
    }
  }
`;

export const impersonation = gql`
  query {
    impersonation {
      state {
        accessToken
        client
        contactId
        csrUsername
        expiresIn
        inContactBaseUri
        inContactToken
        maskingEnabled
        sessionId
        siteUrl
      }
    }
  }
`;

export const requiresTermsQuery = gql`
  query {
    enrollment {
      state {
        enrollmentTermsConditionsRequired
      }
    }
  }
`;

export const basicInformationFromB365 = gql`
  query {
    eligibilityCheck {
      state {
        profile {
          email
          firstName
          lastName
          dob
        }
      }
    }
  }
`;
