import React, { SetStateAction } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { Dispatch } from "react";
import { Payment } from "../../../api/enrollment/Queries";

const paymentSchema = Yup.object().shape({
  name: Yup.string().min(1).required("Required"),
  card: Yup.object().shape({
    number: Yup.string().min(1).max(16).required("Required"),
    expMonth: Yup.string().min(1).required("Required"),
    expYear: Yup.string().min(4).required("Required"),
    zip: Yup.string()
      .min(5, "Zip Code must be 5 digits long")
      .required("Required"),
  }),

  //   ach: null,
});

const PaymentForm = ({
  paymentForm,
  onEdit,
  onClose,
}: {
  paymentForm: Payment;
  onEdit: (paymentMethod: Payment) => () => Promise<void>;
  onClose: Dispatch<SetStateAction<boolean>>;
}) => {
  const initialValues: Payment = paymentForm;
  const year = new Date().getFullYear() - 20;
  const listOfYears = Array.from(new Array(60), (val, index) => index + year);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={paymentSchema}
        onSubmit={() => {
          return;
        }}
      >
        {(props) => (
          <>
            <Modal.Body>
              <Form
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  marginLeft: `10px`,
                }}
              >
                <label className="payment-label" htmlFor="name">
                  CARDHOLDER NAME <span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  style={{
                    border: "solid",
                    borderWidth: "thin",
                    borderColor: "grey",
                  }}
                  className="w-100 rounded mb-2"
                  id="name"
                  name="name"
                />
                <ErrorMessage name="name">
                  {(msg) => (
                    <div
                      style={{
                        fontSize: "0.8rem",
                        marginTop: "0px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "20px",
                      width: "33%",
                    }}
                  >
                    <label className="payment-label" htmlFor="card.expMonth">
                      EXP MONTH <span style={{ color: "red" }}>*</span>
                    </label>
                    <ErrorMessage name="card.expMonth" />
                    <Field
                      className="rounded mb-2"
                      style={{
                        width: "50%",
                        border: "solid",
                        borderWidth: "thin",
                        borderColor: "grey",
                      }}
                      as="select"
                      id="card.expMonth"
                      name="card.expMonth"
                    >
                      <option value={"1"}>1</option>
                      <option value={"2"}>2</option>
                      <option value={"3"}>3</option>
                      <option value={"4"}>4</option>
                      <option value={"5"}>5</option>
                      <option value={"6"}>6</option>
                      <option value={"7"}>7</option>
                      <option value={"8"}>8</option>
                      <option value={"9"}>9</option>
                      <option value={"10"}>10</option>
                      <option value={"11"}>11</option>
                      <option value={"12"}>12</option>
                    </Field>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "33%",
                    }}
                  >
                    <label className="payment-label" htmlFor="card.expYear">
                      EXP YEAR <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      className="rounded mb-2"
                      style={{
                        width: "50%",
                        border: "solid",
                        borderWidth: "thin",
                        borderColor: "grey",
                      }}
                      as="select"
                      id="card.expYear"
                      name="card.expYear"
                    >
                      {listOfYears.map((year) => {
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                </div>
                <label className="payment-label" htmlFor="card.zip">
                  ZIP CODE <span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  className="w-100 rounded mb-2"
                  style={{
                    border: "solid",
                    borderWidth: "thin",
                    borderColor: "grey",
                    marginBottom: "0px",
                  }}
                  id="card.zip"
                  name="card.zip"
                />
                <ErrorMessage name="card.zip">
                  {(msg) => (
                    <div
                      style={{
                        fontSize: "0.8rem",
                        marginTop: "0px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
                <button
                  onClick={() => onClose(false)}
                  className="col btn btn-outline-primary ml-2"
                >
                  Cancel
                </button>
                <button
                  onClick={onEdit(props.values)}
                  className="col mx-2 btn btn-secondary "
                  disabled={!props.isValid && props.dirty === true}
                >
                  Edit Payment Method
                </button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
};

export default PaymentForm;
