import { Form, Formik } from "formik";
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Step, Steps, Wizard as WizardAlbus } from "../wizardAlbus";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import Stepper, { WizardStepperStep } from "./components/Stepper";
import { EligibilityFlow, Flow, FormikWizardState } from "./types";
import * as yup from "yup";
import { Flows, getModel, getSteps, getValidation } from "./model/Flows";
import SmartyStreetsProvider from "../../context/SmartyStreetsProvider";
import SummaryProvider from "../../context/SummaryProvider";
import useEligibilityStart from "../../hooks/useEligibilityStart";
import { enrollment } from "../../graphql/Queries";
import { useQuery } from "@apollo/client";
import { ClientConfigContext } from "../../context/ClientConfigProvider";
import { Persist } from "./components/FormikPersistence";
import { UserContext, USER_STATUS } from "../../context/UserProvider";
import useRouteQuery from "../../hooks/useRouteQuery";

export const stripTypename = (object: Record<string, unknown>) => {
  const mutable = { ...object };
  if (mutable.__typename) {
    delete mutable.__typename;
  }
  return mutable;
};

interface IEnrollment {
  client: string;
  // eligibilityToken: string;
  orderHandle: string;
  orderHandleStatus: string;
  orderInputPreviewHash: string;
  orderInput: {
    billingAddressId: string;
    paymentToken: string;
    shippingAddressId: string;
    sku: string;
  };
  order: {
    onetimeDiscount: string;
    onetimeSubtotal: string;
    onetimeTaxes: string;
    onetimeTotal: string;
    periodicDiscount: string;
    periodicSubtotal: string;
    periodicTotal: string;
    taxCalculated: string;
  };
  paymentPage: {
    hostedPaymentPageId: string;
    hostedPaymentPageUrl: string;
    key: string;
    signature: string;
    tenantId: string;
    token: string;
  };
}

const mergeExisitingProperties = (
  from: { [key: string]: unknown },
  to: { [key: string]: unknown }
): void => {
  for (const key of Object.keys(from)) {
    if (key in to) {
      switch (key) {
        default: {
          if (from[key] !== null) to[key] = from[key];
        }
      }
    }
  }
};

// function mergeDOBExistingProperties(dob: string): {
//   year: string;
//   month: string;
//   day: string;
// } {
//   const dobSplit = dob.split("/");
//   return {
//     year: dobSplit[2],
//     month: dobSplit[1],
//     day: dobSplit[0],
//   };
// }
// const mergeDOBExistingProperties = (
//   dob: string
// ) : (dob) => {
//   const dobSplit = dob.split("/")
//   return dobValues = {
//     year: dobSplit[2],
//     month: dobSplit[1],
//     day: dobSplit[0],
//   }
// };

/*
  Generates a multi step wizard with formik and yup validations,
  Steps are configurable and interchangeable
  initialState.ts has the form flows that correspond to a given
  eligibility flow on the backend.
  TODO: update eligibilityValues type
*/
const Wizard = () => {
  const [initialState, setInitialState] = useState<
    FormikWizardState<EligibilityFlow>
  >({});
  const { user } = useContext(UserContext);

  const [validation, setValidation] = useState<yup.AnyObjectSchema>();
  const [steps, setSteps] = useState<WizardStepperStep[]>([]);
  const [flow, setFlow] = useState<Flow | null>(null);
  const [enrollmentValues, setEnrollmentValues] = useState<IEnrollment | null>(
    null
  );
  const [triggerError, setTriggerError] = useState<null | Error>(null);

  const { client, config } = useContext(ClientConfigContext);
  const { path } = useRouteMatch();
  const query = useRouteQuery();
  const history = useHistory();


  useEffect(() => {
    //for error boundary to catch async errors
    if (triggerError) {
      throw triggerError;
    }
  }, [triggerError])

  const onEligible = () => {
    history.push(
      `${path}/package${
        query.toString().length > 0 ? `?${query.toString()}` : ""
      }`
    );
  };

  const { loading, currentEligibility } = useEligibilityStart(
    client,
    onEligible
  );

  useEffect(() => {
    if (!loading) {
      setFlow(Flows[config["eligibility.scope"]]);
    }
  }, [loading]);

  //setup enrollment data hydrates formik
  useQuery(enrollment, {
    fetchPolicy: "no-cache",
    onCompleted: useCallback((data) => {
      if (data.enrollment?.state) {
        const values = { ...data.enrollment.state };
        setEnrollmentValues(values);
      } else {
        setTriggerError(new Error("Unable to determine eligibility state"));
      }
    }, []),
    onError: () => {
      setTriggerError(new Error("Unable to determine eligibility state"));
    },
  });

  useEffect(() => {
    if (flow && enrollmentValues && !loading) {
      const model = getModel(flow);
      const validation = getValidation(flow);
      const steps = getSteps(flow);
      //merge current eligibility from backend into our initial state
      let splitDob = ["", "", ""];
      if (currentEligibility !== null) {
        const dob: string = (
          currentEligibility?.profile as { [key: string]: string }
        ).dob;
        splitDob =
          dob !== undefined && dob !== null ? dob.split("-") : ["", "", ""];
      }
      //merge current eligibility from backend into our initial state
      mergeExisitingProperties(
        {
          ...(currentEligibility?.profile as { [key: string]: unknown }),
          day: splitDob[2],
          month: splitDob[1],
          year: splitDob[0],
          locked:
            (currentEligibility?.profileStatus as string)?.toUpperCase() ===
            "LOCKED"
              ? true
              : false,
          completed: user.status === USER_STATUS.ELIGIBLE,
        },
        model.eligibility as { [key: string]: unknown }
      );
      setInitialState(model);
      setValidation(yup.object().shape(validation));
      setSteps(steps);
    }
  }, [flow, enrollmentValues, currentEligibility, loading]);

  return (
    <SmartyStreetsProvider>
      <SummaryProvider>
        <Fragment>
          {Object.keys(initialState).length === 0 ? (
            <div className="center-loading">
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="full-height">
              <Container className="wizard-container">
                <Row>
                  <Col>
                    <Route
                      render={({ history, match: { url } }) => (
                        <Formik
                          validationSchema={validation}
                          initialValues={initialState}
                          enableReinitialize
                          onSubmit={() => {
                            return;
                          }}
                        >
                          <Fragment>
                            <Stepper
                              steps={steps}
                              flow={flow}
                              step={
                                steps.find((s) =>
                                  history.location.pathname.includes(
                                    s.label.toLowerCase()
                                  )
                                ) ?? steps[0]
                              }
                            />
                            <Form style={{ marginBottom: "120px" }}>
                              <WizardAlbus history={history} basename={url}>
                                <Steps>
                                  {steps.map((s: WizardStepperStep) => (
                                    <Step
                                      key={`wizard-${s.label}`}
                                      id={s.label.toLowerCase()}
                                      // eslint-disable-next-line
                                      render={({ next, previous }: any) => {
                                        return React.createElement(
                                          s.component!,
                                          {
                                            next,
                                            previous,
                                          }
                                        );
                                      }}
                                    />
                                  ))}
                                </Steps>
                              </WizardAlbus>
                              <Persist
                                name="enrollment-flow"
                                isSessionStorage
                              />
                            </Form>
                          </Fragment>
                        </Formik>
                      )}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </Fragment>
      </SummaryProvider>
    </SmartyStreetsProvider>
  );
};

export default Wizard;
