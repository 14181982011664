import { useMutation } from "@apollo/client";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { addressEnrollment } from "../../../graphql/Mutations";
import Header from "../components/Header";
import Summary from "../components/Summary";
import { EligibilityFlow, FormikWizardState, SmartyAddress } from "../types";
import AddressForm from "../components/AddressForm";
import AddressVerificationModal from "../components/AddressVerificationModal";
import { setAddressQuery } from "../../../api/identity/Queries";
import useSummary from "../../../hooks/useSummary";
import StatusModal from "../components/StatusModal";
import { useCallback } from "react";
import FooterButtons from "../components/FooterButtons";

export enum ADDRESS {
  SHIPPING = "SHIPPING",
  BILLING = "BILLING",
}

const Address = ({
  previous,
  next,
}: {
  previous?: () => void;
  next?: () => void;
}) => {
  const { values, errors, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<FormikWizardState<EligibilityFlow>>();

  const { setMutated } = useSummary();
  //grab the latest summary
  useEffect(() => {
    setMutated(true);
  }, []);
  //initial verification
  useEffect(() => {
    setFieldTouched("address.home.street1");
  }, [setFieldTouched]);

  const [verifyOpen, setVerifyOpen] = useState(false);
  const [show, setShow] = useState(false);

  const [setAddress] = useMutation(addressEnrollment, {
    onError: useCallback(() => {
      setShow(true);
    }, []),
  });
  //this needs billing address
  const handleAddress = async () => {
    try {
      //both the same
      if (!values.address!.billingRequired) {
        const res = await setAddressQuery(
          {
            id: values.address!.homeId,
            address1: values.address!.home.street1,
            address2: values.address!.home.street2,
            city: values.address!.home.city,
            state: values.address!.home.state,
            zip: values.address!.home.zip,
          },
          ["shipping", "billing"]
        );
        if (res.id !== "") {
          setFieldValue("address.homeId", res.id);
          setFieldValue("address.billingId", res.id);
          await setAddress({
            variables: {
              id: res.id,
              type: ADDRESS.SHIPPING,
            },
          });
          await setAddress({
            variables: {
              id: res.id,
              type: ADDRESS.BILLING,
            },
          });
        }
      } else {
        const res1 = await setAddressQuery(
          {
            id: values.address!.homeId,
            address1: values.address!.home.street1,
            address2: values.address!.home.street2,
            city: values.address!.home.city,
            state: values.address!.home.state,
            zip: values.address!.home.zip,
          },
          ["shipping"]
        );
        if (res1.id !== "") {
          setFieldValue("address.homeId", res1.id);
          await setAddress({
            variables: {
              id: res1.id,
              type: ADDRESS.SHIPPING,
            },
          });
        }
        const res2 = await setAddressQuery(
          {
            id: values.address!.billingId,
            address1: values.address!.billing.street1,
            address2: values.address!.billing.street2,
            city: values.address!.billing.city,
            state: values.address!.billing.state,
            zip: values.address!.billing.zip,
          },
          ["billing"]
        );
        if (res2.id !== "") {
          setFieldValue("address.billingId", res2.id);
          await setAddress({
            variables: {
              id: res2.id,
              type: ADDRESS.BILLING,
            },
          });
        }
      }
    } catch (e: unknown) {
      setShow(true);
      setVerifyOpen(false);
      return;
    }
    setFieldValue("address.completed", true);
    if (next) {
      next();
    }
  };
  const handleConfirm = () => {
    handleAddress();
  };
  const handleCancel = () => {
    setVerifyOpen(false);
  };

  const handleNext = () => {
    if (
      !getIn(values, "address.verifiedHome") ||
      (!getIn(values, "address.verifiedBilling") &&
        values.address!.billingRequired)
    ) {
      setVerifyOpen(true);
    } else {
      handleAddress();
    }
  };

  const handleVerified = (field: string, value: boolean) => {
    if (field === "address.billing") {
      setFieldValue("address.verifiedBilling", value);
    } else {
      setFieldValue("address.verifiedHome", value);
    }
  };

  const setAddressValues = (
    field: string,
    smartAddressArr: SmartyAddress[]
  ) => {
    if (smartAddressArr.length > 0) {
      //timeouts required by formik :/
      const smartAddress = smartAddressArr[0];
      setFieldValue(field + ".street1", smartAddress.address.streetLine);
      setTimeout(() => setFieldTouched(field + ".street1", true));
      setFieldValue(field + ".street2", smartAddress.address.secondary);
      setTimeout(() => setFieldTouched(field + ".street2", true));
      setFieldValue(field + ".zip", smartAddress.address.zipcode);
      setTimeout(() => setFieldTouched(field + ".zip", true));
      setFieldValue(field + ".city", smartAddress.address.city);
      setTimeout(() => setFieldTouched(field + ".city", true));
      setFieldValue(field + ".state", smartAddress.address.state);
      setTimeout(() => setFieldTouched(field + ".state", true));
      handleVerified(field, true);
    }
  };
  //set all fields at once
  const handleHomeAddress = (t: SmartyAddress[]) => {
    setAddressValues("address.home", t);
  };

  const handleBillingAddress = (t: SmartyAddress[]) => {
    setAddressValues("address.billing", t);
  };
  //set single field
  const handleChangeWrapper =
    (field: string) => (e: React.ChangeEvent<unknown>) => {
      handleVerified(field, false);
      handleChange(e);
    };

  const handleInputChange = (field: string) => (input: string) => {
    handleVerified(field, false);
    setFieldValue(field + ".street1", input);
    setTimeout(() => setFieldTouched(field + ".street1", true));
  };

  const formatHomeAddress = (): string => {
    return `${values.address!.home.street1}${
      values.address!.home.street2 !== ""
        ? " " + values.address!.home.street2
        : ""
    } ${values.address!.home.city}, ${values.address!.home.state} ${
      values.address!.home.zip
    }`;
  };

  const formatBillingAddress = (): string => {
    return `${values.address!.billing.street1}${
      values.address!.billing.street2 !== ""
        ? " " + values.address!.billing.street2
        : ""
    } ${values.address!.billing.city}, ${values.address!.billing.state} ${
      values.address!.billing.zip
    }`;
  };

  const handleCancelError = () => {
    setShow(false);
  };

  return (
    <div>
      <AddressVerificationModal
        homeAddress={
          !getIn(values, "address.verifiedHome")
            ? formatHomeAddress()
            : undefined
        }
        billingAddress={
          !getIn(values, "address.verifiedBilling") &&
          values.address!.billingRequired
            ? formatBillingAddress()
            : undefined
        }
        show={verifyOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <StatusModal
        show={show}
        status={"ERROR"}
        message=""
        onCancel={handleCancelError}
        onNext={() => {
          return;
        }}
      />
      <Row>
        <Col xs={12} md={7}>
          <Header
            title="Home Address"
            subtitle="Please enter your address below to complete your account setup."
          />

          <Card>
            <Card.Body style={{ padding: "24px" }}>
              <Form>
                <AddressForm
                  onChange={handleChangeWrapper("address.home")}
                  onChangeSmarty={handleHomeAddress}
                  onInputChange={handleInputChange("address.home")}
                  values={values.address!.home}
                  prefix="address.home"
                />
                <Row className="eligibility-form__row p-3">
                  <Form.Group
                    as={Col}
                    sm={12}
                    className="mb-3"
                    controlId="formbillingCheckbox"
                  >
                    <Form.Check
                      style={{ paddingLeft: 0 }}
                      type="checkbox"
                      name="address.billingRequired"
                      label="Use this same address for billing"
                      checked={!values.address!.billingRequired}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(
                          "address.billingRequired",
                          !e.target.checked
                        )
                      }
                    />
                  </Form.Group>
                </Row>
                {values.address!.billingRequired && (
                  <div>
                    <h3>
                      <b>Billing Address</b>
                    </h3>
                    <hr />
                    <AddressForm
                      onChange={handleChangeWrapper("address.billing")}
                      onChangeSmarty={handleBillingAddress}
                      onInputChange={handleInputChange("address.billing")}
                      values={values.address!.billing}
                      prefix="address.billing"
                    />
                  </div>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Summary />
        </Col>
      </Row>
      <FooterButtons
        onSubmit={handleNext}
        onBack={previous}
        submitDisabled={errors.address !== undefined}
      />
    </div>
  );
};

export default Address;
