import React from "react";
import { Card, Form, Spinner } from "react-bootstrap";

const PackageCard = ({
  name,
  sku,
  className,
  price,
  numGyms,
  features,
  handleChange,
  selected,
  loading,
}: {
  name: string;
  sku: string;
  className: string;
  price: number;
  numGyms: number;
  features: { title: string; content: string }[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selected: string;
  loading: boolean;
}) => {
  return (
    <>
      {loading ? (
        <div className="center-loading">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {numGyms ? (
            <label htmlFor={name.toLowerCase()}>
              <Card>
                <Card.Body>
                  <Form.Group>
                    <Form.Check
                      type="radio"
                      id={name.toLowerCase()}
                      value={sku}
                      name="package.sku"
                      checked={selected === sku}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Card.Title>{name}</Card.Title>
                  <Card.Text style={{ textAlign: "center" }}>
                    <div className="mb-0 p0 price">${price}</div>
                    <div className="text-muted p-0 per-month">/ month</div>
                    <p className="mt-4 num-of-gyms">
                      <b>{numGyms.toLocaleString()}+</b>
                      <br />
                      <span>gyms</span>
                    </p>
                    {className === "elite" ? (
                      <div className="mt-4 elite-gym-choice">
                        <b>1 Elite gym choice</b> from <b>100+</b> available
                      </div>
                    ) : null}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <div className="text-muted footer-top-line">
                    Included with purchase
                  </div>
                  {features.map((f, index) => (
                    <div style={{ textAlign: "center" }} key={index}>
                      <div className="feature-title">
                        <b>{f.title}</b>
                      </div>
                      <div className="feature-body">{f.content}</div>
                    </div>
                  ))}
                </Card.Footer>
              </Card>
            </label>
          ) : (
            <label htmlFor={name.split(" ")[0].toLowerCase()}>
              <div className="digital-content-only-container mt-3">
                <div className="digital-content-only-container__tag">
                  digital content only
                </div>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    id={name.split(" ")[0].toLowerCase()}
                    value={sku}
                    checked={selected === sku}
                    name="package.sku"
                    onChange={handleChange}
                  />
                </Form.Group>
                <div className="digital-content-only-container__content-row">
                  <div className="digital-content-only-container__price-container">
                    <div className="digital-content-only-container__price">
                      $10
                    </div>
                    <div className="digital-content-only-container__duration">
                      / month
                    </div>
                  </div>
                  <div className="digital-content-only-container__content-column">
                    <div className="digital-content-only-container__description">
                      <div className="digital-content-only-container__description-top-line">
                        Add hundreds of on-demand videos and live streams to
                        your plan.
                      </div>
                      <div className="digital-content-only-container__description-bottom-line">
                        Content includes workouts, wellness videos, nutrition
                        tutorials, and more!
                      </div>
                    </div>
                    {/* <div className="digital-content-only-container__includes">
                    <div className="digital-content-only-container__includes-top-text">
                      Included with purchase
                    </div>
                    <div className="digital-content-only-container__includes-bottom-text">
                      <span>Digital Content |</span> 30% off every 10th class
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </label>
          )}
        </>
      )}
    </>
  );
};

export default PackageCard;
