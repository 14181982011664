import React from "react";

const Header = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <div className="header-component">
      <h3>
        <b>{title}</b>
      </h3>
      <p>{subtitle}</p>
    </div>
  );
};

export default Header;
