import React from "react";
import { Tile } from "./Tile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useContext } from "react";
import { ClientConfigContext } from "../../../context/ClientConfigProvider";
import useGetEnv from "../../../hooks/useGetEnv";
import WhlLogo from "../../../resources/WhlLogo";

const WhlChoicesTile = () => {
  const { config } = useContext(ClientConfigContext);
  const environment = useGetEnv();
  const handleWhlLink = () => {
    const origin = "https://bcbsa.fitnessyourway.tivityhealth.com";
    let choicesUrl = `https://uat.whlchoices.com/#/register?ReferURL=`;
    switch (environment) {
      case "local":
        choicesUrl = `https://memberportaldev.wholehealthliving.com/#/register?ReferURL=`;
        break;
      case "develop":
        choicesUrl = `https://memberportaldev.wholehealthliving.com/#/register?ReferURL=`;
        break;
      default:
        if (window.location.href.includes(".qa.")) {
          choicesUrl = `https://memberportalqa.wholehealthliving.com/#/register?ReferURL=`;
        } else if (window.location.href.includes(".uat.")) {
          choicesUrl = `https://uat.whlchoices.com/#/register?ReferURL=`;
        } else {
          choicesUrl = `https://www.whlchoices.com/#/register?ReferURL=`;
        }
        break;
    }

    window.open(choicesUrl + origin, "_blank");
  };
  return config.wholeHealthChoices === "true" ? (
    <Tile className="tile-container__card-whl">
      <Tile.THeader className="tile-container__card-whl__whlHeader">
        <WhlLogo />
      </Tile.THeader>
      <Tile.TBody className="tile-container__card-whl__whlBody">
        <>
          <span className="white">
            <b>Get Wellness Discounts</b>
          </span>
          <p className="text-center p-0 mb-0 white w-75">
            Complement your fitness routine with discounts on wellness products
            and services offered by WholeHealth Living Choices.
          </p>
        </>
      </Tile.TBody>
      <Tile.TFooter className="tile-container__card-whl__whlFooter">
        <div
          style={{
            display: "flex",
            width: "100%",
            // flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outline-primary"
            className="btn btn-outline-primary align-self-bottom btn-sm mt-2"
            onClick={handleWhlLink}
          >
            Learn More <FontAwesomeIcon icon={faExternalLinkAlt} />
          </Button>
        </div>
      </Tile.TFooter>
    </Tile>
  ) : null;
};
export default WhlChoicesTile;
