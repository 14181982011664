import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { getClientConfig } from "../api/portal/Queries";

export interface IClientConfig {
  client: string;
  config: {
    "commerce.clientCode": string;
    "communication.addressChanged": string;
    "communication.emailChanged": string;
    "communication.welcomeHomeGym": string;
    "communication.welcomeNoHomeGym": string;
    "contact.phoneNumber": string;
    "eligibility.flow": string;
    "eligibility.kind": string;
    "eligibility.scope": string;
    "google.maps.key": string;
    "google.analytics.key": string;
    "idp.clientId": string;
    "idp.issuer": string;
    "maintenance.enable": string;
    "maintenance.until": string;
    "membership.costCenter": string;
    "network.clientCode": string;
    "site.baseUrl": string;
    "smartystreets.key": string;
    wholeHealthChoices: string;
    "zuora.hostedPagePath": string;
    "zuora.paymentGatewayCode.cc": string;
    "zuora.paymentGatewayCode.echeck": string;
    "zuora.paymentPageId.cc": string;
    "zuora.paymentPageId.echeck": string;
    "hotjar.hjid": string;
    "hotjar.hjsv": string;
    "lesmills.enable": string;
  };
  refresh: () => Promise<void>;
}

const initialContextValue: IClientConfig = {
  client: "",
  config: {
    "commerce.clientCode": "",
    "communication.addressChanged": "",
    "communication.emailChanged": "",
    "communication.welcomeHomeGym": "",
    "communication.welcomeNoHomeGym": "",
    "contact.phoneNumber": "",
    "eligibility.flow": "",
    "eligibility.kind": "",
    "eligibility.scope": "",
    "google.maps.key": "",
    "google.analytics.key": "",
    "idp.clientId": "",
    "idp.issuer": "",
    "maintenance.enable": "",
    "maintenance.until": "",
    "membership.costCenter": "",
    "network.clientCode": "",
    "site.baseUrl": "",
    "smartystreets.key": "",
    wholeHealthChoices: "",
    "zuora.hostedPagePath": "",
    "zuora.paymentGatewayCode.cc": "",
    "zuora.paymentGatewayCode.echeck": "",
    "zuora.paymentPageId.cc": "",
    "zuora.paymentPageId.echeck": "",
    "hotjar.hjsv": "",
    "hotjar.hjid": "",
    "lesmills.enable": "false",
  },
  refresh: () => {
    return Promise.resolve();
  },
};

export const ClientConfigContext =
  React.createContext<IClientConfig>(initialContextValue);

const ClientConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [config, setConfig] = useState<IClientConfig>(initialContextValue);
  const [loading, setLoading] = useState(true);

  const handleUpdateClientConfig = async () => {
    try {
      const res = await getClientConfig(window.location.hostname);
      setConfig(res);
      setLoading(false);
    } catch (e: unknown) {
      await Promise.reject(e);
    }
  };

  useEffect(() => {
    (async () => {
      await handleUpdateClientConfig();
    })();
  }, []);

  //must be called
  const handleFinalUpdate = async () => {
    await handleUpdateClientConfig();
  };

  if (loading) {
    return (
      <div className="center-loading">
        <Spinner animation="border" />
      </div>
    );
  }
  return (
    <ClientConfigContext.Provider
      value={{ ...config, refresh: handleFinalUpdate }}
    >
      {children}
    </ClientConfigContext.Provider>
  );
};

export default ClientConfigProvider;
