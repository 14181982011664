import React from "react";
import { FeatureInput, FeatureMembershipBody } from "../../types";
import FeatureBase from "./FeatureBase";

const FeatureMembership = ({ input }: { input: FeatureInput }) => {
  const body = input.body as FeatureMembershipBody;
  return (
    <FeatureBase
      background={`https://${input.media.slice(2)}`}
      leftAlign={true}
      slanted={input.slanted}
      size="large"
      colorOverlay
    >
      <div className="slanted-feature-right slanted-feature-right--large">
        <div className="feature-content feature-content--large">
          <h1 style={{ marginBottom: "24px" }}>{body.header}</h1>
          {body.attributes.map((a, i) => (
            <div
              key={"attr-" + i + "-1"}
              className="feature-content__attribute"
            >
              <img
                src={a.image}
                height="50"
                width="50"
                alt="icon"
                style={{ filter: "grayscale(1) invert(1)" }}
              />
              <div style={{ marginTop: "12px", marginLeft: "24px" }}>
                {a.text}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>{/*input.callout*/}</div>
    </FeatureBase>
  );
};

export default FeatureMembership;
