import React, { useEffect } from "react";
import { useState } from "react";
import { IBrandingFields } from "../../@types/generated/contentful";
import { client } from "../../client";
import { Branding } from "../../types";
import { convertToBranding } from "../../utility/contentful";

const NavBranding = ({ provider, alt }: { provider: string; alt: string }) => {
  const [branding, setBranding] = useState<Branding>({
    brandImage: "",
    brandingText: "",
  });
  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<IBrandingFields>({
        content_type: "branding",
        "metadata.tags.sys.id[all]": provider,
        limit: 1,
      });
      if (entry.items[0]) {
        setBranding(convertToBranding(entry.items[0]));
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="d-flex justify-content-end p-3 align-items-center"
      style={{ width: "100%" }}
    >
      <span className="nav-bar-top nav-bar-top__text">
        {branding.brandingText}
      </span>
      <img
        src={branding.brandImage}
        alt={alt}
        className="nav-bar-top nav-bar-top__image"
      />
    </div>
  );
};

export default NavBranding;
