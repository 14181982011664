import { useMutation } from "@apollo/client";
import { useAuthKit } from "@authkitcom/react";
import {
  faChevronLeft,
  faExclamationTriangle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CreditBalance from "../../components/account/Billing/CreditBalance";
import PaymentMethods from "../../components/account/Billing/PaymentMethods";
import PlanSummary from "../../components/account/Billing/PlanSummary";
import DiscardCancellationModal from "../../components/account/DiscardCancellationModal";
import CheckoutModal from "../../components/account/EditPlan/CheckoutModal";
import DiscardModal from "../../components/account/EditPlan/DiscardModal";
import BundlesDropDown from "../../components/shared/BundlesDropDown";
import FooterCard from "../../components/wizard/components/FooterCard";
import ProcessingOrderModal from "../../components/wizard/components/ProcessingOrderModal";
import { POLICY_TYPES } from "../../components/wizard/model/InitialState";
import { AccountContext } from "../../context/AccountProvider";
import { ClientConfigContext } from "../../context/ClientConfigProvider";
import {
  enrollmentStartMutation,
  ENROLLMENT_ACTIONS,
  ENROLLMENT_STATUSES,
  ORDER_FROM,
  placeOrder,
  setTermsMutation,
} from "../../graphql/Mutations";
import useCheckOrder from "../../hooks/useCheckOrder";
import useSummary from "../../hooks/useSummary";
import ProfileToast from "../../utility/ProfileToast";
import skuMap from "../../utility/skuMap";
import { userinfoWrapper } from "../../utility/util";
import { NavLink } from "react-router-dom";

const EditPlan = () => {
  const { authKit } = useAuthKit();
  const history = useHistory();
  const { refresh, suspension, hasPaymentMethods } = useContext(AccountContext);
  const {
    setMutated,
    setPromoCode,
    summary,
    uiSummary,
    loading: loadingSummary,
    promoLoading,
    cancel: cancelSummary,
    error: errorSummary,
  } = useSummary();

  //TODO - suspension logic updated in backend
  useEffect(() => {
    if (suspension.status === "CURRENT" || suspension.status === "FUTURE") {
      history.push("/account/manage");
    }
  }, [suspension]);

  const {
    start,
    reset,
    success: orderSuccess,
    error: orderError,
  } = useCheckOrder();

  const { enrollmentFrame, creditBalance, currentPrice } =
    useContext(AccountContext);
  const { client } = useContext(ClientConfigContext);

  const [change, setChange] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const [promo, setPromoState] = useState("");
  const [openProcessingModal, setOpenProcessingModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState<boolean>(false);
  const handleCloseDiscard = () => setOpenDiscardModal(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [progress, setProgress] = useState(0);
  const [promoOpen, setPromoOpen] = useState(true);
  const [validPromo, setValidPromo] = useState("");
  const [currentPromo, setCurrentPromo] = useState("");
  const [show, setShow] = useState(!loadingSummary);

  const [openDiscardCancellationModal, setOpenDiscardCancellationModal] =
    useState<boolean>(false);

  const [startPlanChange] = useMutation(enrollmentStartMutation, {
    onCompleted: () => {
      setMutated(true);
    },
  });

  const [placeOrderMutation] = useMutation(placeOrder, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const phase = data.enrollmentPlaceOrderAction.state.phase;
      if (phase && phase === "PERFORM") {
        start();
      }
    },
  });

  const [setTermsAndConditions] = useMutation(setTermsMutation);

  const convertToCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  useEffect(() => {
    if (orderError) {
      setDiscardLoading(false);
      setOpenProcessingModal(false);
    }
    if (orderSuccess) {
      refresh().then(() => history.push("/account/manage?edit_success=true"));
      // history.push("/account/manage?edit_success=true");
    }
    if (orderError) {
      ProfileToast("Your subscription has failed to update.", false, client);
      setChange(null);
      reset();
    }
  }, [orderSuccess, orderError]);

  useEffect(() => {
    if (change && change !== "termedDefault") {
      cancelSummary();
      if (enrollmentFrame.status === "TERMED") {
        //re-enrollment
        startPlanChange({
          variables: {
            action: ENROLLMENT_ACTIONS.NEW,
            sku: change,
          },
        });
      } else {
        //standard plan change
        startPlanChange({
          variables: {
            action: ENROLLMENT_ACTIONS.CHANGE,
            sku: change,
          },
        });
      }
    } else {
      if (enrollmentFrame.status === "TERMED") {
        setChange("termedDefault");
        //re-enrollment
        // startPlanChange({
        //   variables: {
        //     action: ENROLLMENT_ACTIONS.NEW,
        //     sku: "enrollmentFrame.current?.sku",
        //   },
        // });
      } else {
        cancelSummary();
      }
    }
  }, [change]);

  const handleDiscard = () => {
    setDiscardLoading(true);
    placeOrderMutation({
      variables: {
        from: ORDER_FROM.ACTION,
        action: ENROLLMENT_ACTIONS.DISCARD,
        paymentMethod: "",
      },
    });
  };
  useEffect(() => {
    if (loadingSummary || errorSummary || promoLoading) {
      setShow(false);
    }
  }, [loadingSummary, errorSummary, promoLoading]);

  useEffect(() => {
    isValidPromo(promo);
  }, [show]);

  const handlePromo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoState(e.target.value);
  };

  const handlePromoSubmit = () => {
    if (summary?.promoCodes.length === 0) {
      setPromoCode(promo);
    } else {
      const str = summary?.promoCodes
        .map(function (elem) {
          if (elem.code !== promo && elem.status !== "FAILURE")
            return elem.code;
        })
        .join(",");
      //Regex to remove commas at the beginning, end, and next to each other
      const removed = str?.replace(/^(, *)+|(, *)+$|,+(?=,)/g, "");
      setPromoCode(promo + "," + removed);
    }
  };

  const handlePromoRemove = (deletedPromo: string) => {
    if (summary?.promoCodes.length === 1) {
      setPromoCode("");
    } else {
      const str = summary?.promoCodes
        .map(function (elem) {
          if (elem.code !== deletedPromo) return elem.code;
        })
        .join(",");
      //Regex to remove commas at the beginning, end, and next to each other
      const removed = str?.replace(/^(, *)+|(, *)+$|,+(?=,)/g, "");
      setPromoCode(String(removed));
    }
  };

  const isValidPromo = (promo: string) => {
    setCurrentPromo(promo);
    summary?.promoCodes?.forEach((code) => {
      if (code.code === promo && code.status === "SUCCESS") {
        setValidPromo("TRUE");
        setPromoOpen((prevPromo) => !prevPromo);
        setPromoState("");
      } else if (code.code === promo && code.status === "FAILURE") {
        setValidPromo("FALSE");
      }
    });
  };

  const handleCheckout = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = async (termsAccepted: boolean) => {
    if (termsAccepted) {
      try {
        setOpen(false);
        setProgress(0);
        setIntervalId(setInterval(() => setProgress((p) => p + 5), 700));
        setOpenProcessingModal(true);
        await setTermsAndConditions({
          variables: {
            terms: [POLICY_TYPES.FITNESS].join(","),
          },
        });
        placeOrderMutation({
          variables: {
            from: ORDER_FROM.PREVIEW,
            action: ENROLLMENT_ACTIONS.NONE,
            paymentMethod: "",
          },
        });
      } catch {
        ProfileToast("Your subscription has failed to update.", false, client);
        setChange(null);
        reset();
      }
    } else {
      ProfileToast(
        "You must agree to the terms and conditions.",
        false,
        client
      );
    }
  };

  useEffect(() => {
    if (openProcessingModal && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, openProcessingModal]);

  const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === (enrollmentFrame.current?.sku ?? "")) {
      //need a edge case for if they are termed
      if (enrollmentFrame?.status === "TERMED") {
        setChange(e.target.value);
        setShowWarning(true);
      } else {
        setChange(null);
        setShowWarning(false);
      }
    } else {
      setChange(e.target.value);
      setShowWarning(true);
    }
  };

  const defaultCardBody = (
    <Row>
      <Col xs={12} lg={4}>
        <h6 className="text-uppercase">Primary Member</h6>
        <div>
          <span>
            {`${authKit?.getUserinfo()?.given_name} ${
              authKit?.getUserinfo()?.family_name
            }`}
          </span>
        </div>
        <span>
          <small>{userinfoWrapper(authKit?.getUserinfo())?.email}</small>
        </span>
      </Col>
      <Col>
        <h6 className="text-uppercase">Fitness Package</h6>
        <Form.Group>
          <Form.Control
            as="select"
            value={
              change ||
              enrollmentFrame.pending?.sku ||
              enrollmentFrame.current?.sku ||
              ""
            }
            onChange={handlePlanChange}
            style={{
              appearance: "auto",
              maxWidth: "600px",
            }}
            disabled={
              !enrollmentFrame.allowedActions.find(
                (t) =>
                  t === ENROLLMENT_ACTIONS.CHANGE ||
                  t === ENROLLMENT_ACTIONS.NEW
              )
            }
          >
            {enrollmentFrame.status === "TERMED" ? (
              <option value={"termedDefault"} key={"termedDefault"} disabled>
                Please Select a Package
              </option>
            ) : null}
            <BundlesDropDown
              termed={enrollmentFrame.status === "TERMED"}
              sku={enrollmentFrame.current?.sku}
            />
          </Form.Control>
        </Form.Group>
      </Col>
      {showWarning &&
        enrollmentFrame.current?.sku === "FITNESS-LEGACY" &&
        enrollmentFrame.status !== "TERMED" && (
          <Alert variant="warning">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            {<b> Attention! </b>}
            {
              "You have a special promotional package. If you proceed with this change, the promotional member rate will no longer be available."
            }
          </Alert>
        )}
    </Row>
  );

  const noPaymentMethodBody = (
    <Alert variant="info">
      No payment method found. In order to make changes to your account please
      add one <NavLink to="/account/billing">here</NavLink>.
    </Alert>
  );

  return (
    <>
      <CheckoutModal
        open={open}
        price={`${convertToCurrency(summary?.onetimeTotal ?? 0)}`}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <ProcessingOrderModal
        onClose={() => setOpenProcessingModal(false)}
        progress={progress}
        show={openProcessingModal}
      />
      <Card className="edit-plan">
        <Card.Body>
          <Button
            variant="link"
            className="edit-plan__back"
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> <span>Back</span>
          </Button>
          <Row>
            <Col>
              <Row>
                <Card.Title>Edit Plan</Card.Title>
                <Card.Text>
                  To change plans, select a new package from the options below,
                  verify payment method, and proceed to checkout
                </Card.Text>
                <div className="d-flex flex-column">
                  <div className="mb-4">
                    <h5>Current Members</h5>
                    <Card>
                      <Card.Body>
                        {hasPaymentMethods
                          ? defaultCardBody
                          : noPaymentMethodBody}
                      </Card.Body>
                      {enrollmentFrame.status ===
                        ENROLLMENT_STATUSES.CANCELLING ||
                      enrollmentFrame.status ===
                        ENROLLMENT_STATUSES.TRANSITIONING ? (
                        <Card.Footer className="p-2">
                          <Alert variant="primary" className="m-0">
                            <Row>
                              <Col xs={12} md={8}>
                                <p>
                                  A{" "}
                                  {enrollmentFrame.status ===
                                  ENROLLMENT_STATUSES.TRANSITIONING
                                    ? "change"
                                    : "cancellation"}{" "}
                                  is in progress for this member. In order to
                                  make any further adjustments, the{" "}
                                  {enrollmentFrame.status ===
                                  ENROLLMENT_STATUSES.TRANSITIONING
                                    ? "change"
                                    : "cancellation"}{" "}
                                  must be discarded first.
                                </p>
                              </Col>

                              <Col>
                                <Button
                                  className="edit-plan__discard-button"
                                  onClick={
                                    enrollmentFrame.status ===
                                    ENROLLMENT_STATUSES.TRANSITIONING
                                      ? () => setOpenDiscardModal(true)
                                      : () =>
                                          setOpenDiscardCancellationModal(true)
                                  }
                                >
                                  {discardLoading ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      <span className="sr-only">
                                        Discarding
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <FontAwesomeIcon icon={faUndo} />
                                      <span className="m-2">
                                        Discard{" "}
                                        {enrollmentFrame.status ===
                                        ENROLLMENT_STATUSES.TRANSITIONING
                                          ? "Change"
                                          : "Cancellation"}
                                      </span>
                                    </>
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          </Alert>
                        </Card.Footer>
                      ) : null}
                    </Card>
                  </div>
                  <div>
                    <h5>Current Billing Information</h5>
                    <div className="my-3">
                      {creditBalance !== undefined &&
                      parseInt(creditBalance) > 0 ? (
                        <CreditBalance balance={creditBalance} />
                      ) : null}
                    </div>
                    <PaymentMethods />
                  </div>
                </div>
              </Row>
              <Row></Row>
            </Col>
            <Col xs={12} md="auto" style={{ padding: 0 }}>
              <PlanSummary
                currentPlanName={
                  (enrollmentFrame.current &&
                    enrollmentFrame.current.sku.substr(
                      enrollmentFrame.current.sku.indexOf("-") + 1
                    )) ??
                  null
                }
                currentPlanTotal={convertToCurrency(currentPrice)}
                summary={uiSummary}
                userTermed={enrollmentFrame?.status === "TERMED" ? true : false}
                loadingPending={loadingSummary}
                promoLoading={promoLoading}
                onPromoChange={handlePromo}
                onPromoRemove={handlePromoRemove}
                onPromoSubmit={handlePromoSubmit}
                errorSummary={errorSummary}
                validPromo={validPromo}
                promoOpen={promoOpen}
                promo={promo}
                currentPromo={currentPromo}
                // setPromoState={setPromoState}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <DiscardCancellationModal
        currentPackage={skuMap[enrollmentFrame.current?.sku ?? ""]?.name}
        open={openDiscardCancellationModal}
        takePlaceDate={
          enrollmentFrame?.current?.term ? enrollmentFrame?.current?.term : ""
        }
        onClose={() => setOpenDiscardCancellationModal(false)}
        onDiscard={handleDiscard}
        loading={discardLoading}
      />
      <DiscardModal
        currentPackage={skuMap[enrollmentFrame.current?.sku ?? ""]?.name}
        newPackage={skuMap[enrollmentFrame.pending?.sku ?? ""]?.name ?? ""}
        open={openDiscardModal}
        onClose={handleCloseDiscard}
        onDate={
          enrollmentFrame?.pending?.effective
            ? enrollmentFrame?.pending?.effective
            : ""
        }
        onDiscard={handleDiscard}
        takePlaceDate={enrollmentFrame.pending?.effective ?? ""}
        loading={discardLoading}
      />
      {(change || enrollmentFrame.status === "TERMED") && (
        <FooterCard inline>
          <Container>
            <Row>
              <Col xs={6} md={{ span: 4, offset: 3 }}>
                <Button
                  variant="outline-primary"
                  className="nav-btn-enroll-change font-weight-bold"
                  style={{ whiteSpace: "nowrap", width: "100%" }}
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={6} md={4}>
                <Button
                  variant="primary"
                  className="nav-btn-enroll-change font-weight-bold"
                  style={{ whiteSpace: "nowrap", width: "100%" }}
                  onClick={handleCheckout}
                  disabled={
                    !uiSummary?.pending || loadingSummary || promoLoading
                  }
                >
                  Proceed To Checkout
                </Button>
              </Col>
            </Row>
          </Container>
        </FooterCard>
      )}
    </>
  );
};

export default EditPlan;
