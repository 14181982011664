import React, { useContext, useEffect, useState } from "react";
import ActivityLogTile from "../../components/account/Tiles/ActivityLogTile";
import HomeGymTile from "../../components/account/Tiles/HomeGymTile";
import WhlChoicesTile from "../../components/account/Tiles/WhlChoicesTile";
import DigitalPlanTile from "../../components/account/Tiles/DigitalPlanTile";
import PlanTile from "../../components/account/Tiles/PlanTile";
import NoPlanTile from "../../components/account/Tiles/NoPlanTile";
// import Widget from "../../components/account/Widget/Widget";
import OutStandingBalanceBanner from "../../components/account/OutStandingBalanceBanner";
import { AccountContext } from "../../context/AccountProvider";
import AccountSuspensionBanner from "../../components/account/AccountSuspensionBanner";
import { faDumbbell, faFilm } from "@fortawesome/free-solid-svg-icons";
import WidgetCard from "../../components/account/Widget/WidgetCard";
// import lesMillsTile from "./../../../resources/LesMillsTile.png";
import lesMillsTile from "../../resources/LesMillsTile.png";
import burnAlongTile from "../../resources/BurnAlongTile.png";
import { getBurnalong } from "../../api/portal/Queries";
import { ClientConfigContext } from "../../context/ClientConfigProvider";

const Overview = () => {
  const { enrollmentFrame, entitlements } = useContext(AccountContext);
  const { nextPayment } = useContext(AccountContext);
  const program = enrollmentFrame.current
    ? enrollmentFrame.current.sku.substr(
        enrollmentFrame.current.sku.indexOf("-") + 1
      )
    : "";
  const userStatus = enrollmentFrame?.status;

  const { config } = useContext(ClientConfigContext);
  const [burnalong, setBurnalong] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      const uri = await getBurnalong();
      setBurnalong(uri);
    })();
  }, []);
  return (
    <>
      <div className="widget d-flex flex-wrap flex-row mb-3">
        <AccountSuspensionBanner />
        {nextPayment?.overdue ? (
          <OutStandingBalanceBanner
            amount={nextPayment?.amount}
            due={nextPayment?.due}
          />
        ) : null}
        {entitlements?.includes("lesmills") &&
          config["lesmills.enable"] == "true" && (
            <WidgetCard
              iconName={faDumbbell}
              title="LES MILLS <sup>TM</sup> On-Demand"
              imageUrl={lesMillsTile}
              content="Now get access to LES MILLS On-Demand workouts! Choose from thousands of exclusive workouts led by world-class instructors to motivate and push you each step of the way."
              linkText="Explore Les Mills"
              linkUrl="/account/lesmills"
              // external
            />
          )}
        {entitlements?.includes("burnalong") && (
          <WidgetCard
            iconName={faFilm}
            title="BurnAlong"
            imageUrl={burnAlongTile}
            content="Your Base, Core, Power, or Elite subscription comes with BurnAlong. Access thousands of online classes by clicking below to sign in or register your BurnAlong membership today."
            linkText="Explore Burnalong"
            linkUrl={burnalong}
            external
          />
        )}
        {userStatus === "NONE" || userStatus === "TERMED" ? (
          <NoPlanTile />
        ) : program === "DIGITAL" ? (
          <DigitalPlanTile />
        ) : (
          <PlanTile />
        )}
        {entitlements?.includes("homeGym") && <HomeGymTile />}
        {entitlements?.includes("gym") && <ActivityLogTile />}
        {userStatus === "NONE" || userStatus === "TERMED" ? (
          ""
        ) : (
          <WhlChoicesTile />
        )}
      </div>
    </>
  );
};

export default Overview;
