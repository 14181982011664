import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IProgramAgreementFields } from "../@types/generated/contentful";
import { client } from "../client";
import Section from "../components/terms/Section";
import { ProgramAgreementSection, ProgramAgreementType } from "../types";
import { convertToAgreement, tags } from "../utility/contentful";

const BlueFocusAgreement = () => {
  const [content, setContent] = useState<ProgramAgreementType | null>(null);
  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<IProgramAgreementFields>({
        content_type: "programAgreement",
        "metadata.tags.sys.id[all]": tags["fep"],
        include: 10,
      });
      if (entry.items[0]) {
        setContent(convertToAgreement(entry.items[0]));
      }
    })();
    // eslint-disable-next-line
  }, []);
  if (!content) {
    return null;
  }
  return (
    <Container className="pt-4">
      <p>Program Agreement</p>
      <h1>PROGRAM AGREEMENT</h1>
      <span>Last updated: {content.updated}</span>
      {content.sections.map((section: ProgramAgreementSection) => (
        <Section {...section} key={section.header} agreement />
      ))}
    </Container>
  );
};

export default BlueFocusAgreement;
