import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { momentStartOfDay } from "../../utility/util";

interface DiscardPackageModal {
  currentPackage: string;
  takePlaceDate: string;
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
  loading: boolean;
}
const DiscardCancellationModal = ({
  currentPackage,
  takePlaceDate,
  open,
  onClose,
  onDiscard,
  loading,
}: DiscardPackageModal) => {
  //   const [show, setShow] = useState(open);

  //   const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);
  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          <b>Discard Cancellation?</b>
        </Modal.Title>
        <span style={{ cursor: "pointer" }} onClick={onClose}>
          X
        </span>
      </Modal.Header>
      {loading ? (
        <Modal.Body style={{ minHeight: "160px" }}>
          <div className="d-flex flex-column justify-content-center">
            <div className="center-loading" style={{ marginTop: "0" }}>
              <Spinner animation="border" />
            </div>
            <span className="text-center mt-3">
              Processing... This may take a minute.
            </span>
            <p className="text-center">Please do not refresh the page.</p>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <p>
            {`Our records indicate you cancelled your current package. This change is scheduled to take effect`}
            <b>
              {" on "}
              {momentStartOfDay(takePlaceDate).format("MM/DD/YY")}
            </b>
            .{" "}
            {`Would you like to discard this cancellation? Your package will remain as `}
            <b> {currentPackage}</b>
            {` until further changes are made.`}
          </p>
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button
          style={{ backgroundColor: "white", width: "48%" }}
          onClick={onClose}
        >
          <span style={{ color: "black" }}>Nevermind</span>
        </Button>
        <Button style={{ width: "48%" }} variant="primary" onClick={onDiscard}>
          Yes, Discard Cancellation
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DiscardCancellationModal;
