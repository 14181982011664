import React, { useContext, useState } from "react";
import { useTheme } from "react-jss";
import { LocationsContext } from "../../../../../context/LocationsProvider";
import SetHomeGym from "../../../../account/HomeGym/SetHomeGym";
import SetChangeHomeGym from "../../../../account/HomeGym/SetChangeHomeGym";

import useStyles from "./styles";
import { ENROLLMENT_ACTIONS } from "../../../../../graphql/Mutations";

// TODO: Get distance from the api
const Marker = () => {
  return (
    <svg
      viewBox="0 0 5 24"
      width="24"
      height="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="none" fillRule="evenodd">
          <path
            d="M8 0C3.589 0 0 3.768 0 8.4 0 14.7 8 24 8 24s8-9.3 8-15.6C16 3.768 12.411 0 8 0z"
            fill="black"
          />
          <circle fill="#FFF" cx="8" cy="7.5" r="3" />
        </g>
      </g>
    </svg>
  );
};

const HomeGymIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      margin="-6px 5px 0 0"
      viewBox="0 0 782.208 752"
    >
      <path
        id="Combined_Shape"
        data-name="Combined Shape"
        d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
        fill="#0d2674"
      />
    </svg>
  );
};

const Item = ({ location, style, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() });
  const { setLocationDetail, setLocationHover, account } =
    useContext(LocationsContext);
  const [showModal, setShowModal] = useState(false);
  const [showChangeModal, setChangeShowModal] = useState(false);
  const currentTier = account.enrollmentFrame.current?.sku.substr(
    account.enrollmentFrame.current?.sku.indexOf("-") + 1
  );
  const handleClick = () => {
    setLocationDetail(location);
  };

  const handleMouseEnter = () => {
    setLocationHover(location);
  };

  const handleMouseLeave = () => {
    setLocationHover();
  };

  // Width style hack to show hover border with react-virtualized
  return (
    <>
      <div
        style={{ ...style, width: "calc(100% - 6px)" }}
        className={classes.itemWrapper}
      >
        <button
          type="button"
          className={classes.item}
          onClick={(e) => handleClick(e)}
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          <h4 className={classes.itemName}>{location.name}</h4>
          {location.tiers.indexOf("ELITE") === 0 ? (
            <div className={classes.itemMarkerContainer}>
              <Marker />
              <span className={classes.eliteText}>ELITE</span>
              <div className="position-realtive">
                <div className="locations-setHomeGym">
                  {account.homeGymFrame.status !== "NONE" &&
                    location.id === account.homeGymFrame.current.locationId && (
                      <span>
                        <HomeGymIcon className="locations-setHomeGym__icon" />
                        <div className="locations-setHomeGym__text">
                          HOME GYM
                        </div>
                      </span>
                    )}
                </div>
              </div>
            </div>
          ) : null}
          <div className={classes.itemAddress}>
            <p>
              {location.address}. <br></br>
              {location.city} {location.state}, {location.zip}
            </p>
            <span>{location.distance.toPrecision(2)} miles</span>
          </div>
          {location.tiers.indexOf("ELITE") === 0 &&
            currentTier === "ELITE" &&
            account.homeGymFrame.status !== "NONE" &&
            location.id !== account.homeGymFrame.current.locationId && (
              <div className="mt-2">
                <HomeGymIcon />
                {account.homeGymFrame.allowedActions.find(
                  (t) => t === ENROLLMENT_ACTIONS.CHANGE
                ) ? (
                  <>
                    {location.id !==
                    account.homeGymFrame.pending?.locationId ? (
                      <button
                        onClick={() => setChangeShowModal(true)}
                        className="locations-homeGym"
                      >
                        Set As Home Gym
                      </button>
                    ) : (
                      <button className="locations-homeGym">
                        Future Home Gym
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    onClick={() => setShowModal(true)}
                    className="locations-homeGym"
                  >
                    Set As Home Gym
                  </button>
                )}
              </div>
            )}
          {location.tiers.indexOf("ELITE") === 0 &&
            currentTier === "ELITE" &&
            account.homeGymFrame.status === "NONE" && (
              <div className="mt-2">
                <HomeGymIcon />
                <button
                  onClick={() => setShowModal(true)}
                  className="locations-homeGym"
                >
                  Set As Home Gym
                </button>
              </div>
            )}
        </button>
      </div>
      <SetHomeGym show={showModal} setShowModal={setShowModal} />
      <SetChangeHomeGym
        showChangeModal={showChangeModal}
        setChangeShowModal={setChangeShowModal}
      />
    </>
  );
};

export default Item;
