import { useState } from "react";
import {
  fetchLocationsByAddress,
  fetchLocationsByLatLong,
  GeoCode,
} from "../api/platform/Queries";
import { LocationData } from "../api/platform/Queries";

// TODO: authentication
// TODO: Other query parameters
const useGetLocations = ({
  tier,
  amenities,
  radius,
  womenOnlyFilter,
}: {
  tier: string;
  amenities: string[];
  radius: number;
  womenOnlyFilter: boolean;
}) => {
  const [locations, setLocations] = useState<LocationData[]>([]);
  const [geoCode, setGeoCode] = useState<GeoCode>({
    latitude: 44.96,
    longitude: -103.77,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getQuery = () => {
    let query = "";
    if (amenities.length > 0) {
      query += "&amenities=" + amenities.join(",");
    }
    if (radius !== 0) {
      query += "&radius=" + radius;
    }
    if (tier && tier !== "") {
      query += "&tier=" + tier;
    }
    //add female only
    if (womenOnlyFilter !== false) {
      query += "&womenOnlyFilter=" + womenOnlyFilter;
    }
    return query;
  };
  const getLocations = {
    run: async (lat: number, lng: number) => {
      setLoading(true);
      setError(false);
      try {
        const result = await fetchLocationsByLatLong(lat, lng, getQuery());
        if (result.geocode) {
          setGeoCode(result.geocode);
        }
        if (result.locations) {
          setLocations(result.locations);
        } else {
          setLocations([]);
          setError(true);
        }
        setLoading(false);
        return Promise.resolve();
      } catch (err: unknown) {
        setError(true);
        setLoading(false);
        return Promise.reject();
      }
    },
    runWithAddress: async (address: string) => {
      setLoading(true);
      setError(false);
      try {
        const result = await fetchLocationsByAddress(address, getQuery());
        if (result.geocode) {
          setGeoCode(result.geocode);
        }

        if (result.locations) {
          setLocations(result.locations);
        } else {
          setLocations([]);
          setError(true);
        }
        setLoading(false);
        return Promise.resolve();
      } catch (err: unknown) {
        setError(true);
        setLoading(false);
        return Promise.reject(err);
      }
    },
  };

  return {
    locations,
    setLocations,
    getLocations,
    geoCode,
    loading,
    error,
    amenities,
    radius,
  };
};

export default useGetLocations;
