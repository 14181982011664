import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { LocationsContext } from "../../../../context/LocationsProvider";
import useStyles from "./styles";

const Marker = ({ location, name, $hover, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() });

  const { locationDetail, setLocationDetail, locationHover } =
    useContext(LocationsContext);
  const [isHovering, setIsHovering] = useState(false);

  let active = false;

  // Apply active state if location is selected
  if (Object.keys(locationDetail).length) {
    if (locationDetail.id === location.id) {
      active = true;
    }
  }

  useEffect(() => {
    setIsHovering(false);

    // Apply hover state if user hovers over corresponding card
    if (locationHover) {
      if (locationHover.id === location.id) {
        setIsHovering(true);
      }
    }
  }, [locationHover, location.id]);

  return (
    <button
      type="button"
      className={`${classes.marker} ${active && classes.markerActive} ${
        (isHovering || $hover) && classes.markerHover
      }`}
      onClick={() => setLocationDetail(active ? {} : location)}
    >
      <div
        className={`${classes.markerName} ${
          active && classes.markerNameActive
        }`}
        onClick={() => setLocationDetail({})}
      >
        {name}
        {active ? (
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-19bqh2r"
          >
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        ) : null}
      </div>

      <svg viewBox="0 0 16 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <g
            className={
              !active ? classes.markerGroup : classes.markerGroupActive
            }
            fill="none"
            fillRule="evenodd"
          >
            <path
              d="M8 0C3.589 0 0 3.768 0 8.4 0 14.7 8 24 8 24s8-9.3 8-15.6C16 3.768 12.411 0 8 0z"
              fill="currentColor"
            />
            <circle fill="#FFF" cx="8" cy="7.5" r="3" />
          </g>
        </g>
      </svg>
    </button>
  );
};
export default Marker;
