import React from "react";
import {
  Button,
  Card,
  Collapse,
  Form,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import CollapseHeader from "../../shared/CollapseHeader";
import PromoCodeDisplay from "../../wizard/components/PromoCodeDisplay";
import { SummaryUIMap } from "../../../context/SummaryProvider";
import { convertToCurrency } from "../../../utility/util";
import PromoCollapseHeader from "../../shared/PromoCollapseHeader";

const PlanSummary = ({
  summary,
  currentPlanName,
  currentPlanTotal,
  userTermed = false,
  loadingPending,
  promoLoading,
  errorSummary,
  validPromo,
  promoOpen,
  promo,
  currentPromo,
  onPromoChange,
  onPromoRemove,
  onPromoSubmit,
}: {
  summary: SummaryUIMap | null;
  currentPlanName: string | null;
  currentPlanTotal: string;
  userTermed?: boolean;
  loadingPending: boolean;
  promoLoading: boolean;
  errorSummary: boolean;
  validPromo: string;
  promoOpen: boolean;
  promo: string;
  currentPromo: string;
  onPromoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPromoRemove: (name: string) => void;
  onPromoSubmit: () => void;
}) => {
  const formatContentArray = (items: string[]) => {
    return (
      <div className="d-flex flex-column">
        {items.map((i: string) => (
          <p className="mb-1" key={i}>
            <i>{i}</i>
          </p>
        ))}
      </div>
    );
  };
  const formatContent = (title: string, cost: string) => {
    return (
      <p className="mb-1">
        <i>{title}</i>
        <span style={{ float: "right" }}>{cost}</span>
      </p>
    );
  };
  return (
    <Card bg="light" className="edit-plan__summary">
      <Card.Body>
        <Card.Title>Current Plan</Card.Title>
        {!userTermed && currentPlanName ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <span className="edit-plan__text">
                <i>
                  {currentPlanName === "LEGACY" ? "POWER" : currentPlanName}
                </i>{" "}
                Fitness Package
              </span>
              <span className="edit-plan__text">
                {currentPlanTotal ? currentPlanTotal : "Unavailable"}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span className="edit-plan__text">
                <b>Monthly Total</b>
              </span>
              <span className="edit-plan__text">
                {currentPlanTotal ? currentPlanTotal : "Unavailable"}
              </span>
            </div>
          </>
        ) : (
          <div>
            <h6>
              <i>
                <strong>No current package</strong>
              </i>
            </h6>
          </div>
        )}
      </Card.Body>
      <Collapse in={errorSummary} unmountOnExit mountOnEnter>
        <div className="summary-container__error">
          <h4>
            <b>Sorry, there was an error.</b>
          </h4>
        </div>
      </Collapse>
      <Collapse
        in={loadingPending && !promoLoading && !errorSummary}
        unmountOnExit
        mountOnEnter
      >
        <div className="summary-container__loading">
          <h4>Updating your Order Summary</h4>
          <Spinner animation="border" />
        </div>
      </Collapse>
      {summary?.pending && !errorSummary && (
        <Collapse in={summary.pending && (promoLoading || !loadingPending)}>
          <div>
            <Card>
              <Card.Body>
                <Card.Title>New Plan</Card.Title>
                <CollapseHeader
                  header="Package Selection(s) Total"
                  price={summary.pending?.planTotal}
                >
                  <>
                    {summary.pending.planList.map((p) => (
                      <>
                        {formatContent(p.name, convertToCurrency(p.subtotal))}
                      </>
                    ))}
                  </>
                </CollapseHeader>
                {summary.pending?.showDiscounts && (
                  <CollapseHeader
                    header="Discount(s) Total"
                    price={summary.pending.discountsTotal}
                  >
                    {formatContentArray(summary.pending.discounts)}
                  </CollapseHeader>
                )}
                <p className="mt-3 mb-1">
                  Taxes
                  <span style={{ float: "right" }}>
                    {summary.pending.taxes}
                  </span>
                </p>
                <p className="mb-0">
                  <b>
                    New Monthly Total
                    <span style={{ float: "right" }}>
                      {summary.pending.total}
                    </span>
                  </b>
                </p>
                <span>
                  <small>
                    <i>Starting {summary.pending.startDate}</i>
                  </small>
                </span>
              </Card.Body>
            </Card>
            {summary.due && !errorSummary && (
              <Card>
                <Card.Body>
                  <Card.Title>Due Now</Card.Title>
                  <CollapseHeader
                    header="Package Selection(s) Total"
                    price={summary.due?.planTotal ?? "0.00"}
                  >
                    <>
                      {summary.due.planList.map((p) => (
                        <>
                          {formatContent(p.name, convertToCurrency(p.subtotal))}
                        </>
                      ))}
                    </>
                  </CollapseHeader>
                  <CollapseHeader
                    header="Enrollment Fees"
                    price={summary.due?.enrollmentFee ?? "$0.00"}
                  />
                  <CollapseHeader
                    header="Total Credits"
                    price={summary.due?.credits ?? "0.00"}
                  ></CollapseHeader>
                  <CollapseHeader
                    header="Discount(s) Total"
                    price={summary.due?.discountsTotal ?? "0.00"}
                    initial={true}
                  >
                    {promoLoading ? (
                      <div
                        className="center-loading"
                        style={{ marginTop: "0" }}
                      >
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <>
                        {formatContentArray(summary.due?.discounts)}
                        {summary?.due.promoCodes?.map((code) => {
                          if (code.status === "SUCCESS") {
                            return (
                              <PromoCodeDisplay
                                name={code.code}
                                description={code.description}
                                onRemove={onPromoRemove}
                              />
                            );
                          }
                        })}
                        <PromoCollapseHeader
                          header="Add Promo Code"
                          promoState={promoOpen}
                        >
                          <>
                            <Form.Label className="mt-3 mr-2">
                              ENTER PROMOTIONAL CODE
                            </Form.Label>
                            {summary?.due.promoCodes?.map((code) => {
                              if (
                                code.code === promo &&
                                code.status === "SUCCESS" &&
                                currentPromo !== ""
                              ) {
                                return (
                                  <p className="text-danger">
                                    This promo code has already been applied
                                  </p>
                                );
                              }
                            })}
                            <InputGroup>
                              <FormControl
                                type="text"
                                placeholder="Enter Code Here"
                                onChange={onPromoChange}
                                isInvalid={
                                  validPromo === "FALSE" &&
                                  promo !== "" &&
                                  currentPromo === promo
                                }
                              ></FormControl>
                              <InputGroup.Append>
                                <InputGroup.Append>
                                  <Button
                                    variant="primary"
                                    onClick={onPromoSubmit}
                                    disabled={
                                      validPromo === "FALSE" &&
                                      currentPromo === promo
                                    }
                                  >
                                    Apply
                                  </Button>
                                </InputGroup.Append>
                              </InputGroup.Append>
                              <Form.Control.Feedback type="invalid">
                                Invalid Promo Code
                              </Form.Control.Feedback>
                            </InputGroup>
                          </>
                        </PromoCollapseHeader>
                      </>
                    )}
                  </CollapseHeader>
                  <p className="mt-3 mb-1">
                    Subtotal
                    <span style={{ float: "right" }}>
                      {summary.due?.subtotal}
                    </span>
                  </p>
                  <p className="mb-1">
                    Taxes
                    <span style={{ float: "right" }}>{summary.due?.taxes}</span>
                  </p>
                  <p className="mb-1">
                    <b>
                      Total Due Now
                      <span style={{ float: "right" }}>
                        {summary.due?.total}
                      </span>
                    </b>
                  </p>
                </Card.Body>
              </Card>
            )}
          </div>
        </Collapse>
      )}
    </Card>
  );
};

export default PlanSummary;
