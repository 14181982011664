import React, { useEffect, useContext, useState } from "react";
import { Tile } from "./Tile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { AccountContext } from "../../../context/AccountProvider";
import { getHomeGymDetails } from "../../../api/platform/Queries";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

export type HomeGymDetails = {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  genderSpecific: string;
};

const HomeGymTile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasGym, setHasGym] = useState(false);
  const { homeGymFrame } = useContext(AccountContext);
  const homeGymStatus = homeGymFrame.status;
  const locationId = homeGymFrame.current?.locationId;
  const [homeGymDetails, setHomeGymDetails] = useState<HomeGymDetails | null>(
    null
  );

  useEffect(() => {
    (async () => {
      try {
        if (homeGymStatus !== "NONE" && locationId) {
          setIsLoading(true);
          const location = await getHomeGymDetails(locationId);
          setHasGym(true);
          setHomeGymDetails(location);
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        throw new Error("Unable to fetch home gym");
      }
    })();
  }, []);

  return (
    <>
      <Tile className="widget__card home-gym">
        <Tile.THeader>
          <div>
            <FontAwesomeIcon icon={faHome} /> <b>HOME GYM</b>
          </div>
          {hasGym ? (
            <Link to="/locations" className="a-link">
              <span>Change Home Gym</span>
            </Link>
          ) : (
            ""
          )}
        </Tile.THeader>
        <Tile.TBody className="tile-container__body m-2 align-items-center justify-content-center">
          {isLoading ? (
            <div className="d-flex justify-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="home-gym__content">
              <div className="home-gym__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35%"
                  // maxHeight="50px"
                  viewBox="0 0 782.208 752"
                >
                  <path
                    id="Combined_Shape"
                    data-name="Combined Shape"
                    d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
                    fill="#0d2674"
                  />
                </svg>
              </div>
              {hasGym ? (
                <h5 className="text-center mb-0">{homeGymDetails?.name}</h5>
              ) : (
                <h6 className="text-center mb-0">
                  {hasGym ? homeGymDetails?.name : `No Home Gym Selected`}
                </h6>
              )}
              {hasGym ? (
                <p className="text-center p-0 mb-0">
                  {homeGymDetails?.address} <br />
                  {homeGymDetails?.city}
                  {","} {homeGymDetails?.state} {homeGymDetails?.zip}
                </p>
              ) : (
                <p style={{ padding: "0" }} className="text-center">
                  Once you find and set a home gym location, it will show up
                  here.
                </p>
              )}
            </div>
          )}
        </Tile.TBody>
        {!hasGym && (
          <Tile.TFooter>
            <Button
              as={Link}
              to="/locations"
              variant="outline-primary"
              className="btn btn-outline-primary w-75 btn-sm"
            >
              Find Home Gym
            </Button>
          </Tile.TFooter>
        )}
      </Tile>
    </>
  );
};
export default HomeGymTile;
