import React, { useContext, useEffect, useState } from "react";
import PersonalInformation from "../../components/account/Profile/PersonalInformation";
import ShippingAddress from "../../components/account/Profile/ShippingAddress";
import ChangeEmail from "../../components/account/Profile/ChangeEmail";
import ChangePassword from "../../components/account/Profile/ChangePassword";
import { Card } from "react-bootstrap";
import { Formik } from "formik";
import { ModelValidations } from "../../components/wizard/model/ModelValidations";
import { IAddressFields } from "../../components/wizard/types";
import SmartyStreetsProvider from "../../context/SmartyStreetsProvider";
import { getCurrentAddress } from "../../api/platform/Queries";
import { setAddressQuery } from "../../api/identity/Queries";
import ProfileToast from "../../utility/ProfileToast";
import { ClientConfigContext } from "../../context/ClientConfigProvider";

const ViewProfile = () => {
  const [initialValuesAddress, setInitialValues] = useState<IAddressFields>({
    city: "",
    state: "",
    street1: "",
    street2: "",
    zip: "",
  });
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [id, setId] = useState("");

  const { client } = useContext(ClientConfigContext);
  const [verifiedAddress, setIsVerifiedAddress] = useState(false);

  useEffect(() => {
    //get current address and update initial values
    (async () => {
      const results = await getCurrentAddress();
      if (results.data && results.data.length > 0) {
        const shippingAddr = results.data.find((add) => {
          if (add.defaultFor) {
            return (
              add.defaultFor.findIndex((a) => {
                return a.toLowerCase() === "shipping";
              }) > -1
            );
          }
          return false;
        });
        if (shippingAddr) {
          setId(shippingAddr.id);
          setInitialValues({
            city: shippingAddr.city,
            state: shippingAddr.state,
            street1: shippingAddr.address1,
            street2: shippingAddr.address2 ?? "",
            zip: shippingAddr.zip,
          });
        }
      }
      return;
    })();
  }, []);

  const handle = async (values: IAddressFields) => {
    try {
      await setAddressQuery(
        {
          id: id,
          address1: values.street1,
          address2: values.street2,
          city: values.city,
          state: values.state,
          zip: values.zip,
        },
        ["shipping"]
      );
      setVerifyOpen(false);
      ProfileToast(
        "Your home address has been successfully updated",
        true,
        client
      );
    } catch (e) {
      ProfileToast("Your home address could not be updated.", false, client);
      setVerifyOpen(false);
    }
  };

  const handleSubmit = (values: IAddressFields) => {
    if (verifiedAddress) {
      setVerifyOpen(true);
    } else {
      handle(values);
    }
  };

  return (
    <>
      <div className="vp-title">Profile</div>
      <Card>
        <Card.Body className="vp">
          <PersonalInformation />
          <SmartyStreetsProvider>
            <Formik
              enableReinitialize
              initialValues={initialValuesAddress}
              validationSchema={ModelValidations.AddressFieldsValidation}
              onSubmit={(values) => {
                //TODO - replace with proper handler to submit data and wait for response
                // handle(values);
                handleSubmit(values);
              }}
            >
              <ShippingAddress
                verifyOpen={verifyOpen}
                setVerifyOpen={setVerifyOpen}
                handle={handle}
                setIsVerifiedAddress={setIsVerifiedAddress}
              />
            </Formik>
          </SmartyStreetsProvider>
          <ChangeEmail />
          <ChangePassword />
        </Card.Body>
      </Card>
    </>
  );
};

export default ViewProfile;
