import React, { useState, useEffect } from "react";
import { IPromoBannerFields } from "../../@types/generated/contentful";
import { client } from "../../client";
import { Entry } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useLocation } from "react-router-dom";
const PriceIncreaseBanner = () => {
  const [content, setContent] = useState<Entry<IPromoBannerFields> | null>(
    null
  );
  const [display, setDisplay] = useState(false);

  const location = useLocation();
  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<IPromoBannerFields>({
        content_type: "promoBanner",
        "metadata.tags.sys.id[all]": "pricingIncrease",
        include: 10,
      });
      if (entry.items.length > 0) {
        setContent(entry.items[0]);
      }
    })();
  }, []);
  useEffect(() => {
    if (
      location.pathname !== "/" &&
      location.pathname !== "/how-it-works" &&
      location.pathname !== "/terms" &&
      location.pathname !== "/privacy-policy" &&
      location.pathname !== "/enroll" &&
      location.pathname !== "/enroll/eligibility"
    ) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [location]);

  return (
    <>
      {content && display && (
        <div className="pricingIncreaseBanner">
          <div className="pricingIncreaseBanner__content">
            {documentToReactComponents(content.fields.bannerText!)}
          </div>
        </div>
      )}
    </>
  );
};

export default PriceIncreaseBanner;
