import { useEffect, useState } from "react";
import { fetchAmenities } from "../api/platform/Queries";

const useGetAmenities = () => {
  const [amenities, setAmenities] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchAmenities();
        if (response.amenities) {
          setAmenities(response.amenities);
        }
      } catch (err: unknown) {
        Promise.reject(err);
      }
    })();
  }, []);
  return amenities;
};

export default useGetAmenities;
