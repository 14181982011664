import { Form, Col } from "react-bootstrap";
import { useAuthKit } from "@authkitcom/react";
import React, { useContext, useState } from "react";
import ProfileToast from "../../../utility/ProfileToast";
import { setNewEmail } from "../../../api/platform/Queries";
import { Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import { ClientConfigContext } from "../../../context/ClientConfigProvider";
import { userinfoWrapper } from "../../../utility/util";
import { emailRegex } from "../../../utility/Constants";
interface IEmailChange {
  email: string;
  confirmEmail: string;
}

const ChangeEmail = () => {
  const { authKit } = useAuthKit();
  const userInfo = authKit!.getUserinfo();
  const [initialState] = useState<IEmailChange>({
    email: "",
    confirmEmail: "",
  });
  const [, setFormErrors] = useState({});
  const [newEmail, setNew] = useState("");
  const { client } = useContext(ClientConfigContext);
  const emailValidation = yup
    .object()
    .shape({
      email: yup
        .string()
        .matches(new RegExp(emailRegex), "Please enter a valid email address")
        .required("Required")
        .defined("Please enter a valid email address")
        .test(
          "emails-match",
          "An account already exists with that email. Please enter a different email address",
          function (value) {
            return userinfoWrapper(userInfo)?.email !== value;
          }
        ),
      confirmEmail: yup
        .string()
        .test("emails-match", "Emails must match", function (value) {
          return this.parent.email === value;
        }),
    })
    .defined();

  const handleSubmit = async (values: IEmailChange) => {
    try {
      setFormErrors(emailValidation.values);
      await setNewEmail(values.email);
      sessionStorage.setItem(
        "userinfoModified",
        JSON.stringify({
          email: values.email,
        })
      );
      setNew(values.email);
      ProfileToast(
        `Your email address has been changed to ${values.email}`,
        true,
        client
      );
    } catch (error) {
      if (error instanceof Error) {
        if ((error.message as string).includes("Duplicate Email")) {
          ProfileToast(
            "An account already exists with that email. Please enter a different email address",
            false,
            "none"
          );
        }
      } else
        ProfileToast("Your email address could not be updated.", false, client);
    }
  };

  return (
    <div className="edit-profile">
      <span>Change Email Address</span>
      <p className="mb-0">
        We use this email address to notify you about changes to your account.
      </p>
      <Formik
        validationSchema={emailValidation}
        initialValues={initialState}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {(props) => (
          <FormikForm style={{ marginLeft: "14px" }}>
            <Form.Group as={Col} xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                CURRENT EMAIL
              </Form.Label>
              <Form.Control
                type="text"
                name="currentEmail"
                id="currentEmail"
                disabled={true}
                value={
                  newEmail === "" ? userinfoWrapper(userInfo)?.email : newEmail
                }
                className="edit-profile__input"
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                NEW EMAIL
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                id="email"
                onChange={props.handleChange}
                required
                className="edit-profile__input"
                onBlur={props.handleBlur}
                value={props.values.email}
              />
            </Form.Group>
            {props.touched.email === true && props.errors.email ? (
              <div className="form-requirements invalid extra-margin">
                {props.errors.email}
              </div>
            ) : null}
            <Form.Group as={Col} xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                CONFIRM NEW EMAIL
              </Form.Label>
              <Form.Control
                type="text"
                name="confirmEmail"
                id="confirmEmail"
                onChange={props.handleChange}
                required
                className="edit-profile__input"
                onBlur={props.handleBlur}
                value={props.values.confirmEmail}
              />
            </Form.Group>
            {props.touched.confirmEmail === true &&
            props.errors.confirmEmail ? (
              <div className="form-requirements invalid extra-margin">
                {props.errors.confirmEmail}
              </div>
            ) : null}
            <p style={{ maxWidth: "830px", marginLeft: "-5px" }}>
              By providing your email address and/or any other personal
              information, as defined under applicable law, you acknowledge that
              you are agreeing to our use of your information as provided in our{" "}
              <a
                href="/terms"
                target="_blank"
                rel="noreferrer"
                className="a-link"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="a-link"
              >
                Privacy Policy
              </a>
              .
            </p>
            <button
              disabled={!(props.isValid && props.dirty)}
              type="submit"
              className="nav-btn-enroll font-weight-bold btn btn-primary"
              style={{ marginLeft: "-5px" }}
            >
              Apply Email Change
            </button>
            <div className="vp__personal-info my-4" />
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default ChangeEmail;
