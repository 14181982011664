import { useEffect, useState } from "react";

const useGetEnv = () => {
  const [version, setVersion] = useState<string>();

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch("/boot.json");
        const json = await res.json();
        sessionStorage.setItem("api-url", json.apiUrl);
        setVersion(json.version);
      } catch (err: unknown) {
        Promise.reject(err);
      }
    })();
  }, []);
  return version;
};

export default useGetEnv;
