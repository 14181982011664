/* eslint-disable no-constant-condition */
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { ClientConfigContext } from "../../../context/ClientConfigProvider";
import useIncontact from "../../../hooks/useIncontact";
import useSummary from "../../../hooks/useSummary";
import useZuora from "../../../hooks/useZuoraPayments";
import ProfileToast from "../../../utility/ProfileToast";

const AddPaymentModal = ({
  open,
  onAdd,
  onClose,
}: {
  open: boolean;
  onAdd: () => void;
  onClose: () => void;
}) => {
  const [paymentMethod, setPaymentMethod] = useState("CREDIT");
  const { client } = useContext(ClientConfigContext);

  const { error: summaryError } = useSummary();

  const {
    isImpersonated,
    loading: csrLoading,
    error: csrError,
    canContinue,
    setMaskDisabled,
  } = useIncontact();

  const onSuccess = useCallback(async () => {
    if (isImpersonated) {
      try {
        await setMaskDisabled();
      } catch (e) {
        ProfileToast("Could not disable Incontact mask.", false, client);
      }
    }
    onAdd();
    onClose();
    return;
  }, []);
  const onSubmissionFailed = useCallback(async () => {
    if (isImpersonated) {
      try {
        await setMaskDisabled();
      } catch (e) {
        ProfileToast("Could not disable Incontact mask.", false, client);
      }
    }
    ProfileToast("Payment Method could not be created.", false, client);
    return;
  }, []);
  const onRequestFailed = useCallback(async () => {
    if (isImpersonated) {
      try {
        await setMaskDisabled();
      } catch (e) {
        ProfileToast("Could not disable Incontact mask.", false, client);
      }
    }
    ProfileToast("Payment Method could not be created.", false, client);
  }, []);
  const { render, handleSubmit, hasRendered } = useZuora({
    paymentType: paymentMethod,
    onSuccess,
    onSubmissionFailed,
    onRequestFailed,
  });

  useEffect(() => {
    render();
  }, [render, paymentMethod]);

  const handlePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(e.target.value);
  };

  const handleClose = async () => {
    if (isImpersonated) await setMaskDisabled();
    onClose();
  };

  const CSRState = () => {
    if (csrLoading) {
      <div className="center-loading">
        <Spinner animation="border" />
      </div>;
    }
    if (csrError) {
      return (
        <div>
          <h5 style={{ color: "red" }}>
            Unable to load page due to call recording error
          </h5>
        </div>
      );
    }
    if (!canContinue) {
      return (
        <div>
          <h5>
            Incontact requires a valid sessionId for masking billing data.
          </h5>
        </div>
      );
    }
    return null;
  };

  return open ? (
    <Modal show={open} onHide={handleClose} centered>
      <div className="setHomeGymModal">
        <Modal.Title
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h2>Add Payment Method</h2>
          <span style={{ cursor: "pointer" }} onClick={handleClose}>
            X
          </span>
        </Modal.Title>
        <Modal.Body>
          {csrLoading || csrError || !canContinue ? (
            <Fragment>{CSRState()}</Fragment>
          ) : (
            <>
              <Form.Group className="mb-1">
                <Form.Label>PAYMENT METHOD</Form.Label>
                <Form.Control as="select" onChange={handlePaymentMethod}>
                  <option value="CREDIT">Credit Card</option>
                  <option value="ECHECK">eCheck</option>
                </Form.Control>
              </Form.Group>
              {!hasRendered && !summaryError && (
                <div className="zuora-loading">
                  <Spinner animation="border" />
                </div>
              )}
              {summaryError && (
                <div className="zuora-center-error">
                  <h2>Sorry, there was an error loading this payment page.</h2>
                </div>
              )}
              {!summaryError && <div id="zuora_payment"></div>}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
            <button
              onClick={handleClose}
              className="col btn btn-outline-primary ml-2"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="col mx-2 btn btn-secondary "
            >
              Add Payment Method
            </button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  ) : null;
};

export default AddPaymentModal;
