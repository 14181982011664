import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Fade,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import useSummary from "../../../hooks/useSummary";
import CollapseHeader from "../../shared/CollapseHeader";
import PromoCollapseHeader from "../../shared/PromoCollapseHeader";
import { convertToCurrency } from "../../../utility/util";
import PromoCodeDisplay from "./PromoCodeDisplay";

const Summary = () => {
  const [promo, setPromo] = useState("");
  const { summary, uiSummary, loading, error, setPromoCode, promoLoading } =
    useSummary();
  const [show, setShow] = useState(!loading);
  const [promoOpen, setPromoOpen] = useState(true);
  const [validPromo, setValidPromo] = useState("");
  const [currentPromo, setCurrentPromo] = useState("");
  const [applyingPromo, setApplyingPromo] = useState(true);

  useEffect(() => {
    if (loading || error || promoLoading) {
      setShow(false);
    }
  }, [loading, error, promoLoading]);

  useEffect(() => {
    isValidPromo(promo);
  }, [show]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromo(e.target.value);
  };

  const handleSubmitPromo = () => {
    setApplyingPromo(true);
    if (summary?.promoCodes.length === 0) {
      setPromoCode(promo);
    } else {
      const str = summary?.promoCodes
        .map(function (elem) {
          if (elem.code !== promo && elem.status !== "FAILURE")
            return elem.code;
        })
        .join(",");
      //Regex to remove commas at the beginning, end, and next to each other
      const removed = str?.replace(/^(, *)+|(, *)+$|,+(?=,)/g, "");
      setPromoCode(promo + "," + removed);
    }
  };

  const handleRemovePromo = (deletedPromo: string) => {
    setApplyingPromo(false);
    if (summary?.promoCodes.length === 1) {
      setPromoCode("");
    } else {
      const str = summary?.promoCodes
        .map(function (elem) {
          if (elem.code !== deletedPromo) return elem.code;
        })
        .join(",");
      //Regex to remove commas at the beginning, end, and next to each other
      const removed = str?.replace(/^(, *)+|(, *)+$|,+(?=,)/g, "");
      setPromoCode(String(removed));
    }
  };

  const isValidPromo = (promo: string) => {
    setCurrentPromo(promo);
    summary?.promoCodes.forEach((code) => {
      if (code.code === promo && code.status === "SUCCESS") {
        setValidPromo("TRUE");
        setPromoOpen((prevPromo) => !prevPromo);
        setPromo("");
      } else if (code.code === promo && code.status === "FAILURE") {
        setValidPromo("FALSE");
      }
    });
  };

  const formatContent = (title: string, cost: string) => {
    return (
      <p className="mb-1">
        <i>{title}</i>
        <span style={{ float: "right" }}>{cost}</span>
      </p>
    );
  };

  const formatContentArray = (items: string[]) => {
    return (
      <div className="d-flex">
        {items.map((i: string) => (
          <p className="mb-1" key={i}>
            <i>{i}</i>
          </p>
        ))}
      </div>
    );
  };

  return (
    <Card className="mt-3 summary-container">
      <Card.Body className="summary-container__body">
        <Fade in={error} unmountOnExit mountOnEnter>
          <div className="summary-container__error">
            <h4>
              <b>Sorry, there was an error.</b>
            </h4>
          </div>
        </Fade>

        <Fade
          in={loading && !error && !promoLoading}
          unmountOnExit
          onExited={() => setShow(true)}
          appear
        >
          <div className="summary-container__loading">
            <h4>Getting your Order Summary</h4>
            <Spinner animation="border" />
          </div>
        </Fade>

        <Fade
          in={promoLoading && !error}
          unmountOnExit
          onExited={() => setShow(true)}
          appear
        >
          <div className="summary-container__loading">
            <h4>
              {applyingPromo ? "Applying Promo Code" : "Removing Promo Code"}
            </h4>
            <Spinner animation="border" />
          </div>
        </Fade>

        <Collapse in={show && !error} mountOnEnter>
          <div>
            <Card.Title>
              <h6 className="summary-container_heading">Summary</h6>
            </Card.Title>
            <CollapseHeader
              header="Package Selection(s) Total"
              price={uiSummary?.checkout?.planTotal ?? "0.00"}
            >
              <>
                {uiSummary?.checkout?.planList.map((p) => (
                  <>{formatContent(p.name, convertToCurrency(p.subtotal))}</>
                ))}
              </>
            </CollapseHeader>
            {/* {uiSummary?.checkout?.showDiscounts && ( */}
            <CollapseHeader
              header="Discount(s) Total"
              price={`${
                uiSummary?.checkout?.discountsTotalMinusDigital ?? "$0.00"
              }`}
              initial={true}
            >
              <>
                {summary?.promoCodes?.map((code) => {
                  if (code.status === "SUCCESS") {
                    return (
                      <PromoCodeDisplay
                        name={code.code}
                        description={code.description}
                        onRemove={handleRemovePromo}
                      />
                    );
                  }
                })}
                <PromoCollapseHeader
                  header="Add Promo Code"
                  promoState={promoOpen}
                >
                  <>
                    <Form.Label className="mt-3 promo-code-label">
                      ENTER PROMOTIONAL CODE
                    </Form.Label>
                    {summary?.promoCodes?.map((code) => {
                      if (code.code === promo && code.status === "SUCCESS") {
                        return (
                          <p className="text-danger">
                            This promo code has already been applied
                          </p>
                        );
                      }
                    })}
                    <div className="promo-input-container">
                      <InputGroup>
                        <FormControl
                          type="text"
                          onChange={handleChange}
                          placeholder="Enter Code Here"
                          value={promo}
                          isInvalid={
                            validPromo === "FALSE" &&
                            promo !== "" &&
                            currentPromo === promo
                          }
                        ></FormControl>
                        <InputGroup.Append>
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={handleSubmitPromo}
                              disabled={
                                validPromo === "FALSE" && currentPromo === promo
                              }
                            >
                              Apply
                            </Button>
                          </InputGroup.Append>
                        </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">
                          Invalid Promo Code
                        </Form.Control.Feedback>
                      </InputGroup>
                    </div>
                  </>
                </PromoCollapseHeader>
                {formatContentArray(uiSummary?.checkout?.discounts ?? [])}
              </>
            </CollapseHeader>
            {/* )} */}
            <Row className="mt-2">
              <Col className="ps-0 col-9">
                <span className="summary-container_enrollment-fee">
                  <b>Enrollment Fees</b>
                </span>
              </Col>
              <Col className="pe-0 col-3 price-amount">
                <div className="summary-container_enrollment-price">
                  {uiSummary?.checkout?.enrollmentFee}
                </div>
              </Col>
            </Row>
            <Card.Body className="enrollment-container">
              <Row>
                <Col className="ps-0 col-9">
                  <span className="summary-container_enrollment-fee">
                    Subtotal
                  </span>
                </Col>
                <Col className="pe-0 col-3 price-amount">
                  <div className="summary-container_enrollment-price">
                    {uiSummary?.checkout?.subtotal}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="ps-0 col-9">
                  <span className="summary-container_enrollment-fee">
                    Taxes
                  </span>
                </Col>
                <Col className="pe-0 col-3 price-amount">
                  <div className="summary-container_enrollment-price">
                    {uiSummary?.checkout?.taxes}
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Body className="enrollment-container">
              <Row className="summary-container__total-due-now">
                <Col className="ps-0 col-9">
                  <div>Total due now</div>
                </Col>
                <Col className="pe-0 col-3 price-amount">
                  <div className="summary-container_enrollment-price">
                    {uiSummary?.checkout?.total}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="monthly-total-row">
                <Col className="ps-0 col-9">
                  <div className="summary-container_enrollment-fee">
                    Monthly Total
                  </div>
                  <div>
                    <span className="payment-start-date">
                      <small>Starting {uiSummary?.checkout?.startDate}</small>
                    </span>
                  </div>
                </Col>
                <Col className="pe-0 col-3 price-amount">
                  <div className="summary-container_enrollment-price">
                    {uiSummary?.checkout?.monthlyTotal}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </div>
        </Collapse>
      </Card.Body>
    </Card>
  );
};
export default Summary;
