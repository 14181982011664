import * as yup from "yup";
import * as valid from "card-validator";
import { emailRegex } from "../../../utility/Constants";
import {
  IAddressFields,
  IAddressForm,
  ICheckoutForm,
  IEligibilityFormProfileBCBSA,
  IEligibilityFormProfileBSC,
  IMembershipForm,
  IPackageSelectForm,
} from "../types";

export namespace ModelValidations {
  export const BscValidation: yup.SchemaOf<IEligibilityFormProfileBSC> = yup
    .object()
    .shape({
      firstName: yup.string().defined("First name must be defined."),
      lastName: yup.string().defined("Last name must be defined."),
      middleName: yup.string().notRequired(),
      year: yup.string().required().defined(),
      month: yup.string().required().defined(),
      day: yup.string().required().defined(),
      email: yup
        .string()
        .matches(new RegExp(emailRegex), "Please enter a valid email address")
        .defined("Email must be defined."),
      gender: yup.string().defined("Gender must be defined"),
      zip: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits")
        .defined("Zip code must be defined."),
      groupNumber: yup.string().defined("Group number must be defined."),
      subscriberId: yup.string().defined("Subscriber ID must be defined."),
    })
    .defined();

  export const FEPValidation: yup.SchemaOf<IEligibilityFormProfileBSC> = yup
    .object()
    .shape({
      // firstName: yup.string().defined("First name must be defined."),
      // lastName: yup.string().defined("Last name must be defined."),
      // middleName: yup.string().notRequired(),
      // dob: yup
      //   .string()
      //   .test("test-date", "Date of Birth is invalid", (value: unknown) => {
      //     return isDate(parse(value, "MM/dd/yyyy", new Date()));
      //   })
      //   .defined("Date of birth must be defined."),
      // email: yup
      //   .string()
      //   .email("Must be a valid email.")
      //   .defined("Email must be defined."),
      // gender: yup.string().defined("Gender must be defined"),
      groupNumber: yup.string().defined("Group number must be defined."),
      // subscriberId: yup
      //   .string()
      //   .matches(
      //     /^[rR][a-zA-Z\d]*\d{2}$/,
      //     'Please enter an R number in the correct format. Begins with "R" and is at least 3 characters long.'
      //   )
      //   .defined("Subscriber ID must be defined."),
    })
    .defined();

  export const BcbsaValidation: yup.SchemaOf<IEligibilityFormProfileBCBSA> = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .matches(
          /^([A-Za-z]|-|\.|'|\s)+$/,
          "Name can only contain letters, hyphens, apostrophes or periods"
        )
        .defined("First name must be defined."),
      lastName: yup
        .string()
        .matches(
          /^([A-Za-z]|-|\.|'|\s)+$/,
          "Name can only contain letters, hyphens, apostrophes or periods"
        )
        .defined("Last name must be defined."),
      year: yup.string().required().defined(),
      month: yup.string().required().defined(),
      day: yup.string().required().defined(),
      email: yup
        .string()
        .matches(new RegExp(emailRegex), "Please enter a valid email address")
        .defined("Email must be defined."),
      gender: yup.string().defined("Gender must be defined."),
      zip: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits")
        .defined("Zip code must be defined."),
      primarySubscriber: yup
        .bool()
        .defined("Primary subscriber must be defined."),
      coverageType: yup.string().defined("Coverage type must be defined."),
      hasBlue365: yup.bool().defined("Blue 365 must be defined."),
      groupNumber: yup.string(),
      subscriberId: yup
        .string()
        .matches(
          /^[A-Za-z0-9]+$/,
          "Please enter a subscriber ID using only letters and numbers (no spaces or special characters)"
        )
        .defined("Subscriber ID must be defined."),
    })
    .defined();

  export const PackageValidation: yup.SchemaOf<IPackageSelectForm> = yup
    .object()
    .shape({
      sku: yup.string().min(1).defined("Name must be defined."),
    })
    .defined();

  export const MembershipValidation: yup.SchemaOf<IMembershipForm> = yup
    .object()
    .shape({
      email: yup
        .string()
        .matches(new RegExp(emailRegex), "Please enter a valid email address")
        .max(255)
        .defined("Email must be defined."),
      password: yup
        .string()
        .required()
        .min(8)
        .matches(RegExp("(.*[a-z].*)"), "Lowercase")
        .matches(RegExp("(.*[A-Z].*)"), "Uppercase")
        .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), "Special")
        .defined("Password must be defined."),
    })
    .defined();

  export const AddressFieldsValidation: yup.SchemaOf<IAddressFields> = yup
    .object()
    .shape({
      city: yup.string().defined(),
      state: yup.string().defined(),
      street1: yup.string().defined(),
      street2: yup.string(),
      zip: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits")
        .defined(),
    })
    .defined();

  export const AddressFieldsValidationNotRequired: yup.SchemaOf<IAddressFields> =
    yup
      .object()
      .shape({
        city: yup.string(),
        state: yup.string(),
        street1: yup.string(),
        street2: yup.string(),
        zip: yup.string(),
      })
      .defined();

  export const AddressValidation: yup.SchemaOf<IAddressForm> = yup
    .object()
    .shape({
      home: AddressFieldsValidation.required(),
      billing: yup.object().when("billingRequired", {
        is: true,
        then: AddressFieldsValidation as yup.SchemaOf<IAddressFields>,
        otherwise:
          AddressFieldsValidationNotRequired as yup.SchemaOf<IAddressFields>,
      }) as yup.SchemaOf<IAddressFields>,
      billingRequired: yup.boolean().required(),
      completed: yup.boolean().defined(),
    })
    .defined();

  export const CheckoutValidation: yup.SchemaOf<ICheckoutForm> = yup
    .object()
    .shape({
      number: yup
        .string()
        .test(
          "test-card-number",
          "Credit card number is invalid.",
          (value: unknown) => valid.number(value).isValid
        )
        .defined(),
      expiration: yup
        .string()
        .test(
          "test-card-number",
          "Expiration is not valid.",
          (value: unknown) => valid.expirationDate(value).isValid
        )
        .defined(),
      cvv: yup
        .string()
        .test(
          "test-card-number",
          "CVV is not valid.",
          (value: unknown) => valid.cvv(value).isValid
        )
        .defined(),
      name: yup.string().defined(),
      paymentMethod: yup.string().defined(),
    })
    .defined();
}
