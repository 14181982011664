import React, { useEffect, useState } from "react";
import { Button, Col, ListGroup, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getHomeGymDetails } from "../../../api/platform/Queries";
import PlanListItem from "../PlanListItem";
import { HomeGymDetails } from "../Tiles/HomeGymTile";
import ArrowRotateLeftSolid from "../../../resources/ArrowRotateLeftSolid.svg";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountProvider";
import NewGym from "./NewGym";
import { momentStartOfDay } from "../../../utility/util";

const NewGymEffective = ({
  effectiveDate,
  loading,
}: {
  effectiveDate: string;
  loading: boolean;
}) => {
  const monthAndDay = momentStartOfDay(effectiveDate).format("MMMM D");
  const year = momentStartOfDay(effectiveDate).format("YYYY");

  return (
    <div className="manage-plans__effective-date">
      <PlanListItem
        header={"Effective Date"}
        body={monthAndDay}
        footer={year}
        loading={loading}
      />
    </div>
  );
};

const DiscardHomeGymButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="manage-plans__discard" onClick={onClick}>
      <img
        alt="back"
        className="manage-plans__discard__back-arrow"
        src={ArrowRotateLeftSolid}
      />
      <p>
        <b>Discard Change</b>
      </p>
    </div>
  );
};

const HomeGymRow = ({
  onHomeGymShow,
  loading,
}: {
  onHomeGymShow: () => void;
  loading: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { homeGymFrame } = useContext(AccountContext);
  const homeGymStatus = homeGymFrame.status;
  const locationId = homeGymFrame.current?.locationId;
  const [homeGymDetails, setHomeGymDetails] = useState<HomeGymDetails | null>(
    null
  );

  useEffect(() => {
    (async () => {
      try {
        if (homeGymStatus !== "NONE" && locationId) {
          setIsLoading(true);
          const location = await getHomeGymDetails(locationId);
          setHomeGymDetails(location);
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        throw new Error("Unable to fetch home gym");
      }
    })();
  }, []);

  const formattedCity = homeGymDetails?.city.concat(", ") || "";
  const formattedState = homeGymDetails?.state.concat(" ") || "";
  const formattedZip = homeGymDetails?.zip || "";
  const fullAdress = formattedCity?.concat(formattedState, formattedZip);

  if (homeGymStatus === "TRANSITIONING") {
    return (
      <ListGroup.Item>
        <div className="manage-plans__grid">
          <div className="manage-plans__header">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35%"
              viewBox="0 0 782.208 752"
            >
              <path
                id="Combined_Shape"
                className="manage-plans__svg"
                data-name="Combined Shape"
                d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
              />
            </svg>
            <span className="icon-text">Home Gym</span>
          </div>
          <div className="manage-plans__information">
            {isLoading ? (
              <div className="d-flex justify-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <PlanListItem
                header="Current"
                body={homeGymDetails?.name || ""}
                footer={homeGymDetails?.address.concat(".") || ""}
                subFooter={fullAdress}
                italicize
                loading={loading}
              />
            )}
          </div>
          <NewGym
            homeGymStatus={homeGymFrame.status}
            locationId={homeGymFrame.pending?.locationId ?? ""}
            loading={loading}
          />
          <NewGymEffective
            effectiveDate={homeGymFrame.pending?.effective ?? ""}
            loading={loading}
          />
          <DiscardHomeGymButton onClick={onHomeGymShow} />
        </div>
      </ListGroup.Item>
    );
  }
  if (homeGymStatus === "ACTIVE") {
    return (
      <ListGroup.Item>
        <div className="manage-plans__grid_digital">
          <div className="manage-plans__header">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35%"
              viewBox="0 0 782.208 752"
            >
              <path
                id="Combined_Shape"
                className="manage-plans__svg"
                data-name="Combined Shape"
                d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
              />
            </svg>
            <span className="icon-text">Home Gym</span>
          </div>
          <div className="manage-plans__information">
            {isLoading ? (
              <div className="d-flex justify-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <span>
                <Row style={{ justifyContent: "space-between", width: "100%" }}>
                  <Col style={{ margin: 0, padding: 0, flex: 1 }}>
                    <PlanListItem
                      header="Current"
                      body={homeGymDetails?.name || ""}
                      footer={homeGymDetails?.address.concat(".") || ""}
                      subFooter={fullAdress}
                      italicize
                      loading={loading}
                    />
                  </Col>
                  <Col style={{ flex: 0 }}>
                    <Button
                      as={Link}
                      variant="outline-primary"
                      className="btn w-15 mt-3 ml-2 btn-outline-primary btn-sm p-2"
                      style={{
                        float: "right",
                        whiteSpace: "nowrap",
                      }}
                      to={`/locations`}
                    >
                      Change
                    </Button>
                  </Col>
                </Row>
              </span>
            )}
          </div>
        </div>
      </ListGroup.Item>
    );
  }
  return null;
};

export default HomeGymRow;
