import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getHomeGymDetails } from "../../../api/platform/Queries";
import PlanListItem from "../PlanListItem";
import { HomeGymDetails } from "../Tiles/HomeGymTile";

const NewGym = ({
  homeGymStatus,
  locationId,
  loading,
}: {
  homeGymStatus: string;
  locationId: string;
  loading: boolean;
}) => {
  const [newHomeGymDetails, setNewHomeGymDetails] =
    useState<HomeGymDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      if (homeGymStatus === "TRANSITIONING") {
        try {
          setIsLoading(true);
          const newLocation = await getHomeGymDetails(locationId);
          setNewHomeGymDetails(newLocation);
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          throw new Error("Unable to fetch new home gym");
        }
      }
    })();
  }, []);

  const formattedCity = newHomeGymDetails?.city.concat(", ") || "";
  const formattedState = newHomeGymDetails?.state.concat(" ") || "";
  const formattedZip = newHomeGymDetails?.zip || "";
  const fullAddress = formattedCity?.concat(formattedState, formattedZip);

  return (
    <div className="manage-plans__new-package">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FontAwesomeIcon
          className="manage-plans__new-package__arrow"
          icon={faArrowRight}
        />
        {isLoading ? (
          <div className="d-flex justify-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <PlanListItem
            header="New"
            body={newHomeGymDetails?.name || ""}
            footer={newHomeGymDetails?.address.concat(".") || ""}
            subFooter={fullAddress}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
  return null;
};

export default NewGym;
