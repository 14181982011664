import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useContext } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ClientConfigContext } from "../../../context/ClientConfigProvider";

const AlreadyEligibleToast = () => {
  const { config } = useContext(ClientConfigContext);
  return (
    <Card className="eligibility-toast__card">
      <Card.Body>
        <Row>
          <Col xs={12}>
            <p className="eligibility-toast__title">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span>
                <strong>Attention</strong>
              </span>
            </p>
          </Col>
          <Col xs={12} className="eligibility-toast__body">
            <p>
              Based on the info you have provided, it appears that you already
              have an active Fitness Your Way account. Please{" "}
              <a href="/account" className="a-link">log in</a> or reset your password if you do not
              remember your credentials.
            </p>
            <p>
              If you are having trouble accessing this account, please call
              customer service at{" "}
              <a
                href={
                  config["commerce.clientCode"] === "BSCA"
                    ? `tel:${config["contact.phoneNumber"]}`
                    : `tel:833-277-6863`
                }
              >
                {config["commerce.clientCode"] === "BSCA"
                  ? `${config["contact.phoneNumber"]}`
                  : "833-277-6863"}
              </a>
              .
            </p>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-end">
              <Button
                as={Link}
                style={{ color: "white" }}
                variant="primary"
                to="/account"
              >
                Log In To My Account
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AlreadyEligibleToast;
