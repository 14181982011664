import React, { useContext } from "react";
import { Tile } from "./Tile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import digitalPlan from "./assets/digitalPlan.svg";
import lesMillsLogo from "../../../resources/LesMillsLogo.png";
import burnalong from "../../../resources/NewBurnalongLogo.svg";
import { Link } from "react-router-dom";
import { ClientConfigContext } from "../../../context/ClientConfigProvider";

const DigitalPlanTile = () => {
  const { config } = useContext(ClientConfigContext);

  return (
    <Tile className="widget__card">
      <Tile.THeader>
        <div>
          <FontAwesomeIcon icon={faClipboardCheck} /> <b>MY PLAN</b>
        </div>
        <Link to="/account/manage" className="a-link">
          <span>Manage Plan</span>
        </Link>
      </Tile.THeader>
      <Tile.TBody>
        <div className="flex row">
          <div className="d-flex flex-column hide-card-img__left">
            <h2>Digital Only Package</h2>
            <ul className="mb-0 package-list-horizontal">
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
            <hr />
            <p className="mb-1 p-0">Our Premium Partner:</p>
            {config["lesmills.enable"] == "true" && (
              <img
                alt="logo-alt"
                className="tile-container__card-digital les-mills mt-3"
                src={lesMillsLogo}
                style={{ maxWidth: "132px" }}
              />
            )}
            <img
              alt="logo-alt"
              className="tile-container__card-digital burnalong mt-2"
              src={burnalong}
              style={{ maxWidth: "169px" }}
            />
          </div>
          {config["lesmills.enable"] == "true" && (
            <div className="d-flex flex-column justify-content-end hide-card-img__right">
              <img
                alt="digital"
                className="tile-container__card-digital yoga hide-card-img"
                src={digitalPlan}
              />
            </div>
          )}
          <img
            alt="digital"
            className="tile-container__card-digital yoga hide-card-img__small"
            src={digitalPlan}
          />
        </div>
      </Tile.TBody>
    </Tile>
  );
};
export default DigitalPlanTile;
