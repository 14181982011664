import React, { Fragment, useState, useEffect } from "react";
import { Container, Nav } from "react-bootstrap";
import { Switch } from "react-router";
import { Link, Route, useHistory } from "react-router-dom";
import HowItWorks from "../pages/HowItWorks";
import Landing from "../pages/Landing";
import Flyin from "../components/Flyin";
import NavBar from "../components/navigation/NavBar";
import NavMenu from "../components/navigation/NavMenu";
import { tags } from "../utility/contentful";
import { useContext } from "react";
import { ButtonContext } from "../context/ButtonProvider";
import NavBranding from "../components/navigation/NavBranding";
import FAQ from "../pages/FAQ";
import StandaloneRoutes from "../components/shared/StandaloneRoutes";
import PoweredBy from "../components/shared/PoweredBy";
import ProgramAgreement from "../pages/ProgramAgreement";
import PromotionalBanner from "../components/wizard/components/PromotionalBanner";

/**
 *
 * Routing page for Bsc.
 */
const BscRouting = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { setButton } = useContext(ButtonContext);
  const handleOpen = () => {
    setOpen((open) => !open);
  };

  useEffect(() => {
    setButton({
      text: "Check My Eligibility",
      onClick: () => history.push("/enroll"),
    });
    // eslint-disable-next-line
  }, []);

  const handleLogin = () => {
    history.push("/account");
  };

  return (
    <Fragment>
      <PromotionalBanner tags={tags["bsca"]} />
      <StandaloneRoutes>
        <NavBar logo>
          <NavBranding provider={tags["bsca"]} alt="BSC" />
        </NavBar>
      </StandaloneRoutes>
      <Route exact path={["/", "/how-it-works", "/faqs"]}>
        <Flyin
          open={open}
          handler={() => setOpen(false)}
          viewOnly
          clientCode="bsca"
        />
        <div className="d-none d-sm-none d-md-block sticky-top">
          <NavBar logo>
            <NavBranding provider={tags["bsca"]} alt="BSC" />
          </NavBar>
        </div>
        <NavBar pbLogo second>
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Item>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/how-it-works">
                How It Works
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <NavMenu
            onEligibility={() => history.push("/enroll")}
            onLogin={handleLogin}
          />
        </NavBar>
      </Route>
      <Switch>
        <Route exact path={`/`}>
          <Landing tags={tags["bsca"]} onOpen={handleOpen} />
        </Route>
        <Route exact path={`/how-it-works`}>
          <HowItWorks tags={tags["bsca"]} />
        </Route>
        <Route exact path={`/faqs`}>
          <Fragment>
            <FAQ tags={tags["bsca"]} />
            <section id="legal">
              <div className="legal-footer">
                <PoweredBy />
              </div>
            </section>
          </Fragment>
        </Route>
        <Route exact path="/agreement">
          <Container>
            <div style={{ padding: "24px" }}></div>
            <ProgramAgreement tags={tags["bsca"]} />
          </Container>
        </Route>
      </Switch>
    </Fragment>
  );
};

export default BscRouting;
