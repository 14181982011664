import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

const CarouselCardVideo = ({
  fileId,
  thumbnail,
  title,
  duration,
  selectedVideo,
}: {
  fileId: string;
  thumbnail: string;
  title: string;
  duration: string;
  selectedVideo: string;
}) => {
  const history = useHistory();
  const addDefaultSrc = (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
    ev.currentTarget.src = "https://via.placeholder.com/270x151/aaaaaa";
  };

  const handleClick = (fileId: string) => {
    history.push(`/account/lesmills/${fileId}`);
  };

  return (
    <Fragment>
      <div>
        <div className="col p-0 slides mt-4">
          <a
            className="its-a-link"
            onClick={() => {
              handleClick(fileId);
            }}
          >
            <div
              className={classNames("video-card", {
                "sv-border": selectedVideo == fileId,
              })}
            >
              <img
                src={thumbnail}
                alt={title + "_thumbnail"}
                className="img-fluid video-card-img"
                onError={addDefaultSrc}
              />
              <div className="video-category">
                <div className="video-category-section">
                  {moment
                    .utc(
                      moment.duration(duration, "seconds").as("milliseconds")
                    )
                    .format("mm:ss")}
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <h5 className="pt-3 px-0 title-resp">
                    <b>{title}</b>
                  </h5>
                </div>
                <div className="pb-3">
                  <b>
                    <span className="lm-vdlink">
                      View Details{" "}
                      <FontAwesomeIcon icon={faChevronCircleRight} />{" "}
                    </span>
                  </b>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default CarouselCardVideo;
