//import React, { useContext } from "react";
import React, { useContext } from "react";
import { useState } from "react";
import * as SmartyStreetsSDK from "smartystreets-javascript-sdk";
import { ClientConfigContext } from "./ClientConfigProvider";
const SmartyStreetsCore = SmartyStreetsSDK.core;
const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup;

interface ISmartyStreetsContext {
  lookup: (address: string) => void;
  results: SmartyStreetsSDK.usAutocompletePro.Suggestion[];
  error: unknown | null;
  loading: boolean;
}

export const SmartyStreetsContext = React.createContext<ISmartyStreetsContext>({
  lookup: () => {
    return;
  },
  results: [] as SmartyStreetsSDK.usAutocompletePro.Suggestion[],
  error: null,
  loading: false,
});

const SmartyStreetsProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const [results, setResults] = useState<
    SmartyStreetsSDK.usAutocompletePro.Suggestion[]
  >([]);

  const { config } = useContext(ClientConfigContext);

  const websiteKey = config["smartystreets.key"];
  const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
  const client = SmartyStreetsCore.buildClient.usAutocompletePro(credentials);

  const [error, setError] = useState<unknown | null>(null);
  const [loading, setLoading] = useState(false);
  // const testLookup = async (address: string) => {
  /* const testLookup = async () => {
    const sug: SmartyStreetsSDK.usAutocompletePro.Suggestion[] = [
      {
        city: "Seattle",
        entries: 1,
        secondary: "apt",
        state: "Wa",
        streetLine: "1004 s seattle way",
        zipcode: "98005",
      },
      {
        city: "Everett",
        entries: 1,
        secondary: "",
        state: "Wa",
        streetLine: "500 e tee st",
        zipcode: "97065",
      },
    ];
    setResults(sug);
    setLoading(false);
    Promise.resolve();
  };*/
  const lookup = async (address: string) => {
    const lu = new Lookup(address);
    setLoading(true);
    try {
      const res = await client.send(lu);
      if (res.result) {
        setResults(res.result);
        setLoading(false);
      }
    } catch (e: unknown) {
      setError(e);
      setLoading(false);
    }
  };

  return (
    <SmartyStreetsContext.Provider value={{ lookup, results, error, loading }}>
      {children}
    </SmartyStreetsContext.Provider>
  );
};

export default SmartyStreetsProvider;
