import React from "react";
import { useContext } from "react";
import { ButtonContext } from "../../context/ButtonProvider";
import { FeatureBottomBody, FeatureInput } from "../../types";
import FeatureButton from "./FeatureButton";

const FeatureBottom = ({ input }: { input: FeatureInput }) => {
  const body = input.body as FeatureBottomBody;
  const { button } = useContext(ButtonContext);
  return (
    <div className="bottom-feature">
      <h1>{body.header}</h1>
      <p className="h3">
        <i>{body.subText}</i>
      </p>
      <FeatureButton
        contrast
        large
        onClick={button.onClick}
        text={button.text}
      />
    </div>
  );
};

export default FeatureBottom;
