import React, { Fragment, useEffect, useState } from "react";
import { IFeatureFields } from "../@types/generated/contentful";
import { client, contentfulConfig } from "../client";
import FeatureFactory from "../components/features/FeatureFactory";
import PoweredBy from "../components/shared/PoweredBy";
import LegalDisclaimer from "../components/shared/LegalDisclaimer";
import { EntryCollection, Entry } from "contentful";

const Landing = ({
  onOpen,
  tags,
}: {
  onOpen?: (e: unknown) => void;
  tags: string;
}) => {
  const [content, setContent] =
    useState<EntryCollection<IFeatureFields> | null>(null);
  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<IFeatureFields>({
        content_type: "feature",
        links_to_entry: contentfulConfig.ids.landing,
        "metadata.tags.sys.id[all]": tags,
        order: "fields.priority",
        include: 10,
      });
      setContent(entry);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {content?.items.map((item: Entry<IFeatureFields>, index: number) => (
        <FeatureFactory
          type={item.fields.type ?? ""}
          data={item}
          key={item.fields.type + "-" + index ?? ""}
          params={{ onClick: onOpen }}
          index={index}
        />
      ))}
      <LegalDisclaimer tags={tags} />
      <PoweredBy />
    </Fragment>
  );
};

export default Landing;
