import React, { useContext, useState } from "react";
import { Formik, Field, Form, FormikState } from "formik";
import ProfileToast from "../../../utility/ProfileToast";
import { changePassword } from "../../../api/identity/Queries";
import * as Yup from "yup";
import { ClientConfigContext } from "../../../context/ClientConfigProvider";

interface ChangePasswordValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
type formCallback = (
  n: Partial<FormikState<ChangePasswordValues>> | undefined
) => void;

const ChangePassword = () => {
  const initialValues: ChangePasswordValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const { client } = useContext(ClientConfigContext);
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordChange = async ({
    currentPassword,
    newPassword,
    resetForm,
  }: {
    currentPassword: string;
    newPassword: string;
    resetForm: formCallback;
  }) => {
    try {
      const res = await changePassword({ currentPassword, newPassword });
      if (res.code === 200) {
        ProfileToast("Successfully changed password.", true, client);
        resetForm(undefined);
      } else {
        let errorMessage = "There was an error with the password change.";
        if (res.code === 13) {
          errorMessage = "Current Password does not match, please try again.";
        }
        ProfileToast(errorMessage, false, client);
      }
    } catch (Error) {
      const errorMessage = "There was an error with the password change.";
      ProfileToast(errorMessage, false, client);
    }
  };

  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().min(1).required("Required"),
    newPassword: Yup.string()
      .required()
      .min(8)
      .matches(RegExp("(.*[a-z].*)"), "Lowercase")
      .matches(RegExp("(.*[A-Z].*)"), "Uppercase")
      .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), "Special")
      .defined("Password must be defined."),
    confirmPassword: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.newPassword === value;
      }
    ),
  });
  return (
    <div className="change-password">
      <span className="change-password__title">Change Password</span>
      <p className="mb-2">
        Your password, in combination with your email address, allows you to log
        in to your Fitness Your Way Account.
      </p>
      <Formik
        key="password"
        initialValues={initialValues}
        validationSchema={changePasswordSchema}
        onSubmit={(values, { resetForm }) => {
          const currentPassword = values.currentPassword;
          const newPassword = values.newPassword;
          handlePasswordChange({
            currentPassword,
            newPassword,
            resetForm,
          });
        }}
      >
        {(props) => (
          <>
            <Form
              onSubmit={props.handleSubmit}
              style={{
                display: `flex`,
                flexDirection: `column`,
              }}
            >
              <label
                style={{
                  fontFamily: `Roboto`,
                  color: `#666666`,
                  fontSize: `12px`,
                }}
                htmlFor="currentPassword"
              >
                <b>CURRENT PASSWORD</b>
              </label>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  // marginBottom: "14px",
                }}
              >
                <Field
                  autocomplete="off"
                  style={{
                    backgroundColor: `#F5F5F5`,
                  }}
                  className="p-1 rounded mb-2 change-password__input"
                  type={showPassword ? "text" : "password"}
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Current Password"
                  autofill={false}
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="change-password__show-hide-password"
                >
                  <b>{showPassword ? `Hide` : `Show`}</b>
                </span>
              </div>
              {props.touched.currentPassword === true &&
              props.errors.currentPassword ? (
                <div className={`form-requirements invalid`}>
                  {props.errors.currentPassword}
                </div>
              ) : null}

              <label
                style={{
                  fontFamily: `Roboto`,
                  color: `#666666`,
                  fontSize: `12px`,
                }}
                htmlFor="newPassword"
              >
                <b>NEW PASSWORD</b>
              </label>
              <Field
                className="p-1 rounded mb-2 change-password__input"
                type="password"
                id="newPassword"
                name="newPassword"
                placeholder=""
              />
              {/* <ErrorMessage component="span" name="newPassword" /> */}
              {props.touched.newPassword === true &&
              props.errors.newPassword ? (
                <div className={`form-requirements invalid`}>
                  Eight or more characters, with at least one lowercase letter,
                  one uppercase letter, and one special character.
                </div>
              ) : null}

              <label
                style={{
                  fontFamily: `Roboto`,
                  color: `#666666`,
                  fontSize: `12px`,
                }}
                htmlFor="confirmPassword"
              >
                <b>CONFIRM NEW PASSWORD</b>
              </label>
              <Field
                className="p-1 rounded mb-2 change-password__input"
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder=""
              />
              {props.touched.confirmPassword === true &&
              props.errors.confirmPassword ? (
                <div className={`form-requirements invalid`}>
                  {props.errors.confirmPassword}
                </div>
              ) : null}

              <button
                // disabled={props.isSubmitting}
                disabled={!props.isValid}
                type="submit"
                className="nav-btn-enroll mt-2 font-weight-bold btn btn-primary"
              >
                Apply Password Change
              </button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
