import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faDumbbell,
  faFire,
  faStopwatch,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import videojs, { VideoJsPlayer } from "video.js";
import { responsive } from "../../utility/Carousel";
import "video.js/dist/video-js.css";
import "@videojs/http-streaming";
import { getVideoDetails, getVideosList } from "../../api/platform/Queries";
import CarouselCardVideo from "./CarouselCardVideo";
import { fetchWrapper } from "../../utility/util";
import { Program, VideoData } from "../../pages/LesMills";
import { useHistory, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export type VideoDetails = {
  program: {
    name: string;
  };
  instructors: string[];
  equipment: string[];
  fileId: string;
  thumbnail: string;
  title: string;
  duration: string;
  intensityType: string;
  descriptionLong: string;
  categories: Categories[];
};

type Payload = {
  eventDateTime: string;
  namespace: string;
  kind: string;
  attributes: {
    eventId: string;
    // tivitySub?: string;
    // client: string;
    eventDurationSeconds: string;
    elapsedSeconds?: number;
    completed?: boolean;
  };
} | null;

type Categories = {
  name: string;
};

const VideoToPlay = () => {
  const history = useHistory();
  const node = useRef(null!);
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);
  const [videoDetails, setVideoDetails] = useState<VideoDetails | null>(null);
  const [videoList, setVideoList] = useState<VideoData[]>([]);
  const [programsList, setPrograms] = useState<Program[]>([]);
  const [error, setError] = useState(false);
  const userWatchedVideoSeconds = useRef<number>(0);
  const apiLimiter = useRef<React.ReactNode>(null);
  const apiThrottleDelaySeconds = 30;
  const [userHasWatchedVideo, setUserHasWatchedVideo] = useState(false);
  const [eventInitiatedSent, setEventInitiatedSent] = useState(false);
  // const client = "fyw";
  //const clientId = "71EC810069902F5FDC7CBB21BAC94CED";
  const videoReportingEndpoint = `/app/v1/events`;
  const eventNameSpace = "tivityhealth.com/internal/lesmills";
  const [isLoading, setIsLoading] = useState(false);
  const { videoId } = useParams<{ videoId: string }>();

  //could be handled better -> just uses error boundary in routing
  if (error) {
    throw error;
  }

  const clearApiLimiter = () => {
    if (apiLimiter.current) apiLimiter.current = null;
  };

  const getUserWatchedVideoSeconds = () => {
    return userWatchedVideoSeconds.current;
  };

  // const eventClientIds = {
  //   HCSC: "0361DD38DD127FDA138F25259D6D51E8",
  // };

  const EventType = {
    EventInitiated: "EventInitiated",
    EventProgress: "EventProgress",
  };

  useEffect(() => {
    // getVideoDetails(id);
    (async () => {
      try {
        setIsLoading(true);
        const response = await getVideoDetails(videoId);
        setVideoDetails(response);
        const resp = await getVideosList();
        setVideoList(resp.videos);
        setPrograms(resp.programs);
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    })();
    window.scrollTo(0, 0);
  }, [videoId]);

  useEffect(() => {
    if (!isLoading) {
      (async () => {
        const videoDetailsEndpoint = `/app/v1/video/view?fileId=${videoId}&width=1920&height=1080`;
        const videoUrlCall = await fetchWrapper(videoDetailsEndpoint, {
          credentials: "include",
        });
        const responsePlayer = await videoUrlCall.json();
        const props = {
          autoplay: false,
        };
        const p: VideoJsPlayer = videojs(node.current, props, () =>
          p.src({
            src: responsePlayer.url,
            withCredentials: true,
            loadingSpinner: true,
          })
        );

        setPlayer(p);
      })();
      //return () => player?.dispose();
    }
  }, [isLoading]);

  const isValidPayload = (payload: Payload) => {
    if (
      payload &&
      payload["attributes"]["eventId"] &&
      //payload["attributes"]["tivitySub"] &&
      //payload["attributes"]["client"] &&
      payload["attributes"]["eventDurationSeconds"]
    ) {
      return true;
    }
    return false;
  };

  const sendReport = (
    eventType: string,
    //sub: string,
    fileId: string,
    timestamp: number,
    videoIsComplete: boolean,
    videoLength: string
  ) => {
    if (userHasWatchedVideo) return null; //Prevent any more api calls about this video, unless the user refreshes.

    let payload: Payload = null;

    switch (eventType) {
      case EventType.EventInitiated:
        payload = {
          eventDateTime: new Date().toISOString(),
          namespace: eventNameSpace,
          kind: eventType,
          attributes: {
            eventId: fileId,
            //tivitySub: sub,
            //client: eventClientIds[client.toUpperCase()],
            //client: clientId,
            eventDurationSeconds: videoLength,
          },
        };
        break;

      case EventType.EventProgress:
        payload = {
          eventDateTime: new Date().toISOString(),
          namespace: eventNameSpace,
          kind: eventType,
          attributes: {
            eventId: fileId,
            //tivitySub: sub,
            //client: eventClientIds[client.toUpperCase()],
            //client: clientId,
            elapsedSeconds: getUserWatchedVideoSeconds(),
            eventDurationSeconds: videoLength,
            completed: videoIsComplete,
          },
        };
        break;
    }

    //Send the payload to the api.
    if (videoReportingEndpoint && payload && isValidPayload(payload)) {
      submitPayload(payload);
    }
  };

  const submitPayload = async (payload: {
    eventDateTime: string;
    namespace: string;
    kind: string;
    attributes: {
      eventId: string;
      // tivitySub?: string;
      // client: string;
      eventDurationSeconds: string;
      elapsedSeconds?: number | undefined;
      completed?: boolean | undefined;
    };
  }) => {
    try {
      const res = await fetchWrapper(videoReportingEndpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (res.status !== 2004) {
        return new Error("Bad request");
      }
    } catch (e: unknown) {
      throw new Error("Bad Request");
    }
  };

  const onVideoTimeUpdate = () => {
    if (
      player &&
      getUserWatchedVideoSeconds() < player.duration() &&
      getUserWatchedVideoSeconds() < player.currentTime()
    ) {
      userWatchedVideoSeconds.current = Math.ceil(player.currentTime());

      if (apiLimiter.current === null) {
        apiLimiter.current = setTimeout(() => {
          sendReport(
            EventType.EventProgress,
            //authKit?.getUserinfo()?.sub ?? "",
            videoId,
            getUserWatchedVideoSeconds(),
            false,
            videoDetails?.duration ?? ""
          );
          clearApiLimiter(); //clear out the timeout.
        }, apiThrottleDelaySeconds * 1000);
      }
    }
  };

  const onVideoPlay = () => {
    console.info("onVideoPlay");
    if (!eventInitiatedSent) {
      //send this once, when the video starts playing. User initiated.
      sendReport(
        EventType.EventInitiated,
        //authKit?.getUserinfo()?.sub ?? "",
        videoDetails?.fileId ?? "",
        userWatchedVideoSeconds.current,
        false,
        videoDetails?.duration ?? ""
      );
      setEventInitiatedSent(true);
    }
  };

  const onVideoPause = () => {
    clearApiLimiter();
    sendReport(
      EventType.EventProgress,
      //authKit?.getUserinfo()?.sub ?? "",
      videoDetails?.fileId ?? "",
      userWatchedVideoSeconds.current,
      false,
      videoDetails?.duration ?? ""
    );
  };

  const onVideoEnded = () => {
    //console.info("Video is done!");
    clearApiLimiter();
    sendReport(
      EventType.EventProgress,
      //authKit?.getUserinfo()?.sub ?? "",
      videoDetails?.fileId ?? "",
      userWatchedVideoSeconds.current,
      true,
      videoDetails?.duration ?? ""
    );

    setUserHasWatchedVideo(true);
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="video-container bg-col">
            <div className="container">
              <div className="row">
                <div className="col ml-5 pl-0">
                  <a
                    onClick={() => {
                      history.goBack();
                    }}
                    className="go-back"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} /> Back
                  </a>
                </div>
                <div className="col-8">
                  <div data-vjs-player>
                    <video
                      ref={node}
                      className="video-js vjs-16-9 vjs-big-play-centered"
                      controls
                      onPlay={onVideoPlay}
                      onPause={onVideoPause}
                      onTimeUpdate={onVideoTimeUpdate}
                      onEnded={onVideoEnded}
                    />
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
          <div className="video-details-container bg-details">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-8">
                  <div className="video-category">
                    <div className="video-category-section2">
                      {videoDetails?.categories.map((cat, x) => {
                        return (
                          <>
                            <div key={x} className="video-categories-tabs">
                              {cat.name}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <h1 className="video-title">{videoDetails?.title}</h1>
                  <div className="row pb-3">
                    <div className="pr-2 mb-2 font-small duration-border w-auto">
                      <FontAwesomeIcon icon={faStopwatch} />{" "}
                      {moment
                        .utc(
                          moment
                            .duration(videoDetails?.duration, "seconds")
                            .as("milliseconds")
                        )
                        .format("mm:ss")}
                    </div>
                    <div className="pr-2 mb-2 font-small duration-border w-auto">
                      <FontAwesomeIcon icon={faFire} />{" "}
                      {videoDetails?.intensityType}
                    </div>
                    <div className="pr-2 mb-2 font-small pb-2 w-auto">
                      <FontAwesomeIcon icon={faUserCircle} />{" "}
                      {videoDetails?.instructors.map((instructor, index) => {
                        return <>{(index ? ", " : "") + instructor}</>;
                      })}
                    </div>
                    {videoDetails?.equipment && (
                      <div className="pr-2 mb-2 font-small">
                        <FontAwesomeIcon icon={faDumbbell} />{" "}
                        {videoDetails?.equipment.map((equ, index) => {
                          return <>{(index ? ", " : "") + equ}</>;
                        })}
                      </div>
                    )}
                  </div>
                  <p>{videoDetails?.descriptionLong}</p>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
          <div className="video-details-list bg-details">
            <div className="container pb-3">
              {programsList &&
                programsList.map((program, e) => {
                  if (videoDetails?.program.name == program.name)
                    return (
                      <>
                        <div className="category-section pt-5" key={e}>
                          <h1 key={program.name} style={{ fontSize: "32px" }}>
                            {program.name}{" "}
                            <span className="font-weight-normal">
                              ({program.totalProgramVideos})
                            </span>
                          </h1>
                          <p className="font-small">{program.description}</p>
                        </div>
                        <Carousel
                          className="mt-4 carousel"
                          showDots={false}
                          responsive={responsive}
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          partialVisible={true}
                        >
                          {videoList &&
                            videoList
                              .filter(
                                (video) => program.name == video.program.name
                              )
                              .map((video) => {
                                if (program.name == video.program.name) {
                                  return (
                                    <>
                                      <div key={video.title}>
                                        <CarouselCardVideo
                                          fileId={video.fileId}
                                          thumbnail={video.thumbnail}
                                          title={video.title}
                                          duration={video.duration}
                                          selectedVideo={videoId}
                                        />
                                      </div>
                                    </>
                                  );
                                }
                              })}
                        </Carousel>
                      </>
                    );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VideoToPlay;
