import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React, { useEffect, useState } from "react";
import { useAuthKit } from "@authkitcom/react";
import ButtonProvider from "./context/ButtonProvider";
import ThemeProvider from "./context/ThemeProvider";
import UserProvider from "./context/UserProvider";
import { Spinner } from "react-bootstrap";
import { getSessionId, IToken } from "./api/portal/Queries";
import ImpersonationProvider from "./context/ImpersonationProvider";
import SomethingWentWrong from "./pages/SomethingWentWrong";

const Boot = ({
  children,
}: {
  children: React.ReactChildren | React.ReactChild;
}) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [error, setError] = useState(false);
  const { authKit } = useAuthKit();

  //set api routes/query parameters
  useEffect(() => {
    (async () => {
      try {
        const res = await fetch("/boot.json");
        const json = await res.json();
        sessionStorage.setItem("api-url", json.apiUrl);

        const httpLink = createHttpLink({
          uri: json.apiUrl + "/app/graphql",
        });
        //url parameters setup and session id setup
        const params = new URLSearchParams(window.location.search);
        const notificationParam = params.get("notify");
        if (notificationParam) {
          sessionStorage.setItem("notification", notificationParam);
        }
        const sessionIdParam = params.get("pipeline-session-id");
        if (sessionIdParam) {
          sessionStorage.setItem("pipeline-session-id", sessionIdParam);
        } else {
          let sessionId = sessionStorage.getItem("pipeline-session-id");
          if (!sessionId) {
            sessionId = await getSessionId();
            sessionStorage.setItem("pipeline-session-id", sessionId);
          }
        }
        if (sessionIdParam || notificationParam) {
          window.history.replaceState(
            null,
            document.title,
            window.location.pathname
          );
        }
        //token setup for authkit
        const storage = sessionStorage.getItem("authkit.storage.tokens") ?? "";
        let tokens: null | IToken = null;
        if (storage !== "") {
          tokens = JSON.parse(storage);
          if (tokens) {
            authKit?.setTokens(tokens);
          }
        }
        //apollo header setup
        const authLink = setContext(
          (r, { headers }: { headers: Record<string, string> }) => {
            // return the headers to the context so httpLink can read them
            const id = sessionStorage.getItem("pipeline-session-id");
            const storage =
              sessionStorage.getItem("authkit.storage.tokens") ?? "";
            let tokens: null | IToken = null;
            if (storage !== "") {
              tokens = JSON.parse(storage);
            }
            let headersAdditional: Record<string, unknown> = {
              "pipeline-session-id": id,
            };
            if (tokens && tokens.accessToken) {
              headersAdditional = {
                ...headersAdditional,
                authorization: `Bearer ${tokens.accessToken}`,
              };
            }
            if (headers?.authorization === "") {
              delete headers.authorization;
              delete headersAdditional.authorization;
            }

            return {
              headers: Object.assign({}, headersAdditional, headers),
            };
          }
        );

        const defaultOptions: DefaultOptions = {
          mutate: {
            errorPolicy: "all",
          },
          watchQuery: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
          },
          query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
          },
        };
        setClient(
          new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache(),
            defaultOptions: defaultOptions,
          })
        );
      } catch (e) {
        setError(true);
      }
    })();
  }, [authKit]);

  if (error) {
    return <SomethingWentWrong />;
  }

  if (!client) {
    return (
      <div className="center-loading">
        <Spinner animation="border" />
      </div>
    );
  }
  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        <ImpersonationProvider>
          <UserProvider>
            <ButtonProvider>{children}</ButtonProvider>
          </UserProvider>
        </ImpersonationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default Boot;
