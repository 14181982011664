import React from "react";

/**
 * @description Creates a feature for a landing page given
 *  a common set of parameters. Splits the space evenly between
 *  all children.
 */

const ChevronDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.78" height="10.732">
      <path
        d="M8.661 10.447L.303 2.577a.932.932 0 010-1.374l.975-.918a1.078 1.078 0 011.458 0l6.655 6.237L16.046.285a1.078 1.078 0 011.458 0l.975.918a.932.932 0 010 1.374l-8.358 7.87a1.078 1.078 0 01-1.46 0z"
        fill="#0D7BAB"
      />
    </svg>
  );
};

const FeatureBase = ({
  background,
  backgroundColor,
  leftAlign,
  slanted,
  children,
  size,
  colorOverlay,
  featureIndex,
  featureClassName,
}: {
  background?: string;
  backgroundColor?: string;
  slanted?: boolean;
  leftAlign?: boolean;
  children: React.ReactNodeArray | React.ReactChild;
  size: string;
  colorOverlay?: boolean;
  featureIndex?: unknown;
  featureClassName?: string;
}) => {
  return (
    <>
      <div className="d-none d-sm-none d-md-inline">
        <div
          className={`feature-base ${
            size === "large"
              ? "feature-base--large"
              : size === "xs"
              ? "feature-base--xs"
              : ""
          }${featureClassName}`}
          style={{
            backgroundColor: backgroundColor ? backgroundColor : "",
          }}
          data-feature-index={featureIndex}
        >
          {featureIndex === 0 ? (
            <div className="learn-more">
              <div>
                <ChevronDown />
                <p>learn more</p>
              </div>
            </div>
          ) : null}

          <div
            className={`feature-base__background ${
              slanted && "feature-base__background--slanted"
            } ${colorOverlay && "feature-base__background--color-overlay"}`}
            style={{
              backgroundImage: `url('${background}')`,
              backgroundPosition: leftAlign ? "right" : "left",
            }}
          />
          <div className="feature-base__child-container">
            {Array.isArray(children)
              ? children.map((c, i) => {
                  return (
                    <div
                      key={`feature-base-${i}`}
                      className={`feature-base__child ${
                        i % 2 === 0 ? "even" : "odd"
                      }`}
                    >
                      {c}
                    </div>
                  );
                })
              : children}
          </div>
        </div>
      </div>
      <div className="d-sm-block d-md-none">
        {featureIndex === 0 ? (
          <div className="learn-more d-none d-lg-block">
            <div>
              <ChevronDown />
              <p>learn more</p>
            </div>
          </div>
        ) : null}
        <img
          alt="background"
          src={background}
          //style={{ maxWidth: "390px" }}
          className="d-sm-flex d-md-none position-relative img-fluid"
        />
        <div className="feature-base__child-container">
          {Array.isArray(children)
            ? children.map((c, i) => {
                return (
                  <div
                    key={`feature-base-${i}`}
                    className={`feature-base__child ${
                      i % 2 === 0 ? "even" : "odd"
                    }`}
                  >
                    {c}
                  </div>
                );
              })
            : children}
        </div>
      </div>
    </>
  );
};

export default FeatureBase;
