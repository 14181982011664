import * as contenful from "contentful";

const ContentfulClientConfig = () => {
  let environment = "";
  if (window.location.href.includes(".local.")) {
    environment = "UAT";
  } else if (window.location.href.includes(".dev.")) {
    environment = "UAT";
  } else if (window.location.href.includes(".qa.")) {
    environment = "UAT";
  } else if (window.location.href.includes(".uat.")) {
    environment = "UAT";
  } else {
    environment = "PROD";
  }
  const PRODClient = contenful.createClient({
    space: "uuamrissuwi8",
    accessToken: "TEsse51v5KsEVJdS8x45xN21Qt9jnST-IILGRbShmPM",
    environment: "master",
  });
  const UATClient = contenful.createClient({
    space: "uuamrissuwi8",
    accessToken: "xAyJWujk2CjjBUGfj08AJ99iVxmonFI5a8ex3rvE_qM",
    environment: "UAT",
  });
  if (environment === "UAT") {
    return UATClient;
  }
  return PRODClient;
};

export const clientConfig = ContentfulClientConfig();
