import React, { useState, useEffect } from "react";
import { IPromoBannerFields } from "../../../@types/generated/contentful";
import { client } from "../../../client";
import { Entry } from "contentful";
import { useLocation } from "react-router-dom";

const PromotionalBanner = ({ tags }: { tags: string }) => {
  const [content, setContent] = useState<Entry<IPromoBannerFields> | null>(
    null
  );
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<IPromoBannerFields>({
        content_type: "promoBanner",
        "metadata.tags.sys.id[all]": tags,
        include: 10,
      });
      if (entry.items.length > 0) {
        setContent(entry.items[0]);
      }
    })();
  }, []);
  //disable for blue focus locations page
  if (location.pathname === "/fep-blue-focus-locations") {
    return null;
  }
  return (
    <>
      {content && (
        <div className="promotionalBanner">
          <div className="promotionalBanner__content">
            {content?.fields.promo}
          </div>
        </div>
      )}
    </>
  );
};

export default PromotionalBanner;
