import { useMutation } from "@apollo/client";
import { useAuthKit } from "@authkitcom/react";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ClientConfigContext } from "../context/ClientConfigProvider";
import { eligibilityStart } from "../graphql/Mutations";

const FEPSSO = () => {
  const { client } = useContext(ClientConfigContext);
  const { authKit } = useAuthKit();
  const history = useHistory();

  const [startEligibility] = useMutation(eligibilityStart, {
    fetchPolicy: "no-cache",
    onCompleted: async () => {
      authKit.removeTokens();
      await authKit.logout("https://" + window.location.host + "/");
    },
  });

  useEffect(() => {
    (async () => {
      const ui = authKit.getUserinfo();
      if (ui) {
        if (!ui.metadata?.["account.pending"]) {
          history.push("/account");
        } else {
          await startEligibility({
            variables: {
              client: client,
            },
          });
        }
      } else {
        history.push("/account");
      }
    })();
  }, [authKit]);
  return (
    <div className="center-loading">
      <Spinner animation="border" />
    </div>
  );
};

export default FEPSSO;
