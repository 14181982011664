import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { IActivity } from "./ActivityLog";

interface IProps {
  activities: IActivity[];
  currentPageNum: number;
  setCurrentPageNum: (num: number) => void;
}

const ActivityPagination = ({
  activities,
  currentPageNum,
  setCurrentPageNum,
}: IProps) => {
  const pageNumbers = [];
  const perPage = activities ? Math.ceil(activities.length / 10) : 10;

  for (let i = 1; i <= perPage; i++) {
    pageNumbers.push(i);
  }
  const handlePageNumClick = (number: number) => {
    setCurrentPageNum(number);
  };

  const handlePreviousNextClick = (number: number) => {
    if (number === -1) {
      if (currentPageNum > 1) setCurrentPageNum(currentPageNum + number);
    } else {
      if (currentPageNum < perPage) setCurrentPageNum(currentPageNum + number);
    }
  };

  return (
    <>
      <div className="d-md-flex d-none justify-content-end">
        <div className="d-flex justify-content-end p-0 align-items-baseline pagination mt-3">
          <p className="m-0">
            Showing {10 * (currentPageNum - 1) + 1}-
            {activities &&
              activities.slice(10 * (currentPageNum - 1), 10 * currentPageNum)
                .length +
                10 * (currentPageNum - 1)}{" "}
            of {activities && activities.length} results
          </p>
          <div
            className={
              currentPageNum === 1
                ? "d-none mx-2"
                : "mx-2 activity-pagination-controls"
            }
            onClick={() => handlePreviousNextClick(-1)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          {pageNumbers.map((number, index) => {
            if (
              (currentPageNum <= number && currentPageNum + 2 >= number) ||
              currentPageNum === number
            ) {
              return (
                <p
                  key={index}
                  onClick={() => handlePageNumClick(number)}
                  className={
                    currentPageNum === number
                      ? "text-primary font-weight-bold m-0 mx-2 activity-pagination-controls"
                      : "m-0 mx-2 activity-pagination-controls"
                  }
                >
                  {number}
                </p>
              );
            } else if (
              currentPageNum >= number &&
              currentPageNum - 2 <= number
            ) {
              return (
                <p
                  key={index}
                  onClick={() => handlePageNumClick(number)}
                  className={
                    currentPageNum === number
                      ? "text-primary font-weight-bold m-0 mx-2 activity-pagination-controls"
                      : "m-0 mx-2 activity-pagination-controls"
                  }
                >
                  {number}
                </p>
              );
            }
            return null;
          })}
          <div
            className={
              currentPageNum === perPage
                ? "d-none"
                : "activity-pagination-controls"
            }
            onClick={() => handlePreviousNextClick(1)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
      <div className="container d-flex d-md-none flex-column align-items-center justify-content-center">
        <p className="m-0">
          Showing {10 * (currentPageNum - 1) + 1}-
          {activities &&
            activities.slice(10 * (currentPageNum - 1), 10 * currentPageNum)
              .length +
              10 * (currentPageNum - 1)}{" "}
          of {activities && activities.length} results
        </p>
        <div>
          <div
            className={
              currentPageNum === 1 ? "d-none" : "activity-pagination-controls"
            }
            onClick={() => handlePreviousNextClick(-1)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          {pageNumbers.map((number) => (
            <p
              key={number}
              onClick={() => handlePageNumClick(number)}
              className={
                "m-0 " +
                (currentPageNum === number
                  ? "text-primary font-weight-bold ml-3 activity-pagination-controls"
                  : null)
              }
            >
              {number}
            </p>
          ))}
          <div
            className={
              currentPageNum === perPage
                ? "d-none"
                : "activity-pagination-controls"
            }
            onClick={() => handlePreviousNextClick(1)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    </>
  );
};

export { ActivityPagination };
