import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useEffect, useState } from "react";
import { ILegalBullet, ILegalFields } from "../../@types/generated/contentful";
import { client, contentfulConfig } from "../../client";

const LegalDisclaimer = ({ tags }: { tags: string }) => {
  const [bullets, setBullets] = useState<ILegalBullet[] | null>(null);
  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<ILegalFields>({
        content_type: "legal",
        links_to_entry: contentfulConfig.ids.landing,
        "metadata.tags.sys.id[all]": tags,
        include: 10,
      });
      if (entry.items.length > 0) {
        setBullets(entry.items[0].fields.legalBullets);
      }
    })();
  }, []);
  if (!bullets) {
    return null;
  }
  return (
    <div className="legal-disclaimer">
      <div className="legal-disclaimer__header-container">
        <h4>Legal Disclaimers</h4>
        <hr />
      </div>
      <div className="legal-disclaimer__flex">
        {bullets &&
          bullets.map((b, index: number) => (
            <div
              className="legal-disclaimer__flex-item"
              key={`disclaimer-${index}`}
            >
              <span />
              <h2>{documentToReactComponents(b.fields.header1!)}</h2>
              <div>{documentToReactComponents(b.fields.description1!)}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default LegalDisclaimer;
