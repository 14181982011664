import React, { useContext } from "react";
import { Tile } from "./Tile";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MembershipCard from "../MembershipCard";
import { AccountContext } from "../../../context/AccountProvider";
import { Link } from "react-router-dom";

const PlanTile = () => {
  const enrollment = useContext(AccountContext);
  const program = enrollment.enrollmentFrame.current
    ? enrollment.enrollmentFrame.current.sku.substr(
        enrollment.enrollmentFrame.current.sku.indexOf("-") + 1
      )
    : "";
  const { memberCardBase64 } = useContext(AccountContext);

  const printCard = () => {
    const myWindow: Window | null = window.open("");
    if (myWindow) {
      myWindow.document.write(
        `<img src=data:image/png;base64,${memberCardBase64} alt="card"/>`
      );
      setTimeout(() => {
        myWindow.focus();
        myWindow.print();
      }, 10);
      myWindow.document.close();
    }
  };

  const downloadCard = () => {
    const fileName = `membership-card.png`;
    const link = document.createElement("a");
    link.href = "data:image/png;base64," + memberCardBase64;
    link.download = fileName;
    document.body.append(link);
    link.click();
  };
  const myPlan = () => {
    switch (program) {
      case "BASE":
        return (
          <>
            <h2 className="mt-3">BASE Fitness Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 3k gyms
              </li>
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "CORE":
        return (
          <>
            <h2 className="mt-3">CORE Fitness Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 7k gyms
              </li>
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "POWER":
        return (
          <>
            <h2 className="mt-3">POWER Fitness Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 11k gyms
              </li>
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "ELITE":
        return (
          <>
            <h2 className="mt-3">ELITE Fitness Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 11k gyms
              </li>
              <li className="package-list-horizontal__package">
                1 Premium Gym/month
              </li>
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "DIGITAL":
        return (
          <>
            <h2 className="mt-3">Digital Only Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "LEGACY":
        return (
          <>
            <h2 className="mt-3">POWER Fitness Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 11k gyms
              </li>
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;

      default:
        return (
          <>
            <h2 className="mt-3 align-self-center">No Current Packages</h2>
          </>
        );
        break;
    }
  };

  return (
    <Tile className="widget__card">
      <div className="myPlan-tile">
        <Tile.THeader>
          <div>
            <FontAwesomeIcon icon={faClipboardCheck} /> <b>MY PLAN</b>
          </div>
          <Link to="/account/manage" className="a-link">
            <span>Manage Plan</span>
          </Link>
        </Tile.THeader>
        <Tile.TBody>
          {myPlan()}
          <MembershipCard />
          <span className="title mt-3">HOW TO USE YOUR CARD</span>
          <span>
            Show this card at any gym in your fitness package network.
          </span>
          <div className="mt-3">
            <Button
              variant="outline-primary"
              className="btn btn-outline-primary"
              onClick={() => downloadCard()}
            >
              <FontAwesomeIcon icon={faDownload} /> Download
            </Button>
            <Button
              variant="outline-primary"
              className="btn btn-outline-primary"
              onClick={() => printCard()}
            >
              <FontAwesomeIcon icon={faPrint} /> Print
            </Button>
          </div>
        </Tile.TBody>
      </div>
    </Tile>
  );
};
export default PlanTile;
