import React, { useState } from "react";
import { useEffect } from "react";
import { useAuthKit } from "@authkitcom/react";
import { useContext } from "react";
import { ClientConfigContext } from "../../context/ClientConfigProvider";
import { AccountContext } from "../../context/AccountProvider";
import { Spinner } from "react-bootstrap";

/**
 *
 * @description requires an authkit token
 */
const AuthWrapper = ({ children }: { children: React.ReactElement }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { authKit } = useAuthKit();
  const { refresh } = useContext(ClientConfigContext);
  const { populate, enrollmentFrame } = useContext(AccountContext);

  if (error) throw new Error();

  useEffect(() => {
    (async () => {
      try {
        if (authKit) {
          await authKit.authorize();
          await refresh();
          if (!enrollmentFrame.status) {
            await populate();
          }
        }
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    })();
  }, [authKit]);
  if (loading) {
    return (
      <div className="center-loading">
        <Spinner animation="border" />
      </div>
    );
  }
  return <div>{authKit && authKit.isAuthenticated() && children}</div>;
};

export default AuthWrapper;
