import React from "react";
import ReactDOM from "react-dom";
import { AuthKitProvider } from "@authkitcom/react";
import App from "./App";
import Boot from "./Boot";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Spinner } from "react-bootstrap";
import ClientConfigProvider, {
  ClientConfigContext,
} from "./context/ClientConfigProvider";
import { ErrorBoundary } from "react-error-boundary";
import SomethingWentWrong from "./pages/SomethingWentWrong";

ReactDOM.render(
  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
    <ClientConfigProvider>
      <ClientConfigContext.Consumer>
        {({ config }) => (
          <AuthKitProvider
            createParams={{
              issuer: config["idp.issuer"],
              clientId: config["idp.clientId"],
              scope: ["prime"],
            }}
            loadingNode={
              <div className="center-loading">
                <Spinner animation="border" />
              </div>
            }
          >
            <Boot>
              <App />
            </Boot>
          </AuthKitProvider>
        )}
      </ClientConfigContext.Consumer>
    </ClientConfigProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
