import { Optional } from "@authkitcom/react/dist/Lang";
import React, { useEffect } from "react";
import { useState } from "react";
import { fetchAggregatedEnrollment } from "../api/enrollment/Queries";

export enum ENTITLEMENTS {
  GYM = "gym",
  HOME_GYM = "homeGym",
  BURNALONG = "burnalong",
  LESMILLS = "lesmills",
  STUDIO = "studio",
}

type AccountStatus = {
  sku: string;
  effective: string;
  term: string;
  discardUntil: string;
};
type LocationStatus = {
  locationId: string;
  effective: string;
  term: string;
  set: string;
};
export interface IAccount {
  enrollmentFrame: {
    pending?: AccountStatus;
    current?: AccountStatus;
    status: string;
    allowedActions: string[];
  };
  homeGymFrame: {
    current?: LocationStatus;
    pending?: LocationStatus;
    status: string;
    allowedActions: string[];
  };
  locationsTier: string;
  entitlements?: string[];
  memberCardBase64?: string;
  memberCardNumber?: string;
  nextPayment: {
    due: string;
    amount: number;
    overdue: boolean;
  } | null;
  creditBalance?: string;
  suspension: {
    status: string;
    suspend: string;
    resume: string;
  };
  currentPrice: number;
  hasAccount?: boolean;
  hasPaymentMethods?: boolean;
}

const initialState: IAccount = {
  enrollmentFrame: {
    current: {
      sku: "",
      discardUntil: "",
      effective: "",
      term: "",
    },
    status: "",
    allowedActions: [],
  },
  homeGymFrame: {
    current: {
      effective: "",
      locationId: "",
      term: "",
      set: "",
    },
    status: "",
    allowedActions: [],
  },
  locationsTier: "",
  entitlements: [],
  nextPayment: {
    due: "",
    amount: 0,
    overdue: false,
  },
  creditBalance: "",
  suspension: {
    status: "",
    suspend: "",
    resume: "",
  },
  currentPrice: 0,
  hasAccount: false,
  hasPaymentMethods: false,
};

export const AccountContext = React.createContext<
  IAccount & {
    refresh: () => Promise<void>;
    populate: () => Promise<void>;
    error: boolean;
    loading: boolean;
    refreshing: boolean;
  }
>({
  ...initialState,
  refresh: () => Promise.resolve(),
  populate: () => Promise.resolve(),
  error: false,
  loading: true,
  refreshing: false,
});

const AccountProvider = ({ children }: { children: React.ReactNode }) => {
  //before validation account
  const [tempAccount, setTempAccount] = React.useState<IAccount | null>(null);
  //validated account
  const [account, setAccount] = React.useState<IAccount>(initialState);
  //statuses
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(true);

  const [refreshing, setRefreshing] = useState(false);

  const validate = (values: Optional<IAccount>): boolean => {
    if (!values?.enrollmentFrame.status) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (tempAccount) {
      if (validate(tempAccount)) {
        setAccount(tempAccount);
      } else {
        setError(false);
      }
      setRefreshing(false);
      setLoading(false);
    }
  }, [tempAccount]);

  //refresh the aggregated enrollment
  const handleRefresh = async () => {
    try {
      setRefreshing(true);
      setError(false);
      const response = await fetchAggregatedEnrollment();
      setTempAccount(response);
    } catch (e) {
      setError(true);
    }
  };
  //populates aggregated enrollment for first time, sets loading state
  const handlePopulate = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await fetchAggregatedEnrollment();
      setTempAccount(response);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <AccountContext.Provider
      value={{
        ...account,
        refresh: handleRefresh,
        populate: handlePopulate,
        error,
        loading,
        refreshing,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
