import React, { Fragment } from "react";
import { useContext } from "react";
import { ClientConfigContext } from "../../context/ClientConfigProvider";

/**
 * Matches client in config to route and renders children on true condition
 *
 * @returns children of matching route
 */
const ClientRoute = ({
  match,
  children,
  fep,
}: {
  match: string;
  children: React.ReactNode;
  fep?: boolean;
}) => {
  const { client } = useContext(ClientConfigContext);

  //TODO - remove FEP hardcoded guard
  if (client.toLowerCase() === "bcbsa-fep") {
    if (fep) {
      return <Fragment>{children}</Fragment>;
    } else {
      return null;
    }
  }
  //non-fep bcbsa matches
  if (client.toLowerCase().includes(match) && !fep) {
    return <Fragment>{children}</Fragment>;
  }
  return null;
};

export default ClientRoute;
