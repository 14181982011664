import React, { useContext } from "react";
import { ProductBundleContext } from "../../context/ProductBundleProvider";
import skuMap from "../../utility/skuMap";

const BundlesDropDown = ({
  sku,
  termed,
}: {
  sku?: string;
  termed?: boolean;
}) => {
  const { bundles, loading: loadingBundles } = useContext(ProductBundleContext);

  if (loadingBundles) {
    return <option>Loading...</option>;
  }

  return (
    <>
      {Object.keys(skuMap).map((key) => {
        //fitness legacy non termed shows legacy package not power
        if (
          sku &&
          sku === "FITNESS-LEGACY" &&
          termed === false &&
          key === "FITNESS-POWER"
        ) {
          key = "FITNESS-LEGACY";
          return (
            <option value={key} key={key}>
              {skuMap[key].name.toUpperCase()}
            </option>
          );
        } else {
          if (skuMap[key].display) {
            return (
              <option value={key} key={key}>
                {skuMap[key].name.toUpperCase()}
                {skuMap[key].className !== "digital"
                  ? " + Digital Content"
                  : ""}{" "}
                -{" "}
                {`$${
                  bundles?.data?.find((b) => b.sku === key)?.periodicPrice ?? 0
                }/month`}
              </option>
            );
          }
        }
      })}
    </>
  );
};

export default BundlesDropDown;
