import React, { Fragment, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SomethingWentWrong from "../../../pages/SomethingWentWrong";

const StatusModal = ({
  status,
  message,
  show,
  onNext,
  onCancel,
}: {
  status: string;
  message: string;
  show: boolean;
  onNext: (e: unknown) => void;
  onCancel: (e: unknown) => void;
}) => {
  const [check, setCheck] = useState(false);
  const history = useHistory();
  const checked = () => {
    setCheck(!check);
  };

  const getContent = () => {
    switch (status.toUpperCase()) {
      case "ERROR":
        return (
          <Fragment>
            <Modal.Body className="modal-body-form">
              <SomethingWentWrong small={true} />
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
            </Modal.Footer>
            {/* <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Unfortunately, there was an error with your request.</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              Please close this message and try again.
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
            </Modal.Footer> */}
          </Fragment>
        );
      case "LOADING":
        return (
          <Modal.Body>
            <p style={{ textAlign: "center" }}>
              <h3>
                <b>Hang Tight</b>
              </h3>
            </p>
            <p style={{ textAlign: "center" }}>
              We&apos;re checking to see if you&apos;re eligible.
              <div className="mt-4">
                <Spinner animation="border" />
              </div>
              <span>
                <small>Please do not refresh the page</small>
              </span>
            </p>
          </Modal.Body>
        );
      case "CONFIRMED":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2>
                <b>Eligibility Confirmed!</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6>Congratulations! Your eligibility has been confirmed!</h6>
              <p>
                <small>
                  You&apos;re one step closer to a longer, healthier life.
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold modal-footer-form__button"
                onClick={onNext}
              >
                Proceed to Next Step
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "DENIED":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Eligibility Cannot Be Confirmed</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">{message}</Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Re-Enter Information
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "REDEMPTION":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2 className="redemption-eligibility">
                <b>Welcome Blue365 Members</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6 className="redemption-confirmed">
                Your Eligibility has been confirmed!
              </h6>
              <p>
                <small className="redemption-agreement">
                  Explore our different packages, find participating gyms, and
                  discover more perks that come with a Fitness Your Way
                  membership.
                </small>
              </p>
              <p style={{ marginTop: "10px" }}>
                <small className="redemption-proceed">
                  Please agree to our terms before proceeding.
                </small>
              </p>
              <p style={{ marginBottom: "-20px" }}>
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    onChange={checked}
                    style={{
                      marginRight: "6px",
                      height: "13px",
                      width: "13px",
                    }}
                  />
                  <small className="redemption-tnc">
                    <span className="required-field-marker">*</span>I have read
                    and agree to the{" "}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Privacy Policy
                    </a>
                  </small>
                </label>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="redemption-button"
                disabled={!check}
                onClick={onNext}
              >
                Explore
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "EXISTS":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2 className="redemption-eligibility">
                <b>Welcome Blue365 Members</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6 className="redemption-confirmed">
                Your Eligibility has been confirmed!
              </h6>
              <p>
                <small className="redemption-agreement">
                  Hope you are enjoying your Fitness Your Way membership. Please
                  login below to access the member portal and continue to manage
                  your account.
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="redemption-button"
                onClick={() => history.push("/account")}
              >
                Login
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "ALREADY_CONFIRMED":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2>
                <b>Eligibility Already Confirmed!</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6>Please Proceed to the next step!</h6>
              <p>
                <small>
                  You&apos;re one step closer to a longer, healthier life.
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold modal-footer-form__button"
                onClick={onNext}
              >
                Proceed to Next Step
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "BURNALONG":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form-burnalong">
              <h2>
                <b>You're heading to BurnAlong.com</b>
              </h2>
              <span style={{ cursor: "pointer" }} onClick={onCancel}>
                X
              </span>
            </Modal.Header>
            <Modal.Body className="modal-body-form-burnalong">
              <h6>
                You're leaving the Fitness Your Way portal and being redirected
                to our friends at BurnAlong.com
              </h6>
              <p>
                <small>
                  Please note that BurnAlong.com is not owned, operated or
                  controlled by Tivity Health, inc. Or it's affiliates. By
                  visiting BurnAlong.com, you will be subject to their terms of
                  use and privacy policy
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form-burnalong">
              <Button
                variant="outline-primary"
                className="font-weight-bold modal-footer-form-burnalong__button stay-here"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Stay Here
              </Button>
              <Button
                variant="primary"
                className="font-weight-bold modal-footer-form-burnalong__button"
                onClick={onNext}
              >
                Visit BurnAlong
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      default:
        return null;
    }
  };
  return (
    <Modal centered show={show} dialogClassName="status-modal">
      {getContent()}
    </Modal>
  );
};

export default StatusModal;
