import React from "react";
import { Button } from "react-bootstrap";

const SuccessWidget = ({
  header,
  steps,
  buttonText,
  centered,
  onClick,
}: {
  header: string;
  steps: (string | React.ReactNode)[];
  buttonText?: string;
  centered?: boolean;
  onClick?: (e: unknown) => void;
}) => {
  return (
    <div className="success-widget">
      <h4 className={centered ? "text-center" : ""}>
        <b>{header}</b>
      </h4>
      <ul>
        {steps.map((s, index: number) => (
          <li key={index + 1}>
            <div className="success-widget__container">
              <svg viewBox="0 0 26 26" height="26px" width="26px">
                <circle
                  cx="13"
                  cy="13"
                  r="12"
                  className="success-widget__circle"
                ></circle>
                <text
                  x="50%"
                  y="55%"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  className="success-widget__number"
                >
                  {index + 1}
                </text>
              </svg>
              {typeof s === "string" ? <span>{s}</span> : s}
            </div>
          </li>
        ))}
        {onClick && buttonText && (
          <li className="success-widget__button-container2">
            <Button className="success-widget__button2" onClick={onClick}>
              {buttonText}
            </Button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SuccessWidget;
