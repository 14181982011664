import React, { useContext } from "react";
import { AccountContext } from "../../context/AccountProvider";
import { ClientConfigContext } from "../../context/ClientConfigProvider";
import { momentStartOfDay } from "../../utility/util";
import BannerWrapper from "../shared/BannerWrapper";
import ReEnrollBanner from "./ReEnrollBanner";
import ReEnrollSpecialBanner from "./ReEnrollSpecialBanner";
import UpcomingPaymentDisplay from "./UpcomingPaymentDisplay";

const InformationDisplay = ({ header }: { header: string }) => {
  const { enrollmentFrame, hasAccount } = useContext(AccountContext);
  const { config } = useContext(ClientConfigContext);
  const now = momentStartOfDay();
  const daysDiff = now.diff(enrollmentFrame.current?.term, "days")
  const showSpecial = enrollmentFrame.current
    ? enrollmentFrame.current.sku !== "FITNESS-DIGITAL" &&
      daysDiff < 30 && daysDiff > 0
    : false;

  return (
    <div className="account-information">
      <h1>{header}</h1>
      <BannerWrapper
        show={
          (enrollmentFrame.status === "TERMED" ||
            enrollmentFrame.status === "NONE") &&
          showSpecial
        }
      >
        <div className="widget d-flex flex-wrap flex-row mb-3">
          <ReEnrollSpecialBanner
            enrollHref={
              enrollmentFrame.status === "NONE"
                ? "/enroll/package"
                : enrollmentFrame?.status === "TERMED" &&
                  enrollmentFrame?.current?.sku === "FITNESS-LEGACY" &&
                  !hasAccount
                ? "/enroll/package"
                : "/account/manage/edit"
            }
          />
        </div>
      </BannerWrapper>
      <BannerWrapper
        show={
          (enrollmentFrame.status === "TERMED" ||
            enrollmentFrame.status === "NONE") &&
          !showSpecial
        }
      >
        <div className="widget d-flex flex-wrap flex-row mb-3">
          <ReEnrollBanner
            title="Premium Digital Content and Gyms Near Home and Work"
            content={
              <>
                Enroll and gain access to our vast network of participating gyms
                and digital content from{" "}
                {config["lesmills.enable"] == "true"
                  ? `Les
                Mills and `
                  : ""}
                Burnalong. Packages start <b>as low as $10 a month.</b>
              </>
            }
            enrollHref={
              enrollmentFrame.status === "NONE"
                ? "/enroll/package"
                : enrollmentFrame?.status === "TERMED" &&
                  enrollmentFrame?.current?.sku === "FITNESS-LEGACY" &&
                  !hasAccount
                ? "/enroll/package"
                : "/account/manage/edit"
            }
            exploreHref="/locations"
          />
        </div>
      </BannerWrapper>
      <BannerWrapper
        show={
          !(
            enrollmentFrame.status === "TERMED" ||
            enrollmentFrame.status === "NONE"
          )
        }
      >
        <UpcomingPaymentDisplay />
      </BannerWrapper>
    </div>
  );
};

export default InformationDisplay;
