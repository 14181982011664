import React, { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { IProgramAgreementFields } from "../@types/generated/contentful";
import { client } from "../client";
import Section from "../components/terms/Section";
import { ProgramAgreementType, ProgramAgreementSection } from "../types";
import { convertToAgreement } from "../utility/contentful";

const ProgramAgreement = ({ tags }: { tags: string }) => {
  const history = useHistory();
  const [content, setContent] = useState<ProgramAgreementType | null>(null);
  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<IProgramAgreementFields>({
        content_type: "programAgreement",
        "metadata.tags.sys.id[all]": tags,
        include: 10,
      });
      if (entry.items[0]) {
        setContent(convertToAgreement(entry.items[0]));
      }
    })();
    // eslint-disable-next-line
  }, []);
  if (!content) {
    return null;
  }
  return (
    <Fragment>
      <p>
        <Button
          variant="link"
          onClick={history.goBack}
          style={{
            padding: 0,
            border: "none",
            boxShadow: "none",
            minWidth: "0px",
          }}
        >
          Back
        </Button>
      </p>
      <h1>PROGRAM AGREEMENT</h1>
      <span>Last updated: {content.updated}</span>
      {content.sections.map((section: ProgramAgreementSection) => (
        <Section {...section} key={section.header} agreement />
      ))}
    </Fragment>
  );
};
export default ProgramAgreement;
