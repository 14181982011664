import { useEffect, useState } from "react";

type Notification = {
  exists: boolean;
  acknowledged: boolean;
};

type NotificationHook = {
  values: { exists: boolean; acknowledged: boolean };
  clear: () => void;
  acknowledge: () => void;
};

const key = "user-from";

const useNotification = (): NotificationHook => {
  const [notification, setNotification] = useState<Notification>({
    exists: false,
    acknowledged: false,
  });
  const handleClear = () => {
    sessionStorage.removeItem(key);
    setNotification({ ...notification, exists: false });
  };

  const handleAcknowledge = () => {
    const item = sessionStorage.getItem(key);
    if (item) {
      const itemJSON = JSON.parse(item);
      sessionStorage.setItem(
        key,
        JSON.stringify({ ...itemJSON, acknowledged: true })
      );
      setNotification((n) => ({ ...n, acknowledged: true }));
    }
  };

  useEffect(() => {
    const notificationSession = sessionStorage.getItem(key);
    if (notificationSession) {
      const notification = JSON.parse(notificationSession);
      setNotification({
        exists: true,
        acknowledged: notification.acknowledged,
      });
    } else {
      setNotification((n) => ({ ...n, exists: false }));
    }
    // eslint-disable-next-line
  }, []);

  return {
    values: notification,
    clear: handleClear,
    acknowledge: handleAcknowledge,
  };
};

export default useNotification;
