import { useQuery } from "@apollo/client";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { eligibility, enrollment } from "../graphql/Queries";

export enum USER_STATUS {
  ELIGIBLE,
  ENROLLED,
  FEP,
  PENDING,
  EXISTS,
}
//FEP / REDEMPTION are 'authenticated' DEFAULT is not
export enum USER_FROM {
  FEP,
  REDEMPTION,
  DEFAULT,
}

type IUser = {
  status: USER_STATUS;
  from: USER_FROM;
};

interface IUserProvider {
  user: IUser;
  setUser(u: Partial<IUser>): void;
}

export const UserContext = React.createContext<IUserProvider>({
  user: { status: USER_STATUS.PENDING, from: USER_FROM.DEFAULT },
  setUser: () => {
    return;
  },
});
/**
 * Checks eligibility/enrollment to determine if user is eligible already
 * Determines where the user came from if possible
 * eligibility.status or enrollment client means theyre eligible
 */
const UserProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [user, setUser] = useState<IUser>({
    status: USER_STATUS.PENDING,
    from: USER_FROM.DEFAULT,
  });
  const [eligibilityLoading, setEligibilityLoading] = useState(true);
  useQuery(eligibility, {
    //set the formik state
    onCompleted: useCallback((data) => {
      const eCheck = data.eligibilityCheck?.state;
      if (eCheck) {
        if ((eCheck.status as string).toUpperCase() === "CONFIRMED") {
          setUser((u) => ({ ...u, status: USER_STATUS.ELIGIBLE }));
        } else if ((eCheck.status as string).toUpperCase() === "EXISTS") {
          setUser((u) => ({ ...u, status: USER_STATUS.EXISTS }));
        }
      }
      setEligibilityLoading(false);
    }, []),
    onError: useCallback(() => {
      setEligibilityLoading(false);
    }, []),
  });
  /**
   * Determines if the user is already eligible based on enrollment state.
   */
  useQuery(enrollment, {
    //set the formik state
    onCompleted: useCallback((data) => {
      const e = data.enrollment?.state;
      if (e) {
        if (e.client) {
          setUser((u) => ({ ...u, status: USER_STATUS.ELIGIBLE }));
        }
      }
      setEligibilityLoading(false);
    }, []),
    onError: useCallback(() => {
      setEligibilityLoading(false);
    }, []),
  });
  /*
   * This handles b365 redemption flow users. Sets their user state.
   */
  useEffect(() => {
    const type = sessionStorage.getItem("notification");
    if (type === "jwtValidated") {
      sessionStorage.removeItem("notification");
      setUser((u) => ({ ...u, from: USER_FROM.REDEMPTION }));
      sessionStorage.setItem(
        "user-from",
        JSON.stringify({ from: "b365", acknowledged: false })
      );
    } else {
      const exists = sessionStorage.getItem("user-from");
      if (exists) {
        const existsJSON = JSON.parse(exists);
        if (existsJSON.from === "b365") {
          setUser((u) => ({ ...u, from: USER_FROM.REDEMPTION }));
        }
      }
    }
    //fep
    if (window.location.hostname.split(".")[0].toLowerCase() === "fep") {
      setUser((u) => ({ ...u, from: USER_FROM.FEP }));
    }
  }, []);

  const userValue: IUserProvider = {
    user: user,
    setUser: useCallback(
      (u: Partial<IUser>) => {
        setUser({ ...user, ...u });
      },
      [user]
    ),
  };
  if (eligibilityLoading) return <div></div>;
  return (
    <UserContext.Provider value={userValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
