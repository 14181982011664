import { CurrentAddress } from "../../components/account/Profile/ShippingAddress";
import { HomeGymDetails } from "../../components/account/Tiles/HomeGymTile";
import { VideoDetails } from "../../components/lesMills/VideoToPlay";
import { Program, VideoData } from "../../pages/LesMills";
import { fetchWrapper } from "../../utility/util";

// TODO: location detail requires phone/ website optional/ Amenities additional cost
export type LocationData = {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  genderSpecific: string;
  tiers: string[];
  amenities: string[];
  latitude: number;
  longitude: number;
  count: number;
};
export type GeoCode = {
  latitude: number;
  longitude: number;
};
type LocationResponse = {
  geocode: GeoCode;
  locations: LocationData[];
};

export async function fetchLocationsByAddress(
  address: string,
  params: string
): Promise<LocationResponse> {
  try {
    const query =
      "/api/platform/v1/location-search?address=" +
      encodeURI(address + params);

    const results = await fetchWrapper(query);

    const resultsJson: LocationResponse = await results.json();

    return Promise.resolve(resultsJson);
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function fetchLocationsByLatLong(
  latitude: number,
  longitude: number,
  params: string
): Promise<LocationResponse> {
  try {
    const query =
      "/api/platform/v1/location-search?latitude=" +
      latitude +
      "&longitude=" +
      longitude +
      encodeURI(params);

    const results = await fetchWrapper(query);
    if (results.ok) {
      const resultsJson: LocationResponse = await results.json();

      return Promise.resolve(resultsJson);
    }
    return Promise.reject("Bad Request");
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function fetchTierCount(
  tier: string
): Promise<{ count?: number }> {
  try {
    const query =
      "/api/platform/v1/location-search?omitResults=true&tier=" +
      tier.toUpperCase();
    const results = await fetchWrapper(query);
    if (results.ok) {
      const resultsJson: { count?: number } = await results.json();
      return Promise.resolve(resultsJson);
    }
    return Promise.reject("Bad Request");
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function fetchTiers(): Promise<{ tiers?: string[] }> {
  try {
    const query = "/api/platform/v1/location-tiers";

    const results = await fetchWrapper(query);

    if (results.ok) {
      const resultsJson: { tiers: string[] } = await results.json();
      return Promise.resolve(resultsJson);
    }
    return Promise.reject([]);
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export async function fetchAmenities(): Promise<{ amenities?: string[] }> {
  try {
    const query = "/api/platform/v1/location-amenities";

    const results = await fetchWrapper(query);

    if (results.ok) {
      const resultsJson: { amenities?: string[] } = await results.json();
      return Promise.resolve(resultsJson);
    }
    return Promise.reject([]);
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

//use config to set the flow in eligibility start
//flyin -> call this with flyin code -> redirect with that hostname
export async function getClientConfigByCode(
  code: string,
  key: string
): Promise<Record<string, string> | null> {
  try {
    const query = `/api/platform/v1/clients/${code}/config/${key}`;
    const results = await fetchWrapper(query);
    if (results.ok) {
      const resultsJson: { key: string; value: string } = await results.json();
      if (resultsJson) {
        return Promise.resolve(resultsJson);
      }
    }
    return null;
  } catch (e) {
    throw new Error("Unable to fetch client config");
  }
}

export async function getVideosList(): Promise<{
  videos: VideoData[];
  programs: Program[];
}> {
  const videosList = await fetchWrapper("/api/platform/v1/videos");
  if (videosList.ok) {
    const response: { videos: VideoData[]; programs: Program[] } =
      await videosList.json();
    if (response.videos && response.programs) {
      return Promise.resolve(response);
    }
  }
  throw new Error("Invalid response");
}

export async function getCurrentAddress(): Promise<{
  data: CurrentAddress[];
}> {
  try {
    const query = "/api/identity/v1/me/addresses";
    const currentAddress = await fetchWrapper(query);
    if (currentAddress.ok) {
      const response = await currentAddress.json();
      return Promise.resolve(response);
    }
    return Promise.reject([]);
  } catch (error: unknown) {
    return Promise.reject(error);
  }
}

export async function getVideoDetails(fileId: string): Promise<VideoDetails> {
  const videoData = await fetchWrapper(`/api/platform/v1/videos/${fileId}`);
  if (videoData.ok) {
    const response: VideoDetails = await videoData.json();
    if (response.fileId) {
      return Promise.resolve(response);
    }
  }
  throw new Error("Invalid response");
}

export const setNewEmail = async (
  email: string
): Promise<{
  newEmailAddress: string;
}> => {
  try {
    const query = "/api/identity/v1/me/email";

    const newAddress = await fetchWrapper(query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newEmailAddress: email,
      }),
    });
    if (newAddress.status !== 200) {
      if (newAddress.status === 409) {
        throw new Error("Duplicate Email");
      }
      throw new Error("Bad request");
    }
    const json = await newAddress.json();
    return json as {
      newEmailAddress: string;
    };
  } catch (error: unknown) {
    throw new Error("Error setting address" + error);
  }
};

export async function getHomeGymDetails(
  locationId?: string | null
): Promise<HomeGymDetails> {
  try {
    const myHomeGym = await fetchWrapper(
      `/api/platform/v1/locations/${locationId}`
    );
    if (myHomeGym.ok) {
      const response: HomeGymDetails = await myHomeGym.json();
      return Promise.resolve(response);
    } else {
      throw new Error();
    }
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export interface IProductBundle {
  sku: string;
  name: string;
  periodicPrice: number;
  periodType: string;
  oneTimePrice: number;
}

export interface IProductBundleData {
  data: IProductBundle[];
}

export async function getProductBundles(
  clientCode: string
): Promise<IProductBundleData> {
  try {
    const productBundles = await fetchWrapper(
      `/api/platform/v1/clients/${clientCode}/product-bundles`
    );
    if (productBundles.ok) {
      const resp: IProductBundleData = await productBundles.json();
      return Promise.resolve(resp);
    }
    return Promise.reject("Error fetching bundles");
  } catch (e: unknown) {
    return Promise.reject(e);
  }
}
