import React from "react";
import { Button, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useAuthKit } from "@authkitcom/react";
const NavMenu = ({
  onEligibility,
  onLogin,
  onEnroll,
}: {
  onEligibility?: (e: unknown) => void;
  onLogin?: (e: unknown) => void;
  onEnroll?: (e: unknown) => void;
}) => {
  const { authKit } = useAuthKit();

  const userLoggedIn = authKit.isAuthenticated();
  const handleLogout = async () => {
    await authKit.logout("https://" + window.location.host);
  };
  return (
    <div className="slanted-background pr-2 d-flex align-items-center">
      <Nav className="justify-content-end d-flex" style={{ zIndex: 9999 }}>
        {onLogin && (
          <Nav.Item>
            <Button
              variant="outline-primary"
              className="nav-btn-enroll font-weight-bold"
              onClick={onLogin}
            >
              {userLoggedIn ? "My Account" : "Log in"}
            </Button>
          </Nav.Item>
        )}
        {onEligibility && (
          <Nav.Item>
            <Button
              variant="primary"
              className="nav-btn-enroll font-weight-bold"
              style={{ whiteSpace: "nowrap" }}
              onClick={onEligibility}
            >
              Check My Eligibility
              <FontAwesomeIcon
                icon={faChevronRight}
                className="chevron-right"
              />
            </Button>
          </Nav.Item>
        )}
        {onEnroll &&
          (userLoggedIn ? (
            <Nav.Item>
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={handleLogout}
              >
                Logout
                {/* <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                /> */}
              </Button>
            </Nav.Item>
          ) : (
            <Nav.Item>
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onEnroll}
              >
                Enroll Now
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </Button>
            </Nav.Item>
          ))}
      </Nav>
    </div>
  );
};

export default NavMenu;
