import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { Badge, Button, ListGroup, Spinner } from "react-bootstrap";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { getClientConfigByCode } from "../api/platform/Queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { fetchWrapper } from "../utility/util";

type Plan = { name: string; code: string };

const Flyin = ({
  open,
  handler,
  viewOnly,
  clientCode,
}: {
  open: boolean;
  handler: () => void;
  clientCode: string;
  viewOnly?: boolean;
}) => {
  const [checked, setChecked] = useState("");
  const [list, setList] = useState<Plan[]>([]);
  const [flow, setFlow] = useState<string>("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let running = true;
    (async () => {
      const resp = await fetchWrapper(
        `/api/platform/v1/clients/${clientCode}/leaf-descendants`
      );

      const j = await resp.json();
      if (j.data) {
        if (running) {
          setList(
            j.data.map((plan: Plan) => ({
              name: plan.name,
              code: plan.code,
            }))
          );
          setLoading(false);
        }
      }
    })();
    return () => {
      running = false;
    };
  }, []);

  const handleChange = (state: string, flow: string) => () => {
    setChecked(state);
    setFlow(flow);
  };
  const handleSubmit = async () => {
    try {
      const resp = await getClientConfigByCode(flow, "site.baseUrl");
      if (resp) {
        window.location.href = `${resp.value}/enroll/eligibility`;
      }
    } catch {
      throw new Error("Request failed");
    }
  };
  const Radio = ({ state, flow }: { state: string; flow: string }) => {
    return (
      <input
        type="radio"
        className="form-check-input"
        style={{ margin: "0 20px 0 5px" }}
        name="optionsRadios"
        id="optionsRadios1"
        disabled={viewOnly}
        value={state}
        checked={checked === state}
        onChange={handleChange(state, flow)}
      />
    );
  };
  return (
    <SlidingPane
      from="left"
      width="417px"
      isOpen={open}
      title={clientCode === "fyw" ? "Please select your plan to continue." : ""}
      subtitle=""
      className="flyin"
      onRequestClose={handler}
    >
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <>
          <div className="slide-pane_header">
            Health plans are listed in alphabetical order.
          </div>
          {list.map((item, index, arr) => {
            //sets character badges
            const ret: ReactNode[] = [];
            const firstChar = item.name.substr(0, 1);
            if (
              index === 0 ||
              (index > 0 && !arr[index - 1].name.startsWith(firstChar))
            ) {
              ret.push(
                <Fragment key={"badge-" + index + item.code}>
                  <h3 style={index > 0 ? { marginTop: "24px" } : {}}>
                    <Badge variant="primary" className="remove-edge">
                      {firstChar.toUpperCase()}
                    </Badge>
                  </h3>
                  <ListGroup>
                    {list
                      .filter((f) => f.name.substr(0, 1) === firstChar)
                      .map((i) => (
                        <ListGroup.Item
                          key={"lg-item-" + i.code}
                          style={{ border: "none" }}
                        >
                          <div
                            className="form-check"
                            style={{ paddingLeft: "0.5em" }}
                          >
                            <label
                              className="form-check-label"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "0.9rem",
                              }}
                            >
                              {!viewOnly && (
                                <Radio
                                  state={
                                    i.name.split(",")[0] ?? i.name.split(" ")[0]
                                  }
                                  flow={i.code}
                                />
                              )}
                              {viewOnly && (
                                <ul className="flyin-bullet__bullet" />
                              )}
                              {i.name}
                            </label>
                          </div>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </Fragment>
              );
            }
            return ret;
          })}
          <div className="flyin-button-container">
            <Button
              variant="outline-primary"
              className="nav-btn-enroll font-weight-bold"
              onClick={handler}
            >
              {viewOnly ? "Close" : "Cancel"}
            </Button>
            {!viewOnly && (
              <Button
                className="nav-btn-enroll font-weight-bold text-nowrap"
                style={{ padding: "12px" }}
                onClick={handleSubmit}
              >
                Continue Eligibility Check{" "}
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            )}
          </div>
        </>
      )}
    </SlidingPane>
  );
};

export default Flyin;
