import React from "react";

export const WhlLogo = () => {
  return (
    <svg
      id="Group_1846"
      data-name="Group 1846"
      xmlns="http://www.w3.org/2000/svg"
      width="90%"
      viewBox="0 0 906.903 198.424"
    >
      <path
        id="Path_657"
        data-name="Path 657"
        d="M158.922,40.087a16.412,16.412,0,0,1,15.024-8.7q7.257,0,11.143,4.5t3.884,13.964V78.162h-5.093V49.71q0-7.488-2.95-10.74a10.432,10.432,0,0,0-8.128-3.254,13.706,13.706,0,0,0-8.721,2.743,15.935,15.935,0,0,0-5.157,7.294V78.162h-5.093V11.948h5.093Z"
        transform="translate(165.28 -8.278)"
        fill="#fff"
      />
      <path
        id="Path_658"
        data-name="Path 658"
        d="M171.971,43.536q0-10.144,5.56-16.64A18.443,18.443,0,0,1,192.263,20.4q9.209,0,14.769,6.493t5.56,16.64v1.4q0,10.185-5.539,16.659T192.346,68.07q-9.254,0-14.815-6.475t-5.56-16.659Zm5.093,1.377a21.834,21.834,0,0,0,4.033,13.4,14.256,14.256,0,0,0,22.389,0,21.742,21.742,0,0,0,4.056-13.4v-1.4A21.662,21.662,0,0,0,203.468,30.2a14.171,14.171,0,0,0-22.37,0,21.817,21.817,0,0,0-4.033,13.313Z"
        transform="translate(188.852 2.706)"
        fill="#fff"
      />
      <path
        id="Path_659"
        data-name="Path 659"
        d="M197.918,78.162h-5.052V11.948h5.052Z"
        transform="translate(216.002 -8.278)"
        fill="#fff"
      />
      <path
        id="Path_660"
        data-name="Path 660"
        d="M217.947,68.07A18.438,18.438,0,0,1,203.62,61.7q-5.708-6.37-5.707-16.13V43.239q0-9.85,5.668-16.344A17.581,17.581,0,0,1,217.31,20.4q8.447,0,13.286,5.348t4.838,14.261v4.33H203.006v1.228a20.238,20.238,0,0,0,4.118,12.961,13.122,13.122,0,0,0,10.823,5.21,22.238,22.238,0,0,0,8.105-1.315,15.938,15.938,0,0,0,5.73-3.778l2.166,3.481a18.384,18.384,0,0,1-6.558,4.332A25.1,25.1,0,0,1,217.947,68.07Zm-.637-43.338a12.162,12.162,0,0,0-9.466,4.3A18.461,18.461,0,0,0,203.388,39.8l.085.209h26.912V38.744a14.631,14.631,0,0,0-3.523-9.991A12.054,12.054,0,0,0,217.31,24.732Z"
        transform="translate(222.56 2.706)"
        fill="#fff"
      />
      <path
        id="Path_661"
        data-name="Path 661"
        d="M263.328,75.668h-5.052V47.229H223.133V75.668h-5.052v-61.8h5.052V42.9h35.143V13.868h5.052Z"
        transform="translate(248.765 -5.783)"
        fill="#fff"
      />
      <path
        id="Path_662"
        data-name="Path 662"
        d="M260.219,68.07A18.43,18.43,0,0,1,245.894,61.7q-5.712-6.37-5.709-16.13V43.239q0-9.85,5.668-16.344A17.589,17.589,0,0,1,259.585,20.4q8.443,0,13.283,5.348t4.84,14.261v4.33h-32.43v1.228A20.252,20.252,0,0,0,249.4,58.53a13.125,13.125,0,0,0,10.823,5.21,22.251,22.251,0,0,0,8.107-1.315,15.976,15.976,0,0,0,5.73-3.778l2.164,3.481a18.394,18.394,0,0,1-6.555,4.332A25.121,25.121,0,0,1,260.219,68.07Zm-.635-43.338a12.155,12.155,0,0,0-9.466,4.3A18.463,18.463,0,0,0,245.66,39.8l.085.209h26.912V38.744a14.621,14.621,0,0,0-3.523-9.991A12.046,12.046,0,0,0,259.585,24.732Z"
        transform="translate(277.486 2.706)"
        fill="#fff"
      />
      <path
        id="Path_663"
        data-name="Path 663"
        d="M289.8,67.178q-.51-2.335-.722-4.033a27.444,27.444,0,0,1-.214-3.438,20.214,20.214,0,0,1-6.79,5.983,19.189,19.189,0,0,1-9.549,2.38q-6.708,0-10.421-3.566a12.609,12.609,0,0,1-3.716-9.549,11.836,11.836,0,0,1,5.5-10.188q5.5-3.822,14.833-3.819h10.145V35.258a9.791,9.791,0,0,0-3.162-7.724q-3.163-2.8-8.848-2.8a14.1,14.1,0,0,0-8.809,2.674,7.986,7.986,0,0,0-3.5,6.537l-4.668-.044-.085-.253q-.255-5.136,4.691-9.19T277.064,20.4q7.6,0,12.226,3.863t4.626,11.078V57.925a39.312,39.312,0,0,0,.276,4.709,29.839,29.839,0,0,0,.913,4.543ZM273.119,63.57A18.177,18.177,0,0,0,282.8,61a15.234,15.234,0,0,0,6.068-6.558V44.851H278.637q-6.922,0-11.06,2.95t-4.137,7.323a7.876,7.876,0,0,0,2.568,6.089A10.109,10.109,0,0,0,273.119,63.57Z"
        transform="translate(301.14 2.706)"
        fill="#fff"
      />
      <path
        id="Path_664"
        data-name="Path 664"
        d="M283.736,78.162h-5.054V11.948h5.054Z"
        transform="translate(327.508 -8.278)"
        fill="#fff"
      />
      <path
        id="Path_665"
        data-name="Path 665"
        d="M296.638,15.658V27.415h10.1v4.162h-10.1V61.5q0,4.5,1.635,6.388a5.458,5.458,0,0,0,4.35,1.888c.821,0,1.619-.034,2.4-.106s1.676-.191,2.695-.361l.763,3.78a13.346,13.346,0,0,1-3.1.848,22.7,22.7,0,0,1-3.647.3,9.532,9.532,0,0,1-7.537-3.056Q291.5,68.122,291.5,61.5V31.576h-7.937V27.415H291.5V15.658Z"
        transform="translate(333.851 -3.458)"
        fill="#fff"
      />
      <path
        id="Path_666"
        data-name="Path 666"
        d="M302.48,40.087a16.413,16.413,0,0,1,15.024-8.7q7.26,0,11.143,4.5t3.886,13.964V78.162H327.44V49.71q0-7.488-2.95-10.74a10.437,10.437,0,0,0-8.13-3.254,13.715,13.715,0,0,0-8.721,2.743,15.968,15.968,0,0,0-5.158,7.294V78.162h-5.093V11.948h5.093Z"
        transform="translate(351.812 -8.278)"
        fill="#fff"
      />
      <g
        id="Group_705"
        data-name="Group 705"
        transform="translate(716.262 8.8)"
      >
        <path
          id="Path_667"
          data-name="Path 667"
          d="M331.648,71.649h30.561v4.327H326.553v-61.8h5.1Z"
          transform="translate(-326.553 -14.179)"
          fill="#fff"
        />
        <path
          id="Path_668"
          data-name="Path 668"
          d="M349.53,67.007h-5.054V21.082h5.054Z"
          transform="translate(-303.265 -5.21)"
          fill="#fff"
        />
        <path
          id="Path_669"
          data-name="Path 669"
          d="M366.927,55.506l1.485,5.39h.255l1.612-5.39,12.479-34.424h5.263L370.576,67.007H366.5L349.015,21.082h5.263Z"
          transform="translate(-297.367 -5.21)"
          fill="#fff"
        />
        <path
          id="Path_670"
          data-name="Path 670"
          d="M373.281,67.007h-5.052V21.082h5.052Z"
          transform="translate(-272.401 -5.21)"
          fill="#fff"
        />
        <path
          id="Path_671"
          data-name="Path 671"
          d="M379.371,21.561l.467,8.022a16.366,16.366,0,0,1,5.964-6.558,16.632,16.632,0,0,1,8.892-2.313q7.471,0,11.375,4.562t3.9,14.24V67.486h-5.091V39.5q0-7.909-2.952-11.186t-8.381-3.274a13.12,13.12,0,0,0-8.724,2.828,15.713,15.713,0,0,0-4.859,7.507V67.486h-5.093V21.561Z"
          transform="translate(-263.768 -5.689)"
          fill="#fff"
        />
        <path
          id="Path_672"
          data-name="Path 672"
          d="M393.385,44.907q0-10.992,4.693-17.592a15.108,15.108,0,0,1,13.049-6.6,16.09,16.09,0,0,1,8.448,2.143,17.178,17.178,0,0,1,5.9,6.047l.591-7.342h4.288V66.893q0,8.871-4.9,14.005t-13.647,5.137a33.266,33.266,0,0,1-7.045-.807,27.476,27.476,0,0,1-6.537-2.207l1.315-4.286a25.9,25.9,0,0,0,5.73,2.06,28.462,28.462,0,0,0,6.452.7q6.7,0,10.122-3.861t3.419-10.74V61.122a16.121,16.121,0,0,1-5.9,5.369,17.538,17.538,0,0,1-8.319,1.888q-8.278,0-12.966-6.155T393.385,45.8Zm5.1.9q0,8.312,3.4,13.279t10.1,4.967a14.115,14.115,0,0,0,8.213-2.272,14.687,14.687,0,0,0,5.072-6.261V34.21a16.171,16.171,0,0,0-4.861-6.6,12.923,12.923,0,0,0-8.34-2.571,11.186,11.186,0,0,0-10.145,5.544q-3.439,5.539-3.438,14.32Z"
          transform="translate(-239.715 -5.689)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_706"
        data-name="Group 706"
        transform="translate(243.248 82.983)"
      >
        <path
          id="Path_673"
          data-name="Path 673"
          d="M183.7,82.386l.382-1.051.057-.31c.2-8.42-2.7-15.052-8.62-19.7-5.845-4.6-13.063-6.926-21.455-6.926-10.319,0-18.535,3.534-24.419,10.5-5.845,6.921-8.809,16.1-8.809,27.282v2.865c0,11.228,2.978,20.43,8.846,27.351,5.909,6.969,14.162,10.5,24.529,10.5a34.851,34.851,0,0,0,20.988-6.627,20.623,20.623,0,0,0,8.94-18.1l-.439-1.354H178.67v.973c0,6.528-2.373,11.281-7.261,14.525a30.479,30.479,0,0,1-17.2,4.95c-9.023,0-15.914-2.982-20.487-8.859-4.631-5.953-6.979-13.814-6.979-23.361V92.181c0-9.459,2.348-17.284,6.981-23.26,4.571-5.9,11.414-8.894,20.338-8.894a27.958,27.958,0,0,1,17.51,5.558c4.776,3.681,7.1,8.859,7.1,15.826v.975Z"
          transform="translate(-120.837 -36.107)"
          fill="#fff"
        />
        <path
          id="Path_674"
          data-name="Path 674"
          d="M186.621,64.731c-6.995,0-13.012,1.541-17.887,4.578a28.442,28.442,0,0,0-9.283,9.388V46.442h-5.909V141.8h5.909l-.014-46.9a32.323,32.323,0,0,1,8.319-17.714c4.332-4.527,10.7-6.822,18.94-6.822,6.1,0,11.025,1.906,14.654,5.668s5.479,10.432,5.479,19.807V141.8h5.983V95.972c0-10.646-2.242-18.59-6.657-23.617C201.707,67.295,195.135,64.731,186.621,64.731Z"
          transform="translate(-78.342 -46.442)"
          fill="#fff"
        />
        <path
          id="Path_675"
          data-name="Path 675"
          d="M218.3,54.4c-10.492,0-19.156,3.566-25.744,10.6-6.572,7.018-9.9,16.162-9.9,27.183v2.865c0,11.069,3.343,20.237,9.94,27.25,6.608,7.038,15.307,10.6,25.852,10.6,10.444,0,19.082-3.566,25.67-10.6,6.569-7.015,9.9-16.183,9.9-27.25V92.181c0-11.021-3.329-20.165-9.9-27.183C237.523,57.962,228.836,54.4,218.3,54.4Zm.147,72.871c-9.094,0-16.415-3.132-21.766-9.308-5.387-6.217-8.117-13.927-8.117-22.913V92.181a33.98,33.98,0,0,1,8.121-22.715c5.351-6.261,12.623-9.439,21.614-9.439,8.944,0,16.217,3.178,21.616,9.441A33.807,33.807,0,0,1,248.1,92.181v2.865c0,8.942-2.729,16.64-8.117,22.878C234.635,124.124,227.387,127.267,218.443,127.267Z"
          transform="translate(-40.519 -36.107)"
          fill="#fff"
        />
        <rect
          id="Rectangle_705"
          data-name="Rectangle 705"
          width="5.908"
          height="75.706"
          transform="translate(223.977 19.654)"
          fill="#fff"
        />
        <path
          id="Path_676"
          data-name="Path 676"
          d="M288.95,82.386l.379-1.051.057-.31c.2-8.42-2.7-15.052-8.62-19.7-5.843-4.6-13.063-6.926-21.455-6.926-10.319,0-18.535,3.534-24.419,10.5-5.845,6.921-8.807,16.1-8.807,27.282v2.865c0,11.228,2.975,20.43,8.843,27.351,5.909,6.969,14.164,10.5,24.529,10.5a34.851,34.851,0,0,0,20.989-6.627,20.628,20.628,0,0,0,8.94-18.1l-.437-1.354h-5.031v.973c0,6.528-2.375,11.281-7.261,14.525a30.492,30.492,0,0,1-17.2,4.95c-9.023,0-15.916-2.982-20.487-8.859-4.631-5.953-6.979-13.814-6.979-23.361V92.181c0-9.459,2.348-17.284,6.979-23.26,4.573-5.9,11.416-8.894,20.34-8.894a27.958,27.958,0,0,1,17.51,5.558c4.776,3.681,7.1,8.859,7.1,15.826v.975Z"
          transform="translate(15.918 -36.107)"
          fill="#fff"
        />
        <path
          id="Path_677"
          data-name="Path 677"
          d="M290.9,54.4a31.718,31.718,0,0,0-24.247,10.71c-6.493,7.084-9.786,16.146-9.786,26.939v4.362c0,10.308,3.408,19.055,10.131,26s14.847,10.487,24.12,10.487a46.538,46.538,0,0,0,16.134-2.55,31.334,31.334,0,0,0,11.759-7.563l.577-.6-3.311-4.461-.8.846a26.079,26.079,0,0,1-9.86,6.5,41.7,41.7,0,0,1-14.5,2.187,26.31,26.31,0,0,1-20.168-8.919,31.5,31.5,0,0,1-8.177-21.94V92.133h59.445V85.5c0-9.411-2.948-17.031-8.763-22.651S300.055,54.4,290.9,54.4Zm0,5.631c7.51,0,13.688,2.414,18.36,7.172a24.8,24.8,0,0,1,7.052,18.029V86.5H263.417a30.517,30.517,0,0,1,8.533-18.76A25.537,25.537,0,0,1,290.9,60.027Z"
          transform="translate(55.91 -36.107)"
          fill="#fff"
        />
        <path
          id="Path_678"
          data-name="Path 678"
          d="M339.874,97.9c-4.663-3.362-11.557-6.04-20.49-7.97-9.377-2-15.693-4.341-18.779-6.951a11.481,11.481,0,0,1-4.493-9.135c0-3.624,1.75-6.753,5.344-9.561,3.622-2.826,9.092-4.258,16.259-4.258,7.22,0,13,1.642,17.178,4.872,4.159,3.226,6.183,7,6.183,11.536v.975h5.033l.377-1.055.055-.29a17.957,17.957,0,0,0-7.567-15.744c-5.185-3.932-12.336-5.925-21.26-5.925-8.289,0-14.994,1.865-19.933,5.546-5.029,3.743-7.576,8.469-7.576,14.038,0,6.084,2.274,10.745,6.76,13.858,4.318,2.994,11.336,5.468,20.851,7.346,8.728,1.773,14.9,4.231,18.344,7.3,3.357,3,5.063,6.342,5.063,9.94,0,3.888-1.984,7.245-6.056,10.26-4.109,3.037-9.784,4.58-16.866,4.58a37.09,37.09,0,0,1-17.215-3.773c-4.7-2.449-7.169-6.312-7.539-11.812l-.06-.911H288.66l-.377,1.055-.055.269c-.363,5.827,2.251,10.825,7.767,14.854,5.394,3.948,12.9,5.948,22.306,5.948,8.568,0,15.565-1.872,20.8-5.564,5.33-3.764,8.034-8.758,8.034-14.84A17.046,17.046,0,0,0,339.874,97.9Z"
          transform="translate(96.619 -36.107)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_707"
        data-name="Group 707"
        transform="translate(757.674 8.806)"
      >
        <path
          id="Path_679"
          data-name="Path 679"
          d="M351.281,21.075a15.794,15.794,0,0,0,.75-6.893c-3.858,1.573-5.79,3.764-6.71,5.859h0a9.452,9.452,0,0,0-.37,6.39,8.05,8.05,0,0,0,6.33-5.353h0v0Z"
          transform="translate(-344.563 -14.182)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_708"
        data-name="Group 708"
        transform="translate(812.109 8.806)"
      >
        <path
          id="Path_680"
          data-name="Path 680"
          d="M374.955,21.075a15.816,15.816,0,0,0,.752-6.893c-3.858,1.573-5.79,3.764-6.71,5.859h0a9.446,9.446,0,0,0-.372,6.39,8.047,8.047,0,0,0,6.33-5.353h0v0Z"
          transform="translate(-368.238 -14.182)"
          fill="#fff"
        />
      </g>
      <g id="Group_711" data-name="Group 711">
        <g id="Group_709" data-name="Group 709" transform="translate(0 34.219)">
          <path
            id="Path_681"
            data-name="Path 681"
            d="M15.047,25.234c12.325,180.533,113.79,136.1,113.79,136.1l-2.9-8.634-9.214-7.99c-67.109,16.877-76.831-80.59-76.831-80.59,13.293,9.092,22.623,18.466,29.195,27.107L79.47,74.58C53.143,44.5,15.047,25.234,15.047,25.234Z"
            transform="translate(-15.047 -25.234)"
            fill="#fff"
          />
        </g>
        <g id="Group_710" data-name="Group 710" transform="translate(72.025)">
          <path
            id="Path_682"
            data-name="Path 682"
            d="M77.587,10.352c-83.3,160.639,26.482,175.41,26.482,175.41l2.007-8.885-3.718-11.612C36.254,144.8,78.606,56.484,78.606,56.484A145.659,145.659,0,0,1,90.167,98.178l17.91-7.753C102.3,50.781,77.587,10.352,77.587,10.352Z"
            transform="translate(-46.371 -10.352)"
            fill="#fff"
          />
        </g>
        <path
          id="Path_683"
          data-name="Path 683"
          d="M89.467,189.558S2.54,120.9,156.084,25.143c0,0,2.1,97.679-49.1,126.9l-7.811-17.719s28.452-16.5,34.274-68.971c0,0-86.237,57.587-30.68,110.127Z"
          transform="translate(49.802 8.867)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_684"
        data-name="Path 684"
        d="M192.45,13.868a58.612,58.612,0,0,0-9.326,16.6l-6.983,27.714-1.994,9.508-.258.044-2.249-9.551L158.567,13.868h-4.8L140.7,58.179l-2.334,9.508-.255-.041-1.867-9.466L124.994,13.868h-5.222l15.875,61.8h4.84l14.008-47.41,1.568-6.493h.258l1.612,6.493,13.879,47.41h4.8l15.875-61.8Z"
        transform="translate(121.028 -5.783)"
        fill="#fff"
      />
      <g
        id="Group_712"
        data-name="Group 712"
        transform="translate(691.567 103.795)"
      >
        <path
          id="Path_685"
          data-name="Path 685"
          d="M315.813,62.458a6.873,6.873,0,0,1,.927-3.472,6.8,6.8,0,0,1,2.548-2.557,6.977,6.977,0,0,1,6.965-.009,6.8,6.8,0,0,1,2.557,2.548,6.968,6.968,0,1,1-13,3.49Zm.862,0a5.968,5.968,0,0,0,.818,3.054,6.126,6.126,0,0,0,2.228,2.228,6.094,6.094,0,0,0,6.109,0,6.126,6.126,0,0,0,2.228-2.228,5.947,5.947,0,0,0,.823-3.058,6.028,6.028,0,0,0-.791-3.01,6.113,6.113,0,0,0-2.214-2.251,5.916,5.916,0,0,0-3.1-.851,5.978,5.978,0,0,0-3.042.819,6.08,6.08,0,0,0-2.237,2.246A5.993,5.993,0,0,0,316.675,62.458Zm8.586-1.731a2.362,2.362,0,0,1-.356,1.292,2.387,2.387,0,0,1-1.023.876l2.2,3.64h-1.122L323.01,63.2h-1.557v3.334h-.934V58.383h1.955a3.248,3.248,0,0,1,2.055.575A2.108,2.108,0,0,1,325.261,60.726Zm-3.808,1.731h1.039a1.917,1.917,0,0,0,1.325-.43,1.6,1.6,0,0,0,.48-1.255q0-1.593-1.823-1.593h-1.021Z"
          transform="translate(-315.813 -55.493)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_686"
        data-name="Path 686"
        d="M87.136,91.543h-3.2V90.389h7.774v1.154H88.5v9.347H87.136Z"
        transform="translate(74.469 93.644)"
        fill="#fff"
      />
      <path
        id="Path_687"
        data-name="Path 687"
        d="M96.832,96.278c-.078-1.465-.17-3.224-.156-4.532H96.63c-.359,1.23-.793,2.539-1.324,3.987l-1.853,5.1H92.425l-1.7-5c-.5-1.481-.917-2.835-1.214-4.081h-.032c-.03,1.308-.108,3.067-.2,4.642L89,100.89H87.7l.731-10.5h1.729l1.794,5.079c.435,1.292.793,2.447,1.058,3.536h.048c.264-1.06.637-2.212,1.106-3.536l1.869-5.079h1.729l.653,10.5H97.1Z"
        transform="translate(79.36 93.644)"
        fill="#fff"
      />
    </svg>
  );
};

export default WhlLogo;
