import { eligibility, enrollment } from "./../graphql/Queries";
import { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  eligibilityStart,
  enrollmentResumeMutation,
  handoffEnrollment,
} from "../graphql/Mutations";
import { useAuthKit } from "@authkitcom/react";
import { fetchAggregatedEnrollment } from "../api/enrollment/Queries";
import { useHistory } from "react-router-dom";
/*
 * Determines whether to start eligibility, handoff to enrollment, or resume enrollment

* TODO: possibly return states instead of pushing history
 *
 */
const useEligibilityStart = (
  client: string,
  onEligible: () => void
): {
  loading: boolean;
  currentEligibility: {
    [key: string]: unknown;
  } | null;
} => {
  const [loading, setLoading] = useState(true);
  const [currentEligibility, setCurrentEligibility] = useState<{
    [key: string]: unknown;
  } | null>(null);
  const { authKit } = useAuthKit();
  const history = useHistory();

  const [startEligibility] = useMutation(eligibilityStart, {
    fetchPolicy: "no-cache",
    onCompleted: async () => {
      await getUpdatedEligibility();
    },
    onError: useCallback(() => {
      setLoading(false);
    }, []),
  });

  const [handoff] = useMutation(handoffEnrollment, {
    onCompleted: () => {
      onEligible();
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const [enrollmentQuery] = useLazyQuery(enrollment, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.enrollment?.state) {
        const state = data.enrollment.state;
        if (state.client !== null) {
          setLoading(false);
        } else {
          startEligibility({
            variables: {
              client: client,
            },
          });
        }
      }
    },
  });

  const [getUpdatedEligibility] = useLazyQuery(eligibility, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCurrentEligibility(data.eligibilityCheck?.state ?? null);
      authKit.removeTokens();
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const [eligibilityQuery] = useLazyQuery(eligibility, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.eligibilityCheck.state.status === "CONFIRMED") {
        handoff({
          variables: {
            dummy: "",
          },
        });
      } else {
        //run this if not the fep flow.
        enrollmentQuery();
      }
    },
  });

  const [enrollmentResume] = useMutation(enrollmentResumeMutation, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      setLoading(false);
      onEligible();
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    (async () => {
      const tokens = authKit?.getTokens()?.accessToken;
      try {
        if (tokens) {
          const res = await fetchAggregatedEnrollment();
          if (res.enrollmentFrame?.current) {
            //already valid member
            if (res.enrollmentFrame?.status === "NONE") {
              enrollmentResume();
            } else if (
              res.enrollmentFrame?.status === "TERMED" &&
              res.enrollmentFrame?.current.sku === "FITNESS-LEGACY"
            ) {
              enrollmentResume();
            } else {
              history.push("/account");
            }
          } else {
            //resume enrollment flow
            enrollmentResume();
          }
        } else {
          //standard eligibility flow
          eligibilityQuery();
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, [authKit]);

  return { loading, currentEligibility };
};

export default useEligibilityStart;
