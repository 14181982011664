import React from "react";
import { useContext } from "react";
import { AccountContext } from "../../context/AccountProvider";

const MembershipCard = () => {
  const { memberCardBase64 } = useContext(AccountContext);
  return (
    <div className="membership-card">
      {memberCardBase64 && (
        <img src={`data:image/png;base64,${memberCardBase64}`} alt="card"/>
      )}
    </div>
  );
};

export default MembershipCard;
