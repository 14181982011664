import React, { Fragment, useContext, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";

import { Link, Route, useHistory } from "react-router-dom";
import HowItWorks from "../pages/HowItWorks";
import Landing from "../pages/Landing";
import Flyin from "../components/Flyin";
import NavBar from "../components/navigation/NavBar";
import NavMenu from "../components/navigation/NavMenu";
import { UserContext, USER_STATUS } from "../context/UserProvider";
import { ButtonContext } from "../context/ButtonProvider";
import { tags } from "../utility/contentful";
// import NotFound from "../pages/NotFound";

/**
 *
 * Routing page for fyw.
 */
const GeneralRouting = () => {
  const { user } = useContext(UserContext);
  const { setButton } = useContext(ButtonContext);

  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleOpen = () => {
    setOpen((open) => !open);
  };

  useEffect(() => {
    setButton({
      text: "Check My Eligibility",
      onClick: handleOpen,
    });
    // eslint-disable-next-line
  }, []);

  const handleLogin = () => {
    history.push("/account");
  };
  return (
    <Fragment>
      <Route exact path="/">
        <Flyin open={open} handler={() => setOpen(false)} clientCode="fyw" />
        <NavBar logo>
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Item>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/how-it-works">
                How It Works
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <NavMenu
            onEligibility={
              user.status === USER_STATUS.PENDING ? handleOpen : undefined
            }
            onEnroll={
              user.status === USER_STATUS.ELIGIBLE ? handleOpen : undefined
            }
            onLogin={handleLogin}
          />
        </NavBar>
        <Landing tags={tags["general"]} onOpen={handleOpen} />
      </Route>
      <Route exact path="/how-it-works">
        <Flyin open={open} handler={() => setOpen(false)} clientCode="fyw" />
        <NavBar logo>
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Item>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/how-it-works">
                How It Works
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <NavMenu
            onEligibility={
              user.status === USER_STATUS.PENDING ? handleOpen : undefined
            }
            onEnroll={
              user.status === USER_STATUS.ELIGIBLE ? handleOpen : undefined
            }
            onLogin={handleLogin}
          />
        </NavBar>
        <HowItWorks tags={tags["general"]} />
      </Route>
    </Fragment>
  );
};

export default GeneralRouting;
