import React from "react";
import Logo from "../resources/FitnessYourWay_4c.png";
import SomethingWentWrongImg from "../resources/SomethingWentWrong.svg";
type Error = {
  message: string;
};
const SomethingWentWrong = ({
  small,
}: {
  error?: Error;
  small?: boolean;
}) => {
  return (
    <div className="not-found-container">
      <div className="not-found-container__body">
        <img
          alt="Fitness Your Way"
          src={Logo}
          width="180"
          className={`d-inline-block align-top ${small ? `mb-2` : `mb-5`}`}
        />
        <img
          src={SomethingWentWrongImg}
          alt="Wrong"
          className={`not-found-container__SomethingWentWrongImg ${
            small ? `mt-2` : `mt-5`
          }`}
        />
        <span className="not-found-container__body__header">
          Something Went Wrong
        </span>

        <p>To resolve this issue please call us at: </p>

        <a
          className="not-found-container__body__phone mb-0"
          href={`tel:1-833-277-6863`}
        >
          1-833-277-6863
        </a>

        <span>Monday – Friday, 7 a.m. – 7 p.m., CT (6 a.m. – 6 p.m. MT)</span>
      </div>
    </div>
  );
};

export default SomethingWentWrong;
