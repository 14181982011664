import React, { useContext } from "react";
import { AccountContext } from "../../context/AccountProvider";
import { momentStartOfDay } from "../../utility/util";

const UpcomingPaymentDisplay = () => {
  const { nextPayment, suspension } = useContext(AccountContext);
  let amount;
  const due = momentStartOfDay(nextPayment?.due).format("MM/DD/YYYY");

  //TODO: need to check if this business logic is true.
  if (!nextPayment) {
    return null;
  }
  // Authenticated member without an active subscription
  if (suspension.status === "TERMED" || suspension.status === "CANCELLED") {
    return null;
  }

  // Authenticated member with an active subscription
  if (nextPayment.amount > 0 && nextPayment.due.length > 0) {
    amount = nextPayment?.amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  return (
    <div className="upcoming-payment-display">
      {!nextPayment.due
        ? "No payment due"
        : nextPayment.amount
        ? `Your next payment of ${amount} will be due on ${due}`
        : `No payment due`}
    </div>
  );
};

export default UpcomingPaymentDisplay;
