import React from "react";
import { FeatureInput, FeatureTestimonialBody } from "../../types";
import FeatureBase from "./FeatureBase";

const FeatureTestimonial = ({ input }: { input: FeatureInput }) => {
  const body = input.body as FeatureTestimonialBody;
  return (
    <>
      <div className="d-none d-sm-none d-md-inline">
        <div
          style={{
            backgroundImage: `url('${input.media}')`,
            //backgroundPosition: leftAlign ? "right" : "left",
            backgroundSize: "cover",
          }}
        >
          <FeatureBase leftAlign={input.left} size="large">
            <div className="feature-testimonial-content">
              <div className="feature-testimonial-content__slanted-container">
                <div
                  style={{ width: "20%" }}
                  className="slanted-feature-right slanted-feature-right__secondary-feature"
                />
              </div>
              <div className="feature-testimonial-content__accent-container">
                <div
                  style={{
                    width: "22%",
                  }}
                  className="slanted-feature-right slanted-feature-right__accent-feature"
                />
              </div>
              <div className="slanted-feature-right slanted-feature-right--white-feature">
                <div>{body.content}</div>
              </div>

              <div className="slanted-feature-right__bottom-bar" />
            </div>
          </FeatureBase>
        </div>
      </div>
      <div className="d-sm-block d-md-none">
        <img src={input.media} className="img-fluid" alt="testimonial"/>
        <div className="slanted-feature-right slanted-feature-right--white-feature">
          <div>{body.content}</div>
        </div>
      </div>
    </>
  );
};

export default FeatureTestimonial;
