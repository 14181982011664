import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ClientConfigContext } from "../../context/ClientConfigProvider";
import SuccessWidget from "./SuccessWidget";
const stepsDigitalLesMills: (string | React.ReactNode)[] = [
  <span key="digital-1">
    Visit your{" "}
    <a href="/account" className="a-link">
      Account Overview
    </a>{" "}
    page for links to our digital content.
  </span>,
  "Choose from our original Prime Live & On-Demand Workouts, LES MILLS On-Demand, and thousands of BurnAlong classes.",
];

const stepsStandard: string[] = [
  "Print or download your card here (or in your Account Overview)",
  "Bring your card to any participating location.",
];

const stepsElite: (string | React.ReactNode)[] = [
  <span key="elite-1">
    Explore all of the Elite locations available in our{" "}
    <Link to="/locations" className="a-link">
      location finder
    </Link>
  </span>,
  'Once you find an elite location that you like, set it as your "Home" location and you are good to go.',
];

const stepsDigital: (string | React.ReactNode)[] = [
  <span key="digital-1">
    Visit your{" "}
    <a href="/account" className="a-link">
      Account Overview
    </a>{" "}
    page for links to our digital content.
  </span>,
  "Choose from our original Prime Live & On-Demand Workouts and thousands of BurnAlong classes.",
];

const SuccessStandard = ({
  entitlements,
  onAccount,
  onMembershipCard,
}: {
  entitlements: string[];
  onAccount: () => void;
  onMembershipCard: () => void;
}) => {
  const { config } = useContext(ClientConfigContext);
  return (
    <Container className="success__container">
      <div className="success-widget">
        <h3>
          <b>Where would you like to get started?</b>
        </h3>
      </div>
      {(entitlements.includes("gym") ||
        entitlements.includes("memberCard")) && (
        <SuccessWidget
          header="Get Member Card"
          steps={stepsStandard}
          buttonText="View My Member Card"
          onClick={onMembershipCard}
        />
      )}
      {entitlements.includes("homeGym") && (
        <SuccessWidget header={"Unlock Elite Access"} steps={stepsElite} />
      )}
      {entitlements.includes("burnalong") ||
      entitlements.includes("lesmills") ? (
        <SuccessWidget
          header={"Explore Digital Content"}
          steps={
            config["lesmills.enable"] == "true"
              ? stepsDigitalLesMills
              : stepsDigital
          }
          buttonText={"View Account Home"}
          onClick={onAccount}
        />
      ) : null}
    </Container>
  );
};

export default SuccessStandard;
