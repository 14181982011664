import { useContext, useEffect, useState } from "react";
import { ImpersonationContext } from "../context/ImpersonationProvider";
//Queries required
const setMasking = async (
  url: string,
  sessionId: string,
  contactId: string,
  accessToken: string
) => {
  const endpoint = `${url}services/v13.0/agent-sessions/${sessionId}/interactions/${contactId}/mask`;
  try {
    const resp = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `bearer ${accessToken}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (resp.ok) {
      return;
    }
    throw new Error("Invalid response");
  } catch (e) {
    throw new Error("Invalid query");
  }
};

const setUnmasking = async (
  url: string,
  sessionId: string,
  contactId: string,
  accessToken: string
) => {
  const endpoint = `${url}services/v13.0/agent-sessions/${sessionId}/interactions/${contactId}/unmask`;
  try {
    const resp = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `bearer ${accessToken}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (resp.ok) {
      return;
    }
    throw new Error("Invalid response");
  } catch (e) {
    throw new Error("Invalid query");
  }
};

const useIncontact = () => {
  const [canContinue, setCanContinue] = useState(true);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { values, isImpersonated } = useContext(ImpersonationContext);

  const setMaskEnabled = async () => {
    try {
      await setMasking(
        values.inContactBaseUri,
        values.sessionId,
        values.contactId,
        values.inContactToken
      );
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const setMaskDisabled = async () => {
    try {
      await setUnmasking(
        values.inContactBaseUri,
        values.sessionId,
        values.contactId,
        values.inContactToken
      );
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  /**
   * If Incontact Session exists and NOT CARENET – member will be able to view card section, complete check out, and any card info section entered must be masked (see Collection SetMasking/SetUnmasking)
   * If Incontact Session exists and IS CARENET – member will be able to view card section, complete check out, inContact session is not needed and masking is not required
   * If Incontact Session does not exist and IS CARENET – same as (b) since inContact session is not required for carenet members
   * If inContact Session does not exist and IS NOT CARENET – eligibility can be checked, sign up can proceed up to billing but an error will prevent CSRs from completing as they must be on a call add billing details.
   */
  useEffect(() => {
    if (isImpersonated) {
      if (values.sessionId === "") {
        if (values.maskingEnabled) {
          //No session No CareNet
          setCanContinue(false);
        } else {
          setLoading(false);
        }
      } else {
        if (values.maskingEnabled) {
          //Session No Carenet
          setMaskEnabled();
        } else {
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  }, [isImpersonated]);
  return { loading, error, canContinue, setMaskDisabled, isImpersonated };
};

export default useIncontact;
