import React, { Fragment, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useFormikContext } from "formik";
import {
  enrollmentStartMutation,
  ENROLLMENT_ACTIONS,
} from "../../../graphql/Mutations";
import Header from "../components/Header";
import PackageCard from "../components/PackageCard";
import { EligibilityFlow, FormikWizardState } from "../types";
import Locations from "../components/Locations";
import skuMap from "../../../utility/skuMap";
import { ErrorBoundary } from "react-error-boundary";
import { useState } from "react";
import StatusModal from "../components/StatusModal";
import FooterButtons from "../components/FooterButtons";
import SomethingWentWrong from "../../../pages/SomethingWentWrong";
import { useContext } from "react";
import { UserContext, USER_STATUS } from "../../../context/UserProvider";
import useRouteQuery from "../../../hooks/useRouteQuery";
import { useEffect } from "react";
import { ProductBundleContext } from "../../../context/ProductBundleProvider";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Package = ({
  previous,
  next,
}: {
  previous?: () => void;
  next?: () => void;
}) => {
  const { values, handleChange, setFieldValue } =
    useFormikContext<FormikWizardState<EligibilityFlow>>();

  const { bundles, loading: loadingBundles } = useContext(ProductBundleContext);

  const query = useRouteQuery();
  const { user } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [accordianOpen, setAccordianOpen] = useState(false);

  //set default package from browser parameter
  useEffect(() => {
    if (values.package?.sku === "") {
      setFieldValue("package.sku", "FITNESS-CORE");
    } else {
      setAccordianOpen(true);
    }
  }, []);
  useEffect(() => {
    Object.keys(skuMap).forEach((s) => {
      if (
        skuMap[s].className.toLowerCase() ===
          query.get("tier")?.toLowerCase() &&
        skuMap[s].display
      ) {
        setFieldValue("package.sku", s);
      }
    });
  }, [query]);

  const [startEnrollment] = useMutation(enrollmentStartMutation, {
    onCompleted: useCallback(
      (data) => {
        const d = { ...data.enrollmentStartEnrollmentAction.state };
        const orderInput = d.orderInput;
        setFieldValue("package.sku", orderInput.sku);
        setFieldValue("package.completed", true);
        if (next) {
          next();
        }
      },
      [setFieldValue, next, values.membership]
    ),
    onError: useCallback(() => {
      setShow(true);
    }, []),
  });

  const handleSubmit = () => {
    startEnrollment({
      variables: {
        action: ENROLLMENT_ACTIONS.NEW,
        sku: values.package?.sku ?? "",
      },
    });
  };

  const handleCancel = () => {
    setShow(false);
  };

  return (
    <Fragment>
      <StatusModal
        show={show}
        status={"ERROR"}
        message=""
        onCancel={handleCancel}
        onNext={() => {
          return;
        }}
      />

      <div className="package-step-container row">
        <div
          className={`package-selection-container col ${
            accordianOpen ? `` : `collapsed`
          }`}
        >
          <Header
            title="What fitness package are you interested in?"
            subtitle="Please confirm your selection below."
          />
          <div className="package-card-container row">
            {Object.keys(skuMap).map((p, index) => {
              if (skuMap[p].display) {
                return (
                  <div
                    className={
                      skuMap[p].className === "digital"
                        ? `${skuMap[p].className} row`
                        : `${skuMap[p].className} col mb-4`
                    }
                    key={index}
                  >
                    <PackageCard
                      {...skuMap[p]}
                      price={
                        bundles?.data.find((b) => b.sku === p)?.periodicPrice ??
                        0
                      }
                      sku={p}
                      handleChange={handleChange}
                      selected={values.package!.sku}
                      loading={loadingBundles}
                    />
                    {skuMap[p].recommended && accordianOpen ? (
                      <div id="recommended">Recommended!</div>
                    ) : null}
                  </div>
                );
              }
            })}
            {!accordianOpen && (
              <div
                id="view-all-button"
                onClick={() => setAccordianOpen(!accordianOpen)}
              >
                <span>
                  VIEW ALL <FontAwesomeIcon icon={faChevronRight} />
                </span>
              </div>
            )}
          </div>
          {/* )} */}
        </div>
        <div
          className="map-container col-4 justify-content-center align-items-center d-none d-lg-flex"
          style={{}}
        >
          <ErrorBoundary FallbackComponent={SomethingWentWrong}>
            <Locations
              packageSelected={
                values.package?.sku === undefined || values.package?.sku === ""
                  ? "ELITE"
                  : values.package?.sku === "Digital Content Only"
                  ? "Digital Content Only"
                  : skuMap[values.package!.sku].name
              }
            />
          </ErrorBoundary>
        </div>
      </div>
      <FooterButtons
        onSubmit={handleSubmit}
        onBack={user.status !== USER_STATUS.ELIGIBLE ? previous : undefined}
        submitDisabled={values.package!.sku === ""}
      />
    </Fragment>
  );
};

export default Package;
