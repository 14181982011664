import React, { useState, useEffect, Fragment } from "react";
import ActivityFilters from "./ActivityFilters";
import ActivityList from "./ActivityList";
import { ActivityPagination } from "./ActivityPagination";
// import UpcomingPaymentDisplay from "../../account/UpcomingPaymentDisplay";
import { fetchActivities } from "../../../api/enrollment/Queries";
import { momentStartOfDay } from "../../../utility/util";

export interface IActivity {
  activeLivingMemberId: string;
  class?: string;
  location: string;
  time: string;
  date: string;
  type: string;
  visitType: string;
}
type IState = IActivity[];

const ActivityLog = () => {
  const start = momentStartOfDay();
  const end = momentStartOfDay();
  const defaultStartDate = start.subtract(1, "y").format("l");
  const defaultEndDate = end.format("l");
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState(defaultStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(defaultEndDate);
  const [selectedActivities, setSelectedActivities] = useState<IState>([]);
  const [loadingActivityLog, setLoadingActivityLog] = useState(true);
  const getActivities = async (endDate?: string, startDate?: string) => {
    try {
      setLoadingActivityLog(true);
      const activityList = await fetchActivities(startDate, endDate);
      setSelectedActivities(activityList.activities);
    } catch (error) {
      setSelectedActivities([]);
      throw error;
    } finally {
      setLoadingActivityLog(false);
    }
  };
  const clearFilter = () => {
    setCurrentPageNum(1);
    setSelectedStartDate(defaultStartDate);
    setSelectedEndDate(defaultEndDate);
    getActivities(defaultEndDate, defaultStartDate);
  };

  const applyFilters = () => {
    getActivities(selectedEndDate, selectedStartDate);
  };

  useEffect(() => {
    getActivities(selectedEndDate, selectedStartDate);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currentActivityPage")) {
      const currentActivityPage = localStorage.getItem("currentActivityPage");
      setCurrentPageNum(Number(currentActivityPage));
    }
    return () => {
      localStorage.removeItem("currentActivityPage");
    };
  }, []);

  return (
    <Fragment>
      <div className="my-3 mx-4 d-flex p-4 bg-white rounded-sm shadow flex-grow-1 flex-column">
        <ActivityFilters
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          applyFilters={applyFilters}
          clearFilter={clearFilter}
          setCurrentPageNum={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
        {loadingActivityLog ? (
          <div className="d-flex justify-content-center align-items-center m-5">
            <h3>...Fetching Your Activity Logs</h3>
          </div>
        ) : !selectedActivities || selectedActivities.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center m-5">
            <h3>No activities reported at this time.</h3>
          </div>
        ) : selectedActivities && selectedActivities.length > 0 ? (
          <>
            <ActivityList
              activities={selectedActivities}
              currentPageNum={currentPageNum}
            />
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center m-5">
            <h3>No activities reported at this time.</h3>
          </div>
        )}
        {!loadingActivityLog && selectedActivities?.length > 0 && (
          <ActivityPagination
            activities={selectedActivities}
            currentPageNum={currentPageNum}
            setCurrentPageNum={setCurrentPageNum}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ActivityLog;
