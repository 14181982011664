import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Spinner } from "react-bootstrap";
import { UserContext, USER_STATUS } from "../../context/UserProvider";
import AccountRequiredWrapper from "./AccountRequiredWrapper";
import AuthWrapper from "./AuthWrapper";

/**
 *
 * @description requires an authkit token
 */
const EligibilityWrapper = ({ children }: { children: React.ReactElement }) => {
  const { user } = useContext(UserContext);

  const [userEligible, setUserEligible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setUserEligible(
      USER_STATUS[user.status] === "ELIGIBLE" ||
        USER_STATUS[user.status] === "EXISTS"
        ? true
        : false
    );
    setLoading(false);
  }, []);
  if (loading)
    return (
      <div className="center-loading">
        <Spinner animation="border" />
      </div>
    );

  if (userEligible) {
    return <div>{children}</div>;
  } else {
    return (
      <div>
        <AuthWrapper>
          <AccountRequiredWrapper>{children}</AccountRequiredWrapper>
        </AuthWrapper>
      </div>
    );
  }
};

export default EligibilityWrapper;
