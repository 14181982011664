import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Collapse } from "react-bootstrap";

const PromoCollapseHeader = ({
  initial,
  header,
  children,
  promoState,
}: {
  initial?: boolean;
  header: string;
  children?: React.ReactElement;
  promoState: boolean;
}) => {
  const [open, setOpen] = useState(initial ?? false);
  const [open2, setOpen2] = useState(!open);

  return (
    <>
      {promoState ? (
        <>
          {open === false ? (
            <div className="mb-2">
              <div
                onClick={() => {
                  setOpen((o) => !o);
                  setOpen2((o) => !o);
                }}
                className="d-flex justify-content-between promo-collapse-header"
              >
                <span>
                  {children && open2 ? (
                    <FontAwesomeIcon icon={faPlusCircle} />
                  ) : (
                    <></>
                  )}{" "}
                  {children && open2 ? header : ""}
                </span>
              </div>
              {children && (
                <Collapse in={open}>
                  <div>
                    <div className="promo-collapse-header__content">
                      {children}
                    </div>
                  </div>
                </Collapse>
              )}
            </div>
          ) : (
            <div className="mb-2">
              <div
                onClick={() => {
                  setOpen((o) => !o);
                  setOpen2((o) => !o);
                }}
                className="d-flex justify-content-between promo-collapse-header"
              >
                <span>
                  {children && open2 ? (
                    <FontAwesomeIcon icon={faPlusCircle} />
                  ) : (
                    <></>
                  )}{" "}
                  {children && open2 ? header : ""}
                </span>
              </div>
              {children && (
                <Collapse in={open}>
                  <div>
                    <div className="promo-collapse-header__content">
                      {children}
                    </div>
                  </div>
                </Collapse>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {open === true ? (
            <div className="mb-2">
              <div
                onClick={() => {
                  setOpen((o) => !o);
                  setOpen2((o) => !o);
                }}
                className="d-flex justify-content-between promo-collapse-header"
              >
                <span>
                  {children && open ? (
                    <FontAwesomeIcon icon={faPlusCircle} />
                  ) : (
                    <></>
                  )}{" "}
                  {children && open ? header : ""}
                </span>
              </div>
              {children && (
                <Collapse in={open2}>
                  <div>
                    <div className="promo-collapse-header__content">
                      {children}
                    </div>
                  </div>
                </Collapse>
              )}
            </div>
          ) : (
            <div className="mb-2">
              <div
                onClick={() => {
                  setOpen((o) => !o);
                  setOpen2((o) => !o);
                }}
                className="d-flex justify-content-between promo-collapse-header"
              >
                <span>
                  {children && open ? (
                    <FontAwesomeIcon icon={faPlusCircle} />
                  ) : (
                    <></>
                  )}{" "}
                  {children && open ? header : ""}
                </span>
              </div>
              {children && (
                <Collapse in={open2}>
                  <div>
                    <div className="promo-collapse-header__content">
                      {children}
                    </div>
                  </div>
                </Collapse>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PromoCollapseHeader;
