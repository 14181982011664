import React from "react";
import { Spinner } from "react-bootstrap";

const PlanListItem = ({
  header,
  body,
  footer,
  subFooter,
  italicize,
  loading,
}: {
  header: string;
  body: string;
  footer: string;
  subFooter?: string;
  italicize?: boolean;
  loading: boolean;
}) => {
  return (
    <div className="manage-plans__information-flex">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <h6 className="text-muted text-uppercase">
            <small>{header}</small>
          </h6>
          <h5>{italicize ? <i>{body}</i> : body}</h5>
          <small>{footer}</small>
          <p className="sub-footer">
            <small>{subFooter}</small>
          </p>
        </>
      )}
    </div>
  );
};

export default PlanListItem;
