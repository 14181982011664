import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import useGetAmenities from "../hooks/useGetAmenities";
import useGetLocations from "../hooks/useGetLocations";
import useGetTiers from "../hooks/useGetTiers";
import useGetTierCount from "../hooks/useGetTierCount";
import { LocationData } from "../api/platform/Queries";
import { AccountContext } from "./AccountProvider";
import useGetGeoFromBrowser from "../hooks/useGetGeoFromBrowser";

export const LocationsContext = React.createContext({});

type State = {
  bounds: unknown;
  locationDetail: unknown;
  locationHover: unknown;
  searchCenter: unknown;
  tiersList: unknown;
  amenitiesList: unknown;
  programCodes: unknown;
  visibleLocations: unknown;
  currentAmenities: unknown;
  currentTier: unknown;
  radius: unknown;
  womenOnlyFilter: unknown;
};

type Action = {
  type: string;
} & Partial<State>;

// TODO: Setup config for locations

const LocationsProvider = ({
  packageSelected,
  children,
}: {
  packageSelected: string;
  children: React.ReactNode;
}) => {
  const LocationsReducer = (
    state: State,
    {
      type,
      bounds,
      locationDetail,
      locationHover,
      searchCenter,
      tiersList,
      amenitiesList,
      programCodes,
      visibleLocations,
      currentAmenities,
      currentTier,
      radius,
      womenOnlyFilter,
    }: Action
  ): State => {
    switch (type) {
      // Only set complex state in reducer i.e. Arrays & Objects.
      // Integers, Strings, and Booleans should use useState
      case "SET_BOUNDS":
        return { ...state, bounds };
      case "SET_LOCATION_DETAIL":
        return { ...state, locationDetail };
      case "SET_LOCATION_HOVER":
        return { ...state, locationHover };
      case "SET_SEARCH_CENTER":
        return { ...state, searchCenter };
      case "SET_VISIBLE_LOCATIONS":
        return { ...state, visibleLocations };
      case "SET_PROGRAM_CODES":
        return { ...state, programCodes };
      case "SET_CURRENT_TIER":
        return { ...state, currentTier };
      case "SET_CURRENT_AMENITIES":
        return { ...state, currentAmenities };
      case "SET_RADIUS":
        return { ...state, radius };
      case "SET_WOMEN_ONLY_FILTER":
        return { ...state, womenOnlyFilter };
      case "SET_AMENITIES":
        return { ...state, amenitiesList };
      case "SET_TIERS":
        return { ...state, tiersList };
      default: {
        throw new Error(`Unhandled action type: ${type}`);
      }
    }
  };
  const { refresh, ...rest } = useContext(AccountContext);

  const [
    {
      bounds,
      locationDetail,
      locationHover,
      searchCenter,
      tiersList,
      amenitiesList,
      programCodes,
      visibleLocations,
      currentAmenities,
      currentTier,
      radius,
      womenOnlyFilter,
    },
    dispatch,
  ] = useReducer(LocationsReducer, {
    // Default state
    bounds: {},
    locationDetail: {},
    locationHover: {},
    searchCenter: null,
    visibleLocations: [],
    tiersList: null,
    amenitiesList: null,
    currentTier: packageSelected
      ? packageSelected
      : rest.locationsTier ?? "ELITE",
    currentAmenities: [],
    radius: 20,
    programCodes: null,
    womenOnlyFilter: false,
  });
  const [lookupAddress, setLookupAddress] = useState("Current Location");
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasValidSearch, setHasValidSearch] = useState("initial");
  const [programCode, setProgramCode] = useState();
  const [noResults, setNoResults] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  //const { program: dataProgram, tier: dataTier, apiKey } = ROOT_EL.dataset;

  //const { getApiToken, apiToken, getIsApiTokenValid } = useGetApiToken(apiKey);
  const { getLocations, locations, setLocations, error, geoCode } =
    useGetLocations({
      tier: currentTier as string,
      amenities: currentAmenities as string[],
      radius: radius as number,
      womenOnlyFilter: womenOnlyFilter as boolean,
    });

  const { getTierCount, tierCount } = useGetTierCount();

  const { geo, getCurrentLocation, geoDisabled, geoError } =
    useGetGeoFromBrowser();

  const tiers = useGetTiers();

  const amenities = useGetAmenities();

  useEffect(() => {
    if (tiers) {
      dispatch({ type: "SET_TIERS", tiersList: tiers });
      if (packageSelected !== undefined) {
        const tierIndex = tiers.indexOf(packageSelected.toUpperCase());
        if (tierIndex >= 0) {
          dispatch({ type: "SET_CURRENT_TIER", currentTier: tiers[tierIndex] });
        } else {
          dispatch({ type: "SET_CURRENT_TIER", currentTier: tiers[2] });
        }
      } else {
        dispatch({ type: "SET_CURRENT_TIER", currentTier: tiers[3] });
      }
    }
    // eslint-disable-next-line
  }, [packageSelected, tiers]);

  useEffect(() => {
    dispatch({ type: "SET_AMENITIES", amenitiesList: amenities });
  }, [amenities]);

  // Set search center from Ip Address
  useEffect(() => {
    dispatch({ type: "SET_SEARCH_CENTER", searchCenter: geo });
  }, [geo]);

  const setProgramCodes = (programCodes: unknown) => {
    dispatch({ type: "SET_PROGRAM_CODES", programCodes });
  };
  // Get locations when a new valid search is entered
  useEffect(() => {
    (async () => {
      if (isMapLoaded && hasValidSearch) {
        try {
          if (lookupAddress === "Current Location") {
            await getLocations.run(geo.lat, geo.lng);
          } else {
            await getLocations.runWithAddress(lookupAddress);
          }
        } catch (err: unknown) {
          return;
        }
      }
    })();
    // eslint-disable-next-line
  }, [
    isMapLoaded,
    hasValidSearch,
    programCode,
    lookupAddress,
    radius,
    currentAmenities,
    currentTier,
    womenOnlyFilter,
  ]);

  // Set Loading and No Results states once we have locations
  useEffect(() => {
    if (!error && locations && tiersList) {
      setLoading(false);
      setNoResults(!(locations.length > 0));
    }
  }, [locations, error, tiersList]);

  useEffect(() => {
    dispatch({
      type: "SET_SEARCH_CENTER",
      searchCenter: { lat: geoCode.latitude, lng: geoCode.longitude },
    });
  }, [geoCode]);

  const setVisibleLocations = useCallback(
    (bounds) => {
      const getVisibleLocations = () => {
        // Set default locations to all of them
        let newLocations = locations.slice();

        // Use selected location
        if (Object.keys(locationDetail as Record<string, unknown>).length) {
          newLocations = [locationDetail as LocationData];
        }

        if (newLocations.length > 0) {
          return newLocations.filter((location) =>
            bounds.methods.contains({
              lat: location.latitude,
              lng: location.longitude,
            })
          );
        }
        return newLocations;
      };
      dispatch({
        type: "SET_VISIBLE_LOCATIONS",
        visibleLocations: getVisibleLocations(),
        bounds,
      });
    },
    [locations, locationDetail]
  );
  const setNoLocations = () => {
    dispatch({
      type: "SET_VISIBLE_LOCATIONS",
      visibleLocations: [],
      // bounds,
    });
  };

  useEffect(() => {
    if (isMapLoaded) {
      setVisibleLocations(bounds);
    }
  }, [bounds, locations, isMapLoaded, setVisibleLocations]);

  useEffect(() => {
    if (geoDisabled && isMapLoaded) {
      //searchModal displayed if users geolocation is disabled and map has finished loading.
      setSearchModalOpen(true);
    } else if (!geoDisabled && isMapLoaded && hasValidSearch === "initial") {
      //If we have users geolocation, map is ready and this is the first render, go ahead and get results for their geolocation.
      (async () => {
        try {
          await getLocations.run(geo.lat, geo.lng);
        } catch (err: unknown) {
          return;
        }
      })();
    }
    // eslint-disable-next-line
  }, [geoDisabled, isMapLoaded, geo]);

  useEffect(() => {
    if (isMapLoaded) {
      (async () => {
        try {
          await getTierCount.run(packageSelected as string);
        } catch (err: unknown) {
          return;
        }
      })();
    }
    // eslint-disable-next-line
  }, [packageSelected]);

  useEffect(() => {
    if (isMapLoaded) {
      (async () => {
        try {
          await getTierCount.run(currentTier as string);
        } catch (err: unknown) {
          return;
        }
      })();
    }
    // eslint-disable-next-line
  }, [currentTier, isMapLoaded]);

  return (
    <LocationsContext.Provider
      value={{
        loading,
        setLoading,
        isMapLoaded,
        setIsMapLoaded,
        bounds,
        setBounds: (bounds: unknown) =>
          dispatch({ type: "SET_BOUNDS", bounds }),
        searchCenter,
        setSearchCenter: (searchCenter: unknown) =>
          dispatch({ type: "SET_SEARCH_CENTER", searchCenter }),
        hasValidSearch,
        setHasValidSearch,
        locations,
        setLocations,
        visibleLocations,
        setVisibleLocations,
        setNoLocations,
        locationDetail,
        setLocationDetail: (locationDetail: unknown) =>
          dispatch({ type: "SET_LOCATION_DETAIL", locationDetail }),
        locationHover,
        setLocationHover: (locationHover: unknown) =>
          dispatch({ type: "SET_LOCATION_HOVER", locationHover }),
        clearLocations: (validSearch: unknown) => {
          setLocations([]);
          setHasValidSearch(validSearch ? (validSearch as string) : "initial");
          setNoResults(false);
          dispatch({ type: "SET_LOCATION_DETAIL", locationDetail: {} });
        },
        tiersList,
        tierCount,
        amenitiesList,
        programCode,
        setProgramCode,
        programCodes,
        setProgramCodes,
        noResults,
        setNoResults,
        getCurrentLocation,
        setLookupAddress,
        setTier: (tier: string) =>
          dispatch({ type: "SET_CURRENT_TIER", currentTier: tier }),
        setAmenities: (amenities: string[]) =>
          dispatch({
            type: "SET_CURRENT_AMENITIES",
            currentAmenities: amenities,
          }),
        setRadius: (radius: number) =>
          dispatch({ type: "SET_RADIUS", radius: radius }),
        setWomenOnlyFilter: (womenOnlyFilter: boolean) =>
          dispatch({
            type: "SET_WOMEN_ONLY_FILTER",
            womenOnlyFilter: womenOnlyFilter,
          }),
        currentTier,
        currentAmenities,
        radius,
        womenOnlyFilter,
        searchModalOpen,
        setSearchModalOpen,
        geoError,
        account: rest,
        refresh: refresh,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};

export default LocationsProvider;
