import React, { useContext, useEffect } from "react";
// import { Helmet } from "react-helmet";
import { ClientConfigContext } from "../../context/ClientConfigProvider";
import TagManager from "react-gtm-module";
const GoogleAnalytics = () => {
  const { config } = useContext(ClientConfigContext);
  const googleAnalyticsKey = config["google.analytics.key"];

  const tagManagerArgs = {
    gtmId: googleAnalyticsKey,
  };
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, [googleAnalyticsKey]);
  return <div></div>;
};

export default GoogleAnalytics;
