import { fetchWrapper } from "../../utility/util";

type Address = {
  id?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
};
type PasswordChange = {
  result: boolean;
  error: string;
  code: number;
  message: string;
};
type PasswordChangeError = {
  error: string;
  code: number;
  message: string;
  result: boolean;
};
type PersonNameChange = {
  status : string;
}

type BirthdateChange = {
  status : string;
}
//probably throw an error instead of catching here
export const setAddressQuery = async (
  { id, address1, address2, city, state, zip }: Address,
  defaultFor: string[]
): Promise<Address> => {
  try {
    const res = await fetchWrapper("/api/identity/v1/me/addresses", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zip: zip,
        defaultFor: defaultFor,
      }),
    });
    if (res.status !== 200) {
      throw new Error("Bad request");
    }
    const json = await res.json();
    return json as Address;
  } catch (e: unknown) {
    throw new Error("Error setting address");
  }
};

export const changePassword = async ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}): Promise<PasswordChange | PasswordChangeError> => {
  try {
    const res = await fetchWrapper("/api/identity/v1/me/password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        currentPassword,
        newPassword,
      }),
    });
    if (res.status === 200) {
      return Promise.resolve({
        result: true,
        error: "",
        code: 200,
        message: "",
      });
    }
    if (res.status !== 200) {
      if (res.status === 500) {
        const json = await res.json();
        if (json.code === 13) {
          return json as PasswordChangeError;
        }
      }
      throw new Error("Bad request");
    }
    return Promise.reject("Something else went wrong");
  } catch (e: unknown) {
    throw new Error("Error Changing Password");
  }
};

export const changeUsernameMigration = async (email: string): Promise<void> => {
  try {
    const res = await fetchWrapper("/api/identity/v1/me/confirmLegacy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newEmailAddress: email,
      }),
    });
    if (res.ok) {
      return Promise.resolve();
    }
    const error = await res.json();
    return Promise.reject(error);
  } catch (e: unknown) {
    return Promise.reject({ code: 0 });
  }
};

export const setTermsAndConditions = async (
  terms: string[],
  options?: {
    email: string;
    firstName: string;
    lastName: string;
    dob: string;
  }
): Promise<void> => {
  try {
    let body: Record<string, unknown> = {
      name: terms,
    };
    if (options) {
      body = {
        name: terms,
        email: options.email,
        firstName: options.firstName,
        lastName: options.lastName,
        dob: options.dob,
      };
    }
    const res = await fetchWrapper("/api/identity/v1/terms-and-conditions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (res.ok) {
      return Promise.resolve();
    }
  } catch (e: unknown) {
    return Promise.reject(e);
  }
};

export const changeDateOfBirth = async (dateOfBirth: string): Promise<BirthdateChange> => {
  try {
    const res = await fetchWrapper("/api/identity/v1/me/birthdate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        birthdate: dateOfBirth,
        hasBirthdate: true
      }),
    });
    if (res.ok) {
      const json = await res.json();
      return json as BirthdateChange;
    }
    const error = await res.json();
    return Promise.reject(error);
  } catch (e: unknown) {
    return Promise.reject({ code: 0 });
  }
}

export const changePersonName = async (firstName: string, lastName: string): Promise<PersonNameChange> => {
  try {
    const res = await fetchWrapper("/api/identity/v1/me/name", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName
      }),
    });
    if (res.ok) {
      const json = await res.json();
      return json as PersonNameChange;
    }
    const error = await res.json();
    return Promise.reject(error);
  } catch (e: unknown) {
    return Promise.reject({code: 0});
  }
}