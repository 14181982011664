import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ButtonContext } from "../../context/ButtonProvider";
import {
  UserContext,
  USER_FROM,
  USER_STATUS,
} from "../../context/UserProvider";
import { FeatureDefaultBody, FeatureInput } from "../../types";
import FeatureBase from "./FeatureBase";
import FeatureButton from "./FeatureButton";
import FeatureButtonInverted from "./FeatureButtonInverted";

const Feature = ({ input }: { input: FeatureInput }) => {
  const { button } = useContext(ButtonContext);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const TextArea = () => {
    const body: FeatureDefaultBody = input.body as FeatureDefaultBody;
    if (input.slanted) {
      return (
        <div className="slanted-feature-right slanted-feature-right--default">
          <div className="feature-content">
            {body.text}
            <div className="d-flex align-items-center">
              {input.button && (
                <FeatureButton
                  contrast
                  text={
                    input.buttonText &&
                    (user.status === USER_STATUS.ELIGIBLE ||
                      user.from === USER_FROM.FEP)
                      ? input.buttonText
                      : button.text
                  }
                  onClick={
                    (input.buttonUrl && user.status === USER_STATUS.ELIGIBLE) ||
                    user.from === USER_FROM.FEP
                      ? () => history.push(input.buttonUrl)
                      : button.onClick
                  }
                />
              )}
              {user.from === USER_FROM.DEFAULT &&
                window.location.pathname !== "/how-it-works" && (
                  <FeatureButtonInverted
                    chevron={false}
                    text={"How It Works"}
                    onClick={() => history.push("how-it-works")}
                    margin
                  />
                )}
              {user.from === USER_FROM.REDEMPTION && (
                <FeatureButtonInverted
                  chevron={false}
                  text={
                    user.status === USER_STATUS.ELIGIBLE
                      ? "Explore Locations"
                      : button.text
                  }
                  onClick={() => history.push("locations")}
                  margin
                />
              )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="feature-content large">
        {body.text}
        {input.button && (
          <FeatureButton
            text={
              input.buttonText &&
              (user.status === USER_STATUS.ELIGIBLE ||
                user.from === USER_FROM.FEP)
                ? input.buttonText
                : button.text
            }
            onClick={
              (input.buttonUrl && user.status === USER_STATUS.ELIGIBLE) ||
              user.from === USER_FROM.FEP
                ? () => history.push(input.buttonUrl)
                : button.onClick
            }
          />
        )}
      </div>
    );
  };
  const Alignment = () => {
    if (input.left) {
      return [<TextArea key="1-align" />, <div key="0-align" />];
    }
    return [<div key="0-align" />, <TextArea key="1-align" />];
  };
  return (
    <FeatureBase
      background={`https://${input.media?.slice(2)}`}
      leftAlign={input.left}
      size={input.size ?? "small"}
      slanted={input.slanted}
      backgroundColor={input.backgroundColor}
      featureClassName={input.featureClassName}
      featureIndex={input.featureIndex}
    >
      {Alignment()}
    </FeatureBase>
  );
};

export default Feature;
