import React from "react";
import { Card } from "react-bootstrap";

const FooterCard = ({
  children,
  inline,
}: {
  children: React.ReactChild | React.ReactChildren;
  inline?: boolean;
}) => {
  return (
    <Card className={inline ? "footer-card-non-fixed" : "footer-card"}>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

export default FooterCard;
