import React from "react";

import { useTheme } from "react-jss";

import Map from "../Map";
import FEPSidePane from "../SidePane/FEPSidePane";
import Filters from "../Filters";
import useStyles from "./styles";
import LocationsProvider from "../../../context/LocationsProvider";
import ModalSearch from "../ModalSearch";

const AppContainer = (props) => {
  const classes = useStyles({ ...props, theme: useTheme() });

  return (
    <LocationsProvider packageSelected={"POWER"}>
      <div className={classes.locationFinder}>
        <FEPSidePane />
        <div className={classes.locationFinderMapSearch}>
          <ModalSearch />
          <Filters />
          <Map />
        </div>
      </div>
    </LocationsProvider>
  );
};

export default AppContainer;
