import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import { AccountContext } from "../../context/AccountProvider";

/**
 * requires a valid aggregated enrollment to render children
 */
const AccountRequiredWrapper = ({
  children,
}: {
  children: React.ReactChildren | React.ReactChild;
}) => {
  const { error, loading } = useContext(AccountContext);

  if (loading)
    return (
      <div className="center-loading">
        <Spinner animation="border" />
      </div>
    );

  if (error) throw new Error();

  return <>{children}</>;
};

export default AccountRequiredWrapper;
