import React from "react";
import { Route } from "react-router";

/**
 * All routes that share a common nav bar
 */
const routes: string[] = [
  "/enroll",
  "/privacy-policy",
  "/agreement",
  "/success",
];
const StandaloneRoutes = ({ children }: { children: React.ReactNode }) => {
  return <Route path={routes}>{children}</Route>;
};
export default StandaloneRoutes;
