import React, { useContext, Fragment, useState, useEffect } from "react";
import { Container, Nav } from "react-bootstrap";
import { Switch } from "react-router";
import { Link, Route, useHistory } from "react-router-dom";
import HowItWorks from "../pages/HowItWorks";
import Landing from "../pages/Landing";
import Flyin from "../components/Flyin";
import useNotification from "../hooks/useNotification";
import NavBar from "../components/navigation/NavBar";
import NavMenu from "../components/navigation/NavMenu";
import { ButtonContext } from "../context/ButtonProvider";
import { tags } from "../utility/contentful";
import NavBranding from "../components/navigation/NavBranding";
import FAQ from "../pages/FAQ";
import StandaloneRoutes from "../components/shared/StandaloneRoutes";
import PoweredBy from "../components/shared/PoweredBy";
import StatusModal from "../components/wizard/components/StatusModal";
import FEPLocations from "../pages/FEPLocations";
import ProgramAgreement from "../pages/ProgramAgreement";
import { useLazyQuery } from "@apollo/client";
import { basicInformationFromB365 } from "../graphql/Queries";
import { setTermsAndConditions } from "../api/identity/Queries";
import { POLICY_TYPES } from "../components/wizard/model/InitialState";
import PromotionalBanner from "../components/wizard/components/PromotionalBanner";
import { UserContext, USER_STATUS } from "../context/UserProvider";

/**
 *
 * Routing page for Bcbsa.
 */
const BcbsaRouting = ({ authenticated }: { authenticated: boolean }) => {
  const { setButton } = useContext(ButtonContext);
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { clear, acknowledge, values } = useNotification();
  //const { authKit } = useAuthKit();

  const handleOpen = () => {
    setOpen((open) => !open);
  };
  const handleLogin = () => {
    clear();
    history.push("/account");
  };
  const handleEnroll = () => {
    clear();
    history.push("/enroll");
  };

  useEffect(() => {
    if (authenticated) {
      setButton({
        text: "Enroll Now",
        onClick: handleEnroll,
      });
    } else {
      setButton({
        text: "Check My Eligibility",
        onClick: handleEnroll,
      });
    }
    // eslint-disable-next-line
  }, []);

  const [getBasicInformation] = useLazyQuery(basicInformationFromB365);

  const handleSubmit = async () => {
    try {
      const res = await getBasicInformation();
      if (res.data.eligibilityCheck.state) {
        const state = res.data.eligibilityCheck.state.profile;
        await setTermsAndConditions([POLICY_TYPES.PRIVACY], {
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          dob: state.dob,
        });
        acknowledge();
      }
    } catch (error) {
      throw new Error("Error accepting terms and conditions");
    }
  };

  return (
    <Fragment>
      <PromotionalBanner
        tags={tags["bcbsa"] + (authenticated ? "Authenticated" : "")}
      />
      {/* setup nav bars per route */}
      <StandaloneRoutes>
        <NavBar logo>
          <NavBranding provider={tags["bcbsa"]} alt="BCBSA" />
        </NavBar>
      </StandaloneRoutes>
      <Route exact path={["/", "/how-it-works", "/faqs"]}>
        <Flyin
          open={open}
          handler={() => setOpen(false)}
          viewOnly
          clientCode="bcbsa"
        />
        <div className="d-none d-sm-none d-md-block sticky-top">
          <NavBar logo>
            <NavBranding provider={tags["bcbsa"]} alt="BCBSA" />
          </NavBar>
        </div>
        <NavBar pbLogo second>
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Item>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to={`/how-it-works`}>
                How It Works
              </Nav.Link>
            </Nav.Item>
            {authenticated && (
              <Fragment>
                <Nav.Item>
                  <Nav.Link as={Link} to="/locations">
                    Locations
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to={`/faqs`}>
                    FAQs
                  </Nav.Link>
                </Nav.Item>
              </Fragment>
            )}
          </Nav>
          <NavMenu
            onEligibility={!authenticated ? handleEnroll : undefined}
            onEnroll={authenticated ? handleEnroll : undefined}
            onLogin={!authenticated ? handleLogin : undefined}
          />
          <>
            {authenticated && (
              <StatusModal
                show={values.exists && !values.acknowledged}
                status={
                  USER_STATUS[user.status] === "EXISTS"
                    ? "EXISTS"
                    : "REDEMPTION"
                }
                message=""
                onCancel={() => {
                  return;
                }}
                onNext={handleSubmit}
              />
            )}
          </>
        </NavBar>
      </Route>
      {/* <Route exact path={["/fep-blue-focus-locations"]}>
        <div className="d-none d-sm-none d-md-block">
          <NavBar logo>
            <NavBranding provider={tags["bcbsa"]} alt="BCBSA" />
          </NavBar>
        </div>
      </Route> */}
      {/* setup routes */}
      <Switch>
        <Route exact path={`/`}>
          <Landing
            onOpen={handleOpen}
            tags={tags["bcbsa"] + (authenticated ? "Authenticated" : "")}
          />
        </Route>
        <Route exact path={`/how-it-works`}>
          <HowItWorks
            tags={tags["bcbsa"] + (authenticated ? "Authenticated" : "")}
          />
        </Route>
        <Route exact path={`/faqs`}>
          <Fragment>
            <FAQ
              tags={tags["bcbsa"] + (authenticated ? "Authenticated" : "")}
            />
            <section id="legal">
              <div className="legal-footer">
                <PoweredBy />
              </div>
            </section>
          </Fragment>
        </Route>
        <Route exact path="/fep-blue-focus-locations">
          <PromotionalBanner tags={tags["fep"]} />
          <FEPLocations />
        </Route>
        <Route exact path="/agreement">
          <Container>
            <div style={{ padding: "24px" }}></div>
            <ProgramAgreement
              tags={tags["bcbsa"] + (authenticated ? "Authenticated" : "")}
            />
          </Container>
        </Route>
      </Switch>
    </Fragment>
  );
};

export default BcbsaRouting;
