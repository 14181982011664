import React, { useContext } from "react";
import { ClientConfigContext } from "../../context/ClientConfigProvider";

const PoweredBy = () => {
  const { client } = useContext(ClientConfigContext);
  return (
    <div className="footer-main">
      <p>Powered by Tivity Health</p>
      <p>
        <a href="/terms" rel="noreferrer" target="_blank" className="a-link">
          <b>Terms of Use</b>
        </a>{" "}
        |{" "}
        <a
          href="/privacy-policy"
          rel="noreferrer"
          target="_blank"
          className="a-link"
        >
          <b>Privacy Policy</b>
        </a>{" "}
        |{" "}
        <a
          href="https://submit-irm.trustarc.com/services/validation/55050fa3-172f-4f69-be04-985bf0513e66/?source=fitnessyourway&referred_url=https://fitnessyourway.tivityhealth.com"
          target="_blank"
          className="a-link"
        >
          <b>Your Privacy Choices</b>
        </a>{" "}
        |{" "}
        <a
          href="/privacy-policy#information_we_collect_and_how_we_use_personal_information"
          target="_blank"
          className="a-link"
        >
          <b>Notice of Collection (CA)</b>
        </a>
      </p>
      <p>Copyright 2022 Tivity Health, Inc. All rights reserved.</p>
      {client === "bcbsa" && (
        <p>
          WholeHealth Living Choices is a registered trademark of Tivity Health,
          Inc. The WholeHealth Living Choices logotype is a trademark of Tivity
          Health, Inc.
        </p>
      )}
    </div>
  );
};

export default PoweredBy;
