import React, { useContext } from "react";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { useTheme } from "react-jss";
import FEPItemDetail from "./ItemDetail/FEPItemDetails";
import Item from "./Item";
import useStyles from "./styles";
import { LocationsContext } from "../../../../context/LocationsProvider";

// Results
const SearchContent = ({ style, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() });
  const { locations, locationDetail } = useContext(LocationsContext);

  const rowCache = new CellMeasurerCache({
    defaultHeight: 75, // At full width, this is almost always the height
    fixedWidth: true,
  });

  // Renderer for React-Virtualized List Items
  const renderItems = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    parent, // Parent Element
    // isScrolling, // The List is currently being scrolled
    // isVisible, // This row is visible within the List (eg it is not an overscanned row)
    // eslint-disable-next-line no-shadow
    style, // Style object to be applied to row (to position it)
  }) => {
    return (
      <CellMeasurer
        cache={rowCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <Item location={locations[index]} key={key} style={style} />
      </CellMeasurer>
    );
  };

  // Results Pane
  return (
    <div className={classes.paneContainer} style={style}>
      {Object.keys(locationDetail).length ? (
        // Selected Location/Event
        <FEPItemDetail />
      ) : (
        // Search Results
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              rowCount={locations.length}
              rowHeight={rowCache.rowHeight}
              rowRenderer={renderItems}
              overscanRowCount={10}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

export default SearchContent;
