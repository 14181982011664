import React from "react";
import { Button } from "react-bootstrap";
import Logo from "../resources/FitnessYourWay_4c.png";
import NotFoundImg from "../resources/404NotFound.svg";
import { useHistory } from "react-router-dom";
const NotFound = () => {
  const history = useHistory();
  return (
    <div className="not-found-container">
      <div className="not-found-container__body">
        <img
          alt="Fitness Your Way"
          src={Logo}
          width="180"
          className="d-inline-block align-top mb-4"
        />
        <img src={NotFoundImg} alt="Migrate" className="mw-100" />
        <span className="not-found-container__body__header">
          We couldn&apos;t find what you are looking for
        </span>

        <p>
          Sorry, this page could not be found at this time. Please click the
          button below to return to your account page.
        </p>
        <Button
          variant="primary"
          className="primary nav-btn-enroll font-weight-bold"
          style={{ whiteSpace: "nowrap" }}
          onClick={() => history.push("/account")}
        >
          <span className="not-found-container__body__button">My Account</span>
        </Button>
        <p>
          If this problem persists, please call{" "}
          <a href={`tel:1-833-277-6863`} className="a-link">1-833-277-6863</a> ,<br />
          Monday – Friday, 7 a.m. – 7 p.m., CT (6 a.m. – 6 p.m. MT)
        </p>
      </div>
    </div>
  );
};

export default NotFound;
