import { EntryCollection } from "contentful";
import React, { useEffect, useState } from "react";
import { Popover } from "react-bootstrap";
import { createPortal } from "react-dom";
import { ICardPlaceholderFields } from "../../../@types/generated/contentful";
import { client } from "../../../client";

const CardImagePortals = ({
  nodes,
  tags,
}: {
  nodes: (Element | null)[];
  tags: string;
}) => {
  const [content, setContent] =
    useState<EntryCollection<ICardPlaceholderFields> | null>(null);

  useEffect(() => {
    (async () => {
      const entry = await client.getEntries<ICardPlaceholderFields>({
        content_type: "cardPlaceholder",
        "metadata.tags.sys.id[all]": tags,
        include: 10,
      });
      setContent(entry);
    })();
  }, [tags]);

  const GetImage = ({ index }: { index: number }) => {
    if (content && index > content.items.length - 1) {
      return null;
    }
    return (
      content && (
        <Popover id={`popover-card-${index}`}>
          <Popover.Content>
            <div>
              <img
                src={
                  content.items[index]
                    ? content?.items[index].fields.image.fields.file.url
                    : ""
                }
                alt="card"
              />
            </div>
          </Popover.Content>
        </Popover>
      )
    );
  };
  return (
    nodes && (
      <>
        {nodes.map((n, i) =>
          n ? createPortal(<GetImage index={i} />, n) : null
        )}
      </>
    )
  );
};

export default CardImagePortals;
