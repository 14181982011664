import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { SetNewHomeGym } from "../../../api/enrollment/Queries";
import { getHomeGymDetails } from "../../../api/platform/Queries";
import { HomeGymDetails } from "../Tiles/HomeGymTile";
import moment from "moment";

const HomeGymIcon = () => {
  return (
    <svg
      style={{ width: "40px", margin: "-6px 5px 0 0" }}
      viewBox="0 0 782.208 752"
    >
      <path
        id="Combined_Shape"
        data-name="Combined Shape"
        d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
        fill="#072c4c"
      />
    </svg>
  );
};

const CheckCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="currentColor"
      className="check-circle-fill"
      viewBox="0 0 16 16"
    >
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg>
  );
};

const SetChangeHomeGym = ({
  showChangeModal,
  setChangeShowModal,
  locationId,
  locationName,
  locationAddress,
  oldLocationId,
}: {
  showChangeModal: boolean;
  setChangeShowModal: (val: boolean) => void;
  locationId: string;
  locationName: string;
  locationAddress: string;
  oldLocationId: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [homeGymDetails, setHomeGymDetails] = useState<HomeGymDetails | null>(
    null
  );

  useEffect(() => {
    (async () => {
      try {
        if (locationId) {
          const location = await getHomeGymDetails(oldLocationId);
          setIsLoading(true);
          setHomeGymDetails(location);
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        throw new Error("Unable to fetch home gym");
      }
    })();
  }, []);

  const handleSetHomeGym = async () => {
    try {
      setIsLoading(true);
      await SetNewHomeGym(locationId);
      setIsLoading(false);
      setShowConfirm(true);
    } catch {
      setIsLoading(false);
      throw new Error("Error setting home gym");
    }
  };
  return (
    <Modal show={showChangeModal} centered>
      <div className="setHomeGymModal">
        <Modal.Title>
          <h2>Update Home Gym</h2>
        </Modal.Title>
        {isLoading ? (
          <>
            <div className="center mb-4">
              <Spinner animation="border" />
            </div>
            <div className="d-flex justify-content-center">
              <p>Changing your home gym</p>
            </div>
          </>
        ) : (
          <>
            {showConfirm ? (
              <>
                <Modal.Body>
                  <div className="center">
                    <CheckCircle />
                    <p className="mt-4">
                      <b>Home Gym Set!</b>
                    </p>
                    <p className="mt-3">
                      You can visit location beginning the first of next month.
                      Be sure to bring your member ID card with you and present
                      to the front desk when you arrive.
                    </p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
                    <button
                      onClick={() => setChangeShowModal(false)}
                      className="col btn btn-secondary ml-2"
                    >
                      Close
                    </button>
                  </div>
                </Modal.Footer>
              </>
            ) : (
              <>
                <Modal.Body>
                  <div className="setHomeGymModal__details">
                    <p className="setHomeGymModal__title m-0">
                      {homeGymDetails?.name}
                    </p>
                    <p className="setHomeGymModal__subtitle m-0">
                      {homeGymDetails?.address}
                    </p>
                  </div>
                  <div className="d-flex justify-content-center my-3">
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      style={{ color: "orange" }}
                    />
                  </div>
                  <div className="setHomeGymModal__details">
                    <HomeGymIcon />
                    <p className="setHomeGymModal__title m-0">{locationName}</p>
                    <p className="setHomeGymModal__subtitle m-0">
                      {locationAddress}
                    </p>
                    <p className="setHomeGymModal__subtitle mt-1">
                      <b>Access Date</b>
                      <br />
                      {moment()
                        .startOf("month")
                        .add(1, "month")
                        .format("dddd, MMMM D, YYYY")}
                    </p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
                    <button
                      onClick={() => setChangeShowModal(false)}
                      className="col btn btn-outline-primary ml-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleSetHomeGym()}
                      className="col mx-2 btn btn-secondary "
                    >
                      Set As Home Gym
                    </button>
                  </div>
                </Modal.Footer>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default SetChangeHomeGym;
